import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, MenuItem, Select } from '@material-ui/core';
import { dateDiffMonths2 } from '../../util/dateUtil';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
    marginTop: '25px',
  },
  sectionHeader: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    fontWeight: 600,
  },
  subSectionHeader: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
  },
  groupHeader: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  spacer: {
    marginTop: '30px',
  },
}));

export default function Scoring(props) {
  const {
    gradingGPA,
    setGradingGPA,
    gradingFinancialNeed,
    setGradingFinancialNeed,
    gradingLengthOfStay,
    setGradingLengthOfStay,
    gradingEssayVoice,
    setGradingEssayVoice,
    gradingEssayInspiration,
    setGradingEssayInspiration,
    gradingEssayLanguage,
    setGradingEssayLanguage,
    gradingEssayCompleted,
    setGradingEssayCompleted,
    gradingCitizenshipLetters,
    setGradingCitizenshipLetter,
    gradingCitizenshipActivities,
    setGradingCitizenshipActivities,
    gradingCitizenshipRoleModel,
    setGradingCitizenshipRoleModel,
    disabled,
    senior,
    applicant_type,
  } = props;

  const classes = styles();

  const todaysDate = new Date();

  const calculatePointsForGPA = () => {
    let score = 0;
    const gpa = props.senior.gpa || 0;
    if (gpa) {
      if (gpa > 3.25) {
        score = 40;
        setGradingGPA(score);
        return score;
      }
      if (gpa > 2.5) {
        score = 30;
        setGradingGPA(score);
        return score;
      }
      if (gpa > 2) {
        score = 20;
        setGradingGPA(score);
        return score;
      }
      score = 0;
      setGradingGPA(score);
      return score;
    }
  };

  const calculatePointsForLengthOfStay = () => {
    let score = 0;
    const lengthOfStay =
      senior.departure_date > /Date(0)/
        ? dateDiffMonths2(props.senior.departure_date, props.senior.admission_date)
        : dateDiffMonths2(todaysDate, props.senior.admission_date);

    if (lengthOfStay) {
      if (lengthOfStay > 24) {
        score = 20;
        setGradingLengthOfStay(score);
        return score;
      }
      if (lengthOfStay > 12) {
        score = 15;
        setGradingLengthOfStay(score);
        return score;
      }
      if (lengthOfStay > 6) {
        score = 10;
        setGradingLengthOfStay(score);
        return score;
      }
      score = 5;
      setGradingLengthOfStay(score);
      return score;
    }
  };

  return (
    <>
      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} align="left" className={classes.sectionSpacer}>
        <label className={classes.sectionHeader}>Scoring</label>
      </Grid>
      {applicant_type === 'senior' && (
        <>
          <Grid item xs={12} align="left"></Grid>
          <Grid item xs={12} align="left">
            <label className={classes.subSectionHeader}>Academics (40 points)</label>
          </Grid>
          <Grid item xs={12} align="left"></Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
            <label>GPA</label>
          </Grid>
          <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
            {calculatePointsForGPA()}
          </Grid>
          <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
            <label className={classes.subSectionHeader}>Academics Score</label>
          </Grid>
          <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
            {gradingGPA}
          </Grid>
          <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
        </>
      )}

      <Grid item xs={12} align="left">
        <label className={classes.subSectionHeader}>Financial Need (20 points)</label>
      </Grid>
      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Student's Financial Needs Covered:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="financial-needs-covered-label"
            id="financial-needs-covered"
            value={gradingFinancialNeed}
            onChange={(event) => setGradingFinancialNeed(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={0}>76% - 100% of student's financial needs are covered</MenuItem>
            <MenuItem value={10}>51% - 75% of student's financial needs are covered</MenuItem>
            <MenuItem value={15}>26% - 50% of student's financial needs are covered</MenuItem>
            <MenuItem value={20}>0% - 25% of student's financial needs are covered</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label className={classes.subSectionHeader}>Financial Need Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {gradingFinancialNeed < 0 ? 0 : gradingFinancialNeed}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label className={classes.subSectionHeader}>Boys Town (40 points)</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Length of Stay</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        {calculatePointsForLengthOfStay()}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label className={classes.groupHeader}>Essay / Personal Statement (10 points)</label>
      </Grid>
      <Grid item xs={12} align="left"></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Purpose and Voice:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="essay-voice-label"
            id="essay-voice"
            value={gradingEssayVoice}
            onChange={(event) => setGradingEssayVoice(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={3}>
              Student’s purpose and voice is expressive with clear narrative. Student has done an outstanding job engaging the audience.
            </MenuItem>
            <MenuItem value={2}>Student’s purpose and voice is expressive with clear narrative.</MenuItem>
            <MenuItem value={1}>Student’s purpose and voice is inconsistent and oesn’t have a clear conception of audience.</MenuItem>
            <MenuItem value={0}>Student’s voice is almost completely missing and makes little attempt to engage the audience.</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Inspiration:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="essay-inspiration-label"
            id="essay-inspiration"
            value={gradingEssayInspiration}
            onChange={(event) => setGradingEssayInspiration(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={3}>
              Student tells the story in a way that makes people want to root for them. The student shows they have overcome obstacles and inspires
              others to be better.
            </MenuItem>
            <MenuItem value={2}>
              Student tells the story in a way that makes people know that they have overcome obstacles or makes others want to be better.
            </MenuItem>
            <MenuItem value={1}>Student is trying to tell a story of overcoming obstacles, but is inconsistent and unclear.</MenuItem>
            <MenuItem value={0}>Student does not share any information that appears as overcoming obstacles or as an inspiration to others.</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Language and Word Choice:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="essay-word-label"
            id="essay-word"
            value={gradingEssayLanguage}
            onChange={(event) => setGradingEssayLanguage(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={3}>
              Student’s writing is clear and word choice is easily understood. May have used a few above average words/phrases.
            </MenuItem>
            <MenuItem value={2}>Student’s writing is mostly clear and understood.</MenuItem>
            <MenuItem value={1}>
              Student’s writing uses several words or phrases incorrectly or out of context, but the point is still apparent.
            </MenuItem>
            <MenuItem value={0}>Student uses profanity, has not used spell check, and has used multiple words and phrases incorrectly.</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Completed</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="essay-complete-label"
            id="essay-complete"
            value={gradingEssayCompleted}
            onChange={(event) => setGradingEssayCompleted(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={1}>Essay was complete.</MenuItem>
            <MenuItem value={0}>Essay was incomplete.</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label className={classes.groupHeader}>Essay / Personal Statement Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(gradingEssayVoice && gradingEssayVoice >= 0 ? gradingEssayVoice : 0) +
          (gradingEssayInspiration && gradingEssayInspiration >= 0 ? gradingEssayInspiration : 0) +
          (gradingEssayLanguage && gradingEssayLanguage >= 0 ? gradingEssayLanguage : 0) +
          (gradingEssayCompleted && gradingEssayCompleted >= 0 ? gradingEssayCompleted : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label className={classes.groupHeader}>Citizenship (10 points)</label>
      </Grid>
      <Grid item xs={12} align="left"></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Recommendation Letters:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="citizenship-letters-label"
            id="citizenship-letters"
            value={gradingCitizenshipLetters}
            onChange={(event) => setGradingCitizenshipLetter(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={4}>
              Student received recommendation letters from two teachers, family teachers, assistant family teachers, or peers.
            </MenuItem>
            <MenuItem value={2}>Student received one letter of recommendation.</MenuItem>
            <MenuItem value={0}>Student did not receive any letters of recommendation.</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Extracurricular Activities:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="citizenship-activities-label"
            id="citizenship-activities"
            value={gradingCitizenshipActivities}
            onChange={(event) => setGradingCitizenshipActivities(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={3}>
              Student is involved in two or more extracurricular activities or holds a leadership role in one extracurricular activity and attends
              peers' extracurricular activities and cheers for them.
            </MenuItem>
            <MenuItem value={2}>
              Student is involved in one extracurricular activity and attends peers' extracurriculars and cheers for them.
            </MenuItem>
            <MenuItem value={1}>Student attends peers' extracurricular activities.</MenuItem>
            <MenuItem value={0}>Student not participate in extracurricular activities nor attends peers' extracurricular activities.</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Positive Role Model:</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="citizenship-rolemodel-label"
            id="citizenship-rolemodel"
            value={gradingCitizenshipRoleModel}
            onChange={(event) => setGradingCitizenshipRoleModel(event.target.value)}
            disabled={disabled}
          >
            <MenuItem value={-1} disabled>
              Select Grade
            </MenuItem>
            <MenuItem value={3}>
              Student engages in positive behaviors and encourages peers and younger youth to make good decisions. Assists within the home and
              community without prompting.
            </MenuItem>
            <MenuItem value={2}>
              Student engages in mostly positive behaviors and encourages peers and younger youth occasionally. Assists in home and community with
              prompting.
            </MenuItem>
            <MenuItem value={1}>
              Student struggles with behaviors and peer relationships but is redirectable. Assists in the home and community after more than one
              prompt.
            </MenuItem>
            <MenuItem value={0}>
              Student does not engage in positive behavior. Student does not assist in the home or community even when prompted.
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label className={classes.groupHeader}>Citizenship Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(gradingCitizenshipLetters && gradingCitizenshipLetters >= 0 ? gradingCitizenshipLetters : 0) +
          (gradingCitizenshipActivities && gradingCitizenshipActivities >= 0 ? gradingCitizenshipActivities : 0) +
          (gradingCitizenshipRoleModel && gradingCitizenshipRoleModel >= 0 ? gradingCitizenshipRoleModel : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label className={classes.subSectionHeader}>Boys Town Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(gradingLengthOfStay && gradingLengthOfStay >= 0 ? gradingLengthOfStay : 0) +
          (gradingEssayVoice && gradingEssayVoice >= 0 ? gradingEssayVoice : 0) +
          (gradingEssayInspiration && gradingEssayInspiration >= 0 ? gradingEssayInspiration : 0) +
          (gradingEssayLanguage && gradingEssayLanguage >= 0 ? gradingEssayLanguage : 0) +
          (gradingEssayCompleted && gradingEssayCompleted >= 0 ? gradingEssayCompleted : 0) +
          (gradingCitizenshipLetters && gradingCitizenshipLetters >= 0 ? gradingCitizenshipLetters : 0) +
          (gradingCitizenshipActivities && gradingCitizenshipActivities >= 0 ? gradingCitizenshipActivities : 0) +
          (gradingCitizenshipRoleModel && gradingCitizenshipRoleModel >= 0 ? gradingCitizenshipRoleModel : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label className={classes.subSectionHeader}>Overall Total Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        {(gradingGPA && gradingGPA >= 0 ? gradingGPA : 0) +
          (gradingFinancialNeed && gradingFinancialNeed >= 0 ? gradingFinancialNeed : 0) +
          (gradingLengthOfStay && gradingLengthOfStay >= 0 ? gradingLengthOfStay : 0) +
          (gradingEssayVoice && gradingEssayVoice >= 0 ? gradingEssayVoice : 0) +
          (gradingEssayInspiration && gradingEssayInspiration >= 0 ? gradingEssayInspiration : 0) +
          (gradingEssayLanguage && gradingEssayLanguage >= 0 ? gradingEssayLanguage : 0) +
          (gradingEssayCompleted && gradingEssayCompleted >= 0 ? gradingEssayCompleted : 0) +
          (gradingCitizenshipLetters && gradingCitizenshipLetters >= 0 ? gradingCitizenshipLetters : 0) +
          (gradingCitizenshipActivities && gradingCitizenshipActivities >= 0 ? gradingCitizenshipActivities : 0) +
          (gradingCitizenshipRoleModel && gradingCitizenshipRoleModel >= 0 ? gradingCitizenshipRoleModel : 0)}{' '}
        / {applicant_type === 'senior' ? 100 : 60}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
    </>
  );
}

Scoring.propTypes = {
  gradingGPA: PropTypes.number.isRequired,
  setGradingGPA: PropTypes.func.isRequired,
  gradingFinancialNeed: PropTypes.string.isRequired,
  setGradingFinancialNeed: PropTypes.func.isRequired,
  gradingLengthOfStay: PropTypes.number.isRequired,
  setGradingLengthOfStay: PropTypes.func.isRequired,
  gradingEssayVoice: PropTypes.string.isRequired,
  setGradingEssayVoice: PropTypes.func.isRequired,
  gradingEssayInspiration: PropTypes.string.isRequired,
  setGradingEssayInspiration: PropTypes.func.isRequired,
  gradingEssayLanguage: PropTypes.string.isRequired,
  setGradingEssayLanguage: PropTypes.func.isRequired,
  gradingEssayCompleted: PropTypes.string.isRequired,
  setGradingEssayCompleted: PropTypes.func.isRequired,
  gradingCitizenshipLetters: PropTypes.string.isRequired,
  setGradingCitizenshipLetter: PropTypes.func.isRequired,
  gradingCitizenshipActivities: PropTypes.string.isRequired,
  setGradingCitizenshipActivities: PropTypes.func.isRequired,
  gradingCitizenshipRoleModel: PropTypes.string.isRequired,
  setGradingCitizenshipRoleModel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  senior: PropTypes.object.isRequired,
  applicant_type: PropTypes.string.isRequired,
};
