import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScholarshipApplicationCard from './ScholarshipApplicationCard';

const ScholarshipApplicationCards = ({ list, open, showAwards, displayStatus, displayApplicantName }) => {
  return (
    <>
      <Grid container direction="row">
        {list &&
          list.map((r) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={r.id}>
                <ScholarshipApplicationCard
                  app={r}
                  open={open}
                  showAwards={showAwards}
                  displayStatus={displayStatus}
                  displayApplicantName={displayApplicantName}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

ScholarshipApplicationCards.propTypes = {
  list: PropTypes.array.isRequired,
  open: PropTypes.func.isRequired,
  showAwards: PropTypes.func.isRequired,
  displayStatus: PropTypes.func.isRequired,
  displayApplicantName: PropTypes.func.isRequired,
};

export default ScholarshipApplicationCards;
