import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '../CustomButtons/Button.js';
import { observer } from 'mobx-react';
import { blackColor, whiteColor } from '../../assets/jss/nextjs-material-kit-pro';

const formStyles = makeStyles({
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  dialog: {
    width: 500,
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: '30px',
  },
  buttonWidth: {
    width: '110px',
  },
});

const HelpDialog = observer((props) => {
  const { title, content, showHelp, handleClose } = props;

  const formClasses = formStyles();

  return (
    <React.Fragment>
      <Dialog open={showHelp} onClose={handleClose} scroll="paper" aria-labelledby="Pop-up Help">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeaderFooter}>
            {title}
          </DialogTitle>
          <DialogContent className={formClasses.dialog} style={{ minHeight: 250 }}>
            <Box sx={{ width: '100%' }}>
              <Grid container direction="row">
                <Grid item xs={12} className={formClasses.rowSpacer}>
                  {content}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </React.Fragment>
        <DialogActions className={formClasses.dialogHeaderFooter}>
          <Button className={formClasses.buttonWidth} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default HelpDialog;

HelpDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.elementType,
  showHelp: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
