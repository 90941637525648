import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Grid, FormControl, FormControlLabel, Hidden, TextField } from '@material-ui/core';
import { btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  checklistRow: {
    margin: '10px 0',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checklistColumn: {
    alignItems: 'center',
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  pointsLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  dueLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    verticalAlign: 'top',
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,

    marginTop: 5,
  },
  underline: {
    margin: '5px 0',
    borderBottom: '1px solid #000',
  },
  checkOutline: {
    border: '1px solid #000',
    borderRadius: 6,
    maxWidth: '35px',
    alignContent: 'center',
    minWidth: '35px',
  },
}));

export default function GeneralItems(props) {
  const { form, setForm, auth, emailAddress, alumni } = props;

  const classes = styles();

  const isFieldDisabled = (val) => {
    if (alumni) return true;
    return false;
  };

  const isConfirmDisabled = (val) => {
    if (alumni || auth.roles.includes('STUDENT')) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="1. Create a safe, organized system for all important documents, username/password information, and any other notes/information that will
              become an important part of your senior plan."
              disabled={isFieldDisabled(form.confirmCreateSystem)}
              checked={form.createSystem}
              onChange={(event) => setForm({ ...form, createSystem: !form.createSystem })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.createSystem)}
            checked={form.confirmCreateSystem}
            onChange={(event) => setForm({ ...form, confirmCreateSystem: !form.confirmCreateSystem })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="1. Create a safe, organized system for all important documents, username/password information, and any other notes/information that will
              become an important part of your senior plan."
              checked={form.createSystem}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmCreateSystem}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="2. Verify your email address is appropriate and that it is stored, along with your password, in your senior binder. Create and access the email account with a trusted adult."
              disabled={isFieldDisabled(form.confirmCreateEmail)}
              checked={form.createEmail}
              onChange={(event) => setForm({ ...form, createEmail: !form.createEmail })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.createEmail)}
            checked={form.confirmCreateEmail}
            onChange={(event) => setForm({ ...form, confirmCreateEmail: !form.confirmCreateEmail })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ margin: '10px 0 5px 50px' }}>
        <Grid item xs={9}>
          <label>Email Address: {emailAddress}</label>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="2. Verify your email address is appropriate and that it is stored, along with your password, in your senior binder. Create and access the email account with a trusted adult."
              checked={form.createEmail}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmCreateEmail}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
        {form.createEmail && (
          <React.Fragment>
            <Grid container direction="row" style={{ margin: '5px 0 25px 30px' }}>
              <Grid item xs={2}>
                <label>Email Address:</label>
              </Grid>
              <Grid item xs={9} className={classes.subtleBold}>
                <summary>{form.emailAddress}</summary>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="3. Successfully log into the Successful Futures website."
              disabled={isFieldDisabled(form.confirmLoginToWeb)}
              checked={form.loginToWeb}
              onChange={(event) => setForm({ ...form, loginToWeb: !form.loginToWeb })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.loginToWeb)}
            checked={form.confirmLoginToWeb}
            onChange={(event) => setForm({ ...form, confirmLoginToWeb: !form.confirmLoginToWeb })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="3. 3. Successfully log into the Successful Futures website."
              checked={form.loginToWeb}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmLoginToWeb}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="4. Obtain original copies of official documents, such as birth certificate, social security card, immunization records, school
              transcripts, etc."
              disabled={isFieldDisabled(form.confirmObtainCopies)}
              checked={form.obtainCopies}
              onChange={(event) => setForm({ ...form, obtainCopies: !form.obtainCopies })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>5</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.obtainCopies)}
            checked={form.confirmObtainCopies}
            onChange={(event) => setForm({ ...form, confirmObtainCopies: !form.confirmObtainCopies })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="4. Obtain original copies of official documents, such as birth certificate, social security card, immunization records, school
              transcripts, etc."
              checked={form.obtainCopies}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            5<Checkbox color="default" disabled={true} checked={form.confirmObtainCopies}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="5. Obtain your driver's (learner) permit."
              disabled={isFieldDisabled(form.confirmObtainPermit)}
              checked={form.obtainPermit}
              onChange={(event) => setForm({ ...form, obtainPermit: !form.obtainPermit })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} spacing={3} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" spacing={2} className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.obtainPermit)}
            checked={form.confirmObtainPermit}
            onChange={(event) => setForm({ ...form, confirmObtainPermit: !form.confirmObtainPermit })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="5. Obtain your driver's (learner) permit."
              checked={form.obtainPermit}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmObtainPermit}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="6. Register for a Driver's Education course."
              disabled={isFieldDisabled(form.confirmRegisterDriversEd)}
              checked={form.registerDriversEd}
              onChange={(event) => setForm({ ...form, registerDriversEd: !form.registerDriversEd })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.registerDriversEd)}
            checked={form.confirmRegisterDriversEd}
            onChange={(event) => setForm({ ...form, confirmRegisterDriversEd: !form.confirmRegisterDriversEd })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="6. Register for a Driver's Education course."
              checked={form.registerDriversEd}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmRegisterDriversEd}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="7. Look for opportunities where you can volunteer or join community groups after graduation."
              disabled={isFieldDisabled(form.confirmVolunteerOpportunities)}
              checked={form.volunteerOpportunities}
              onChange={(event) => setForm({ ...form, volunteerOpportunities: !form.volunteerOpportunities })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>5</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.volunteerOpportunities)}
            checked={form.confirmVolunteerOpportunities}
            onChange={(event) => setForm({ ...form, confirmVolunteerOpportunities: !form.confirmVolunteerOpportunities })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="7. Look for opportunities where you can volunteer or join community groups after graduation."
              checked={form.volunteerOpportunities}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            5<Checkbox color="default" disabled={true} checked={form.confirmVolunteerOpportunities}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" />}
              label="8. Clean up your social media accounts."
              disabled={isFieldDisabled(form.confirmCleanUpSocialMedia)}
              checked={form.cleanUpSocialMedia}
              onChange={(event) => setForm({ ...form, cleanUpSocialMedia: !form.cleanUpSocialMedia })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.cleanUpSocialMedia)}
            checked={form.confirmCleanUpSocialMedia}
            onChange={(event) => setForm({ ...form, confirmCleanUpSocialMedia: !form.confirmCleanUpSocialMedia })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="8. Clean up your social media accounts."
              checked={form.cleanUpSocialMedia}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmCleanUpSocialMedia}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="9. Complete Driver's Education course."
              disabled={isFieldDisabled(form.confirmCompleteDriversEd)}
              checked={form.completeDriversEd}
              onChange={(event) => setForm({ ...form, completeDriversEd: !form.completeDriversEd })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>5</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.completeDriversEd)}
            checked={form.confirmCompleteDriversEd}
            onChange={(event) => setForm({ ...form, confirmCompleteDriversEd: !form.confirmCompleteDriversEd })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="9. Complete Driver's Education course."
              checked={form.completeDriversEd}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            5<Checkbox color="default" disabled={true} checked={form.confirmCompleteDriversEd}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="10. Obtain a Driver's License or State ID."
              disabled={isFieldDisabled(form.confirmObtainDriversLicense)}
              checked={form.obtainDriversLicense}
              onChange={(event) => setForm({ ...form, obtainDriversLicense: !form.obtainDriversLicense })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>5</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.obtainDriversLicense)}
            checked={form.confirmObtainDriversLicense}
            onChange={(event) => setForm({ ...form, confirmObtainDriversLicense: !form.confirmObtainDriversLicense })}
          ></Checkbox>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="10. Obtain a Driver's License or State ID."
              checked={form.obtainDriversLicense}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            5<Checkbox color="default" disabled={true} checked={form.confirmObtainDriversLicense}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={5} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="11. Explore and identify where you will live after graduation."
              disabled={isFieldDisabled(form.confirmLiveAfterGraduation)}
              checked={form.liveAfterGraduation}
              onChange={(event) => setForm({ ...form, liveAfterGraduation: !form.liveAfterGraduation })}
            />
          </FormControl>
        </Grid>
        <Hidden lgUp>
          {/* Space to fill up line for smaller resolutions: */}
          <Grid item xs={1} sm={1} md={4} />
          <Grid item xs={false} sm={false} md={1} />
        </Hidden>
        <Grid item xs={7} sm={4} md={3} lg={2} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="live-after-graduation"
              size="small"
              fullWidth
              label="I will live:"
              variant="outlined"
              value={form.liveAfterGraduationResponse}
              onChange={(event) => setForm({ ...form, liveAfterGraduationResponse: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              className={classes.root}
              disabled={isFieldDisabled(form.liveAfterGraduationResponse)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={7} md={4} lg={1}></Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>April - May</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.liveAfterGraduation)}
            checked={form.confirmLiveAfterGraduation}
            onChange={(event) => setForm({ ...form, confirmLiveAfterGraduation: !form.confirmLiveAfterGraduation })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="11. Explore and identify where you will live after graduation."
              checked={form.liveAfterGraduation}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} className={classes.textRowSpacer}></Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmLiveAfterGraduation}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>April - May</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="12. Discuss your transportation needs. Identify options for transportation, if needed."
              disabled={isFieldDisabled(form.confirmDiscussTransportationNeeds)}
              checked={form.discussTransportationNeeds}
              onChange={(event) => setForm({ ...form, discussTransportationNeeds: !form.discussTransportationNeeds })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.discussTransportationNeeds)}
            checked={form.confirmDiscussTransportationNeeds}
            onChange={(event) => setForm({ ...form, confirmDiscussTransportationNeeds: !form.confirmDiscussTransportationNeeds })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="12. Discuss your transportation needs. Identify options for transportation, if needed."
              checked={form.discussTransportationNeeds}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmDiscussTransportationNeeds}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="13. Complete the Boys Town scholarship application."
              disabled={isFieldDisabled(form.confirmBoysTownScholarship)}
              checked={form.boysTownScholarship}
              onChange={(event) => setForm({ ...form, boysTownScholarship: !form.boysTownScholarship })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.boysTownScholarship)}
            checked={form.confirmBoysTownScholarship}
            onChange={(event) => setForm({ ...form, confirmBoysTownScholarship: !form.confirmBoysTownScholarship })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="13. Complete the Boys Town scholarship application."
              checked={form.boysTownScholarship}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmBoysTownScholarship}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" className={classes.unprintedContent + ' ' + classes.checkListRow}>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="14. Complete the request for letters of recommendation for your scholarship application. (3)"
              disabled={isFieldDisabled(form.confirmRequestLettersofRecommendation)}
              checked={form.requestLettersofRecommendation}
              onChange={(event) => setForm({ ...form, requestLettersofRecommendation: !form.requestLettersofRecommendation })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.requestLettersofRecommendation)}
            checked={form.confirmRequestLettersofRecommendation}
            onChange={(event) => setForm({ ...form, confirmRequestLettersofRecommendation: !form.confirmRequestLettersofRecommendation })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="14. Complete the request for letters of recommendation for your scholarship application. (3)"
              checked={form.requestLettersofRecommendation}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmRequestLettersofRecommendation}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

GeneralItems.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  auth: PropTypes.object,
  emailAddress: PropTypes.string,
  alumni: PropTypes.bool.isRequired,
};
