import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import classNames from 'classnames';
import { loadCSS } from 'fg-loadcss';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, List, ListItem, Button } from '@material-ui/core';
import ActivityIcon from '@material-ui/icons/EventNote';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import CustomDropdown from '../CustomDropdown/CustomDropdown.js';
import styles from '../../assets/jss/nextjs-material-kit-pro/components/headerLinksStyle.js';
import { msalConfig } from '../../services/msal';
import PleaseWait from './PleaseWait';
import { logoutGoogle } from '../../util/apiWrapper.js';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(styles);

const SignInSignOutItem = (props) => {
  const authStore = props.authStore;
  const { inProgress, instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const classes = useStyles();

  const handleSignIn = () => {
    // instance.loginRedirect(msalConfig.loginRequest);
    instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
    // const account = accounts[0] || null;
    // authStore.handleUserLogin(account);
  };

  const handleSignOut = async () => {
    await logoutGoogle(authStore);
    authStore.setNotAuthorized(false);
    // await instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
    await instance.logoutRedirect({ postLogoutRedirectUri: '/home' });
  };

  if (isAuthenticated) {
    return (
      <Button
        size="small"
        variant="text"
        className={classes.dropdownLink}
        style={{ marginBottom: '10px' }}
        startIcon={<Icon className={classNames(classes.dropdownIcons, 'fa', 'fa-sign-out-alt')} />}
        onClick={handleSignOut}
      >
        Sign Out
      </Button>
    );
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    return (
      <Button
        size="small"
        variant="text"
        className={classes.dropdownLink}
        style={{ marginBottom: '10px' }}
        startIcon={<Icon className={classNames(classes.dropdownIcons, 'fa', 'fa-sign-in-alt')} />}
        onClick={handleSignIn}
      >
        Sign In
      </Button>
    );
  } else {
    return null;
  }
};

const MenuItems = inject('AuthStore')(
  observer((props) => {
    const authStore = props.authStore;
    const isAuthenticated = useIsAuthenticated();
    const classes = useStyles();
    const { dropdownHoverColor } = props;
    const [isLoading, setIsLoading] = useState(false);
    const isStudent = authStore && authStore.roles && authStore.roles.includes('STUDENT');
    const isAlumni = authStore && authStore.roles && authStore.roles.includes('ALUMNI');
    const history = useHistory();

    useEffect(() => {
      setIsLoading(true);
      loadCSS('https://use.fontawesome.com/releases/v5.12.0/css/all.css', document.querySelector('#font-awesome-css'));
      setIsLoading(false);
    }, []);

    const clearSeniorList = () => {
      window.localStorage.removeItem('SeniorListIndexStore');
      history.push('/seniorlist');
    };

    const clearAlumniList = () => {
      window.localStorage.removeItem('SeniorListIndexStore');
      history.push('/alumnilist');
    };

    const clearAccessList = () => {
      window.localStorage.removeItem('SeniorListIndexStore');
      history.push('/accessrequestlist');
    };

    const clearAftercareList = () => {
      window.localStorage.removeItem('SeniorListIndexStore');
      history.push('/aftercarelist');
    };

    const clearScholarshipList = () => {
      window.localStorage.removeItem('SeniorListIndexStore');
      history.push('/scholarshiplist');
    };

    const seniorList = (
      <a onClick={clearSeniorList} className={classes.dropdownLink}>
        <ActivityIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>High School Senior List</span>
      </a>
    );

    const alumniList = (
      <a onClick={clearAlumniList} className={classes.dropdownLink}>
        <ActivityIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Alumni List</span>
      </a>
    );

    const onboardingList = (
      <a onClick={clearAccessList} className={classes.dropdownLink}>
        <ListIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Onboarding Request List</span>
      </a>
    );

    const aftercareList = (
      <a onClick={clearAftercareList} className={classes.dropdownLink}>
        <ListIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Aftercare Applications List</span>
      </a>
    );

    const scholarshipList = (
      <a onClick={clearScholarshipList} className={classes.dropdownLink}>
        <ListIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Scholarship Applications List</span>
      </a>
    );

    const helprequestList = (
      <a href="/helprequestlist" className={classes.dropdownLink}>
        <ListIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Help Request List</span>
      </a>
    );

    const checkinsList = (
      <a href="/contactlist" className={classes.dropdownLink}>
        <ListIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Proactive Check-ins List</span>
      </a>
    );

    const reportMenu = (
      <a href="/reports" className={classes.dropdownLink}>
        <DescriptionIcon className={classes.dropdownIcons} />
        <span style={{ marginLeft: 6, marginTop: 4 }}>Reports</span>
      </a>
    );

    const menuItems = isAuthenticated
      ? isStudent || isAlumni
        ? [<SignInSignOutItem authStore={authStore} />]
        : [
            seniorList,
            alumniList,
            aftercareList,
            scholarshipList,
            helprequestList,
            checkinsList,
            reportMenu,
            <SignInSignOutItem authStore={authStore} />,
          ]
      : [<SignInSignOutItem authStore={authStore} />];

    return (
      <>
        <PleaseWait isLoading={isLoading} />
        {isAuthenticated && authStore.notAuthorized === false && (
          <List className={classes.list + ' ' + classes.mlAuto}>
            <ListItem className={classes.listItem}>
              <Hidden mdDown implementation="css">
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonText="Menu"
                  buttonProps={{
                    className: classes.navLink,
                    color: 'transparent',
                  }}
                  buttonIcon={MenuIcon}
                  dropdownList={menuItems}
                />
              </Hidden>
              <Hidden lgUp>{menuItems}</Hidden>
            </ListItem>
          </List>
        )}
      </>
    );
  })
);

export default MenuItems;
