import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, Hidden, MenuItem, Select, TableContainer, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { DateTime } from 'luxon';
import { dateToS, dateDiffYearsMonths } from '../../../util/dateUtil';

const AwardHeaderComponent = ({
  formClasses,
  tableClasses,
  application,
  senior,
  updateApplicationStatus,
  awardPendingTotal,
  awardAwardedTotal,
  awardTotal,
  seniorPlanningScore,
  parent,
}) => {
  const todaysDate = new Date();

  const calculateLOS = (departureDate, admissionDate) => {
    let rc = '';
    if (departureDate) {
      if (admissionDate) {
        if (senior.departure_date > /Date(0)/) {
          rc = dateDiffYearsMonths(
            DateTime.fromMillis(+senior.departure_date)
              .toJSDate()
              .getTime(),
            senior.admission_date
          );
        }
      } else {
        rc = '';
      }
    } else {
      rc = dateDiffYearsMonths(todaysDate, senior.admission_date);
    }
    return rc;
  };

  const calculateApplicationScore = () => {
    if (!application) {
      return 0;
    }
    if (
      (!application.essay_voice_score || application.essay_voice_score.length === 0) &&
      (!application.essay_inspiration_score || application.essay_inspiration_score.length === 0) &&
      (!application.essay_word_score || application.essay_word_score.length === 0) &&
      (!application.essay_completed_score || application.essay_completed_score.length === 0) &&
      (!application.citizenship_letters_score || application.citizenship_letters_score.length === 0) &&
      (!application.citizenship_activities_score || application.citizenship_activities_score.length === 0) &&
      (!application.citizenship_rolemodel_score || application.citizenship_rolemodel_score.length === 0) &&
      (!application.length_of_stay_score || application.length_of_stay_score.length === 0) &&
      (!application.gpa_score || application.gpa_score.length === 0) &&
      (!application.financial_need_score || application.financial_need_score.length === 0)
    ) {
      return 0;
    }
    const score =
      (application.essay_voice_score && parseInt(application.essay_voice_score, 10)) +
      (application.essay_inspiration_score && parseInt(application.essay_inspiration_score)) +
      (application.essay_word_score && parseInt(application.essay_word_score)) +
      (application.essay_completed_score && parseInt(application.essay_completed_score)) +
      (application.citizenship_letters_score && parseInt(application.citizenship_letters_score)) +
      (application.citizenship_activities_score && parseInt(application.citizenship_activities_score)) +
      (application.citizenship_rolemodel_score && parseInt(application.citizenship_rolemodel_score)) +
      (application.length_of_stay_score && parseInt(application.length_of_stay_score)) +
      (application.gpa_score && parseInt(application.gpa_score)) +
      (application.financial_need_score && parseInt(application.financial_need_score));
    if (score < 0) return 0;
    return score;
  };

  return (
    <>
      <div className={formClasses.smallHeader}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <span style={{ marginRight: 20 }}>
                <strong>Applicant:</strong> {senior && senior.first_name} {senior && senior.last_name}
              </span>
              <strong>LOS:</strong> {senior && calculateLOS(senior.departure_date, senior.admission_date)}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>Application Score:</strong> {calculateApplicationScore()}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>Submitted On:</strong> {dateToS(application.submitted_date)}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>Senior Planning Score:</strong> {seniorPlanningScore}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>SSM Rating Scale:</strong> {application.ssm_model_category}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div
              className={formClasses.headingSummary}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <span style={{ marginRight: 10 }}>
                <strong>Application Status:</strong>
              </span>

              {parent === 'applist' ? (
                <FormControl>
                  <Select
                    labelId="application-status-label"
                    id="application-status"
                    value={application.status}
                    onChange={(event) => updateApplicationStatus(application.id, event.target.value)} // this would have different params if used for ScholarshipApp
                    className={formClasses.headerSelect}
                    inputProps={{
                      classes: {
                        icon: formClasses.headerSelectIcon,
                      },
                    }}
                    sx={{
                      color: 'white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fff',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#fff',
                      },
                    }}
                    disabled={parent === 'app'}
                  >
                    <MenuItem value={'submitted'}>Submitted</MenuItem>
                    <MenuItem value={'returned'}>Returned</MenuItem>
                    <MenuItem value={'qualified'}>Qualified</MenuItem>
                    <MenuItem value={'awarded'}>Awarded</MenuItem>
                    <MenuItem value={'denied'}>Denied</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <div>{application.status}</div>
              )}
            </div>
          </Grid>
          <br />
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>Pending:</strong>{' '}
              {awardPendingTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>Awarded:</strong>{' '}
              {awardAwardedTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={false} lg={false}>
            <div className={formClasses.headingSummary}>
              <strong>Total:</strong>{' '}
              {awardTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          </Grid>
        </Grid>
      </div>
      <Hidden lgUp implementation="css">
        <div className={formClasses.mediumHeader}>
          <Grid container direction="row">
            <Grid item xs={false} sm={false} md={7} lg={false}>
              <div className={formClasses.headingSummary}>
                <span style={{ marginRight: 20 }}>
                  <strong>Applicant:</strong> {senior && senior.first_name} {senior && senior.last_name}
                </span>
                <strong>LOS:</strong> {senior && calculateLOS(senior.departure_date, senior.admission_date)}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>Pending:</strong>{' '}
                {awardPendingTotal.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={7} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>Application Score:</strong> {calculateApplicationScore()}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>Awarded:</strong>{' '}
                {awardAwardedTotal.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={7} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>Submitted On:</strong> {dateToS(application.submitted_date)}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>Total:</strong>{' '}
                {awardTotal.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={7} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>Senior Planning Score:</strong> {seniorPlanningScore}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={false}></Grid>
            <Grid item xs={false} sm={false} md={7} lg={false}>
              <div className={formClasses.headingSummary}>
                <strong>SSM Rating Scale:</strong> {application.ssm_model_category}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={false}></Grid>

            <Grid item xs={false} sm={false} md={7} lg={false}>
              <div
                className={formClasses.headingSummary}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
              >
                <span style={{ marginRight: 10 }}>
                  <strong>Application Status:</strong>
                </span>

                {parent === 'applist' ? (
                  <FormControl>
                    <Select
                      labelId="application-status-label"
                      id="application-status"
                      value={application.status}
                      onChange={(event) => updateApplicationStatus(application.id, event.target.value)} // this would have different params if used for ScholarshipApp
                      className={formClasses.headerSelect}
                      inputProps={{
                        classes: {
                          icon: formClasses.headerSelectIcon,
                        },
                      }}
                      sx={{
                        color: 'white',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#fff',
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#fff',
                        },
                      }}
                      disabled={parent === 'app'}
                    >
                      <MenuItem value={'submitted'}>Submitted</MenuItem>
                      <MenuItem value={'returned'}>Returned</MenuItem>
                      <MenuItem value={'qualified'}>Qualified</MenuItem>
                      <MenuItem value={'awarded'}>Awarded</MenuItem>
                      <MenuItem value={'denied'}>Denied</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <div>{application.status}</div>
                )}
              </div>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={false}></Grid>
          </Grid>
        </div>
      </Hidden>
      <div className={formClasses.largeHeader}>
        <TableContainer>
          <Table className={tableClasses.awardHeaderTable}>
            <TableBody>
              <TableRow>
                <TableCell className={formClasses.cellData} style={{ width: '33%' }}>
                  <div className={formClasses.headingSummary}>
                    <span style={{ marginRight: 20 }}>
                      <strong>Applicant:</strong> {senior && senior.first_name} {senior && senior.last_name}
                    </span>
                    <strong>LOS:</strong> {senior && calculateLOS(senior.departure_date, senior.admission_date)}
                  </div>
                </TableCell>
                <TableCell className={formClasses.cellData} style={{ width: '33%', textAlign: 'center' }}>
                  <div className={formClasses.headingSummary}>
                    <strong>Application Score:</strong> {calculateApplicationScore()}
                  </div>
                </TableCell>
                <TableCell className={formClasses.cellData} style={{ width: '33%', textAlign: 'right' }}>
                  <div className={formClasses.headingSummary}>
                    <strong>Pending:</strong>{' '}
                    {awardPendingTotal.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <div className={formClasses.headingSummary}>
                    <strong>Submitted On:</strong> {dateToS(application.submitted_date)}
                  </div>
                </TableCell>
                <TableCell className={formClasses.cellData} style={{ textAlign: 'center' }}>
                  <div className={formClasses.headingSummary}>
                    <strong>Senior Planning Score:</strong> {seniorPlanningScore}
                  </div>
                </TableCell>
                <TableCell className={formClasses.cellData} style={{ textAlign: 'right' }}>
                  <div className={formClasses.headingSummary}>
                    <strong>Awarded:</strong>{' '}
                    {awardAwardedTotal.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <div
                    className={formClasses.headingSummary}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <span style={{ marginRight: 10 }}>
                      <strong>Application Status:</strong>
                    </span>
                    {parent === 'applist' ? (
                      <FormControl>
                        <Select
                          labelId="application-status-label"
                          id="application-status"
                          value={application.status}
                          onChange={(event) => updateApplicationStatus(application.id, event.target.value)} // this would have different params if used for ScholarshipApp
                          className={formClasses.headerSelect}
                          inputProps={{
                            classes: {
                              icon: formClasses.headerSelectIcon,
                            },
                          }}
                          sx={{
                            color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#fff',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#fff',
                            },
                          }}
                          disabled={parent === 'app'}
                        >
                          <MenuItem value={'submitted'}>Submitted</MenuItem>
                          <MenuItem value={'returned'}>Returned</MenuItem>
                          <MenuItem value={'qualified'}>Qualified</MenuItem>
                          <MenuItem value={'awarded'}>Awarded</MenuItem>
                          <MenuItem value={'denied'}>Denied</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <div>{application.status}</div>
                    )}
                  </div>
                </TableCell>
                <TableCell className={formClasses.cellData} style={{ textAlign: 'center' }}>
                  <div className={formClasses.headingSummary}>
                    <strong>SSM Rating Scale:</strong> {application.ssm_model_category}
                  </div>
                </TableCell>
                <TableCell className={formClasses.cellData} style={{ textAlign: 'right' }}>
                  <div className={formClasses.headingSummary}>
                    <strong>Total:</strong>{' '}
                    {awardTotal.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

AwardHeaderComponent.propTypes = {
  formClasses: PropTypes.object.isRequired,
  tableClasses: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  senior: PropTypes.object.isRequired,
  updateApplicationStatus: PropTypes.func.isRequired,
  awardPendingTotal: PropTypes.number,
  awardAwardedTotal: PropTypes.number,
  awardTotal: PropTypes.number,
  seniorPlanningScore: PropTypes.number,
  parent: PropTypes.string.isRequired,
};

export default AwardHeaderComponent;
