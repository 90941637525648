import { Route, Redirect } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
