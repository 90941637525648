import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '../../CustomButtons/Button.js';
import { observer } from 'mobx-react';
import { blackColor, whiteColor } from '../../../assets/jss/nextjs-material-kit-pro';

const formStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  buttonWidth: {
    width: '110px',
  },
  buttonSpacer: {
    marginLeft: 20,
  },
  dialog: {
    width: 500,
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: '30px',
  },
  fontSize: '0.85rem',
  fontWeight: 500,
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
  dialogExplain: {
    margin: '0 15px 10px 25px',
    fontSize: '0.90rem',
    fontWeight: 500,
  },
  labelInlineEditSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 40,
      paddingTop: 3,
    },
    [theme.breakpoints.up('md')]: {
      height: 45,
      paddingTop: 5,
    },
  },
  filterOptionGroup: {
    minWidth: '99%',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
}));

const ConfirmSubmitDialog = observer((props) => {
  const { setContinueSubmit, setShowConfirmSubmitDialog, showDialog, handleClose } = props;

  const formClasses = formStyles();

  const handleResponse = (response) => {
    setContinueSubmit(response);
    setShowConfirmSubmitDialog(false);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={showDialog} onClose={handleClose} scroll="paper" aria-labelledby="Expanded Filter Menu">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeaderFooter}>
            Submit Confirmation
          </DialogTitle>
          <summary className={formClasses.dialogExplain}>
            Submit your Planning Meeting 2 form <strong>only</strong> if it is complete and ready to share with the committee during your second
            planning meeting. No additional changes can be made once the form is submitted.
          </summary>
          <summary className={formClasses.dialogExplain}>Do you want to continue submitting your form?</summary>
          <DialogContent className={formClasses.dialog} style={{ width: '100%', marginTop: 20 }} align="center">
            <Box sx={{ width: '100%' }} align="center">
              <Button color="primary" className={formClasses.buttonWidth} onClick={(e) => handleResponse(true)}>
                Yes
              </Button>
              <Button className={formClasses.buttonWidth + ' ' + formClasses.buttonSpacer} onClick={(e) => handleResponse(false)}>
                No
              </Button>
            </Box>
          </DialogContent>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  );
});

export default ConfirmSubmitDialog;

ConfirmSubmitDialog.propTypes = {
  setContinueSubmit: PropTypes.func,
  setShowConfirmSubmitDialog: PropTypes.func,
  showDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
