import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useReactToPrint } from 'react-to-print';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, IconButton, Typography } from '@material-ui/core';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import ScholarshipApp from './ScholarshipApp';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import HelpIcon from '@material-ui/icons/Help';
import HelpDialog from '../Help/HelpDialog';
import ScholarshipsIcon from '../../assets/img/planner-scholarships-icon.svg';
import { webPrimaryColor, whiteColor } from '../../assets/jss/nextjs-material-kit-pro.js';

const useStyles = makeStyles((theme) => ({
  welcome: {
    margin: 25,
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 30px',
  },
  menuTitle: {
    fontSize: 24,
    textAlign: 'left',
    margin: '5px 0',
    padding: 10,
  },
  menuDesc: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: whiteColor,
  },
  appSubmittedDate: {
    fontSize: 24,
    margin: '5px 0 50px 0',
    padding: 10,
  },
  appStatus: {
    fontSize: 20,
    position: 'relative',
    bottom: '5px',
  },
  statusIcon: {
    fontSize: 20,
  },
  appStatusDate: {
    fontSize: 20,
    position: 'relative',
    bottom: '5px',
    paddingLeft: 25,
  },
  statusBottom: {
    marginLeft: 10,
    position: 'absolute',
    bottom: '20px',
  },
  printMenuDesc: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: '#000',
  },
  icon: {
    fontSize: '100px',
  },
  nav: {
    cursor: 'pointer',
  },
  homeIconSpace: {
    marginTop: 50,
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      height: '280px',
      minHeight: '280px',
    },
    [theme.breakpoints.up('md')]: {
      height: '375px',
      minHeight: '375px',
    },
    margin: '0, 20px',
  },
  pageHeader: {
    margin: '10px 0 0 0',
  },
  pageTitle: {
    fontSize: '1.438rem',
    textAlign: 'left',
    fontWeight: 600,
  },
  pageIcon: {
    width: '8%',
    marginRight: '15px',
  },
  printHeaderText: {
    color: '#000',
    fontWeight: 600,
  },
  homeIcon: {
    fontSize: '30px',
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    padding: 30,
  },
  pageDesc: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: '#000',
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  breadcrumb: {
    padding: '18px 0 0 10px',
  },
  breadCrumbText: {
    fontSize: '0.80rem',
  },
  breadCrumbLink: {
    fontSize: '0.80rem',
    cursor: 'pointer',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
  addIcon: {
    color: whiteColor,
    fontSize: '80px',
    marginTop: 40,
    textAlign: 'center',
  },
  addSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerCard: {
    marginBottom: 10,
    padding: '20px',
  },
  headerDesc: {
    width: '90%',
    fontSize: '1.063rem',
    lineHeight: '1.313rem',
    padding: '20px 0 5px 0',
    textAlign: 'left',
    color: webPrimaryColor,
  },
  printHeaderDesc: {
    width: '90%',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 0',
    color: webPrimaryColor,
  },
}));

const ScholarshipDisposition = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const classes = useStyles();
    const history = useHistory();

    const [showHelp, setShowHelp] = useState(false);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const handleHelpClose = () => {
      setShowHelp(false);
    };

    const handleHelp = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowHelp(true);
    };

    const handleReturnHome = () => {
      store.setScholarshipAppId(null);
      store.setScholarshipApp(null);
      store.setSenior(null);
      history.push('/');
    };

    const handleReturnToScholarshipList = () => {
      store.setScholarshipAppId(null);
      store.setScholarshipApp(null);
      store.setSenior(null);
      history.push('/scholarshiplist');
    };

    return (
      <React.Fragment>
        <HelpDialog
          title="Printer Tips & Tricks"
          content={
            <React.Fragment>
              <Grid container direction="row" style={{ marginBottom: 15 }}>
                <Grid item xs={12}>
                  When the print menu opens, choose the destination (such as a specific printer or PDF file) on the top right side of the window.
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginBottom: 15 }}>
                <Grid item xs={12}>
                  To improve the look of your printed copies, click on "More settings" on the right side and change the "Margins" setting to "Minimum"
                  or "Custom." This will add a margin around the contents, so the page break doesn't happen in the middle of a line.
                </Grid>
              </Grid>
            </React.Fragment>
          }
          showHelp={showHelp}
          handleClose={handleHelpClose}
        />
        {(auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) && (
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12}>
              <div className={classes.breadcrumb}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
                  <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnHome()}>
                    Home
                  </Typography>
                  <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnToScholarshipList()}>
                    Scholarship Applications List
                  </Typography>
                  <Typography color="textPrimary" className={classes.breadCrumbText}>
                    Submitted Application
                  </Typography>
                </Breadcrumbs>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} direction="row" className={classes.menu} ref={componentRef}>
          <Grid item xs={12} align="left" className={classes.unprintedContent}>
            <Card className={classes.pageHeader}>
              <CardBody className={classes.headerCard}>
                <Grid container direction="row">
                  <Grid item xs={11} align="left">
                    <section className={classes.pageTitle}>
                      <img src={ScholarshipsIcon} alt="Scholarship App Icon" className={classes.pageIcon} />
                      <span className={classes.pagecardHeader}>Scholarship Application</span>
                    </section>
                    <aside className={classes.headerDesc}>
                      This Scholarship application has been submitted {store.ScholarshipApp && ` by ${store.ScholarshipApp.status_by}`}. Please review
                      and disposition the application using the form at the bottom of this page.
                    </aside>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <IconButton color="inherit" aria-label="print scholarship application" onClick={handlePrint}>
                      <PrintIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
                    </IconButton>
                    <IconButton color="inherit" aria-label="printer tips and tricks" onClick={handleHelp}>
                      <HelpIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <ScholarshipApp SeniorStore={store} AuthStore={auth} handleSaveReturn={handleReturnToScholarshipList} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  })
);

export default ScholarshipDisposition;
