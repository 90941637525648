import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, Grid, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

const ContactReschedule = ({
  contactInfo,
  classes,
  senior,
  rescheduleDate,
  setRescheduleDate,
  rescheduleTime,
  setRescheduleTime,
  attemptResult,
  setAttemptResult,
  attemptCount,
  finalAttempt,
  setFinalAttempt,
}) => {
  useEffect(() => {
    if (attemptResult.length === 0) {
      setAttemptResult('Reschedule');
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container align="left" direction="row" className={classes.unprintedContent}>
        <Grid item xs={6} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label id="Result-label" className={classes.adminLabel}>
            Result
          </label>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <FormControl fullWidth>
            <Select id="result" value={attemptResult} onChange={(event) => setAttemptResult(event.target.value)}>
              <MenuItem value="Reschedule">Reschedule</MenuItem>
              <MenuItem value="Unavailable">Client Unavailable</MenuItem>
              <MenuItem value="OtherContacts">Other Contacts (Friends, Family Teachers, NDB Contacts, etc.)</MenuItem>
              <MenuItem value="Refused">Refused to Complete</MenuItem>
              <MenuItem value="WrongNumber">Wrong Number</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={false} sm={5} md={7} lg={8}></Grid>
        {attemptResult === 'Reschedule' && (
          <>
            <Grid item xs={12} sm={4} md={3} lg={2} className={classes.rowSpacer}>
              <label id="reschedule-date-label" className={classes.adminLabel}>
                Reschedule Date
              </label>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} className={classes.rowSpacer}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  id="reschedule-date"
                  hiddenLabel
                  disablePast
                  value={rescheduleDate}
                  onChange={(date) => {
                    setRescheduleDate(date ? date : null);
                  }}
                  format="MM/dd/yyyy"
                  minDateMessage="Date cannot be earlier than today."
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={2} md={2} lg={2} className={classes.rowSpacer}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <TimePicker
                  label="Time"
                  value={rescheduleTime}
                  disableFuture
                  style={{ marginTop: '-16px', marginLeft: 10 }}
                  onChange={(time) => {
                    setRescheduleTime(time ? time : null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        )}
        <Grid item xs={false} sm={2} md={4} lg={6}></Grid>
      </Grid>
      <Grid container align="left" className={classes.rowSpacer}>
        {/* This is the attempt number prior to this attempt, so SFS is working on the 3rd attempt here. */}
        {attemptCount > 2 && attemptResult !== 'Refused' && (
          <>
            <Grid item xs={3} sm={2} md={2} lg={2} className={classes.rowSpacer}>
              <label id="Result-label" className={classes.adminLabel}>
                Final Attempt
              </label>
            </Grid>
            <Grid item xs={10} sm={5} md={9} lg={10}>
              <div>
                <FormControl fullWidth>
                  <FormControlLabel
                    className={classes.checkboxLabel + ' ' + classes.error}
                    style={{
                      verticalAlign: 'top',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginTop: 10,
                    }}
                    control={<Checkbox color="default" style={{ marginTop: '-10px' }} />}
                    label="*Contacts require at least 3 unsuccessful attempts before they can be closed. Please try to reach clients via text message, Facebook or other social media, or through an alternative legal guardian before closing."
                    checked={finalAttempt}
                    onChange={(event) => setFinalAttempt(!finalAttempt)}
                  />
                </FormControl>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

ContactReschedule.propTypes = {
  contactInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  senior: PropTypes.object.isRequired,
  rescheduleDate: PropTypes.string,
  setRescheduleDate: PropTypes.func.isRequired,
  rescheduleTime: PropTypes.string,
  setRescheduleTime: PropTypes.func.isRequired,
  attemptResult: PropTypes.string.isRequired,
  setAttemptResult: PropTypes.func.isRequired,
  attemptCount: PropTypes.number.isRequired,
  finalAttempt: PropTypes.bool.isRequired,
  setFinalAttempt: PropTypes.func.isRequired,
};

export default ContactReschedule;
