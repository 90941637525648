import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, TextField } from '@material-ui/core';

const FamilyFinancialSupport = ({ form, setForm, classes, disabled }) => {
  return (
    <Grid container align="left">
      <Grid item xs={12}>
        <section align="left" className={classes.sectionTitle}>
          Family Financial Support
        </section>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>My family is committed to help me with:</label>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Housing</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Housing"
              name="housing"
              value={form.familyFinancialSupportHousing}
              onChange={(event) => setForm({ ...form, familyFinancialSupportHousing: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Housing</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportHousing}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Medical Insurance</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Medical Insurance"
              name="Medical-Insurance"
              value={form.familyFinancialSupportMedicalInsurance}
              onChange={(event) => setForm({ ...form, familyFinancialSupportMedicalInsurance: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Medical Insurance</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportMedicalInsurance}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Health Needs</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Health Needs"
              name="Health-Needs"
              value={form.familyFinancialSupportHealthNeeds}
              onChange={(event) => setForm({ ...form, familyFinancialSupportHealthNeeds: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Health Needs</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportHealthNeeds}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Employment</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Employment"
              name="Employment"
              value={form.familyFinancialSupportEmployment}
              onChange={(event) => setForm({ ...form, familyFinancialSupportEmployment: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Employment</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportEmployment}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Tuition</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Tuition"
              name="Tuition"
              value={form.familyFinancialSupportTuition}
              onChange={(event) => setForm({ ...form, familyFinancialSupportTuition: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Tuition</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportTuition}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Workforce Supplies</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Workforce Supplies"
              name="Workforce-Supplies"
              value={form.familyFinancialSupportWorkforceSupplies}
              onChange={(event) => setForm({ ...form, familyFinancialSupportWorkforceSupplies: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Workforce Supplies</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportWorkforceSupplies}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={5} md={4} className={classes.textRowLabelSpacer}>
          <label>Transportation</label>
        </Grid>
        <Grid item xs={7} md={8} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Transportation"
              name="Transportation"
              value={form.familyFinancialSupportTransportation}
              onChange={(event) => setForm({ ...form, familyFinancialSupportTransportation: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Transportation</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.familyFinancialSupportTransportation}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} className={classes.textRowLabelSpacer} style={{ marginBottom: '10px' }}>
          <label>Other</label>
        </Grid>
        <Grid item xs={12} className={[classes.rowSpacer, classes.unprintedContent].join(' ')}>
          <FormControl fullWidth>
            <TextField
              id="other-family-financial-support"
              size="small"
              label="Please explain"
              variant="outlined"
              value={form.familyFinancialSupportOtherExplain}
              onChange={(event) => setForm({ ...form, familyFinancialSupportOtherExplain: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={[classes.rowSpacer, classes.printedContent].join(' ')}>
          {form.familyFinancialSupportOtherExplain}
        </Grid>
      </Grid>
    </Grid>
  );
};

FamilyFinancialSupport.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FamilyFinancialSupport;
