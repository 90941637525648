import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { dateToS } from '../../util/dateUtil';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const ContactDetails = ({ contactInfo, classes, senior, specialist, formatPhoneNumber, setShowContactInfo, setDialogOpen }) => {
  const displayUnemployed = (val) => {
    switch (val) {
      case 'lookingforwork':
        return 'Unemployed, looking for work';
      case 'notlookingforwork':
        return 'Unemployed, not looking for work';
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      <Grid container align="left" direction="row" className={classes.unprintedContent}>
        <Grid item xs={11} sm={11} md={11} lg={11} className={classes.rowSpacer}>
          Client Information <EditIcon className={classes.detailsLink} onClick={(e) => setDialogOpen(true)} fontSize="small" />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} align="right" className={classes.rowSpacer}>
          <div style={{ marginRight: 15 }} onClick={(event) => setShowContactInfo(false)}>
            <ExpandLessIcon fontSize="small" />
          </div>
        </Grid>

        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Name</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>
            {contactInfo.alumniUpdatedFirstName ? contactInfo.alumniUpdatedFirstName : senior.first_name}{' '}
            {contactInfo.alumniUpdatedLastName ? contactInfo.alumniUpdatedLastName : senior.last_name}
          </strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Graduated</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{senior.graduation_date && dateToS(senior.graduation_date)}</strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Discharge Date</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{senior.departure_date && dateToS(senior.departure_date)}</strong>
        </Grid>

        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Specialist</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{specialist}</strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Program</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{senior.program}</strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          {/* <label>Post-Grad Plan</label> */}
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          {/* <strong>{senior.checklist && JSON.parse(senior.checklist).postGraduationPlan}</strong> */}
        </Grid>

        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Phone</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>
            {senior.alumni_phone_1 && formatPhoneNumber(senior.alumni_phone_1)} {senior.alumni_phone_1_type && '(' + senior.alumni_phone_1_type + ')'}
          </strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Phone 2</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>
            {formatPhoneNumber(senior.alumni_phone_2)} {senior.alumni_phone_2_type && '(' + senior.alumni_phone_2_type + ')'}
          </strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Email Address</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{senior.user.email}</strong>
        </Grid>

        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>Employer</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{senior && senior.employer && senior.employer.length > 0 ? senior.employer : displayUnemployed(senior.unemployed)}</strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}>
          <label>School</label>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}>
          <strong>{senior.school}</strong>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} className={classes.rowSpacer}></Grid>
        <Grid item xs={6} sm={3} md={2} lg={3} className={classes.rowSpacer}></Grid>
      </Grid>
    </React.Fragment>
  );
};

ContactDetails.propTypes = {
  contactInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  senior: PropTypes.object.isRequired,
  specialist: PropTypes.string,
  formatPhoneNumber: PropTypes.func.isRequired,
  setShowContactInfo: PropTypes.func.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
};

export default ContactDetails;
