import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Card from '../../Card/Card.js';
import CardBody from '../../Card/CardBody.js';
import ScholarshipsIcon from '../../../assets/img/planner-scholarships-icon.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/Timer';
import { webPrimaryColor } from '../../../assets/jss/nextjs-material-kit-pro.js';
import { dateToS } from '../../../util/dateUtil';

const ScholarshipApps = ({
  senior,
  alumni,
  roles,
  aftercareSpecialist,
  formatPhoneNumber,
  handleCreateScholarshipApp,
  showReturnedScholarshipApp,
  scholarshipList,
  openScholarshipApp,
  setPage,
  handleReturnToSeniorList,
  displayStatus,
  classes,
}) => {
  window.scrollTo({ top: 0 });

  return (
    <React.Fragment>
      <Grid container spacing={3} direction="row" className={classes.subMenu}>
        <Grid item xs={12}>
          {!roles.includes('STUDENT') && !roles.includes('ALUMNI') && (
            <div className={classes.breadcrumb}>
              <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnToSeniorList()}>
                  {alumni ? 'Alumni List' : 'Senior List'}
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(e) => setPage('home')}>
                  {alumni ? 'Alumni Planner' : 'Senior Planner'}
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbText}>
                  Scholarship Applications
                </Typography>
              </Breadcrumbs>
            </div>
          )}
        </Grid>

        <Grid item xs={12} style={{ height: '25px' }} />
        {roles.includes('ALUMNI') ? (
          <>
            <Grid item xs={12} className={roles.includes('ALUMNI') ? classes.alumniWelcomeGrid : classes.welcomeGrid}>
              <Typography variant="h4" className={classes.welcome} style={{ margin: '5px 20px' }}>
                <main>Scholarship Applications</main>
              </Typography>
              <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                <HomeIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={6} lg={6} className={classes.welcomeGrid}>
              <Typography variant="h4" className={classes.welcome}>
                {roles.includes('STUDENT') || roles.includes('ALUMNI') ? (
                  <main>Scholarship Applications</main>
                ) : (
                  <main>
                    Scholarship Applications for{' '}
                    {senior && senior.alumni_updated_first_name && senior.alumni_updated_first_name.length > 0
                      ? senior.alumni_updated_first_name
                      : senior.first_name}{' '}
                    {senior && senior.alumni_updated_last_name && senior.alumni_updated_last_name.length > 0
                      ? senior.alumni_updated_last_name
                      : senior.last_name}
                  </main>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.specialistGrid}>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Successful Futures Specialist:</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.user && aftercareSpecialist.user.id > 0
                    ? aftercareSpecialist.user.first_name + ' ' + aftercareSpecialist.user.last_name
                    : 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Office Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {(aftercareSpecialist && aftercareSpecialist.office_phone && formatPhoneNumber(aftercareSpecialist.office_phone)) || 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Cell Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {(aftercareSpecialist && aftercareSpecialist.cell_phone && formatPhoneNumber(aftercareSpecialist.cell_phone)) || 'N/A'}
                </span>
              </aside>
            </Grid>
            <Grid item xs={12}>
              <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                <HomeIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
              </IconButton>
            </Grid>
          </>
        )}

        <Grid item xs={12} align="center">
          {(roles.includes('STUDENT') || roles.includes('ALUMNI')) && showReturnedScholarshipApp && (
            <aside className={classes.error}>NOTE: You have a returned Scholarship application. Please review immediately!</aside>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} direction="row" className={classes.menu}>
        {(roles.includes('ADMIN') || roles.includes('AFTERCARE_SPECIALIST')) && scholarshipList.length === 0 && (
          <Grid item xs={12} align="center">
            <summary className={classes.noApps}>{senior.first_name} has not created any Scholarship applications.</summary>
          </Grid>
        )}

        <Grid item xs={12} align="left" className={classes.cardGrid} style={{ width: '100vw' }}>
          {(roles.includes('STUDENT') || roles.includes('ALUMNI')) && (
            <nav
              onClick={(event) => handleCreateScholarshipApp()}
              className={classes.nav}
              disabled={!roles.includes('STUDENT') && !roles.includes('ALUMNI')}
            >
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={ScholarshipsIcon} alt="Scholarship Icon" className={classes.cardIcon} />

                    <Tooltip title="Click to create a new Scholarship application." placement="bottom">
                      <section className={classes.addSection}>
                        <AddIcon className={classes.addIcon} />
                      </section>
                    </Tooltip>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}

          {scholarshipList.map((app, idx) => {
            return (
              <nav onClick={(event) => openScholarshipApp(app.id)} className={classes.nav}>
                <Card className={classes.card}>
                  <CardBody className={classes.cardBody}>
                    <div className={classes.cardContainer}>
                      <img src={ScholarshipsIcon} alt="Scholarship Icon" className={classes.cardIcon} />
                      <section className={classes.appSubmittedDate}>
                        <span className={classes.cardHeader}>{(app.submitted_date > /Date(0)/ && dateToS(app.submitted_date)) || 'Unsubmitted'}</span>
                      </section>
                      <summary className={classes.statusBottom}>
                        <section className={classes.appStatus}>
                          {app.status === 'awarded' ? (
                            <CheckIcon className={classes.statusIcon} />
                          ) : app.status === 'denied' ? (
                            <CheckIcon className={classes.statusIcon} />
                          ) : app.status === 'qualified' || app.status === 'qualifiedinitial' || app.status === 'qualifiedprogram' ? (
                            <VisibilityIcon className={classes.statusIcon} />
                          ) : (
                            <HelpOutlineIcon className={classes.statusIcon} />
                          )}{' '}
                          <span className={classes.iconText}>{displayStatus(app.status)}</span>
                        </section>
                        <section className={classes.appStatusDate}>
                          <span className={classes.iconText}>{dateToS(app.status_date)}</span>
                        </section>
                      </summary>
                    </div>
                  </CardBody>
                </Card>
              </nav>
            );
          })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

ScholarshipApps.propTypes = {
  senior: PropTypes.object,
  alumni: PropTypes.bool,
  roles: PropTypes.array,
  aftercareSpecialist: PropTypes.object,
  formatPhoneNumber: PropTypes.func,
  handleCreateScholarshipApp: PropTypes.func,
  showReturnedScholarshipApp: PropTypes.func,
  scholarshipList: PropTypes.array,
  openScholarshipApp: PropTypes.func,
  setPage: PropTypes.func,
  handleReturnToSeniorList: PropTypes.func,
  displayStatus: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default ScholarshipApps;
