import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, FormControlLabel, FormHelperText, MenuItem, Select, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import ExpandingList2 from '../../ExpandingList/ExpandingList2';
import { stateLabelValues } from '../../../util/stringUtil';
import { dateToS } from '../../../util/dateUtil';
import PhoneInput from '../../Shared/PhoneInput';

const AlumniSchoolProgram = ({ form, setForm, senior, classes, disabled }) => {
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [advisorPhoneIsValid, setAdvisorPhoneIsValid] = useState(true);

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} style={{ marginTop: '50px' }}>
          <section align="left" className={classes.sectionTitle}>
            School or Training Program for which you are requesting financial aid
          </section>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={5} className={classes.textRowSpacer}>
          <label>Name of School</label>
          <FormControl fullWidth>
            <TextField
              id="school-name"
              size="small"
              placeholder="School"
              variant="outlined"
              value={form.alumniSchoolName}
              onChange={(event) => setForm({ ...form, alumniSchoolName: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowSpacer}>
          <label>School Website</label>
          <FormControl fullWidth>
            <TextField
              id="school-website"
              size="small"
              hiddenLabel
              placeholder="Website"
              variant="outlined"
              value={form.alumniSchoolWebsite}
              onChange={(event) => setForm({ ...form, alumniSchoolWebsite: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Name of School</label>
          <br />
          {form.alumniSchoolName}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>School Website</label>
          <br />
          {form.alumniSchoolWebsite}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={5} className={classes.textRowSpacer}>
          <label>School Address</label>
          <FormControl fullWidth style={{ marginBottom: 10 }}>
            <TextField
              id="school-address"
              size="small"
              placeholder="Street Address"
              variant="outlined"
              value={form.alumniSchoolAddress}
              onChange={(event) => setForm({ ...form, alumniSchoolAddress: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 10 }}>
            <TextField
              id="school-city"
              size="small"
              placeholder="City"
              variant="outlined"
              value={form.alumniSchoolCity}
              onChange={(event) => setForm({ ...form, alumniSchoolCity: event.target.value })}
              InputProps={{ maxLength: 30, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <Grid container direction="row">
            <Grid item xs={12} md={6} className={classes.textRowSpacer}>
              <FormControl fullWidth style={{ marginBottom: 10 }}>
                <Select
                  id="school-state"
                  value={form.alumniSchoolState}
                  onChange={(event) => setForm({ ...form, alumniSchoolState: event.target.value })}
                >
                  <MenuItem value=""></MenuItem>
                  {stateLabelValues.map((state) => {
                    return <MenuItem value={state.value}>{state.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={false} md={1} className={classes.textRowSpacer}></Grid>
            <Grid item xs={12} md={5} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="school-zip"
                  size="small"
                  placeholder="Zip"
                  variant="outlined"
                  value={form.alumniSchoolZip}
                  onChange={(event) => setForm({ ...form, alumniSchoolZip: event.target.value })}
                  InputProps={{ maxLength: 6, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={3} lg={2} className={classes.textRowSpacer}>
          <label>School Phone Number</label>
          <FormControl error={!phoneIsValid}>
            <PhoneInput
              id="phone"
              variant="outlined"
              value={form.alumniSchoolPhone}
              handleChange={(val, isValid) => {
                setForm({ ...form, alumniSchoolPhone: val.replace(/\D/g, '') });
                setPhoneIsValid(isValid);
              }}
              disabled={disabled}
            />
            <FormHelperText id="my-helper-text">{!phoneIsValid && 'Please enter a valid phone number'}</FormHelperText>
          </FormControl>

          <div className={classes.labelSpacer}>
            <label>Student ID Number (if you have one)</label>
          </div>
          <FormControl fullWidth>
            <TextField
              id="school-student-id"
              size="small"
              hiddenLabel
              placeholder="Student ID Number"
              variant="outlined"
              value={form.alumniStudentID}
              onChange={(event) => setForm({ ...form, alumniStudentID: event.target.value })}
              InputProps={{ maxLength: 30, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} md={3} lg={4} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>School Address</label>
          <br />
          {form.alumniSchoolAddress}
          <br />
          {form.alumniSchoolCity} {form.alumniSchoolState} {form.alumniSchoolZip}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>School Phone Number</label>
          <br />
          {form.alumniSchoolPhone}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Student ID Number</label>
          <br />
          {form.alumniStudentID || 'N/A'}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}></Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={4} className={classes.textRowSpacer}>
          <label>Scholarship Coordinator / Financial Aid Officer / Advisor</label>
          <FormControl fullWidth>
            <TextField
              id="advisor-name"
              size="small"
              placeholder="Name"
              variant="outlined"
              value={form.alumniAdvisorName}
              onChange={(event) => setForm({ ...form, alumniAdvisorName: event.target.value })}
              InputProps={{ maxLength: 60, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className={classes.textRowSpacer}>
          <br />
          <FormControl error={!advisorPhoneIsValid}>
            <PhoneInput
              id="phone"
              variant="outlined"
              value={form.alumniAdvisorPhone}
              handleChange={(val, isValid) => {
                setForm({ ...form, alumniAdvisorPhone: val.replace(/\D/g, '') });
                setAdvisorPhoneIsValid(isValid);
              }}
              disabled={disabled}
            />
            <FormHelperText id="my-helper-text">{!advisorPhoneIsValid && 'Please enter a valid phone number'}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} className={classes.textRowSpacer}>
          <br />
          <FormControl fullWidth>
            <TextField
              id="advisor-email"
              size="small"
              placeholder="Email Address"
              variant="outlined"
              value={form.alumniAdvisorEmail}
              onChange={(event) => setForm({ ...form, alumniAdvisorEmail: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className={classes.textRowSpacer}></Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={5} className={classes.textRowSpacer}>
          <label>Address where scholarship award money should be mailed</label>
          <FormControl fullWidth style={{ marginBottom: 10 }}>
            <TextField
              id="scholarship-mail-address"
              size="small"
              placeholder="Street Address"
              variant="outlined"
              value={form.alumniScholarshipMailingAddress}
              onChange={(event) => setForm({ ...form, alumniScholarshipMailingAddress: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 10 }}>
            <TextField
              id="scholarship-mail-city"
              size="small"
              placeholder="City"
              variant="outlined"
              value={form.alumniScholarshipMailingCity}
              onChange={(event) => setForm({ ...form, alumniScholarshipMailingCity: event.target.value })}
              InputProps={{ maxLength: 30, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <Grid container direction="row">
            <Grid item xs={12} md={6} className={classes.textRowSpacer}>
              <FormControl fullWidth style={{ marginBottom: 10 }}>
                <Select
                  id="scholarship-mail-state"
                  value={form.alumniScholarshipMailingState}
                  onChange={(event) => setForm({ ...form, alumniScholarshipMailingState: event.target.value })}
                >
                  <MenuItem value=""></MenuItem>
                  {stateLabelValues.map((state) => {
                    return <MenuItem value={state.value}>{state.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={false} md={1} className={classes.textRowSpacer}></Grid>
            <Grid item xs={12} md={5} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="scholarship-mail-zip"
                  size="small"
                  placeholder="Zip"
                  variant="outlined"
                  value={form.alumniScholarshipMailingZip}
                  onChange={(event) => setForm({ ...form, alumniScholarshipMailingZip: event.target.value })}
                  InputProps={{ maxLength: 6, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={3} className={classes.textRowSpacer}></Grid>
        <Grid item xs={false} md={3} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Scholarship Coordinator / Financial Aid Officer / Advisor</label>
          <br />
          {form.alumniAdvisorName} / {form.alumniAdvisorPhone} / {form.alumniAdvisorEmail}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Address where scholarship money should be mailed</label>
          <br />
          {form.alumniScholarshipMailingAddress}
          <br />
          {form.alumniScholarshipMailingCity}, {form.alumniScholarshipMailingState} {form.alumniScholarshipMailingZip}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={3} className={classes.textRowSpacer}>
          <label>Program for which you are requesting help</label>
          <FormControl fullWidth style={{ marginBottom: 10 }}>
            <Select
              id="degree"
              value={form.alumniDegree}
              onChange={(event) => setForm({ ...form, alumniDegree: event.target.value })}
              disabled={disabled}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="2 Year Degree">2 Year Degree</MenuItem>
              <MenuItem value="4 Year Degree">4 Year Degree</MenuItem>
              <MenuItem value="Graduate Degree">Graduate Degree</MenuItem>
              <MenuItem value="Certification Program">Certification Program</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer}></Grid>
        <Grid item xs={12} md={3} className={classes.textRowSpacer}>
          <label>Major Area of Study</label>
          <FormControl fullWidth>
            <TextField
              id="area-of-study"
              size="small"
              placeholder="Area of Study"
              variant="outlined"
              value={form.alumniAreaOfStudy}
              onChange={(event) => setForm({ ...form, alumniAreaOfStudy: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer}></Grid>
        <Grid item xs={12} md={4} className={classes.textRowSpacer}>
          <div>
            <label>Planned Completion Date</label>
          </div>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              id="degree-completion-date"
              hiddenLabel
              disablePast
              value={form.alumniPlannedCompletionDate ? dateToS(form.alumniPlannedCompletionDate) : null}
              onChange={(date) => {
                setForm({ ...form, alumniPlannedCompletionDate: date ? date : '' });
              }}
              format="MM/dd/yyyy"
              className={classes.datepicker}
              disabled={disabled}
              minDateMessage="Date cannot be earlier than today."
              invalidDateMessage={'Interview Date is invalid.'}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Program for which you are requesting help</label>
          <br />
          {form.alumniDegree}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Major Area of Study</label>
          <br />
          {form.alumniAreaOfStudy}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
          <label>Planned Completion Date</label>
          <br />
          {dateToS(form.alumniPlannedCompletionDate)}
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}></Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <section align="left" className={classes.sectionTitle}>
            Academic Information
          </section>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowSpacer}>
          <label>Latest ACT, SAT or GPA</label>
          <br />
          <FormControl>
            <TextField
              id="act"
              size="small"
              hiddenLabel
              placeholder="ACT, SAT, or GPA"
              variant="outlined"
              value={form.actScore}
              onChange={(event) => setForm({ ...form, actScore: event.target.value })}
              InputProps={{ maxLength: 5, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} md={1} className={classes.textRowLabelSpacer}></Grid>
        <Grid item xs={12} md={4} className={classes.textRowLabelSpacer}></Grid>
        <Grid item xs={12} md={4} className={classes.textRowLabelSpacer}></Grid>
      </Grid>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textRowLabelSpacer}>
            <label>School / Professional History (Please check all that apply.)</label>
          </Grid>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="High School Diploma or GED"
                disabled={disabled || form.alumniHistoryNone}
                checked={form.alumniHistoryNone ? false : form.alumniHistoryDiploma}
                onChange={(event) => setForm({ ...form, alumniHistoryDiploma: !form.alumniHistoryDiploma })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Trade/2-year College"
                disabled={disabled || form.alumniHistoryNone}
                checked={form.alumniHistoryNone ? false : form.alumniHistoryTrade}
                onChange={(event) => setForm({ ...form, alumniHistoryTrade: !form.alumniHistoryTrade })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={1} md={3} lg={3} />
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="4-Year College"
                disabled={disabled || form.alumniHistoryNone}
                checked={form.alumniHistoryNone ? false : form.alumniHistory4Year}
                onChange={(event) => setForm({ ...form, alumniHistory4Year: !form.alumniHistory4Year })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Military"
                disabled={disabled || form.alumniHistoryNone}
                checked={form.alumniHistoryNone ? false : form.alumniHistoryMilitary}
                onChange={(event) => setForm({ ...form, alumniHistoryMilitary: !form.alumniHistoryMilitary })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={1} md={3} lg={3} />
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Job Corps"
                disabled={disabled || form.alumniHistoryNone}
                checked={form.alumniHistoryNone ? false : form.alumniHistoryJobCorps}
                onChange={(event) => setForm({ ...form, alumniHistoryJobCorps: !form.alumniHistoryJobCorps })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="None"
                disabled={disabled}
                checked={form.alumniHistoryNone}
                onChange={(event) => setForm({ ...form, alumniHistoryNone: !form.alumniHistoryNone })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={false} md={4} lg={4} />
        </Grid>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
            <label>ACT, SAT or GPA</label>
            <br />
            {form.actScore}
          </Grid>
          <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
          <Grid item xs={12} md={5} className={classes.textRowLabelSpacer}>
            <label>School / Professional History</label>
            <br />
            <ul>
              {form.alumniHistoryNone ? false : form.alumniHistoryDiploma && <li>High School Diploma or GED</li>}
              {form.alumniHistoryNone ? false : form.alumniHistoryTrade && <li>Trade / 2-Year College</li>}
              {form.alumniHistoryNone ? false : form.alumniHistory4Year && <li>4-year College</li>}
              {form.alumniHistoryNone ? false : form.alumniHistoryMilitary && <li>Military</li>}
              {form.alumniHistoryNone ? false : form.alumniHistoryJobCorps && <li>Job Corps</li>}
              {form.alumniHistoryNone && <li>None</li>}
            </ul>
          </Grid>
          <Grid item xs={false} md={1} className={classes.textRowLabelSpacer} />
        </Grid>
      </section>

      <Grid item xs={12} align="left" className={classes.labelXLSpacer}>
        <Grid item xs={12}>
          <section align="left" className={classes.sectionTitle}>
            Please enter the year and a broad description of any extracurricular and community-based activities (volunteering, sports, clubs, etc.)
            you have been involved in within the past two years.
          </section>
        </Grid>

        {form.alumniActivitiesNone && form.alumniActivitiesNone ? (
          ''
        ) : (
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.rowSpacer}>
                <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                  <label>Year</label>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                  <label>Activity</label>
                </Grid>
              </Grid>
              <ExpandingList2
                className={classes.unprintedContent}
                form={form}
                setForm={setForm}
                listname="alumniExtraCurricularActivities"
                rowsRequired={1}
                maxRowsAllowed={5}
                disabled={disabled}
                field1="schoolYear"
                field1Cols={3}
                field1MaxLength={100}
                field2="description"
                field2Cols={8}
                field2MaxLength={255}
              />
            </Grid>
          </Grid>
        )}
        {form.alumniActivitiesNone ? (
          ''
        ) : (
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.printableTableHeader}>
                <Grid item xs={6}>
                  <label>Year</label>
                </Grid>
                <Grid item xs={6}>
                  <label>Activity</label>
                </Grid>
              </Grid>
              {form.alumniExtraCurricularActivities &&
                form.alumniExtraCurricularActivities.map((e, i) => {
                  return (
                    <Grid container direction="row" className={classes.printableTableHeight} key={i}>
                      <Grid item xs={6}>
                        {e.schoolYear}
                      </Grid>
                      <Grid item xs={6}>
                        {e.description}
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="I have not participated in any extracurricular or community-based activities in the past two years."
                disabled={disabled}
                checked={form.alumniActivitiesNone}
                onChange={(event) => setForm({ ...form, alumniActivitiesNone: !form.alumniActivitiesNone })}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={12} className={classes.textRowLabelSpacer}>
            {form.alumniActivitiesNone ? 'I have not participated in any extra-curricular or community-based activities in the past two years.' : ''}
          </Grid>
        </Grid>
      </Grid>

      <Grid container align="left" className={classes.labelXLSpacer}>
        <Grid item xs={12}>
          <section align="left" className={classes.sectionTitle}>
            Please enter the position and a broad description of any leadership positions you have held or have been involved in within the past two
            years.
          </section>
        </Grid>
        {form.alumniLeadershipPositionsNone ? (
          ''
        ) : (
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.rowSpacer}>
                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                  <label>Position</label>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                  <label>Description</label>
                </Grid>
              </Grid>
              <ExpandingList2
                className={classes.unprintedContent}
                form={form}
                setForm={setForm}
                listname="alumniLeadershipPositions"
                rowsRequired={1}
                maxRowsAllowed={5}
                disabled={disabled}
                field1="position"
                field1Cols={3}
                field1MaxLength={30}
                field2="description"
                field2Cols={8}
                field2MaxLength={100}
              />
            </Grid>
          </Grid>
        )}
        {form.alumniLeadershipPositionsNone ? (
          ''
        ) : (
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.printableTableHeader}>
                <Grid item xs={6}>
                  <label>Position</label>
                </Grid>
                <Grid item xs={6}>
                  <label>Description</label>
                </Grid>
              </Grid>
              {form.alumniLeadershipPositions &&
                form.alumniLeadershipPositions.map((e, i) => {
                  return (
                    <Grid container direction="row" className={classes.printableTableHeight} key={i}>
                      <Grid item xs={6}>
                        {e.position}
                      </Grid>
                      <Grid item xs={6}>
                        {e.description}
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="I have not held any leadership positions in the past two years."
                disabled={disabled}
                checked={form.alumniLeadershipPositionsNone}
                onChange={(event) => setForm({ ...form, alumniLeadershipPositionsNone: !form.alumniLeadershipPositionsNone })}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={12} className={classes.textRowLabelSpacer}>
            {form.alumniLeadershipPositionsNone ? 'I have not held any leadership positions in the past two years.' : ''}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AlumniSchoolProgram.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  senior: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AlumniSchoolProgram;
