import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import SeniorTips from '../SeniorTips';

const Tips = ({ setPage, classes }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row" className={classes.menu}>
        <Grid item xs={12} align="left">
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={11} align="left">
              <Typography variant="h5" align="left" style={{ marginTop: 10 }}>
                Tips, Links, and Information
              </Typography>
            </Grid>
            <Grid item xs={1} align="right">
              <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                <HomeIcon className={classes.homeIcon} style={{ color: '#000' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SeniorTips />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Tips.propTypes = {
  setPage: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default Tips;
