import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { dateUtcToS, dateDiffYearsMonths, dateOnlyFormat } from '../../../util/dateUtil';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
}));

export default function GeneralInformation(props) {
  const { senior, form, setForm, disabled, roles } = props;

  const classes = styles();
  const todaysDate = new Date();

  const [currentStudent, setCurrentStudent] = useState(false);

  useEffect(() => {
    const today = new Date();
    // setCurrentStudent(false);
    setForm({ ...form, currentStudent: 'no' });
    if (senior.user.roles.includes('STUDENT')) {
      if (!senior.departure_date || dateOnlyFormat(senior.departure_date) > dateOnlyFormat(today)) {
        setForm({ ...form, currentStudent: 'yes' });
        setCurrentStudent(true);
      }
    }
  }, [senior]);

  const showHomeState = () => {
    let todaysDate = new Date();
    if (senior.user.roles.includes('STUDENT')) {
      if (!senior.departure_date) {
        return true;
      }
      if (dateUtcToS(senior.departure_date) > dateUtcToS(todaysDate)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box sx={{ width: '95%' }}>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          <label>Name</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {roles.includes('ALUMNI') ? senior.alumni_updated_first_name : senior.first_name}{' '}
          {roles.includes('ALUMNI') ? senior.alumni_updated_last_name : senior.last_name}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          {currentStudent && <label>Family Teachers</label>}
        </Grid>
        <Grid item xs={1} md={1} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {currentStudent && senior.family_teaching_couple}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Name</label>
        </Grid>
        <Grid item xs={3}>
          {roles.includes('ALUMNI') ? senior.alumni_updated_first_name : senior.first_name}{' '}
          {roles.includes('ALUMNI') ? senior.alumni_updated_last_name : senior.last_name}
        </Grid>
        <Grid item xs={3}>
          {currentStudent && <label>Family Teachers</label>}
        </Grid>
        <Grid item xs={3}>
          {currentStudent && senior.family_teaching_couple}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          {currentStudent && <label>Site/Community</label>}
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {currentStudent && senior.site}{' '}
          {currentStudent && senior.site === 'Home Campus' && senior.community && senior.community.length > 0 && ' - ' + senior.community}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={3}>
          {currentStudent && <label>Site/Community</label>}
        </Grid>
        <Grid item xs={3}>
          {currentStudent && senior.site}{' '}
          {currentStudent && senior.site === 'Home Campus' && senior.community && senior.community.length > 0 && ' - ' + senior.community}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          <label>Phone Number</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {senior.home_phone}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          {currentStudent && <label>Consultant</label>}
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {currentStudent && senior.consultant}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Phone Number</label>
        </Grid>
        <Grid item xs={3}>
          {senior.home_phone}
        </Grid>
        <Grid item xs={3}>
          {currentStudent && <label>Consultant</label>}
        </Grid>
        <Grid item xs={3}>
          {currentStudent && senior.consultant}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          <label>Email Address</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.user.email}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          <label>Admission</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Email Address</label>
        </Grid>
        <Grid item xs={9}>
          {senior.user.email}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Address</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        {currentStudent ? (
          <Grid item xs={11} sm={8} md={5} lg={2} className={classes.textRowLabelSpacer}>
            {senior.home_address}
          </Grid>
        ) : (
          <Grid item xs={11} sm={8} md={5} lg={2} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="address-entered"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.addressEntered}
                onChange={(event) => setForm({ ...form, addressEntered: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={false} sm={false} md={3} lg={1} />
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Departure Date</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={6} md={3} lg={3} className={classes.textRowLabelSpacer}>
          {(senior.departure_date > /Date(0)/ && dateUtcToS(senior.departure_date)) || 'N/A'}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Address</label>
        </Grid>
        <Grid item xs={3}>
          {currentStudent ? senior.home_address : form.addressEntered}
        </Grid>
        <Grid item xs={3}>
          <label>Admission Date</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
          {!currentStudent && <label>City, State Zip</label>}
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        {currentStudent ? (
          <Grid item xs={11} sm={8} md={5} lg={2} className={classes.textRowLabelSpacer}></Grid>
        ) : (
          <Grid item xs={11} sm={8} md={5} lg={2} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="city-state-zip"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.cityStateZip}
                onChange={(event) => setForm({ ...form, cityStateZip: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={false} sm={false} md={4} lg={1} />
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Length of Stay</label>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.textRowLabelSpacer}>
          {senior.departure_date > /Date(0)/
            ? dateDiffYearsMonths(senior.departure_date, senior.admission_date)
            : dateDiffYearsMonths(todaysDate, senior.admission_date)}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}></Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}></Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          <label>Graduation Date</label>
        </Grid>
        <Grid item xs={1} sm={1} md={false} lg={false} />
        <Grid item xs={11} sm={11} md={3} lg={3} className={classes.rowSpacer}>
          {(senior.graduation_date > /Date(0)/ && dateUtcToS(senior.graduation_date)) || 'N/A'}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          {!currentStudent && <label>City, State Zip</label>}
        </Grid>
        <Grid item xs={3}>
          {!currentStudent && form.cityStateZip}
        </Grid>
        <Grid item xs={3}>
          <label>Departure Date</label>
        </Grid>
        <Grid item xs={3}>
          {(senior.departure_date > /Date(0)/ && dateUtcToS(senior.departure_date)) || 'N/A'}
        </Grid>
      </Grid>
      {currentStudent && (
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
            <label>Motivation System</label>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={false} />
          <Grid item xs={11} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <Select
                labelId="motivation-system-label"
                id="motivation-system"
                value={form.motivationSystem}
                onChange={(event) => setForm({ ...form, motivationSystem: event.target.value })}
                disabled={disabled}
              >
                <MenuItem value={'Daily'}>Daily</MenuItem>
                <MenuItem value={'Weekly'}>Weekly</MenuItem>
                <MenuItem value={'Achievement'}>Achievement</MenuItem>
                <MenuItem value={'Subsystem'}>Subsystem</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={false} md={5} lg={1} />
          <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
            <label>Placement Status</label>
          </Grid>
          <Grid item xs={1} sm={1} md={false} />
          <Grid item xs={11} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
            {senior.placement_status}
          </Grid>
          <Grid item xs={false} sm={false} md={5} lg={false} />
        </Grid>
      )}
      {currentStudent && (
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={3}>
            <label>Motivation System</label>
          </Grid>
          <Grid item xs={3}>
            {form.motivationSystem}
          </Grid>
          <Grid item xs={3}>
            <label>Length of Stay</label>
          </Grid>
          <Grid item xs={3}>
            {senior.departure_date > /Date(0)/
              ? dateDiffYearsMonths(senior.departure_date, senior.admission_date)
              : dateDiffYearsMonths(todaysDate, senior.admission_date)}
          </Grid>
        </Grid>
      )}
      {showHomeState() && (
        <React.Fragment>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}>
              <label>Home State</label>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={false} />
            <Grid item xs={11} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
              {senior.home_state}
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={1} />
            <Grid item xs={12} sm={12} md={2} lg={2} className={classes.textRowLabelSpacer}></Grid>
            <Grid item xs={1} sm={1} md={false} />
            <Grid item xs={11} sm={6} md={3} lg={2} className={classes.textRowSpacer}></Grid>
            <Grid item xs={false} sm={false} md={5} lg={false} />
          </Grid>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={3}>
              <label>Home State</label>
            </Grid>
            <Grid item xs={3}>
              {senior.home_state}
            </Grid>
            <Grid item xs={3}>
              <label>Placement Status</label>
            </Grid>
            <Grid item xs={3}>
              {senior.placement_status}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

GeneralInformation.propTypes = {
  senior: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
  roles: PropTypes.array,
};
