export const calculatePlanningScore = (plan1, plan2) => {
  let score = 0;
  if (plan1) {
    if (plan1.gradingSheet1Completed > 0) {
      score += plan1.gradingSheet1Completed;
    }
    if (plan1.gradingSheet1Submitted > 0) {
      score += plan1.gradingSheet1Submitted;
    }
    if (plan1.gradingSheet1Clarity > 0) {
      score += plan1.gradingSheet1Clarity;
    }
    if (plan1.module1Submitted > 0) {
      score += plan1.module1Submitted;
    }
    if (plan1.module1Completed > 0) {
      score += plan1.module1Completed;
    }
    if (plan1.module2Submitted > 0) {
      score += plan1.module2Submitted;
    }
    if (plan1.module2Completed > 0) {
      score += plan1.module2Completed;
    }
    if (plan1.module3Submitted > 0) {
      score += plan1.module3Submitted;
    }
    if (plan1.module3Completed > 0) {
      score += plan1.module3Completed;
    }
  }
  if (plan2) {
    if (plan2.gradingSheet2Completed > 0) {
      score += plan2.gradingSheet2Completed;
    }
    if (plan2.gradingSheet2Submitted > 0) {
      score += plan2.gradingSheet2Submitted;
    }
    if (plan2.gradingSheet2Clarity > 0) {
      score += plan2.gradingSheet2Clarity;
    }
    if (plan2.module4Submitted > 0) {
      score += plan2.module4Submitted;
    }
    if (plan2.module4Completed > 0) {
      score += plan2.module4Completed;
    }
    if (plan2.module5Submitted > 0) {
      score += plan2.module5Submitted;
    }
    if (plan2.module5Completed > 0) {
      score += plan2.module5Completed;
    }
    if (plan2.module6Submitted > 0) {
      score += plan2.module6Submitted;
    }
    if (plan2.module6Completed > 0) {
      score += plan2.module6Completed;
    }
  }
  return score;
};

export const displayStatus = (status) => {
  if (status === 'submitted') {
    return 'Submitted';
  }
  if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram') {
    return 'Under Review';
  }
  if (status === 'returned') {
    return 'Returned';
  }
  if (status === 'awarded') {
    return 'Awarded';
  }
  if (status === 'denied') {
    return 'Denied';
  }
  return status;
};

export const getScholarshipNameFromId = (id, scholarships) => {
  const ind = scholarships.findIndex((obj) => obj.id === id);
  return scholarships[ind].name;
};

export const displayApplicantName = (record) => {
  let fname = '';
  let lname = '';
  if (record.alumni_updated_first_name && record.alumni_updated_first_name.length > 0) {
    fname = record.alumni_updated_first_name;
  } else {
    fname = record.first_name;
  }
  if (record.alumni_updated_last_name && record.alumni_updated_last_name.length > 0) {
    lname = record.alumni_updated_last_name;
  } else {
    lname = record.last_name;
  }
  return fname + ' ' + lname;
};

export const getSortName = (first, last, updatedfirst, updatedlast) => {
  let fname = '';
  let lname = '';
  if (updatedfirst && updatedfirst.length > 0) {
    fname = updatedfirst;
  } else {
    fname = first;
  }
  if (updatedlast && updatedlast.length > 0) {
    lname = updatedlast;
  } else {
    lname = last;
  }
  return lname + ', ' + fname;
};

export const updateAwardTotals = (appAwards) => {
  let pendingTotal = 0;
  let awardedTotal = 0;
  let total = 0;
  appAwards &&
    appAwards.length > 0 &&
    appAwards.forEach((a) => {
      if (a.status === 'pending') {
        pendingTotal += parseInt(a.amount, 10);
        total += parseInt(a.amount, 10);
      }
      if (a.status === 'awarded') {
        awardedTotal += parseInt(a.amount, 10);
        total += parseInt(a.amount, 10);
      }
    });
  return { 'pendingTotal': pendingTotal, 'awardedTotal': awardedTotal, 'total': total };
};
