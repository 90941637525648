import { container, description, cardTitle, primaryColor, whiteColor, grayColor, hexToRgb } from '../../nextjs-material-kit-pro.js';

const signupPageStyle = (theme) => ({
  description,
  cardTitle: {
    ...cardTitle,
    color: whiteColor + '  !important',
  },
  container: {
    ...container,
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  pageHeader: {
    color: whiteColor,
    border: '0',
    height: '100%',
    margin: '0',
    display: 'flex!important',
    padding: '120px 0',
    position: 'relative',
    minHeight: '100vh',
    alignItems: 'center',
    '&:before': {
      background: 'rgba(' + hexToRgb(primaryColor[0]) + ', 0.5)',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
  },
  form: {
    margin: '0',
  },
  cardHeader: {
    width: 'auto',
    textAlign: 'center',
  },
  socialLine: {
    marginTop: '1rem',
    textAlign: 'center',
    padding: '0',
  },
  inputIconsColor: {
    color: grayColor[13],
  },
  textCenter: {
    textAlign: 'center',
  },
  iconButtons: {
    marginRight: '3px !important',
    marginLeft: '3px !important',
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  left: {
    float: 'left!important',
    display: 'block',
    '&,& *,& *:hover,& *:focus': {
      color: whiteColor + '  !important',
    },
  },
  right: {
    padding: '15px 0',
    margin: '0',
    float: 'right',
    '&,& *,& *:hover,& *:focus': {
      color: whiteColor + '  !important',
    },
  },
  icon: {
    width: '18px',
    height: '18px',
    top: '3px',
    position: 'relative',
  },
  footer: {
    position: 'relative',
    width: '100%',
    height: '4rem',
    background: 'transparent',
    bottom: '5',
    left: '0',
    color: grayColor,
    zIndex: '2',
    paddingTop: '.5rem',
  },
  version: {
    fontSize: '0.80rem',
    color: grayColor,
    alignText: 'center',
  },
});

export default signupPageStyle;
