import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, Breadcrumbs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { primaryColor } from '../../assets/jss/nextjs-material-kit-pro';

const useStyles = makeStyles((theme) => ({
  titlebar: {
    backgroundColor: '#fff',
    padding: '30px 10px 5px 10px',
    margin: '-15px 0 15px 0',
    borderBottom: '1px inset #f0f0f0',
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  breadcrumb: {
    padding: '18px 0 0 10px',
  },
  breadCrumbText: {
    fontSize: '0.80rem',
  },
  linkButton: {
    fontSize: '0.80rem',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    display: 'inline',
    margin: 0,
    padding: 0,
    '&:hover,&:focus': {
      textDecoration: 'underline',
      color: primaryColor[0],
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  addlInfo: {
    fontSize: '0.95rem',
    fontWeight: 400,
    marginTop: 5,
  },
}));

const TitleBar = (props) => {
  const classes = useStyles();
  const { title, currentPage, paths, additionalInformation } = props;
  const history = useHistory();

  return (
    <Grid container className={classes.titlebar}>
      <Grid item xs={12} md={4} lg={3} className={classes.unprintedContent}>
        <Hidden smDown implementation="css">
          <section className={classes.breadcrumb}>
            <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
              {paths.map((p, i) => {
                return (
                  <button
                    key={i}
                    size="sm"
                    color="inherit"
                    className={[classes.breadCrumbText, classes.linkButton].join(' ')}
                    link={true}
                    onClick={() => (p.onClickFunc ? p.onClickFunc() : history.push(p.path))}
                  >
                    {p.name}
                  </button>
                );
              })}
              <Typography color="textPrimary" className={classes.breadCrumbText}>
                {currentPage}
              </Typography>
            </Breadcrumbs>
          </section>
        </Hidden>
      </Grid>
      <Grid item xs={12} md={4} lg={6} justifyContent="center">
        <section className={classes.title}>{title}</section>
      </Grid>
      <Grid item xs={false} md={4} lg={3}>
        <section className={classes.addlInfo}>{additionalInformation}</section>
      </Grid>
    </Grid>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
      onClickFunc: PropTypes.func,
    })
  ).isRequired,
  additionalInformation: PropTypes.string,
};

export default TitleBar;
