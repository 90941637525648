import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PhoneInput from '../Shared/PhoneInput';
import { stateLabelValues } from '../../util/stringUtil';
import { FormControl, FormHelperText, TextField, MenuItem, Select } from '@material-ui/core';

const HelpRequestContact = ({
  phoneTypes,
  isFormDisabled,
  contactInfo,
  setContactInfo,
  phone1IsValid,
  setPhone1IsValid,
  phone2IsValid,
  setPhone2IsValid,
  phone3IsValid,
  setPhone3IsValid,
  formatPhoneNumber,
  email,
  classes,
}) => {
  return (
    <React.Fragment>
      <Grid container direction="row" className={classes.rowSpacer} />

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={5} lg={3} className={classes.firstSectionSpacer}>
          <label>Now, a few questions about you</label>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.rowSpacer}>
          <label>Full Legal Name</label>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="legal-first-name"
              size="small"
              hiddenLabel
              placeholder="Legal First Name"
              variant="outlined"
              value={contactInfo.alumniUpdatedFirstName}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniUpdatedFirstName: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={false} md={1} lg={1}>
          <br />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="legal-last-name"
              size="small"
              hiddenLabel
              placeholder="Legal Last Name"
              variant="outlined"
              value={contactInfo.alumniUpdatedLastName}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniUpdatedLastName: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={false} md={3} lg={5}>
          <br />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.rowSpacer}>
          <label>Email Address</label> <p>{email}</p>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent} style={{ width: '90%', margin: '40px 0 0 0' }}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Address</label>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="alumni-address"
              size="small"
              hiddenLabel
              placeholder="Address"
              variant="outlined"
              value={contactInfo.alumniAddress}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniAddress: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent} style={{ width: '90%' }}>
        <Grid item xs={12} sm={6} md={4} lg={4} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="alumni-address2"
              size="small"
              hiddenLabel
              placeholder="Address Line 2"
              variant="outlined"
              value={contactInfo.alumniAddress2}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniAddress2: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={false} md={4} lg={4}>
          <br />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={5} md={3} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="alumni-city"
              size="small"
              hiddenLabel
              placeholder="City"
              variant="outlined"
              value={contactInfo.alumniCity}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniCity: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={false} md={1} lg={1}>
          <br />
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={1} className={classes.textRowLabelSpacer} align="right">
          <label style={{ marginRight: '10px' }}>State</label>
        </Grid>
        <Grid item xs={9} sm={9} md={2} lg={2} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <Select
              id="alumni-state"
              className={classes.valignSelect}
              value={contactInfo.alumniState}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniState: event.target.value })}
              disabled={isFormDisabled()}
            >
              <MenuItem value="  "></MenuItem>
              {stateLabelValues.map((state) => {
                return (
                  <MenuItem key={state.value} value={state.value}>
                    {state.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={false} md={1} lg={1}>
          <br />
        </Grid>
        <Grid item xs={1} className={classes.textRowLabelSpacer} align="right">
          <label style={{ marginRight: '10px' }}>Zip</label>
        </Grid>
        <Grid item xs={4} sm={3} md={1} lg={1} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="alumni-zip"
              size="small"
              hiddenLabel
              placeholder="Zip Code"
              variant="outlined"
              value={contactInfo.alumniZip}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniZip: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Country</label>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="alumni-country"
              size="small"
              hiddenLabel
              placeholder="Country"
              variant="outlined"
              value={contactInfo.alumniCountry}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniCountry: event.target.value })}
              disabled={isFormDisabled()}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={false}>
          <br />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Phone Number(s)</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
          <FormControl error={!phone1IsValid}>
            <PhoneInput
              id="phone"
              variant="outlined"
              value={contactInfo.alumniPhone1}
              handleChange={(val, isValid) => {
                setContactInfo({ ...contactInfo, alumniPhone1: val.replace(/\D/g, '') });
                setPhone1IsValid(isValid);
              }}
              disabled={isFormDisabled()}
            />
            <FormHelperText id="my-helper-text">{!phone1IsValid && 'Please enter a valid phone number'}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowLabelSpacer} align="right">
          <label style={{ marginRight: '10px' }}>Type</label>
        </Grid>
        <Grid item xs={5} sm={5} md={2} lg={2} className={classes.textEditSpacer}>
          <FormControl fullWidth>
            <Select
              labelId="alumni-phone-1-type"
              id="alumni-phone-1-type"
              className={classes.valignSelect}
              value={contactInfo.alumniPhone1Type}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniPhone1Type: event.target.value })}
              disabled={isFormDisabled()}
            >
              <MenuItem value={''}></MenuItem>
              {phoneTypes.slice().map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={6} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
          <FormControl error={!phone2IsValid}>
            <PhoneInput
              id="phone"
              variant="outlined"
              value={contactInfo.alumniPhone2}
              handleChange={(val, isValid) => {
                setContactInfo({ ...contactInfo, alumniPhone2: val.replace(/\D/g, '') });
                setPhone2IsValid(isValid);
              }}
              disabled={isFormDisabled()}
            />
            <FormHelperText id="my-helper-text">{!phone2IsValid && 'Please enter a valid phone number'}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowLabelSpacer} align="right">
          <label style={{ marginRight: '10px' }}>Type</label>
        </Grid>
        <Grid item xs={5} sm={5} md={2} lg={2} className={classes.textEditSpacer}>
          <FormControl fullWidth>
            <Select
              labelId="alumni-phone-2-type"
              id="alumni-phone-2-type"
              className={classes.valignSelect}
              value={contactInfo.alumniPhone2Type}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniPhone2Type: event.target.value })}
              disabled={isFormDisabled()}
            >
              <MenuItem value={''}></MenuItem>
              {phoneTypes.slice().map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={6} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
          <FormControl error={!phone3IsValid}>
            <PhoneInput
              id="phone"
              variant="outlined"
              value={contactInfo.alumniPhone3}
              handleChange={(val, isValid) => {
                setContactInfo({ ...contactInfo, alumniPhone3: val.replace(/\D/g, '') });
                setPhone3IsValid(isValid);
              }}
              disabled={isFormDisabled()}
            />
            <FormHelperText id="my-helper-text">{!phone3IsValid && 'Please enter a valid phone number'}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowLabelSpacer} align="right">
          <label style={{ marginRight: '10px' }}>Type</label>
        </Grid>
        <Grid item xs={5} sm={5} md={2} lg={2} className={classes.textEditSpacer}>
          <FormControl fullWidth>
            <Select
              labelId="alumni-phone-3-type"
              id="alumni-phone-3-type"
              className={classes.valignSelect}
              value={contactInfo.alumniPhone3Type}
              onChange={(event) => setContactInfo({ ...contactInfo, alumniPhone3Type: event.target.value })}
              disabled={isFormDisabled()}
            >
              <MenuItem value={''}></MenuItem>
              {phoneTypes.slice().map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={false}>
          <br />
        </Grid>
      </Grid>

      <div className={classes.printedContent}>
        <div>
          <label>Full Legal Name</label>
          <p className={classes.printedContent} style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>
            {contactInfo.legal_first_name || null} {contactInfo.legal_last_name || null}
            <br />
            <br />
            <label>Email Address</label>
            <br />
            {email || null}
            <br />
            <br />
            <label>Address</label>
            <br />
            {contactInfo.alumniAddress || null}
            <br />
            {contactInfo.alumniAddress2 || null}
            <br />
            {contactInfo.alumniCity || null}
            {contactInfo.alumniState && contactInfo.alumniState.length > 0 ? ',' : ''} {contactInfo.alumniState || null}{' '}
            {contactInfo.alumniZip || null}
            <br />
            {contactInfo.alumniCountry || null}
            <br />
            <br />
            <label>Phone Numbers</label>
            <br />
            {contactInfo.alumniPhone1Type || null} {formatPhoneNumber(contactInfo.alumniPhone1) || null}
            {contactInfo.alumniPhone2 && contactInfo.alumniPhone2.length > 0 && (
              <>
                <br />
                {contactInfo.alumniPhone2Type || null} {formatPhoneNumber(contactInfo.alumniPhone2) || null}
              </>
            )}
            {contactInfo.alumniPhone3 && contactInfo.alumniPhone3.length > 0 && (
              <>
                <br />
                {contactInfo.alumniPhone3Type || null} {formatPhoneNumber(contactInfo.alumniPhone3) || null}
              </>
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

HelpRequestContact.propTypes = {
  phoneTypes: PropTypes.array.isRequired,
  isFormDisabled: PropTypes.func.isRequired,
  contactInfo: PropTypes.object.isRequired,
  setContactInfo: PropTypes.func.isRequired,
  phone1IsValid: PropTypes.bool.isRequired,
  setPhone1IsValid: PropTypes.func.isRequired,
  phone2IsValid: PropTypes.bool.isRequired,
  setPhone2IsValid: PropTypes.func.isRequired,
  phone3IsValid: PropTypes.bool.isRequired,
  setPhone3IsValid: PropTypes.func.isRequired,
  formatPhoneNumber: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default HelpRequestContact;
