export const getRowFromListById = (list, id) => {
  let searchList = [];
  searchList = list;
  const item = searchList.filter((s) => {
    return s.id === id;
  });
  return item ? item[0] : null;
};

export const convertSiteCode = (code) => {
  switch (code) {
    case 'HC':
      return 'Home Campus';
    case 'CFLA':
      return 'Central Florida';
    case 'GI':
      return 'Grand Island';
    case 'IA':
      return 'Iowa';
    case 'LA':
      return 'Louisiana';
    case 'NV':
      return 'Nevada';
    case 'NE':
      return 'New England';
    case 'NFLA':
      return 'North Florida';
    case 'SFLA':
      return 'South Florida';
    case 'WDC':
      return 'Washington DC';
    default:
      return code;
  }
};
