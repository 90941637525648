import { secondaryColor, whiteColor } from '../../nextjs-material-kit-pro.js';

const globalHeaderStyle = (theme) => ({
  appBar: {
    display: 'flex',
    border: '0',
    padding: '0',
    marginBottom: '20px',
    width: '100%',
    transition: 'all 150ms ease 0s',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
    height: '40px',
    fontFamily: 'Source Sans 3',
  },
  absolute: {
    position: 'absolute',
    top: 'auto',
  },
  fixed: {
    position: 'fixed',
  },
  containerWrapper: {
    backgroundColor: '#1F3C5D',
    position: 'relative',
  },
  borderFrame: {
    borderBottom: '4px solid #f4aa00',
    margin: '0 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    border: 'none',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    maxWidth: '1392px',
    margin: '0 auto',
    '& > li': {
      backgroundColor: 'transparent',
      color: '#f1f1f1',
      borderRight: 'thin solid rgba(255, 255, 255, 0.2)',
      '& > a': {
        textDecoration: 'none',
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: '600',
        padding: '12px 0.75rem',
        border: 'none',
        display: 'block',
      },
    },
  },
  yellowBox: {
    backgroundColor: '#f4aa00',
    textTransform: 'uppercase',
    '&, & a': {
      color: `${whiteColor}`,
      cursor: 'default',
      '&:hover,&:focus,&:active': {
        color: `${whiteColor} !important`,
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px 2px 0 2px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '12px 12px 0 12px',
      borderRight: '.25px solid #a9a9a9',
    },
  },
  helpPurpleBox: {
    backgroundColor: '#644258',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
      alignItems: 'space-between',
      position: 'absolute',
      top: 0,
      left: '85%',
      height: '92%',
      padding: '6px 2px 0 2px',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      width: '90%',
      margin: '20px auto',
    },
  },
  mobileHelpPurpleBox: {
    backgroundColor: '#644258',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55px',
    width: '300px',
    margin: '20px auto',
  },
  mobileHelpPurpleBoxPlaceholder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
  },
  logo: {
    fontSize: '0.75rem',
    fontFamily: 'Source Sans 3',
    fontWeight: 700,
    lineHeight: '1.25rem',
    [theme.breakpoints.up('md')]: {
      filter: 'brightness(0) invert(1)',
      width: '1.25rem',
      height: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      width: '1.25rem',
      height: 'auto',
    },
  },
  mobileLogo: {
    fontSize: '0.75rem',
    fontFamily: 'Source Sans 3',
    fontWeight: 700,
    lineHeight: '1.25rem',
    width: '1.25rem',
    height: 'auto',
  },
  hidden: {
    width: '100%',
  },
  mobileLink: {
    textTransform: 'uppercase',
    fontSize: '1.10rem',
    fontFamily: 'Source Sans 3',
    fontWeight: 600,
    margin: '10px 5px',
    '&, & a': {
      color: `${whiteColor} !important`,
    },
  },
  links: {
    textTransform: 'uppercase',
    fontFamily: 'Source Sans 3',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      minHeight: '45px',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      display: 'flex !important',
      MsFlexPreferredSize: 'auto',
      flexBasis: 'auto',
      alignContent: 'center',
      justifyContent: 'flex-start',
      '&, & a': {
        color: `${whiteColor}`,
        // '&:hover,&:focus,&:active': {
        //   color: `${secondaryColor}`,
        // },
      },
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '40px',
      fontSize: '0.70rem',
      lineHeight: '1.25rem',
      display: 'flex !important',
      MsFlexPreferredSize: 'auto',
      flexBasis: 'auto',
      // alignContent: 'center',
      justifyContent: 'flex-start',
      '&, & a': {
        color: '#1F3C5D',
        '&:hover,&:focus,&:active': {
          color: `${secondaryColor}`,
        },
      },
    },
    WebkitBoxFlex: '1',
    MsFlexPositive: '1',
    flexGrow: '1',
    WebkitBoxAlign: 'left',
    MsFlexAlign: 'left',
  },
  logoContainer: {
    padding: '0 5px',
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoLink: {
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontFamily: 'Source Sans 3',
    marginBottom: 5,
    fontWeight: 700,
    color: '#22547D',
    '&, & a': {
      color: '#22547D',
      fontWeight: 700,
    },
  },
  getHelpNowLink: {
    textTransform: 'uppercase',
    fontFamily: 'Source Sans 3',
    lineHeight: '1.25rem',
    marginLeft: '0.25rem',
    fontWeight: 700,
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
      marginBottom: 5,
      '&, & a': {
        color: '#FFFFFF',
        fontWeight: 700,
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.00rem',
      '&, & a': {
        color: '#FFFFFF',
        fontWeight: 700,
      },
    },
  },
  globalLink: {
    borderRight: '.25px solid #a9a9a9',
    padding: '12px 12px 0 12px',
    fontSize: '0.875rem',
  },
  mobileGlobalLink: {
    padding: '8px 0 0 0',
    color: '#1F3C5D',
  },
  boystownListContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boystownList: {
    margin: '0 1rem 1rem 1rem',
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '1px',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    width: '400px',
    '& > li': {
      backgroundColor: '#f1f1f1',
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
        fontSize: '0.70rem',
        color: 'rgb(19 61 96)',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: '600',
        padding: '12px 0.5rem',
        border: 'none',
      },
    },
  },
  globalListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default globalHeaderStyle;
