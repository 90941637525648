import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import HelpApplicationCard from './HelpApplicationCard';

const HelpApplicationCards = ({ list, open }) => {
  return (
    <>
      <Grid container direction="row">
        {list &&
          list.map((r) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={r.id}>
                <HelpApplicationCard app={r} open={open} />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

HelpApplicationCards.propTypes = {
  list: PropTypes.array.isRequired,
};

export default HelpApplicationCards;
