import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { callApi } from '../../util/apiWrapper';
import styles from '../../assets/jss/nextjs-material-kit-pro/components/globalHeaderStyle.js';

const useStyles = makeStyles(styles);

const BTGlobalHeader = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;

    const classes = useStyles();

    const [agilityData, setAgilityData] = React.useState(false);

    useEffect(() => {
      const fetchAgilityGlobalHeaderData = async (ctx) => {
        try {
          const res = await callApi(authStore, 'agilitysiteheader?preview=true&Locale=en-us&ReferenceName=sharedheader', 'GET');
          setAgilityData(res.result[0]);
          return res;
        } catch (err) {
          console.log(err);
          return Promise.reject(err);
        }
      };
      fetchAgilityGlobalHeaderData();
    }, []);

    return (
      <div>
        <Hidden mdDown implementation="css" style={{ position: 'relative' }}>
          <AppBar>
            <div className={classes.containerWrapper}>
              <div className={classes.borderFrame}>
                <div className={classes.container}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Link href={agilityData?.mainLink?.href} style={{ cursor: 'pointer', marginBottom: 7 }}>
                      <img
                        className={classes.logo}
                        height={agilityData?.mainIcon?.height}
                        width={agilityData?.mainIcon?.width}
                        src={agilityData?.mainIcon?.url}
                        alt={agilityData?.mainIcon?.label}
                      />
                    </Link>

                    <div className={classes.links}>
                      {agilityData && agilityData.mainLink && (
                        <div className={classes.globalLink}>
                          <a href={agilityData.mainLink.href} target={agilityData.mainLink.target}>
                            {agilityData.mainLabel}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.links}>
                    {agilityData &&
                      agilityData.sites &&
                      agilityData.sites.map((site, idx) => {
                        return (
                          <span className={site?.label.toUpperCase() === 'SUCCESSFULFUTURES.ORG' ? classes.yellowBox : classes.globalLink}>
                            <a href={site.link.href} target={site.link.target}>
                              {site.label}
                            </a>
                          </span>
                        );
                      })}
                  </div>

                  {/* <div className={classes.helpPurpleBox}>
                <span className={classes.logoContainer}>
                  {agilityData && agilityData.getHelpNowLink && (
                    <div className={classes.getHelpNowLink}>
                      <a href={agilityData.getHelpNowLink.href} target={agilityData.getHelpNowLink.target}>
                        {agilityData.getHelpNowLabel}
                      </a>
                    </div>
                  )}
                </span>
              </div> */}
                </div>
              </div>
            </div>
          </AppBar>
        </Hidden>

        {/* Mobile */}
        <Hidden lgUp implementation="css" style={{ position: 'relative' }}>
          {/* <div className={classes.mobileHelpPurpleBox}>
            <span className={classes.logoContainer}>
              {agilityData && agilityData.getHelpNowLink && (
                <div className={classes.getHelpNowLink}>
                  <a href={agilityData.getHelpNowLink.href} target={agilityData.getHelpNowLink.target}>
                    {agilityData.getHelpNowLabel}
                  </a>
                </div>
              )}
            </span>
          </div> */}
          <div className={classes.mobileHelpPurpleBoxPlaceholder}></div>

          <div className={classes.boystownListContainer}>
            <div className={classes.boystownList}>
              <li className={classes.globalListItem}>
                <Link href={agilityData?.mainLink?.href} style={{ cursor: 'pointer' }}>
                  <img
                    className={classes.mobileLogo}
                    height={agilityData?.mainIcon?.height}
                    width={agilityData?.mainIcon?.width}
                    src={agilityData?.mainIcon?.url}
                    alt={agilityData?.mainIcon?.label}
                  />
                </Link>

                <div className={classes.links} style={{ marginTop: '9px' }}>
                  {agilityData && agilityData.mainLink && (
                    <div className={classes.mobileGlobalLink}>
                      <a href={agilityData.mainLink.href} target={agilityData.mainLink.target}>
                        {agilityData.mainLabel}
                      </a>
                    </div>
                  )}
                </div>
              </li>
              {agilityData &&
                agilityData.sites &&
                agilityData.sites.map((site, idx) => {
                  return (
                    <li style={{ background: site?.label.toUpperCase() === 'SUCCESSFULFUTURES.ORG' && '#f4aa00' }}>
                      <a href={site.link.href} target={site.link.target} rel="noreferrer">
                        {site.label}
                      </a>
                    </li>
                  );
                })}
            </div>
          </div>
        </Hidden>
      </div>
    );
  })
);
export default BTGlobalHeader;
