import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { dateUtcToS } from '../../../util/dateUtil';

const MilitaryPlan = ({ form, setForm, classes, disabled }) => {
  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>ASVAB Score</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textRowSpacer}>
          <FormControl>
            <TextField
              id="act"
              size="small"
              hiddenLabel
              placeholder="ASVAB Score"
              variant="outlined"
              value={form.asvabScore}
              onChange={(event) => setForm({ ...form, asvabScore: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControlLabel
            style={{ marginLeft: '10px' }}
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={form.asvabScoreNotTaken ? form.asvabScoreNotTaken : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    setForm({ ...form, asvabScoreNotTaken: true, asvabScore: '' });
                  } else {
                    setForm({ ...form, asvabScoreNotTaken: ev.target.checked });
                  }
                }}
              />
            }
            label="Have Not Taken"
          />
        </Grid>
        <Grid item xs={false} md={3} lg={false} />
        {form.asvabScoreNotTaken && (
          <>
            <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
              <label>Please explain why ASVAB not taken</label>
            </Grid>
            <Grid item xs={12} md={6} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="act"
                  size="small"
                  hiddenLabel
                  placeholder="Please provide more information"
                  variant="outlined"
                  value={form.asvabScoreNotTakenReason}
                  onChange={(event) => setForm({ ...form, asvabScoreNotTakenReason: event.target.value })}
                  InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={false} md={3} lg={false} />
          </>
        )}
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>Oath of Enlistment Ceremony Date</label>
        </Grid>
        <Grid item xs={6} md={6} className={classes.rowSpacer}>
          {!disabled ? (
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                fullWidth
                autoOk
                variant="inline"
                id="elistment-ceremony-date"
                hiddenLabel
                disablePast
                value={form.elistmentCeremonyDate || null}
                onChange={(date) => {
                  setForm({ ...form, elistmentCeremonyDate: date ? date : null });
                }}
                format="MM/dd/yyyy"
                className={classes.datepicker}
                disabled={disabled}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
              {dateUtcToS(form.elistmentCeremonyDate || null)}
            </summary>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>ASVAB Score</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        {form.asvabScoreNotTaken && (
          <Grid item xs={11} sm={2} className={classes.textRowSpacer}>
            <span>ASVAB not taken: </span>
            {form.asvabScoreNotTakenReason}
          </Grid>
        )}
        {!form.asvabScoreNotTaken && (
          <Grid item xs={11} sm={2} className={classes.textRowSpacer}>
            {form.asvabScore}
          </Grid>
        )}
        <Grid item xs={12} sm={4} className={classes.textRowLabelSpacer}>
          <label>Oath of Enlistment Ceremony Date</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} className={classes.rowSpacer}>
          {dateUtcToS(form.elistmentCeremonyDate || null)}
        </Grid>
      </Grid>
    </>
  );
};

MilitaryPlan.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MilitaryPlan;
