import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function SpiritualInformation(props) {
  const { form, setForm, disabled } = props;

  const classes = styles();

  return (
    <Box sx={{ width: '95%' }}>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="spiritual-plan"
              autoFocus
              size="small"
              fullWidth
              label="My plan post-graduation (church, bible study, etc.)"
              variant="outlined"
              value={form.spiritualPlan}
              onChange={(event) => setForm({ ...form, spiritualPlan: event.target.value })}
              disabled={disabled}
              InputProps={{ maxLength: 500, classes: { input: classes.textField } }}
              className={classes.root}
            />
          </FormControl>
        </Grid>
      </Grid>
      <summary className={classes.printedContent}>
        <label>My plan post-graduation (church, bible study, etc.)</label>
      </summary>
      <summary className={classes.printedContent}>{form.spiritualPlan || null}</summary>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={8} lg={6} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="church"
              size="small"
              fullWidth
              label="I will attend church at"
              variant="outlined"
              value={form.church}
              onChange={(event) => setForm({ ...form, church: event.target.value })}
              disabled={disabled}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              className={classes.root}
            />
          </FormControl>
        </Grid>
      </Grid>
      <summary className={classes.printedContent}>
        <label>I will attend church at</label>
      </summary>
      <summary className={classes.printedContent}>{form.church || null}</summary>
    </Box>
  );
}

SpiritualInformation.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};
