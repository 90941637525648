import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { dateToS, dateTimeToS, dateUtcToS, dateOnlyFormat, getCurrentDateTime } from '../../util/dateUtil';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormLabel,
  Button,
  Checkbox,
  Grid,
  Hidden,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
  Typography,
  TablePagination,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableStyle from '../../styles/TableStyle';
import { observer, inject } from 'mobx-react';
import { logException, appInsights } from '../../services/appInsights';
import { logAudit } from '../../util/auditUtil';
import { btNavyBlueColor, btMediumGrayColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import { callApi } from '../../util/apiWrapper';
import TitleBar from '../Shared/TitleBar';
import ScholarshipApplicationCards from './ScholarshipApplicationCards';
import PageUnauthorized from '../Shared/PageUnauthorized';
import { titleize } from '../../util/stringUtil';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import AwardDialog from './ScholarshipDialogs/AwardDialog';
import { convertSiteCode } from '../../util/miscUtil';
import {
  calculatePlanningScore,
  displayStatus,
  getScholarshipNameFromId,
  displayApplicantName,
  getSortName,
  updateAwardTotals,
} from '../../util/scholarshipsUtil';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles((theme) => ({
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
    border: 'none',
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  tableRow: {
    height: '45px',
  },
  approvalLink: {
    fontSize: '0.70rem',
    height: '28px',
    padding: '10px, 5px 5px 5px',
    textTransform: 'none',
    justifyContent: 'center',
    margin: '31px 10px 0 0',
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  filter: {
    margin: '5px 0 15px 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
  cardContainer: {
    padding: '30px 20px',
  },
  card: {
    color: '#000',
    backgroundColor: '#fff',
    margin: '0 10px',
    height: '100px',
  },
  completedCardContent: {
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  awardedCardContent: {
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  completed: {
    paddingTop: 5,
    fontSize: '1.00rem',
    lineHeight: '1.425rem',
    fontWeight: 500,
  },
  totalSummary: {
    color: btNavyBlueColor,
    fontSize: '.90rem',
    lineHeight: '1.425rem',
  },
  awardAmount: {
    color: btNavyBlueColor,
    fontSize: '1.75rem',
    lineHeight: '2.2rem',
  },
  headerSelect: {
    width: '160px',
  },
}));

const ScholarshipApplicationList = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const history = useHistory();
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [pageState, setPageState] = useState({
      allRecords: [],
      records: [],
      filter: '',
      fetchedRecords: false,
      loading: false,
      showAllRecords: false,
      sortDirection: 'asc',
      sortBy: '',
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [pageOptions, setPageOptions] = useState({
      searching: false,
      page: 0,
      perPage: 15,
    });
    const [filter, setFilter] = React.useState('');
    const [totalApps, setTotalApps] = React.useState(0);
    const [completedApps, setCompletedApps] = React.useState(0);
    const [completedPercent, setCompletedPercent] = React.useState(0);
    const [pageUserId, setPageUserId] = React.useState(0);

    // Awards
    const [showAwardsDialog, setShowAwardsDialog] = React.useState(false);
    const [appClicked, setAppClicked] = React.useState({});
    const [appSenior, setAppSenior] = React.useState({});
    const [showAddAward, setShowAddAward] = React.useState(false);
    const [awardPendingTotal, setAwardPendingTotal] = React.useState(0);
    const [awardAwardedTotal, setAwardAwardedTotal] = React.useState(0);
    const [awardTotal, setAwardTotal] = React.useState(0);
    const [totalAmountAwarded, setTotalAmountAwarded] = React.useState(0);
    const [seniorPlanning1, setSeniorPlanning1] = React.useState({});
    const [seniorPlanning2, setSeniorPlanning2] = React.useState({});
    const [seniorPlanningScore, setSeniorPlanningScore] = React.useState(0);
    const [originalStatus, setOriginalStatus] = React.useState('');
    const [appAwardsFetched, setAppAwardsFetched] = React.useState(false);
    const [yearsList, setYearsList] = React.useState([]);
    const [academicYear, setAcademicYear] = React.useState('');

    const fetchAllScholarshipAwards = async () => {
      try {
        const list = await callApi(auth, `awards`, 'GET');
        store.setAllAwards(list.result);
      } catch (error) {
        logException(error);
        setErrorMsg('An unexpected error has occurred. Please try again.');
        console.log('Error fetching list of all scholarship awards:', error);
      }
    };

    const handleYearSelectChange = (year) => {
      store.setShowCompletedScholarshipApps(true);
      setAcademicYear(year);
      fetchRecords(year);
    };

    const getBeginYear = (year) => {
      const yeararray = year.split(' - ');
      const begin = yeararray[0] + '-08-01';
      return begin;
    };

    const getEndYear = (year) => {
      const yeararray = year.split(' - ');
      const end = yeararray[1] + '-07-31';
      return end;
    };

    useEffect(() => {
      const fetchStaffSites = async () => {
        let siteList = [];
        try {
          if (store.staffsites.length === 0) {
            const email = auth.user.result.email;
            const sites = await callApi(auth, 'staff/sites/' + email, 'GET');
            sites.result.forEach((s, i) => {
              siteList.push(convertSiteCode(s.node_id));
            });
            store.setStaffSites(siteList);
          }
        } catch (error) {
          logException(error);
          console.warn('Error fetching staff sites:', error);
        }
      };
      if (auth && auth.user && store.staffsites.length === 0) {
        fetchStaffSites();
        setPageUserId(auth.user.result.id);
      }
    }, [auth]);

    const fetchRecords = async (year) => {
      try {
        setPageState({ ...pageState, loading: true });
        const rsp = await callApi(auth, `scholarship/submitted?siteList=${store.staffsites}&showAll=${store.showCompletedScholarshipApps}`, 'GET');
        const begin = dateOnlyFormat(getBeginYear(year));
        const end = dateOnlyFormat(getEndYear(year));
        const first = rsp.result.filter((s) => dateOnlyFormat(s.submitted_date) > begin);
        const second = first.filter((s) => dateOnlyFormat(s.submitted_date) < end);

        for (let i = 0; i < second.length; i++) {
          const rec = second[i];
          rec.name = getSortName(rec.first_name, rec.last_name, rec.alumni_updated_first_name, rec.alumni_updated_last_name);
          rec.displayStatus = displayStatus(rec.status);
        }

        setPageState({
          ...pageState,
          loading: false,
          records: [...second],
          allRecords: [...second],
          sortBy: 'status',
          fetchedRecords: true,
        });
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false, fetchedRecords: false });
        setErrorMsg('An unexpected error has occurred. Please try again.');
        console.log('Error fetching list of submitted scholarship applications:', error);
      }
    };

    useEffect(() => {
      let thisyear = '';
      const getAcademicYearsList = () => {
        let academicYears = [];

        const year = new Date().getFullYear();
        const firstYear = 2022;
        const lastYear = year;
        let start = firstYear;
        let end = firstYear + 1;

        for (var i = firstYear; i < lastYear + 1; i++) {
          academicYears.push({ 'value': start + ' - ' + end, 'label': start + ' - ' + end });
          start = start + 1;
          end = end + 1;
        }
        academicYears.push({ 'value': firstYear + ' - ' + (end - 1), 'label': 'All Records' });
        setYearsList(academicYears);
        // default select to latest year
        setAcademicYear(academicYears[academicYears.length - 2].value);
        thisyear = academicYears[academicYears.length - 1].value;
        fetchRecords(thisyear);
      };
      const fetchScholarshipStats = async () => {
        try {
          const scholarshipStats = await callApi(auth, `scholarship/dashboard?siteList=${store.staffsites}`, 'GET');
          const totalApps =
            scholarshipStats.completedApps + scholarshipStats.interviewApps + scholarshipStats.pendingApps + scholarshipStats.qualifiedApps;
          setCompletedApps(scholarshipStats.completedApps);
          setTotalApps(totalApps);
          if (totalApps > 0) {
            setCompletedPercent((scholarshipStats.completedApps / totalApps) * 100);
          } else {
            setCompletedPercent(0);
          }
        } catch (error) {
          logException(error);
          setErrorMsg('An unexpected error has occurred. Please try again.');
          console.log('Error fetching list of scholarship stats:', error);
        }
      };
      const fetchScholarships = async () => {
        try {
          const today = getCurrentDateTime();
          const list = await callApi(auth, `scholarships/list`, 'GET');

          const filteredList = list.result
            .filter((s) => dateOnlyFormat(s.begin_date) <= dateOnlyFormat(today) && (s.end_date >= dateOnlyFormat(today) || !s.end_date))
            .sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            });
          store.setScholarships(filteredList);
        } catch (error) {
          logException(error);
          setErrorMsg('An unexpected error has occurred. Please try again.');
          console.log('Error fetching list of scholarship list:', error);
        }
      };
      if (store.staffsites && store.staffsites.length > 0 && !pageState.fetchedRecords && !pageState.loading) {
        getAcademicYearsList();
        fetchAllScholarshipAwards();
        fetchScholarshipStats();
        fetchScholarships();
        if (auth && auth.user && auth.user.result && auth.user.result.id) {
          setPageUserId(auth.user.result.id);
        }
      }
    }, [store.staffsites, academicYear, pageState.fetchedRecords]);

    useEffect(() => {
      const getTotalAwards = () => {
        let total = 0;
        store.allAwards &&
          store.allAwards.length > 0 &&
          store.allAwards.forEach((a) => {
            if (a.status === 'awarded') {
              total += parseInt(a.amount, 10);
            }
          });
        setTotalAmountAwarded(total);
      };

      if (store.allAwards && store.allAwards.length > 0) {
        getTotalAwards();
      }
    }, [store.allAwards]);

    useEffect(() => {
      try {
        const plan1 = seniorPlanning1 ? JSON.parse(seniorPlanning1.planning_form_1_data) : null;
        const plan2 = seniorPlanning2 ? JSON.parse(seniorPlanning2.planning_form_2_data) : null;
        const planningScore = calculatePlanningScore(plan1, plan2);
        setSeniorPlanningScore(planningScore);
      } catch (e) {
        console.warn('Error reading planning form scores: ', e);
      }
    }, [seniorPlanning1, seniorPlanning2]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    useEffect(() => {
      if (appAwardsFetched) {
        const totalResults = updateAwardTotals(store.appAwards);
        setAwardPendingTotal(totalResults.pendingTotal);
        setAwardAwardedTotal(totalResults.awardedTotal);
        setAwardTotal(totalResults.total);
        setShowAwardsDialog(true);
      }
    }, [store.appAwards, appAwardsFetched]);

    // 'name' || key === 'status'
    // ? a[key].toLowerCase()
    // : // : key === 'submission_date'
    // ? dateToNum(dateToS(a[key]))
    // : key === 'pendingAwards' || 'awardedAwards' || 'awardedAmount'

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          const varA = !a.hasOwnProperty(key) ? '' : key === 'name' || key === 'displayStatus' ? a[key].toLowerCase() : a[key];
          const varB = !b.hasOwnProperty(key) ? '' : key === 'name' || key === 'displayStatus' ? b[key].toLowerCase() : b[key];
          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return order === 'desc' ? comparison * -1 : comparison;
        };
      };

      let filteredData = pageState.allRecords;

      if (filter && filter.length > 0) {
        filteredData = filteredData.filter(
          (s) =>
            (s.applicant_type && s.applicant_type.toLowerCase().includes(filter.toLowerCase())) ||
            (s.status && s.status.toLowerCase().includes(filter.toLowerCase())) ||
            (s.first_name && s.first_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.last_name && s.last_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.alumni_updated_first_name && s.alumni_updated_first_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.alumni_updated_last_name && s.alumni_updated_last_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.submitted_date && dateUtcToS(s.submitted_date).toString().includes(filter))
        );
      }

      let sortedRecords = filteredData;
      if (sortedRecords) {
        if (!pageState.showAllRecords) {
          sortedRecords = sortedRecords.filter((x) => !x.approved);
        }
        sortedRecords.sort(compareValues(pageState.sortBy, pageState.sortDirection));
        setPageState((p) => {
          return { ...p, records: sortedRecords };
        });
      }
    }, [pageState.showAllRecords, pageState.allrecords, pageState.sortBy, pageState.sortDirection, pageState.allRecords, filter]);

    const setSort = (val) => {
      let direction = 'asc';
      if (val === pageState.sortBy) {
        if (pageState.sortDirection === 'asc') {
          direction = 'desc';
        } else {
          direction = 'asc';
        }
        setPageState({ ...pageState, sortDirection: direction });
      } else {
        setPageState({ ...pageState, sortDirection: 'asc', sortBy: val });
      }
    };

    const dateToNum = (d) => {
      d = d.split('/');
      return Number(d[2] + d[0] + d[1]);
    };

    const filterData = (val) => {
      setFilter(val);
    };

    const fetchScholarshipAwards = async (id) => {
      try {
        const list = await callApi(auth, `awards/app?scholarship_application_id=${id}`, 'GET');
        store.setAppAwards(list.result);
        setAppAwardsFetched(true);
      } catch (error) {
        logException(error);
        setErrorMsg('An unexpected error has occurred. Please try again.');
        console.log('Error fetching list of scholarship awards by application:', error);
      }
    };

    const fetchPlanningForm1 = async (userId) => {
      try {
        const pf1 = await callApi(auth, 'planningform1/' + userId, 'GET');
        const score = pf1.result;
        setSeniorPlanning1(score);
        store.setPlanningForm1Data(score);
      } catch (error) {
        logException(error);
        setErrorMsg('An unexpected error has occurred. Please try again.');
        console.log('Error fetching planning form 1 data:', error);
      }
    };

    const fetchPlanningForm2 = async (userId) => {
      try {
        const pf2 = await callApi(auth, 'planningform2/' + userId, 'GET');
        const score = pf2.result;
        setSeniorPlanning2(score);
        store.setPlanningForm2Data(score);
      } catch (error) {
        logException(error);
        setErrorMsg('An unexpected error has occurred. Please try again.');
        console.log('Error fetching planning form 2 data:', error);
      }
    };

    const handleOpenAwardsDialog = async (event, record, id, type) => {
      event.preventDefault();
      event.stopPropagation();
      setAppClicked(record);
      setOriginalStatus(record.status);
      fetchScholarshipAwards(id);

      store.setScholarshipAppId(id);
      if (type === 'alumni') {
        store.setAlumni(true);
      } else {
        store.setAlumni(false);
      }

      try {
        setPageState({ ...pageState, loading: true });
        let senior = await callApi(auth, 'studentplan/' + record.userId, 'GET');
        setAppSenior(senior.result);
        if (senior.result) {
          fetchPlanningForm1(senior.result.id);
          fetchPlanningForm2(senior.result.id);
        }
        setPageState({ ...pageState, loading: false });
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false });
        console.log('Error fetching studentplan for scholarship app:', error);
      }
    };

    const closeAwardsDialog = () => {
      setShowAwardsDialog(false);
    };

    const handleOpenScholarshipApp = async (event, id, type) => {
      event.preventDefault();
      event.stopPropagation();
      store.setScholarshipAppId(id);
      if (type === 'alumni') {
        store.setAlumni(true);
      } else {
        store.setAlumni(false);
      }
      try {
        setPageState({ ...pageState, loading: true });
        let app = await callApi(auth, 'scholarship/' + id, 'GET');
        if (app && app.result) {
          store.setScholarshipApp(app.result);
          store.setScholarshipAppId(app.result.id);
          const appUserId = app.result.userId;
          let senior = await callApi(auth, 'studentplan/' + appUserId, 'GET');
          store.setSenior(senior.result);
        }
        setPageState({ ...pageState, loading: false });
        history.push('/scholarshipapp');
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false });
        console.log('Error fetching senior:', error);
      }
    };

    const handleCardSortChange = (event) => {
      setPageState({ ...pageState, sortBy: event.target.value });
    };

    const updateAwardScholarship = (rec, scholarship_id) => {
      const selectedName = getScholarshipNameFromId(scholarship_id, store.scholarships);

      // if an existing award is being updated, update corresponding store.appAwards record with new scholarship_id.
      if (rec.id > 0) {
        const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);

        if (ind > -1) {
          // update existing record with new scholarship_id and clear out name (it will be updated on save)
          rec.scholarship_id = scholarship_id;
          rec.name = selectedName;
          store.appAwards[ind] = rec;
        }
      } else {
        // if a new award is being added, update the store.appAwards record using the tempid
        const ind = store.appAwards.findIndex(
          (obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id
        );
        if (ind > -1) {
          rec.scholarship_id = scholarship_id;
          rec.name = selectedName;
          store.appAwards[ind] = rec;
        }
      }
    };

    const updateAwardStatus = (rec, status) => {
      // if an is being updated, update the store.appAwards record using the id
      if (rec.id > 0) {
        const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
        if (ind > -1) {
          rec.status = status;
          if (status === 'awarded' || status === 'denied') {
            rec.awarded_on = new Date();
          }
          store.appAwards[ind] = rec;
        }
      } else {
        // if a new award is being added, update the store.appAwards record using the tempid
        const ind = store.appAwards.findIndex(
          (obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id
        );
        if (ind > -1) {
          rec.status = status;
          if (status === 'awarded' || status === 'denied') {
            rec.awarded_on = new Date();
          }
          store.appAwards[ind] = rec;
        }
      }
    };

    const updateAwardAmount = (rec, amount) => {
      // if an award is being updated, update the store.appAwards record using the id
      if (rec.id > 0) {
        const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
        if (ind > -1) {
          rec.amount = amount;
          store.appAwards[ind] = rec;
        }
      } else {
        // if a new award is being added, update the store.appAwards record using the tempid
        const ind = store.appAwards.findIndex(
          (obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id
        );
        if (ind > -1) {
          rec.amount = amount;
          store.appAwards[ind] = rec;
        }
      }
    };

    const updateAwardNotes = (rec, notes) => {
      // if an award is being updated, update the store.appAwards record using the id
      if (rec.id > 0) {
        const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
        if (ind > -1) {
          rec.notes = notes;
          store.appAwards[ind] = rec;
        }
      } else {
        // if a new award is being added, update the store.appAwards record using the tempid
        const ind = store.appAwards.findIndex(
          (obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id
        );
        if (ind > -1) {
          rec.notes = notes;
          store.appAwards[ind] = rec;
        }
      }
    };

    const updateAwardedOn = (rec, awardedDate) => {
      // if an award is being updated, update the store.appAwards record using the id
      if (rec.id > 0) {
        const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
        if (ind > -1) {
          rec.awarded_on = awardedDate;
          store.appAwards[ind] = rec;
        }
      } else {
        // if a new award is being added, update the store.appAwards record using the tempid
        const ind = store.appAwards.findIndex(
          (obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id
        );
        if (ind > -1) {
          rec.awarded_on = awardedDate;
          store.appAwards[ind] = rec;
        }
      }
    };

    const updateAward = async (award) => {
      try {
        setPageState({ ...pageState, loading: true });

        let scholarshipName = '';
        let version = 0;
        const ind = store.scholarships.findIndex((obj) => obj.id === award.scholarship_id);

        if (ind > -1) {
          scholarshipName = store.scholarships[ind].name;
          version = store.scholarships[ind].version;
        }

        let item = {
          id: award.id,
          scholarship_application_id: award.scholarship_application_id,
          userId: award.userId,
          scholarship_id: award.scholarship_id,
          version: version,
          name: scholarshipName,
          status: award.status,
          amount: award.amount,
          notes: award.notes,
          awarded_on: award.status === 'pending' ? null : award.awarded_on,
          updated_at: new Date(),
          updated_by: pageUserId,
        };

        const res = await callApi(auth, 'awards', 'PUT', { item });

        // Updated award record in awards lists
        if (res.result) {
          let tempAward = store.appAwards.find((s) => s.id === res.id);
          if (tempAward) {
            tempAward = res.result;
          }
          let tempAllAward = store.allAwards.find((s) => s.id === res.id);
          if (tempAllAward) {
            tempAllAward = res.result;
          }
        }

        appInsights.trackTrace({ message: `Scholarship award updated by ${auth.user.result.email}` });
        const auditObj = {
          audit_ts: new Date(),
          user_id: pageUserId,
          username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          audit_type: 'scholarship award',
          action: 'updated',
          value: JSON.stringify(item).substring(0, 999),
        };
        logAudit(auth, auditObj);
        setPageState({ ...pageState, loading: false });

        const totalResults = updateAwardTotals(store.appAwards);
        setAwardPendingTotal(totalResults.pendingTotal);
        setAwardAwardedTotal(totalResults.awardedTotal);
        setAwardTotal(totalResults.total);

        fetchAllScholarshipAwards(); // update totals for all
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false });
        console.log('Error writing updated scholarship award:', error);
      }
    };

    const createAward = async (award) => {
      try {
        setPageState({ ...pageState, loading: true });

        let scholarshipName = '';
        let version = 0;
        const ind = store.scholarships.findIndex((obj) => obj.id === award.scholarship_id);
        if (ind > -1) {
          scholarshipName = store.scholarships[ind].name;
          version = store.scholarships[ind].version;
        }

        let item = {
          scholarship_application_id: award.scholarship_application_id,
          userId: appSenior.userId,
          scholarship_id: award.scholarship_id,
          version: version,
          name: scholarshipName,
          status: award.status || 'pending',
          amount: award.amount,
          notes: award.notes,
          awarded_on: award.awarded_on ? award.awarded_on : award.status === 'awarded' ? new Date() : null,
          'updated_at': new Date(),
          'updated_by': pageUserId,
        };

        const app = await callApi(auth, 'awards', 'POST', { item });

        // Updated award record in awards lists
        store.appAwards.push(app.result);
        store.allAwards.push(app.result);

        appInsights.trackTrace({ message: `Scholarship award created by ${auth.user.result.email}` });
        const auditObj = {
          audit_ts: new Date(),
          user_id: pageUserId,
          username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          audit_type: 'scholarship award',
          action: 'created',
          value: JSON.stringify(item).substring(0, 999),
        };
        logAudit(auth, auditObj);
        setPageState({ ...pageState, loading: false });

        updateAwardTotals(store.appAwards); // Update totals for this application
        fetchAllScholarshipAwards(); // update totals for all
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false });
        console.log('Error creating scholarship award:', error);
      }
    };

    const sendApplicationReturnedEmail = async (id) => {
      try {
        await callApi(auth, 'scholarshipreturnemail', 'POST', { student_id: id });
      } catch (error) {
        logException(error);
        console.log('Error sending scholarship returned email:', error);
      }
    };

    const updateApplicationStatus = async (id, status) => {
      try {
        setPageState({ ...pageState, loading: true });

        const fetchIndividualApp = await callApi(auth, `scholarship/${id}`, 'GET');
        const app = fetchIndividualApp.result;

        // Send email
        if (originalStatus !== 'returned' && status === 'returned') {
          await sendApplicationReturnedEmail(app.userId);
        }

        const item = {
          id: app.id,
          userId: app.userId,
          application: JSON.parse(app.application),
          status,
          submitted_date: app.submitted_date,
          disposition_reason: app.disposition_reason,
          youth_strengths: app.youth_strengths,
          ssm_model_category: app.ssm_model_category,
          application_score: app.application_score,
          essay_score: app.essay_score,
          spirituality_score: app.spirituality_score,
          pell_amount: app.pell_amount,
          planning_meeting_1_score: app.planning_meeting_1_score,
          planning_meeting_2_score: app.planning_meeting_2_score,
          gpa_score: app.gpa_score,
          financial_need_score: app.financial_need_score,
          length_of_stay_score: app.length_of_stay_score,
          essay_voice_score: app.essay_voice_score,
          essay_inspiration_score: app.essay_inspiration_score,
          essay_word_score: app.essay_word_score,
          essay_completed_score: app.essay_completed_score,
          citizenship_letters_score: app.citizenship_letters_score,
          citizenship_activities_score: app.citizenship_activities_score,
          citizenship_rolemodel_score: app.citizenship_rolemodel_score,
          status_date: new Date(),
          applicant_type: app.applicant_type,
          status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        };

        const res = await callApi(auth, 'scholarship', 'PUT', { item });

        // Updated status in page table list
        if (res.result) {
          setAppClicked({ ...appClicked, status });
          store.setScholarshipApp(res.result);
          store.setScholarshipAppId(res.result.id);
          let tempApp = pageState.records.find((s) => s.id === app.id);
          if (tempApp) {
            tempApp.status = res.result.status;
          }
        }

        setPageState({ ...pageState, loading: false });
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false });
        console.log('Error updating scholarship application status:', error);
      }
    };

    const deleteAward = async (record) => {
      try {
        setPageState({ ...pageState, loading: true });

        if (record.id && record.id > 0) {
          await callApi(auth, 'awards/' + record.id, 'DELETE');

          appInsights.trackTrace({ message: `Scholarship award ${record.id} deleted by ${auth.user.result.email}` });
          const auditObj = {
            audit_ts: new Date(),
            user_id: pageUserId,
            username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
            audit_type: 'scholarship award',
            action: 'deleted',
            value: 'Award ' + JSON.stringify(record) + ' deleted.',
          };
          logAudit(auth, auditObj);

          // Remove award from app awards list and all awards list.
          const awards = store.appAwards;
          const idx = awards.findIndex((s) => s.id === record.id);
          if (idx > -1) {
            awards.splice(idx, 1);
            store.setAppAwards(awards);
          }
          const allAwards = store.allAwards;
          const idx3 = allAwards.findIndex((s) => s.id === record.id);
          if (idx3 > -1) {
            allAwards.splice(idx3, 1);
            store.setAllAwards(allAwards);
          }

          updateAwardTotals(store.appAwards); // Update totals for this application
          fetchAllScholarshipAwards(); // update totals for all
        } else {
          const awards = store.appAwards;
          const ind = awards.findIndex((s) => s.tempid === record.tempid);
          if (ind > -1) {
            awards.splice(ind, 1);
            store.setAppAwards(awards);
          }
        }
        setPageState({ ...pageState, loading: false });
      } catch (error) {
        logException(error);
        setPageState({ ...pageState, loading: false });
        console.log('Error deleting scholarship award:', error);
      }
    };

    const handleShowAll = () => {
      store.setShowCompletedScholarshipApps(!store.showCompletedScholarshipApps);
      fetchRecords(academicYear);
    };

    const emptyRows =
      pageOptions.perPage - Math.min(pageOptions.perPage, pageState.records && pageState.records.length - pageOptions.page * pageOptions.perPage);

    if (auth.roles && auth.roles.includes('STUDENT')) {
      return <PageUnauthorized />;
    }

    return (
      <Grid container className={classes.whiteBg}>
        <React.Fragment>
          <PleaseWait isLoading={pageState.loading} />
          <TitleBar title="Scholarship Applications" currentPage="Scholarship Applications" paths={[{ path: '/', name: 'Home' }]} />
          <Grid container className={classes.whiteBg} align="left">
            <Grid item xs={12} sm={7} md={4} lg={3} className={classes.cardContainer} align="left">
              <Card className={classes.card}>
                <CardBody className={classes.completedCardContent}>
                  {auth && auth.user && (
                    <summary>
                      <div className={classes.completed}>Completed</div>
                      <div className={classes.totalSummary}>
                        {completedApps} out of {totalApps} applications
                      </div>
                      <div className={classes.awardAmount}>{completedPercent.toFixed(0)}%</div>
                    </summary>
                  )}

                  <svg width="95px" height="95px" viewBox="0 0 42 42" class="donut" style={{ marginRight: 10 }}>
                    <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                    <circle
                      class="donut-ring"
                      cx="21"
                      cy="21"
                      r="15.91549430918954"
                      fill="transparent"
                      stroke="rgba(255, 185, 70, 1)"
                      stroke-width="4"
                    ></circle>
                    <circle
                      class="donut-segment"
                      cx="21"
                      cy="21"
                      r="15.91549430918954"
                      fill="transparent"
                      stroke="rgba(5, 115, 29, 1)"
                      stroke-width="4"
                      stroke-dasharray={[completedPercent, 100]}
                      stroke-dashoffset="0"
                    ></circle>
                  </svg>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7} md={3} lg={2} className={classes.cardContainer} align="left">
              <Card className={classes.card}>
                <CardBody className={classes.awardedCardContent}>
                  <summary>
                    <div className={classes.completed}>Total Awarded</div>
                    <div className={classes.totalSummary}>
                      {store.allAwards && store.allAwards.filter((a) => a.status === 'awarded').length} awards to {totalApps} applicants
                    </div>
                  </summary>
                  <div className={classes.awardAmount}>
                    {totalAmountAwarded &&
                      totalAmountAwarded > 0 &&
                      totalAmountAwarded.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                  </div>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={false} sm={false} md={5} lg={7} align="right" className={classes.whiteBg}></Grid>
          </Grid>
          <Grid container className={classes.whiteBg}>
            <Grid item xs={12}>
              <FormLabel error>{errorMsg}</FormLabel>
            </Grid>

            <Grid item xs={12} className={classes.whiteBg} align="left">
              <Grid item xs={6} className={classes.whiteBg} align="left"></Grid>
              <Grid item xs={6} align="right" className={classes.whiteBg}></Grid>

              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.whiteBg} align="left">
                    <TextField
                      id="filter-field"
                      type="search"
                      className={classes.filter}
                      size="small"
                      hiddenLabel
                      placeholder="Filter"
                      variant="outlined"
                      onChange={(event) => filterData(event.target.value)}
                      clearable="true"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.whiteBg} align="left">
                    <div style={{ marginTop: 10, marginLeft: 15 }}>
                      <span style={{ margin: '20px 10px 0 10px' }}>
                        <strong>Academic Year:</strong>
                      </span>
                      <FormControl>
                        <Select
                          labelId="academic-year-label"
                          id="academic-year"
                          value={academicYear}
                          onChange={(event) => handleYearSelectChange(event.target.value)}
                          className={classes.headerSelect}
                        >
                          {yearsList.slice().map((year) => {
                            return (
                              <MenuItem value={year.value} key={year.value} style={{ fontSize: '.9rem' }}>
                                {year.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={6} align="left" className={classes.whiteBg}>
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Show Completed Applications"
                        onChange={(ev) => handleShowAll()}
                        checked={store.showCompletedScholarshipApps}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '160px' }}></TableCell>
                          <Tooltip title="Click to sort by name." placement="top">
                            <TableCell onClick={() => setSort('name')} className={classes.sortHeader} style={{ width: '200px' }}>
                              YOUNG ADULT{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'name' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'name' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by status." placement="top">
                            <TableCell onClick={() => setSort('displayStatus')} className={classes.sortHeader} style={{ width: '150px' }}>
                              STATUS{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'displayStatus' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'displayStatus' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by submitted date." placement="top">
                            <TableCell onClick={() => setSort('submitted_date')} className={classes.sortHeader} style={{ width: '180px' }}>
                              SUBMITTED ON{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by number of pending awards." placement="top">
                            <TableCell className={classes.noSortHeader} style={{ width: '120px' }}>
                              <TableCell onClick={() => setSort('pendingAwards')} className={classes.sortHeader} style={{ width: '200px' }}>
                                # PENDING{' '}
                                <span
                                  className="fas fa-caret-up"
                                  style={{
                                    display:
                                      pageState.sortBy === 'pendingAwards' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                  }}
                                />
                                <span
                                  className="fas fa-caret-down"
                                  style={{
                                    display:
                                      pageState.sortBy === 'pendingAwards' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                  }}
                                />
                              </TableCell>
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by number of awarded awards." placement="top">
                            <TableCell className={classes.noSortHeader} style={{ width: '120px' }}>
                              <TableCell onClick={() => setSort('awardedAwards')} className={classes.sortHeader} style={{ width: '200px' }}>
                                # AWARDED{' '}
                                <span
                                  className="fas fa-caret-up"
                                  style={{
                                    display:
                                      pageState.sortBy === 'awardedAwards' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                  }}
                                />
                                <span
                                  className="fas fa-caret-down"
                                  style={{
                                    display:
                                      pageState.sortBy === 'awardedAwards' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                  }}
                                />
                              </TableCell>
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by amount of awards." placement="top">
                            <TableCell
                              onClick={() => setSort('awardedAmount')}
                              className={classes.sortHeader}
                              style={{ width: '150px', textAlign: 'center' }}
                            >
                              $ AWARDED{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'awardedAmount' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'awardedAmount' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pageOptions.searching ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          pageState.records &&
                          pageState.records
                            .slice(pageOptions.page * pageOptions.perPage, pageOptions.page * pageOptions.perPage + pageOptions.perPage)
                            .map((record, idx) => (
                              <TableRow key={record.id} className={classes.tableRow}>
                                <TableCell
                                  style={{
                                    width: '100px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.approvalLink}
                                    aria-describedby="approve request"
                                    onClick={(ev) => handleOpenAwardsDialog(ev, record, record.id, record.applicant_type)}
                                  >
                                    AWARDS
                                  </Button>
                                  <Button
                                    color="default"
                                    variant="outlined"
                                    className={classes.approvalLink}
                                    aria-describedby="approve request"
                                    onClick={(ev) => handleOpenScholarshipApp(ev, record.id, record.applicant_type)}
                                  >
                                    REVIEW
                                  </Button>
                                </TableCell>
                                <TableCell style={{ width: '200px' }}>
                                  {displayApplicantName(record)}{' '}
                                  {record.applicant_type && record.applicant_type.length > 0 && record.applicant_type === 'alumni'
                                    ? '(' + titleize(record.applicant_type) + ')'
                                    : ''}
                                </TableCell>
                                <TableCell style={{ width: '150px' }}>{displayStatus(record.status)}</TableCell>
                                <TableCell style={{ width: '180px' }}>{dateTimeToS(record.submitted_date)}</TableCell>
                                <TableCell style={{ width: '120px', textAlign: 'center' }}>{record.pendingAwards}</TableCell>
                                <TableCell style={{ width: '110px', textAlign: 'center' }}>{record.awardedAwards}</TableCell>
                                <TableCell style={{ width: '150px', textAlign: 'center' }}>
                                  {record.awardedAmount &&
                                    record.awardedAmount > 0 &&
                                    parseInt(record.awardedAmount, 10).toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })}
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                        {/* application essayText asvabScore gradDate alumniSignature */}
                        {!pageOptions.searching && pageState.records && pageState.records.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noSeniorsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        menuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100]}
                      count={pageState.records ? pageState.records.length : 0}
                      rowsPerPage={pageOptions.perPage}
                      page={pageOptions.page}
                      onChangePage={(ev, val) => {
                        setPageOptions({ ...pageOptions, page: val });
                      }}
                      onRowsPerPageChange={(ev) => setPageOptions({ ...pageOptions, perPage: parseInt(ev.target.value, 10), page: 0 })}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                </Hidden>

                <Hidden lgUp implementation="css">
                  <Grid container>
                    <Grid item xs={12} sm={7} md={5} className={classes.radioRow}>
                      <RadioGroup
                        aria-label="card-sorting"
                        name="date-group"
                        className={classes.centerRadioSorting}
                        row
                        value={pageState.sortBy}
                        onChange={handleCardSortChange}
                      >
                        <FormControlLabel
                          value="status_by"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Name</span>}
                          onClick={() => setSort('status_by')}
                          className={classes.radioLabel}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'status_by' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: pageState.sortBy === 'status_by' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                        <FormControlLabel
                          value="submission_date"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Date</span>}
                          onClick={() => setSort('submission_date')}
                          className={classes.radioLabel}
                          style={{
                            marginLeft: '20px',
                          }}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display:
                                pageState.sortBy === 'submission_date' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display:
                                pageState.sortBy === 'submission_date' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <ScholarshipApplicationCards
                    list={pageState.records}
                    open={handleOpenScholarshipApp}
                    showAwards={handleOpenAwardsDialog}
                    displayStatus={displayStatus}
                    displayApplicantName={displayApplicantName}
                  />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <AwardDialog
            showDialog={showAwardsDialog}
            setShowDialog={setShowAwardsDialog}
            handleClose={closeAwardsDialog}
            application={appClicked}
            senior={appSenior}
            user={pageUserId}
            scholarships={store.scholarships}
            appAwards={store.appAwards}
            setAppAwards={store.setAppAwards}
            showAddAward={showAddAward}
            setShowAddAward={setShowAddAward}
            updateAward={updateAward}
            createAward={createAward}
            updateAwardScholarship={updateAwardScholarship}
            updateAwardStatus={updateAwardStatus}
            updateAwardAmount={updateAwardAmount}
            updateAwardNotes={updateAwardNotes}
            updateAwardedOn={updateAwardedOn}
            awardPendingTotal={awardPendingTotal}
            awardAwardedTotal={awardAwardedTotal}
            awardTotal={awardTotal}
            seniorPlanningScore={seniorPlanningScore}
            updateApplicationStatus={updateApplicationStatus}
            deleteAward={deleteAward}
            parent="applist"
          />
        </React.Fragment>
      </Grid>
    );
  })
);

export default ScholarshipApplicationList;
