import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, Chip, Button } from '@material-ui/core';
import { convertFile, arrayBufferToBase64 } from '../../../util/stringUtil';

const WorkforcePlan = ({ form, setForm, classes, disabled }) => {
  const [uploadError, setUploadError] = useState();
  const [resume, setResume] = useState(null);

  useEffect(() => {
    if (form.resume && resume === null) {
      let tempFile = JSON.parse(JSON.stringify(form.resume));
      tempFile.browserFile = convertFile({ file: tempFile.data, file_type: tempFile.file_type, file_name: tempFile.filename });
      setResume(tempFile);
    }
  }, [form.resume, resume]);

  const downloadFile = async (file) => {
    let a = document.createElement('a'); //Create <a>
    a.href = `data:${file.browserFile.type};base64,${arrayBufferToBase64(await file.browserFile.arrayBuffer())}`;
    a.download = file.browserFile.name;
    a.click();
  };

  const removeFile = () => {
    setResume(null);
    setForm({ ...form, resume: null });
  };

  const checkExtensionForDocument = (file) => {
    return /\.(doc|docx|pdf|txt)$/i.test(file[0].name);
  };

  const confirmFileUpload = async (file) => {
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
          'browserFile': convertFile({ file: data, file_type: file[0].type, file_name: file[0].name }),
        };
        setResume(filedetails);
        let tempFile = JSON.parse(JSON.stringify(filedetails));
        delete tempFile.browserFile;
        setForm({ ...form, resume: tempFile });
        setUploadError('');
      } else {
        setUploadError('Error: The file could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded.');
      }
    } catch (error) {
      console.log('Error setting resume details:', error);
      setUploadError('Sorry, an error occurred. Please try again.');
    }
  };

  return (
    <>
      {form.notCurrentlyEmployed && (
        <>
          <Grid container direction="row" className={classes.unprintedContent} style={{ marginTop: 15 }}>
            <Grid item xs={12} sm={3} className={classes.textRowLabelSpacer}>
              <label>Potential Employers</label>
            </Grid>
            <Grid item xs={1} sm={true} />
            <Grid item xs={11} sm={9} className={classes.rowSpacer50}>
              <FormControl fullWidth>
                <TextField
                  id="potential-employers"
                  size="small"
                  hiddenLabel
                  placeholder="List potential employers."
                  variant="outlined"
                  value={form.potentialEmployers}
                  onChange={(event) => setForm({ ...form, potentialEmployers: event.target.value })}
                  InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={3}>
              <label>Potential Employers</label>
            </Grid>
            <Grid item xs={9}>
              {form.potentialEmployers}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={3} className={classes.textRowLabelSpacer}>
          <label>Type of Job</label>
        </Grid>
        <Grid item xs={1} sm={true} />
        <Grid item xs={11} sm={9} className={classes.rowSpacer50}>
          <FormControl fullWidth>
            <TextField
              id="type-of-job"
              size="small"
              hiddenLabel
              placeholder="Describe the type of job being persued or career interest."
              variant="outlined"
              value={form.jobType}
              onChange={(event) => setForm({ ...form, jobType: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Type of Job</label>
        </Grid>
        <Grid item xs={9}>
          {form.jobType}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={3} className={classes.textRowLabelSpacer}>
          <label>Housing</label>
        </Grid>
        <Grid item xs={1} sm={true} />
        <Grid item xs={11} sm={9} className={classes.rowSpacer50}>
          <FormControl fullWidth>
            <TextField
              id="housing-plans"
              size="small"
              hiddenLabel
              placeholder="Explain my housing plans."
              variant="outlined"
              value={form.housingPlans}
              onChange={(event) => setForm({ ...form, housingPlans: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Housing</label>
        </Grid>
        <Grid item xs={9}>
          {form.housingPlans}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={3} className={classes.textRowLabelSpacer}>
          <label>Transportation</label>
        </Grid>
        <Grid item xs={1} sm={true} />
        <Grid item xs={11} sm={9} className={classes.rowSpacer50}>
          <FormControl fullWidth>
            <TextField
              id="transport-plans"
              size="small"
              hiddenLabel
              placeholder="Describe my plans for transportation."
              variant="outlined"
              value={form.transportPlans}
              onChange={(event) => setForm({ ...form, transportPlans: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Transportation</label>
        </Grid>
        <Grid item xs={9}>
          {form.transportPlans}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={3} className={classes.textRowLabelSpacer}>
          <label>Resume</label>
        </Grid>
        <Grid item xs={1} sm={true} />
        <Grid item xs={11} sm={9} className={classes.rowSpacer50}>
          <div style={{ padding: '10px 0' }}>
            {resume && (
              <div style={{ marginBottom: 5 }}>
                <Chip
                  style={{ margin: 5 }}
                  label={resume && resume.filename}
                  color="primary"
                  onDelete={disabled ? null : removeFile}
                  onClick={() => downloadFile(resume)}
                />
              </div>
            )}
            {!resume && (
              <div>
                <Button variant="outlined" component="label" color="primary">
                  Upload Resume
                  <input type="file" onChange={(e) => confirmFileUpload(e.target.files)} hidden />
                </Button>
                {resume && resume.name}
                <br />
                <aside className={classes.error}>{uploadError}</aside>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3} sm={3}>
          <label>Resume</label>
        </Grid>
        <Grid item xs={9}>
          {resume && <span>{resume.filename} has been uploaded.</span>}
        </Grid>
      </Grid>
    </>
  );
};

WorkforcePlan.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default WorkforcePlan;
