import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Card from '../../Card/Card.js';
import CardBody from '../../Card/CardBody.js';
import HomeIcon from '@material-ui/icons/Home';
import PrintIcon from '@material-ui/icons/Print';
import HelpIcon from '@material-ui/icons/Help';
import ChecklistIcon from '../../../assets/img/planner-checklist-icon.svg';
import SeniorChecklist from '../../Checklist/Checklist';
import { useReactToPrint } from 'react-to-print';

const Checklist = ({ senior, alumni, roles, handleHelp, store, auth, handleAfterSaveReturn, setPage, handleReturnToSeniorList, classes }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      {!roles.includes('STUDENT') && !roles.includes('ALUMNI') && (
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12}>
            <div className={classes.breadcrumb}>
              <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnToSeniorList()}>
                  {alumni ? 'Alumni List' : 'Senior List'}
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(e) => setPage('home')}>
                  {alumni ? 'Alumni Planner' : 'Senior Planner'}
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbText}>
                  Checklist {senior && 'for ' + senior.first_name + ' ' + senior.last_name}
                </Typography>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} direction="row" className={classes.menu} ref={componentRef}>
        <Grid item xs={12} align="left" className={classes.unprintedContent}>
          <Card className={classes.pageHeader}>
            <CardBody className={classes.headerCard}>
              <Grid container direction="row">
                <Grid item xs={10} align="left">
                  <section className={classes.pageTitle}>
                    <img src={ChecklistIcon} alt="Checklist Icon" className={classes.pageIcon} />
                    <span className={classes.pagecardHeader}>Senior Checklist</span>
                  </section>
                  {!alumni && !roles.includes('ALUMNI') && (
                    <aside className={classes.headerDesc}>
                      This checklist will help you keep track of the most relevant activities to be completed during the year. In addition, it will
                      also help you get ready to embrace your chosen path after graduation. Each activity will earn you points and points will be
                      converted into a financial incentive. Work on this checklist with your Family Teachers and Successful Futures Specialist and
                      bring them to each of the Senior Planning meetings. Please make sure you can show documentation that proves each of the
                      activities were completed.
                    </aside>
                  )}
                  {(alumni || roles.includes('ALUMNI')) && (
                    <aside className={classes.headerDesc}>
                      This checklist was completed by the alum during their senior year of high school to help them keep track of the most relevant
                      activities to be completed during the year. In addition, it helped them plan their chosen path after high school graduation. You
                      cannot modify this form.
                    </aside>
                  )}
                </Grid>
                <Grid item xs={2} align="right">
                  <Tooltip title="Click to return to the planner home page." placement="bottom">
                    <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                      <HomeIcon className={classes.homeIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Click to return to open the printer dialog." placement="bottom">
                    <IconButton color="inherit" aria-label="print senior checklist" onClick={handlePrint}>
                      <PrintIcon className={classes.homeIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Click to return to view printer tips & tricks." placement="bottom">
                    <IconButton color="inherit" aria-label="printer tips and tricks" onClick={handleHelp}>
                      <HelpIcon className={classes.homeIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} align="left" className={classes.printedContent}>
          <section className={classes.pageTitle}>
            <span className={classes.printHeaderText}>Senior Checklist</span>
          </section>
          <aside className={classes.printHeaderDesc}>
            {/* Please use this checklist to help with your senior planning activities and bring it to your Senior Planning meetings. */}
            This checklist will help you keep track of the most relevant activities to be completed during the year. In addition, it will also help
            you get ready to embrace your chosen path after graduation. Each activity will earn you points and points will be converted into a
            financial incentive. Work on this checklist with your Family Teachers and Successful Futures Specialist and bring them to each of the
            Senior Planning meetings. Please make sure you can show documentation that proves each of the activities were completed.
          </aside>
        </Grid>
        <Grid item xs={12}>
          <SeniorChecklist SeniorStore={store} AuthStore={auth} handleSaveReturn={handleAfterSaveReturn} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Checklist.propTypes = {
  senior: PropTypes.object,
  alumni: PropTypes.bool,
  roles: PropTypes.array,
  handleHelp: PropTypes.func,
  store: PropTypes.object,
  auth: PropTypes.object,
  handleAfterSaveReturn: PropTypes.func,
  setPage: PropTypes.func,
  handleReturnToSeniorList: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default Checklist;
