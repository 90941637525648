import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { dateToS, dateTimeToS, dateUtcToS } from '../../util/dateUtil';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormLabel,
  Grid,
  Hidden,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
  Typography,
  TablePagination,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableStyle from '../../styles/TableStyle';
import { observer, inject } from 'mobx-react';
import { logException } from '../../services/appInsights';
import { btNavyBlueColor, btMediumGrayColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import { callApi } from '../../util/apiWrapper';
import TitleBar from '../Shared/TitleBar';
import HelpApplicationCards from './HelpApplicationCards';
import PageUnauthorized from '../Shared/PageUnauthorized';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles((theme) => ({
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  approvalLink: {
    fontSize: '0.85rem',
    padding: '2px, 5px',
    textTransform: 'none',
    justifyContent: 'center',
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  filter: {
    margin: '5px 0 15px 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
}));

const summarizeHelpOptions = (app) => {
  if (!app || (app && app.length === 0)) return [];
  let list = [];
  const appValues = JSON.parse(app);
  if (appValues.coaching) {
    list.push('Coaching');
  }
  if (appValues.dailyLiving) {
    list.push('Daily Living');
  }
  if (appValues.education) {
    list.push('Education');
  }
  if (appValues.employment) {
    list.push('Employment');
  }
  if (appValues.finances) {
    list.push('Finances');
  }
  if (appValues.food) {
    list.push('Food');
  }
  if (appValues.health) {
    list.push('Health');
  }
  if (appValues.housing) {
    list.push('Housing');
  }
  if (appValues.legalDocs) {
    list.push('Legal Documents');
  }
  if (appValues.lifeSkills) {
    list.push('Life Skills');
  }
  if (appValues.mentalHealth) {
    list.push('Mental Health');
  }
  if (appValues.scholarships) {
    list.push('Scholarships');
  }
  if (appValues.transportation) {
    list.push('Transportation');
  }
  if (appValues.utilities) {
    list.push('Utilities');
  }
  if (appValues.other) {
    list.push('Other');
  }
  return list;
};

const HelpApplicationList = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const history = useHistory();
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [pageState, setPageState] = useState({
      allRecords: [],
      records: [],
      filter: '',
      fetchedRecords: false,
      loading: false,
      showAllRecords: false,
      sortDirection: 'asc',
      sortBy: '',
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [pageOptions, setPageOptions] = useState({
      searching: false,
      page: 0,
      perPage: 15,
    });
    const [filter, setFilter] = React.useState('');

    useEffect(() => {
      const fetchRecords = async () => {
        try {
          setPageState({ ...pageState, loading: true });
          const rsp = await callApi(auth, 'helprequest/pending', 'GET');
          rsp.result.forEach((r, i) => {
            r.submitted_date_formatted = dateTimeToS(r.submitted_date);
          });
          setPageState({
            ...pageState,
            loading: false,
            records: [...rsp.result],
            allRecords: [...rsp.result],
            sortBy: 'status',
            fetchedRecords: true,
          });
        } catch (error) {
          logException(error);
          setPageState({ ...pageState, loading: false, fetchedRecords: true });
          setErrorMsg('An unexpected error has occurred. Please try again.');
          console.log('Error fetching list of submitted help applications:', error);
        }
      };
      if (auth.authToken && !pageState.fetchedRecords && !pageState.loading) {
        fetchRecords();
      }
      // }, []);
    }, [auth, auth.authToken, pageState, pageState.fetchedRecords, pageState.allRecords]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          const varA = !a.hasOwnProperty(key)
            ? ''
            : key === 'id'
            ? new Date().getYear() + a[key].toString().padStart(6, 0)
            : key === 'submitted_by' || key === 'status'
            ? a[key].toLowerCase()
            : key === 'assigned_to'
            ? lookupAssignee(a[key])
            : key === 'submission_date'
            ? dateToNum(dateToS(a[key]))
            : a[key];
          const varB = !b.hasOwnProperty(key)
            ? ''
            : key === 'id'
            ? new Date().getYear() + b[key].toString().padStart(6, 0)
            : key === 'submitted_by' || key === 'status'
            ? b[key].toLowerCase()
            : key === 'assigned_to'
            ? lookupAssignee(b[key])
            : key === 'submission_date'
            ? dateToNum(dateToS(b[key]))
            : b[key];
          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return order === 'desc' ? comparison * -1 : comparison;
        };
      };

      let filteredData = pageState.allRecords;

      if (filter && filter.length > 0) {
        filteredData = filteredData.filter(
          (s) =>
            (s.id && s.id.toString().includes(filter.toLowerCase())) ||
            (s.status && s.status.toLowerCase().includes(filter.toLowerCase())) ||
            (s.status_by && s.status_by.toLowerCase().includes(filter.toLowerCase())) ||
            (s.application && summarizeHelpOptions(s.application).join(', ').toLowerCase().includes(filter.toLowerCase())) ||
            (s.submitted_date_formatted && dateUtcToS(s.submitted_date_formatted).toString().includes(filter.toLowerCase())) ||
            (s.assigned_to && lookupAssignee(s.assigned_to).toLowerCase().includes(filter.toLowerCase()))
        );
      }

      let sortedRecords = filteredData;
      if (sortedRecords) {
        if (!pageState.showAllRecords) {
          sortedRecords = sortedRecords.filter((x) => !x.approved);
        }
        sortedRecords.sort(compareValues(pageState.sortBy, pageState.sortDirection));
        setPageState((p) => {
          return { ...p, records: sortedRecords };
        });
      }
    }, [pageState.showAllRecords, pageState.allrecords, pageState.sortBy, pageState.sortDirection, pageState.allRecords, filter]);

    const setSort = (val) => {
      let direction = 'asc';
      if (val === pageState.sortBy) {
        if (pageState.sortDirection === 'asc') {
          direction = 'desc';
        } else {
          direction = 'asc';
        }
        setPageState({ ...pageState, sortDirection: direction });
      } else {
        setPageState({ ...pageState, sortDirection: 'asc', sortBy: val });
      }
    };

    const dateToNum = (d) => {
      d = d.split('/');
      return Number(d[2] + d[0] + d[1]);
    };

    const filterData = (val) => {
      setFilter(val);
    };

    const handleOpenHelpApp = async (event, id, type) => {
      event.preventDefault();
      event.stopPropagation();
      store.setHelpAppId(id);
      store.setAlumni(true);
      try {
        store.setLoading(true);
        store.setSearching(true);
        let app = await callApi(auth, 'helprequest/' + id, 'GET');
        store.setHelpApp(app.result);
        store.setHelpAppId(app.result.id);
        const appUserId = app.result.userId;
        let senior = await callApi(auth, 'studentplan/' + appUserId, 'GET');
        store.setSenior(senior.result);
        store.setSearching(false);
        store.setLoading(false);
        history.push('/helprequest');
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error fetching senior:', error);
      }
    };

    const handleCardSortChange = (event) => {
      setPageState({ ...pageState, sortBy: event.target.value });
    };

    const displayStatus = (status) => {
      if (status === 'new') {
        return 'New';
      }
      if (status === 'inprogress') {
        return 'In Progress';
      }
      if (status === 'completed') {
        return 'Completed';
      }
      if (status === 'created') {
        return 'Created';
      }
      return status;
    };

    const lookupAssignee = (assigned_to) => {
      if (!assigned_to || assigned_to === 0) return '';
      const idx = store.aftercareSpecialistList.findIndex((s) => s.id === assigned_to);
      if (idx > -1) {
        return store.aftercareSpecialistList[idx].user.first_name + ' ' + store.aftercareSpecialistList[idx].user.last_name;
      }
    };

    const emptyRows =
      pageOptions.perPage - Math.min(pageOptions.perPage, pageState.records && pageState.records.length - pageOptions.page * pageOptions.perPage);

    if (auth.roles && auth.roles.includes('STUDENT')) {
      return <PageUnauthorized />;
    }

    return (
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <TitleBar title="Help Requests" currentPage="Help Requests" paths={[{ path: '/', name: 'Home' }]} />{' '}
            </Grid>
          </Grid>

          <Grid container className={classes.whiteBg}>
            <Grid item xs={12}>
              <PleaseWait isLoading={pageState.loading} />
              <FormLabel error>{errorMsg}</FormLabel>
            </Grid>

            <Grid container className={classes.whiteBg} align="left">
              <Grid item xs={6} className={classes.whiteBg} align="left"></Grid>
              <Grid item xs={6} align="right" className={classes.whiteBg}></Grid>

              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.whiteBg} align="left">
                    <TextField
                      id="filter-field"
                      type="search"
                      className={classes.filter}
                      size="small"
                      hiddenLabel
                      placeholder="Filter"
                      variant="outlined"
                      onChange={(event) => filterData(event.target.value)}
                      clearable="true"
                    />
                  </Grid>
                  <Grid item xs={12} sm={7} md={4} lg={4} className={classes.whiteBg} align="left"></Grid>
                  <Grid item xs={12} sm={5} md={4} lg={5} align="right" className={classes.whiteBg}></Grid>
                </Grid>
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.noSortHeader} style={{ width: '180px', textAlign: 'center' }}>
                            REVIEW
                          </TableCell>
                          <Tooltip title="Click to sort by case #." placement="top">
                            <TableCell onClick={() => setSort('id')} className={classes.sortHeader} style={{ width: '150px' }}>
                              CASE #{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'id' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'id' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by submitted by." placement="top">
                            <TableCell onClick={() => setSort('status_by')} className={classes.sortHeader} style={{ width: '250px' }}>
                              SUBMITTED BY{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'status_by' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'status_by' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by submitted on." placement="top">
                            <TableCell onClick={() => setSort('submitted_date')} className={classes.sortHeader} style={{ width: '200px' }}>
                              SUBMITTED ON{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell className={classes.noSortHeader} style={{ width: '250px' }}>
                            REASON
                          </TableCell>
                          <Tooltip title="Click to sort by status." placement="top">
                            <TableCell onClick={() => setSort('status')} className={classes.sortHeader} style={{ width: '150px' }}>
                              STATUS{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'status' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'status' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by assigned to." placement="top">
                            <TableCell onClick={() => setSort('assigned_to')} className={classes.sortHeader} style={{ width: '200px' }}>
                              ASSIGNED TO{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'assigned_to' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'assigned_to' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell style={{ width: '250px' }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pageOptions.searching ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          pageState.records &&
                          pageState.records
                            .slice(pageOptions.page * pageOptions.perPage, pageOptions.page * pageOptions.perPage + pageOptions.perPage)
                            .map((record, idx) => (
                              <TableRow key={record.id}>
                                <TableCell style={{ width: '100px', textAlign: 'center' }}>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.approvalLink}
                                    aria-describedby="review help request"
                                    onClick={(ev) => handleOpenHelpApp(ev, record.id)}
                                  >
                                    Review
                                  </Button>
                                </TableCell>
                                <TableCell style={{ width: '90px', alignText: 'center' }}>
                                  {/* Formatting record.id as a case number - use created year! */}
                                  {new Date(dateTimeToS(record.created_at)).getYear() + record.id.toString().padStart(6, 0)}
                                </TableCell>
                                <TableCell style={{ width: '150px' }}>{record.status_by}</TableCell>
                                <TableCell style={{ width: '160px' }}>{record.submitted_date_formatted}</TableCell>
                                <TableCell style={{ width: '200px' }}>
                                  {summarizeHelpOptions(record.application).join(', ').length > 40
                                    ? summarizeHelpOptions(record.application).join(', ').substr(0, 40) + '...'
                                    : summarizeHelpOptions(record.application).join(', ')}
                                </TableCell>
                                <TableCell style={{ width: '130px' }}>{displayStatus(record.status)}</TableCell>
                                <TableCell style={{ width: '150px' }}>{lookupAssignee(record.assigned_to)}</TableCell>
                              </TableRow>
                            ))
                        )}
                        {/* application essayText asvabScore gradDate alumniSignature */}
                        {!pageOptions.searching && pageState.records && pageState.records.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noSeniorsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        InputProps: { 'aria-label': 'rows per page' },
                        MenuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100]}
                      count={pageState.records ? pageState.records.length : 0}
                      rowsPerPage={pageOptions.perPage}
                      page={pageOptions.page}
                      onChangePage={(ev, val) => {
                        setPageOptions({ ...pageOptions, page: val });
                      }}
                      onChangeRowsPerPage={(ev) => setPageOptions({ ...pageOptions, perPage: parseInt(ev.target.value, 10), page: 0 })}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                </Hidden>

                <Hidden lgUp implementation="css">
                  <Grid container>
                    <Grid item xs={12} sm={7} md={5} className={classes.radioRow}>
                      <RadioGroup
                        aria-label="card-sorting"
                        name="date-group"
                        className={classes.centerRadioSorting}
                        row
                        value={pageState.sortBy}
                        onChange={handleCardSortChange}
                      >
                        <FormControlLabel
                          value="status_by"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Name</span>}
                          onClick={() => setSort('status_by')}
                          className={classes.radioLabel}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'status_by' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: pageState.sortBy === 'status_by' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                        <FormControlLabel
                          value="submission_date"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Date</span>}
                          onClick={() => setSort('submission_date')}
                          className={classes.radioLabel}
                          style={{
                            marginLeft: '20px',
                          }}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display:
                                pageState.sortBy === 'submission_date' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display:
                                pageState.sortBy === 'submission_date' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <HelpApplicationCards list={pageState.records} open={handleOpenHelpApp} />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  })
);

export default HelpApplicationList;
