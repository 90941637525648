import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { dateUtcToS, dateToMMMMYYYY, dateDiffYears, dateToApi } from '../../../util/dateUtil';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function GeneralInformation(props) {
  const { senior } = props;

  const classes = styles();

  return (
    <Box sx={{ width: '95%' }}>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Name</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.first_name} {senior.last_name}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Community</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.community}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Name</label>
        </Grid>
        <Grid item xs={3}>
          {senior.first_name} {senior.last_name}
        </Grid>
        <Grid item xs={3}>
          <label>Community</label>
        </Grid>
        <Grid item xs={3}>
          {senior.community}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Family Teachers</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.family_teaching_couple}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={3}>
          <label>Family Teachers</label>
        </Grid>
        <Grid item xs={3}>
          {senior.family_teaching_couple}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Consultant</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.consultant}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
        <Grid item xs={3}>
          <label>Consultant</label>
        </Grid>
        <Grid item xs={3}>
          {senior.consultant}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Graduation Date</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.graduation_date > /Date(0)/ && dateToMMMMYYYY(senior.graduation_date)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Age at Graduation</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.graduation_date > /Date(0)/ && dateDiffYears(dateToApi(senior.dob), dateToApi(senior.graduation_date))}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Graduation Date</label>
        </Grid>
        <Grid item xs={3}>
          {senior.graduation_date > /Date(0)/ && dateToMMMMYYYY(senior.graduation_date)}
        </Grid>
        <Grid item xs={3}>
          <label>Age at Graduation</label>
        </Grid>
        <Grid item xs={3}>
          {senior.graduation_date > /Date(0)/ && dateDiffYears(dateToApi(senior.dob), dateToApi(senior.graduation_date))}
        </Grid>
      </Grid>
    </Box>
  );
}

GeneralInformation.propTypes = {
  senior: PropTypes.object,
};
