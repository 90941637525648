import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, FormControl, FormControlLabel, TextField, InputAdornment } from '@material-ui/core';
import AlumniScholarshipExpandingList from './AlumniScholarshipExpandingList';

const AlumniSchoolProgram = ({ form, setForm, senior, classes, disabled }) => {
  const calculate4YearCosts = () => {
    const tuition = form.alumniExpensesTuition || 0;
    const books = form.alumniExpensesBooks || 0;
    const fees = form.alumniExpensesFees || 0;
    const other = form.alumniExpensesOther || 0;
    return parseInt(tuition, 10) + parseInt(books, 10) + parseInt(fees, 10) + parseInt(other, 10);
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} style={{ margin: '30px 0 10px 0' }}>
          <section align="left" className={classes.sectionTitle}>
            Financial Information
          </section>
          <section align="left" className={classes.subtleBold}>
            Please describe other scholarships for which you have applied.
          </section>
        </Grid>
      </Grid>

      {form.alumniOtherScholarshipsNone ? (
        ''
      ) : (
        <React.Fragment>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.rowSpacer}>
                <Grid item xs={6} sm={6} md={3} lg={3} className={classes.rowSpacer}>
                  <label>Scholarship Name</label>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} className={classes.rowSpacer}>
                  <label>Institution</label>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} className={classes.rowSpacer}>
                  <label>Scholarship Amount</label>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} className={classes.rowSpacer}>
                  <label>Status</label>
                </Grid>
              </Grid>
              <AlumniScholarshipExpandingList
                className={classes.unprintedContent}
                form={form}
                setForm={setForm}
                listname="alumniOtherScholarships"
                disabled={disabled}
                field1="scholarshipName"
                field1Cols={2}
                field1MaxLength={50}
                field2="institution"
                field2Cols={3}
                field2MaxLength={60}
                field3="scholarshipAmount"
                field3Cols={6}
                field3MaxLength={5}
                field4="scholarshipStatus"
                field4Cols={6}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {form.alumniOtherScholarshipsNone ? (
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={12} className={classes.textRowLabelSpacer}>
            I have not applied for any other scholarships.
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.printableTableHeader}>
                <Grid item xs={3}>
                  <label>Scholarship Name</label>
                </Grid>
                <Grid item xs={3}>
                  <label>Institution</label>
                </Grid>
                <Grid item xs={3}>
                  <label>Scholarship Amount</label>
                </Grid>
                <Grid item xs={3}>
                  <label>Status</label>
                </Grid>
              </Grid>
              {form.alumniOtherScholarships &&
                form.alumniOtherScholarships.map((e, i) => {
                  return (
                    <Grid container direction="row" className={classes.printableTableHeight} key={i}>
                      <Grid item xs={3}>
                        {e.scholarshipName}
                      </Grid>
                      <Grid item xs={3}>
                        {e.institution}
                      </Grid>
                      <Grid item xs={3}>
                        {e.scholarshipAmount}
                      </Grid>
                      <Grid item xs={3}>
                        {e.scholarshipStatus}
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} style={{ margin: '10px 0 20px 0' }}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" />}
              label="I have not applied for any other scholarships."
              disabled={disabled}
              checked={form.alumniOtherScholarshipsNone}
              onChange={(event) => setForm({ ...form, alumniOtherScholarshipsNone: !form.alumniOtherScholarshipsNone })}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.sectionSpacer}>
        <Grid item xs={12} align="left">
          <div className={classes.printedContent}>
            <br />
          </div>
          <section align="left" className={classes.sectionSubTitle}>
            Expenses
          </section>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={3} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
          <label>Tuition Amount</label>
          <FormControl fullWidth>
            <TextField
              id="alumni-expenses-tuition"
              size="small"
              type="number"
              hiddenLabel
              variant="outlined"
              value={form.alumniExpensesTuition}
              onChange={(event) => setForm({ ...form, alumniExpensesTuition: event.target.value })}
              disabled={disabled}
              InputProps={{
                maxLength: 6,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                classes: { input: classes.textField },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowSpacer}></Grid>
        <Grid item xs={3} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
          <label>Cost of Books</label>
          <FormControl fullWidth>
            <TextField
              id="alumni-expenses-books"
              size="small"
              hiddenLabel
              type="number"
              variant="outlined"
              value={form.alumniExpensesBooks}
              onChange={(event) => setForm({ ...form, alumniExpensesBooks: event.target.value })}
              disabled={disabled}
              InputProps={{
                maxLength: 6,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                classes: { input: classes.textField },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowSpacer}></Grid>
        <Grid item xs={3} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
          <label>Fees</label>
          <FormControl fullWidth>
            <TextField
              id="alumni-expenses-fees"
              size="small"
              hiddenLabel
              type="number"
              variant="outlined"
              value={form.alumniExpensesFees}
              onChange={(event) => setForm({ ...form, alumniExpensesFees: event.target.value })}
              disabled={disabled}
              InputProps={{
                maxLength: 6,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                classes: { input: classes.textField },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={3} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
          <label>Other</label>
          <FormControl fullWidth>
            <TextField
              id="alumni-expenses-other"
              size="small"
              hiddenLabel
              type="number"
              variant="outlined"
              value={form.alumniExpensesOther}
              onChange={(event) => setForm({ ...form, alumniExpensesOther: event.target.value })}
              disabled={disabled}
              InputProps={{
                maxLength: 6,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                classes: { input: classes.textField },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowSpacer}></Grid>
        <Grid item xs={3} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
          <label>Explanation</label>
          <FormControl fullWidth>
            <TextField
              id="fees-other-explanation"
              size="small"
              placeholder="Explain other expenses."
              variant="outlined"
              value={form.alumniExpensesOtherExplanation}
              onChange={(event) => setForm({ ...form, alumniExpensesOtherExplanation: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.textRowSpacer}></Grid>
        <Grid item xs={3} sm={2} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label style={{ fontWeight: 600 }}>Total School Costs</label>
          <br />
          <div style={{ marginTop: 5, fontWeight: 600 }}>$ {calculate4YearCosts()}</div>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Tuition Amount</label>
        </Grid>
        <Grid item xs={3}>
          <label>Cost of Books</label>
        </Grid>
        <Grid item xs={2}>
          <label>Fees</label>
        </Grid>
        <Grid item xs={2}>
          <label>Other</label>
        </Grid>
        <Grid item xs={2}>
          <label>Total</label>
        </Grid>
        <Grid item xs={3}>
          $ {form.alumniExpensesTuition || 0}
        </Grid>
        <Grid item xs={3}>
          $ {form.alumniExpensesBooks || 0}
        </Grid>
        <Grid item xs={2}>
          $ {form.alumniExpensesFees || 0}
        </Grid>
        <Grid item xs={2}>
          $ {form.alumniExpensesOther || 0}
        </Grid>
        <Grid item xs={2}>
          $ {calculate4YearCosts() || 0}
        </Grid>
        <Grid container direction="row" className={classes.printedContent} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <label>Explanation of 'Other' Expenses</label>
          </Grid>
          <Grid item xs={12}>
            {form.alumniExpensesOtherExplanation || ''}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 30 }}>&nbsp;</div>
    </>
  );
};

AlumniSchoolProgram.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  senior: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AlumniSchoolProgram;
