import { DateTime, Interval } from 'luxon';

const convertMSJSONDateToDate = (date) => {
  return new Date(parseInt(date.toString().substr(6)));
};

export const dateToS = (date) => {
  if (!date || date.length === 0) return '';

  // if it is already a LuxonDateTime, just use it
  if (date.hasOwnProperty('isLuxonDateTime') && date.isLuxonDateTime) {
    return date.toFormat('MM/dd/yyyy');
  }
  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('MM/dd/yyyy');
  } catch (err) {
    console.log(err);
  }
};

export const dateUtcToS = (date) => {
  if (!date) return '';
  try {
    // if it is already a LuxonDateTime, just use it
    if (date.hasOwnProperty('isLuxonDateTime') && date.isLuxonDateTime) {
      return date.toFormat('MM/dd/yyyy');
    }
    let dt = null;
    if (parseInt(date.toString().substr(6)) > 3000) {
      dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
    } else {
      dt = DateTime.fromJSDate(new Date(date)).toUTC();
    }
    return dt.toFormat('MM/dd/yyyy');
  } catch (err) {
    console.log(err);
  }
};

export const dateUtcToyyyyMMdd = (date) => {
  if (!date) return '';
  try {
    // if it is already a LuxonDateTime, just use it
    if (date.hasOwnProperty('isLuxonDateTime') && date.isLuxonDateTime) {
      return date.toFormat('yyyy/MM/dd');
    }
    let dt = null;
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
    return dt.toFormat('yyyy/MM/dd');
  } catch (err) {
    console.log(err);
  }
};

export const displayDateAsUTC = (date) => {
  if (!date) return '';
  try {
    const dt = DateTime.fromJSDate(new Date(date)).toUTC();
    return dt.toFormat('MM/dd/yyyy');
  } catch (err) {
    console.log(err);
  }
};

export const dateTimeToS = (date) => {
  if (!date) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date));
  }

  try {
    return dt.toFormat('MM/dd/yyyy hh:mm a');
  } catch (err) {
    console.log(err);
  }
};

export const dateTimeUtcToS = (date) => {
  if (!date) return '';
  try {
    // if it is already a LuxonDateTime, just use it
    if (date.hasOwnProperty('isLuxonDateTime') && date.isLuxonDateTime) {
      return date.toFormat('MM/dd/yyyy hh:mm a');
    }
    let dt = null;
    if (parseInt(date.toString().substr(6)) > 3000) {
      dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
    } else {
      dt = DateTime.fromJSDate(new Date(date)).toUTC();
    }
    return dt.toFormat('MM/dd/yyyy hh:mm a');
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentDateTime = () => {
  const dt = DateTime.fromJSDate(new Date());
  try {
    return dt.toFormat('MM/dd/yyyy hh:mm a');
  } catch (err) {
    console.log(err);
  }
};

export const dateToMMMMYYYY = (date) => {
  if (!date || date.length === 0) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('MMMM yyyy');
  } catch (err) {
    console.log(err);
  }
};

export const dateToYYYYMM = (date) => {
  if (!date || date.length === 0) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('yyyyMM');
  } catch (err) {
    console.log(err);
  }
};

export const dateOnlyFormat = (date) => {
  if (!date || date.length === 0) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('yyyy-MM-dd');
  } catch (err) {
    console.log(err);
  }
};

export const dateOnlyFormatJS = (date) => {
  if (!date || date.length === 0) return '';

  const dt = DateTime.fromJSDate(new Date(date)).toUTC();

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('yyyy-MM-dd');
  } catch (err) {
    console.log(err);
  }
};

export const timeToS = (date) => {
  if (!date || date.length === 0) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('hh:mm a');
  } catch (err) {
    console.log(err);
  }
};

export const dateToApi = (date) => {
  if (!date || date.length === 0) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt.toFormat('dd/MM/yyyy');
  } catch (err) {
    console.log(err);
  }
};

export const dateToLuxonDate = (date) => {
  if (!date || date.length === 0) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date)).toUTC();
  }

  try {
    if (!dt.isValid) {
      return '';
    }
    return dt;
  } catch (err) {
    console.log(err);
  }
};

export const dateDiffYears = (start, end) => {
  if (!start) return '';
  if (!end) return '';

  const diff = DateTime.fromFormat(end, 'dd/MM/yyyy').diff(DateTime.fromFormat(start, 'dd/MM/yyyy'), ['years']).years;
  return Math.floor(diff);
};

export const dateDiffYears2 = (start, end) => {
  if (!start) return '';
  if (!end) return '';

  const a = dateToLuxonDate(start.toLocaleString());
  const b = dateToLuxonDate(end.toLocaleString());
  const diff = a.diff(b, ['years']);

  return Math.floor(diff.years);
};

export const dateDiffYearsMonths = (start, end) => {
  if (!start) return '';
  if (!end) return '';

  const a = dateToLuxonDate(start.toLocaleString());
  const b = dateToLuxonDate(end.toLocaleString());
  const diff = a.diff(b, ['years', 'months']);

  return Math.floor(diff.years) + ' years ' + Math.floor(diff.months) + ' months';
};

export const dateDiffMonths = (start, end) => {
  if (!start) return '';
  if (!end) return '';

  const diff = DateTime.fromFormat(end, 'dd/MM/yyyy').diff(DateTime.fromFormat(start, 'dd/MM/yyyy'), ['months']).months;
  return Math.floor(diff);
};

export const dateDiffMonths2 = (start, end) => {
  if (!start) return '';
  if (!end) return '';

  const a = dateToLuxonDate(start.toLocaleString());
  const b = dateToLuxonDate(end.toLocaleString());
  const diff = a.diff(b, ['months']);

  return Math.floor(diff.months);
};

export const isValidDate = (date) => {
  let dt = DateTime.fromObject(date);
  return dt.isValid;
};

export const allowNewScholarshipApps = () => {
  const todaysDate = new Date();
  // Only allowed scholarship apps between March 18 and August 1.
  if ((todaysDate.getMonth() === 2 && todaysDate.getDate() < 19) || todaysDate.getMonth() < 2 || todaysDate.getMonth() > 6) return true;
  return true;
};

export const getTodaysDate = () => {
  const todaysDate = new Date();
  return dateOnlyFormat(todaysDate.toLocaleString());
};

export const getYear = (date) => {
  if (!date) return '';
  try {
    let dt = null;
    if (parseInt(date.toString().substr(6)) > 3000) {
      dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
    } else {
      dt = DateTime.fromJSDate(new Date(date)).toUTC();
    }
    return dt.toFormat('yyyy');
  } catch (err) {
    console.log(err);
  }
};

export const getMonth = (date) => {
  if (!date) return '';
  try {
    let dt = null;
    if (parseInt(date.toString().substr(6)) > 3000) {
      dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date))).toUTC();
    } else {
      dt = DateTime.fromJSDate(new Date(date)).toUTC();
    }
    return dt.toFormat('MM');
  } catch (err) {
    console.log(err);
  }
};

export const dateyyyyMMdd = (date) => {
  if (!date || date.length === 0) return '';

  let dt = DateTime.fromJSDate(new Date(date)).toUTC();

  try {
    return dt.toFormat('yyyy-MM-dd');
  } catch (err) {
    console.log(err);
  }
};

export const dateDiffDays = (date) => {
  const diff = DateTime.fromFormat(date, 'MM/dd/yyyy').diffNow('days').days;
  return diff;
};

export const dateCompare = (date) => {
  if (!date) return '';

  let dt = null;
  if (parseInt(date.toString().substr(6)) > 3000) {
    dt = DateTime.fromJSDate(new Date(convertMSJSONDateToDate(date)));
  } else {
    dt = DateTime.fromJSDate(new Date(date));
  }

  try {
    return dt.toFormat('yyyy-MM-dd hh:mm a');
  } catch (err) {
    console.log(err);
  }
};
