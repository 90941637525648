import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  webInfoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from '../../nextjs-material-kit-pro.js';

const headerStyle = (theme) => ({
  appBar: {
    display: 'flex',
    border: '0',
    padding: '0',
    marginBottom: '20px',
    color: grayColor[15],
    width: '100%',
    backgroundColor: whiteColor,
    boxShadow: '0 4px 18px 0px rgba(' + hexToRgb(blackColor) + ', 0.12), 0 7px 10px -5px rgba(' + hexToRgb(blackColor) + ', 0.15)',
    transition: 'all 150ms ease 0s',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
    top: 'auto',
  },
  fixed: {
    position: 'fixed',
  },
  container: {
    ...container,
    minHeight: '50px',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  title: {
    letterSpacing: 'unset',
    '&,& a': {
      ...defaultFont,
      minWidth: 'unset',
      lineHeight: '30px',
      fontSize: '18px',
      borderRadius: '3px',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      color: 'inherit',
      paddingBottom: 0,
      paddingTop: 0,
      '&:hover,&:focus': {
        color: 'inherit',
        background: 'transparent',
      },
    },
  },
  brandNameLine1: {
    ...defaultFont,
    color: '#fdb515',
    textTransform: 'uppercase',
    fontSize: '1.10rem',
    lineHeight: '1.30rem',
    letterSpacing: '3px',
    fontWeight: 800,
    fontFamily: '"Source Sans Pro", Helvetica, Roboto, Arial, sans-serif',
  },
  brandNameLine2: {
    ...defaultFont,
    color: whiteColor,
    textTransform: 'uppercase',
    fontSize: '1.60rem',
    lineHeight: '1.10rem',
    letterSpacing: '2px',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  logo: {
    height: 54,
    // width: 277,
    margin: 10,
  },
  appResponsive: {
    marginTop: '0px',
    color: '#FFF',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(primaryColor[0]) + ', 0.46)',
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(infoColor[0]) + ', 0.46)',
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(successColor[0]) + ', 0.46)',
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(warningColor[0]) + ', 0.46)',
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(dangerColor[0]) + ', 0.46)',
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(roseColor[0]) + ', 0.46)',
  },
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    paddingTop: '25px',
    color: whiteColor,
  },
  dark: {
    color: whiteColor,
    backgroundColor: grayColor[9] + ' !important',
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(grayColor[9]) + ', 0.46)',
  },
  white: {
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    color: grayColor[15],
    backgroundColor: whiteColor + ' !important',
    boxShadow: '0 4px 18px 0px rgba(' + hexToRgb(blackColor) + ', 0.12), 0 7px 10px -5px rgba(' + hexToRgb(blackColor) + ', 0.15)',
  },
  drawerPaper: {
    backgroundColor: webInfoColor,
    color: '#FFF',
    opacity: '.90',
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    // top: '0',
    right: '0',
    left: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition,
    [theme.breakpoints.down('md')]: {
      top: '80px',
      width: '100%', // drawerWidth,
      height: '65vh',
    },
    [theme.breakpoints.down('sm')]: {
      top: '95px',
      width: '55%', // drawerWidth,
      height: '55vh',
    },
    [theme.breakpoints.down('xs')]: {
      top: '67px',
      width: '100%', // drawerWidth,
      height: '73vh',
    },
    [theme.breakpoints.up('md')]: {
      top: '150px',
      width: '37%', // drawerWidth,
      height: '57vh',
    },
  },
  hidden: {
    width: '100%',
  },
  collapse: {
    [theme.breakpoints.up('md')]: {
      display: 'flex !important',
      MsFlexPreferredSize: 'auto',
      flexBasis: 'auto',
    },
    WebkitBoxFlex: '1',
    MsFlexPositive: '1',
    flexGrow: '1',
    WebkitBoxAlign: 'center',
    MsFlexAlign: 'center',
    alignItems: 'center',
  },
  closeButtonDrawer: {
    position: 'absolute',
    right: '8px',
    top: '9px',
    zIndex: '1',
  },
  appBarPosition: {
    [theme.breakpoints.up('md')]: {
      marginTop: '49px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
});

export default headerStyle;
