import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  TextField,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { logException, appInsights } from '../../services/appInsights';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import { titleize } from '../../util/stringUtil';
import CloseIcon from '@material-ui/icons/Close';
import GeneralInformation from './SeniorPlanning1Components/GeneralInformation';
import ExpandingList from '../ExpandingList/ExpandingList';
import FallPlan from './SeniorPlanning1Components/FallPlan';
import Scoring from './SeniorPlanning1Components/Scoring';
import { callApi } from '../../util/apiWrapper';
import { getCurrentDateTime } from '../../util/dateUtil';
import { createPrintedList } from '../../util/stringUtil';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';

const formStyles = makeStyles((theme) => ({
  subTitle: {
    margin: '15px 0',
    fontSize: '1.05rem',
    fontWeight: 500,
  },
  firstSectionSpacer: {
    height: 40,
  },
  sectionSpacer: {
    height: 60,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textSpacer: {
    margin: '15px 0 5px 0',
  },
  smallRowSpacer: {
    margin: '3px 0 3px 0',
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 85,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
    },
  },
  textArea10RowSpacer: {
    height: 185,
  },
  labelRowSpacer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: 5,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
      overflow: 'auto',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  buttonWidth: {
    width: 110,
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  firstSectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
    },
  },
  sectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0',
    },
  },
  disabledSectionTitle: {
    color: 'gray',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0',
    },
  },
  sectionSubTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.80rem',
      fontWeight: 600,
      margin: '2px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.90rem',
      fontWeight: 600,
      margin: '5px 0',
    },
  },
  hideForXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideForMd: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  reminder: {
    color: 'blue',
    fontSize: '1.05rem',
    margin: '10px 0',
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  bigLabel: {
    fontSize: '0.85rem',
  },
  addButton: {
    padding: 0,
    color: btNavyBlueColor,
  },
  topMargin10: {
    marginTop: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  commentSaveNote: {
    fontSize: '0.90rem',
    fontWeight: 600,
    color: 'blue',
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  spacer2520: {
    margin: '25px 0 20px 0',
  },
  dispositionNote: {
    fontSize: '1.05rem',
    padding: '15px 15px',
    margin: '20px 0',
    '@media print': {},
    '@media screen': {
      border: '1px solid blue',
      backgroundColor: '#FFFAF0',
    },
  },
  warning: {
    backgroundColor: '#fdb515',
  },
  errorAlert: {
    backgroundColor: '#fa0000',
    width: '900px',
  },
  note: {
    color: 'blue',
  },
  submittedInfo: {
    color: btNavyBlueColor,
    fontStyle: 'italic',
    fontSize: '0.95rem',
    fontWeight: 600,
  },
  meetingSaveNote: {
    fontSize: '0.90rem',
    fontWeight: 600,
    color: '#808080',
  },
  floatingSaveButton: {
    width: 210,
    position: 'absolute',
    right: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  floatingSubmitButton: {
    width: 210,
    position: 'absolute',
    left: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  space10After: {
    marginBottom: 10,
  },
  totalScore: {
    color: btNavyBlueColor,
    fontSize: '1.00rem',
    fontWeight: 600,
    paddingBottom: 25,
  },
}));

const SeniorPlanning1 = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const commentRef = useRef();
    const meetingCommentRef = useRef();

    const { handleSaveReturn } = props;

    const formClasses = formStyles();

    const [addMeetingNote, setAddMeetingNote] = useState('');

    const [requiredFields, setRequiredFields] = useState(null);
    const [requiredFieldsTitle, setRequiredFieldsTitle] = useState('');
    const [optionalFieldOnly, setOptionalFieldOnly] = useState(false);
    const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
    const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);

    const [originalStatus, setOriginalStatus] = useState('');

    const [showIncomplete, setShowIncomplete] = useState(false);

    const [warning, setWarning] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const [fetchSuccess, setFetchSuccess] = useState(false);

    const isFieldDisabled = () => {
      if (!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')) return true;
      if (form.status === 'submitted' || form.status === 'approved' || form.status === 'incomplete') return true;
      return false;
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowWarning(false);
    };

    const handleErrorClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowError(false);
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    const errorAction = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleErrorClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    // prettier-ignore
    const [form, setForm] = useState({
      interestsPostGraduation: '',
      interest1: '',
      interest2: '',
      interest3: '',
      fallPlanA: '',
      planDescriptionA: '',
      jobRequirementsA: '',
      educationTrainingA: '',
      educationTrainingCostA: '',
      paymentPlanA: '',
      goodPlanA: '',
      barriersPlanA: '',
      fallPlanB: '',
      planDescriptionB: '',
      jobRequirementsB: '',
      educationTrainingB: '',
      educationTrainingCostB: '',
      paymentPlanB: '',
      goodPlanB: '',
      barriersPlanB: '',
      fallPlanC: '',
      planDescriptionC: '',
      jobRequirementsC: '',
      educationTrainingC: '',
      educationTrainingCostC: '',
      paymentPlanC: '',
      goodPlanC: '',
      barriersPlanC: '',
      comments: [],
      tasks: [{ "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }],
      status: 'created',
      dispositionReason: '',
      submitted_date: null,
      followup: false,
      gradingSheet1Completed: -1,
      gradingSheet1Submitted: -1,
      gradingSheet1Clarity: -1,
      module1Submitted: -1,
      module1Completed: -1,
      module2Submitted: -1,
      module2Completed: -1,
      module3Submitted: -1,
      module3Completed: -1,
      meetingComments: [],
      rubricMotivation: 0,
      rubricMotivationExplain: '',
      rubricFuture: 0,
      rubricFutureExplain: '',
      rubricSFTeam: 0,
      rubricSFTeamExplain: '',
      rubricMotivationScore: 0,
      rubricSuccess: 0
    });

    const [addNote, setAddNote] = useState('');

    const writeSeniorPlan1 = async (closeInd) => {
      try {
        store.setLoading(false);

        // Send email
        if (originalStatus !== 'returned' && form.status === 'returned') {
          sendPlanningForm1ReturnedEmail(store.senior.id);
        }

        let updatedPlanningForm1 = null;

        if (store.planform1Obj && store.planform1Obj.id && store.planform1Obj.id > 0) {
          updatedPlanningForm1 = store.planform1Obj;
          updatedPlanningForm1.planning_form_1_data = form;
          updatedPlanningForm1.updated_at = new Date();
          updatedPlanningForm1.updated_by = auth.user.result.id;
        }

        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> in SeniorPlanning1 updatedPlanningForm1: ', updatedPlanningForm1);
        if (updatedPlanningForm1) {
          updatedPlanningForm1 = await callApi(auth, 'planningform1', 'PUT', {
            item: updatedPlanningForm1,
          });
        } else {
          store.setLoading(true);
          const res = await callApi(auth, 'planningform1', 'POST', {
            item: {
              student_id: store.senior.id,
              created_at: new Date(),
              created_by: auth.user.result.id, //store.senior.userId,
              updated_at: new Date(),
              updated_by: auth.user.result.id, //store.senior.userId,
              planning_form_1_data: null,
            },
          });

          updatedPlanningForm1 = res.result;
          console.log('.....in writeSeniorPlan1 setting fetchSuccess to TRUE');
          setFetchSuccess(true);

          // PUT to update planning_form_1_data in correct format
          updatedPlanningForm1.planning_form_1_data = form;
          const c = await callApi(auth, 'planningform1', 'PUT', {
            item: updatedPlanningForm1,
          });

          if (c.result) {
            updatedPlanningForm1 = res.result;
            store.setPlanform1Obj(updatedPlanningForm1);
          }
        }

        appInsights.trackTrace({ message: `Planning Form 1 saved by ${auth.user.result.email}` });

        store.setLoading(false);
        closeInd && handleSaveReturn();
      } catch (error) {
        logException(error);
        store.setLoading(false);
        console.log('Error writing updated planning form 1:', error);
      }
    };

    useEffect(() => {
      const fetchPlanningForm1 = async (studentId) => {
        try {
          store.setLoading(true);
          store.setSearching(true);

          const planningform1 = await callApi(auth, 'planningform1/' + studentId, 'GET');

          if (planningform1 && planningform1.result) {
            if (planningform1.result.id > 0) {
              store.setPlanform1Obj(planningform1.result);
              setFetchSuccess(true);

              let adjustForm = JSON.parse(planningform1.result.planning_form_1_data);

              if (!adjustForm.hasOwnProperty('tasks')) {
                adjustForm.tasks = [{ 'name': '' }, { 'name': '' }, { 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustForm.hasOwnProperty('status')) {
                adjustForm.status = 'created';
              }
              if (!adjustForm.hasOwnProperty('meetingComments')) {
                adjustForm.meetingComments = [];
              }

              if (!adjustForm.hasOwnProperty('rubricMotivation')) {
                adjustForm.rubricMotivation = 0;
              }
              if (!adjustForm.hasOwnProperty('rubricFuture')) {
                adjustForm.rubricFuture = 0;
              }
              if (!adjustForm.hasOwnProperty('rubricSFTeam')) {
                adjustForm.rubricSFTeam = 0;
              }
              if (!adjustForm.hasOwnProperty('rubricMotivationScore')) {
                adjustForm.rubricMotivationScore = 0;
              } else {
                const avg = (adjustForm.rubricMotivation + adjustForm.rubricFuture + adjustForm.rubricSFTeam) / 3;
                adjustForm.rubricMotivationScore = avg;
              }
              if (!adjustForm.hasOwnProperty('rubricSuccess')) {
                adjustForm.rubricSuccess = 0;
              }

              setOriginalStatus(adjustForm.status);
              setForm(adjustForm);
            } else {
              writeSeniorPlan1(false);
            }
          } else {
            writeSeniorPlan1(false);
          }
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          setFetchSuccess(false);
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching planning form1:', error);
          setError('Your Planning Form 1 did not load successfully. Please close your browser and retry. Failure to do so may result in data loss!');
          appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user.' });
          setShowError(true);
          logException(error);
        }
      };

      if (store.senior.id > 0) {
        fetchPlanningForm1(store.senior.id);
      }
    }, [auth, store, store.senior]);

    // AUTO-SAVE
    useEffect(() => {
      if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        const interval = setInterval(() => {
          if (fetchSuccess) {
            handleSavePlanningForm1(false);
            appInsights.trackTrace({ message: `Auto saved Planning Form 1 form for ${auth.user.result.email}` });
          } else {
            setError(
              'Your Planning Form 1 cannot be saved, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
            );
            setShowError(true);
            appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user on auto-save.' });
          }
        }, 300000);
        return () => clearInterval(interval);
      }
    }, [auth, auth.roles, form]);

    const handleAddComment = () => {
      const updatedComments = [
        ...form.comments,
        {
          timestamp: getCurrentDateTime(),
          text: addNote,
          user: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        },
      ];
      setForm({ ...form, comments: updatedComments });
      setAddNote('');
      document.getElementById('comment').value = ''; // clear comment input after adding to array
    };

    const handleAddMeetingComment = () => {
      const updatedMeetingComments = [
        ...form.meetingComments,
        {
          timestamp: getCurrentDateTime(),
          text: addMeetingNote,
          user: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        },
      ];
      setForm({ ...form, meetingComments: updatedMeetingComments });
      setAddMeetingNote('');
      document.getElementById('meetingComment').value = ''; // clear comment input after adding to array
    };

    const sendPlanningForm1ReturnedEmail = async (id) => {
      try {
        store.setLoading(true);
        await callApi(auth, 'planningform1returnemail', 'POST', { student_id: id });
        appInsights.trackTrace({ message: `PlanningForm1 returned email sent to ${auth.user.result.email}` });
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setLoading(false);
        console.log('Error sending Planning Form 1 returned email:', error);
      }
    };

    const handleSavePlanningForm1 = (closeInd) => {
      console.log('............in handleSavePlanningForm1 fetchSuccess: ', fetchSuccess ? 'YES' : 'NO');
      if (!fetchSuccess) {
        setError(
          'Your Planning Form 1 cannot be saved, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
        );
        setShowError(true);
        appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user on save.' });
        return;
      }

      // Requirements if Dispositioning by Admin/Specialist
      if ((auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) && canDisposition() === false) {
        setShowRequiredFieldsDialog(true);
        return;
      }

      // if admin is returning the planning form, require a reason.
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        if (form.status === 'returned') {
          if (!form.dispositionReason || form.dispositionReason.length === 0) {
            setWarning('You must provide a reason for returning the planning form.');
            setShowWarning(true);
            return;
          }
        }
      }

      writeSeniorPlan1(closeInd);
    };

    const handleSubmitPlanningForm1 = () => {
      if (!fetchSuccess) {
        setError(
          'Your Planning Form 1 cannot be submitted, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
        );
        setShowError(true);
        appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user on submit.' });
        return;
      }

      if (canSubmit()) {
        submitPlanningFormContinue();
      } else {
        setShowRequiredFieldsDialog(true);
      }
    };

    const submitPlanningFormContinue = () => {
      async function submitPlanningForm1() {
        if (!fetchSuccess) {
          setError(
            'Your Planning Form 1 cannot be saved, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
          );
          setShowError(true);
          return;
        }
        try {
          store.setLoading(false);

          let updatedPlanningForm1 = null;

          form.status = 'submitted';
          form.dispositionReason = '';
          form.submitted_date = getCurrentDateTime();

          if (store.planform1Obj && store.planform1Obj.id && store.planform1Obj.id > 0) {
            updatedPlanningForm1 = store.planform1Obj;
            updatedPlanningForm1.planning_form_1_data = form;
            updatedPlanningForm1.updated_at = new Date();
            updatedPlanningForm1.updated_by = auth.user.result.id;
          }

          if (updatedPlanningForm1) {
            updatedPlanningForm1 = await callApi(auth, 'planningform1', 'PUT', {
              item: updatedPlanningForm1,
            });
          } else {
            try {
              store.setLoading(true);

              const res = await callApi(auth, 'planningform1', 'POST', {
                item: {
                  student_id: store.senior.id,
                  created_at: new Date(),
                  created_by: auth.user.result.id,
                  updated_at: new Date(),
                  updated_by: auth.user.result.id,
                  planning_form_1_data: null,
                },
              });
              updatedPlanningForm1 = res.result;

              // PUT to update planning_form_1_data in correct format
              updatedPlanningForm1.planning_form_1_data = form;
              const c = await callApi(auth, 'planningform1', 'PUT', {
                item: updatedPlanningForm1,
              });

              if (c.result) {
                updatedPlanningForm1 = res.result;
                store.setPlanform1Obj(updatedPlanningForm1);
              }

              store.setLoading(false);
            } catch (error) {
              logException(error);
              store.setLoading(false);
              console.log('Error submiting Planning Form 1:', error);
            }
          }

          appInsights.trackTrace({ message: `PlanningForm1 submitted by ${auth.user.result.email}` });

          store.setLoading(false);
          handleSaveReturn();
        } catch (error) {
          logException(error);
          store.setLoading(false);
          console.log('Error submitting updated PlanningForm1:', error);
        }
      }
      submitPlanningForm1();
    };

    const displayStatus = (status) => {
      // created, submitted, returmed
      if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram') {
        return 'Under Review';
      }
      return status;
    };

    const canSubmit = () => {
      let rc = true;
      let requiredFields = [];

      let senior = store.senior;
      if (!senior.act || senior.act.length === 0 || !senior.asvab || senior.act.asvab === 0) {
        requiredFields = [
          ...requiredFields,
          {
            requirement:
              'If you have taken the ACT/ASVAB, please enter your score on your personal information page in order to be included on this form.',
          },
        ];
        rc = false; //Optional
      }

      if (!form.interestsPostGraduation || form.interestsPostGraduation.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Indicate your post-graduation plans before submitting your Planning Form #1.' }];
        rc = false;
      }

      let totalInterestsEntered = 0;
      if (form.interest1) totalInterestsEntered += 1;
      if (form.interest2) totalInterestsEntered += 1;
      if (form.interest3) totalInterestsEntered += 1;

      if (totalInterestsEntered < 2) {
        requiredFields = [...requiredFields, { requirement: 'At least 2 career interests must be entered.' }];
        rc = false;
      }

      //Plan A
      if (!form.fallPlanA || form.fallPlanA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Plan A is required.' }];
        rc = false;
      }

      if (!form.planDescriptionA || form.planDescriptionA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please describe Plan A.' }];
        rc = false;
      }

      if (!form.jobRequirementsA || form.jobRequirementsA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure requirements needed to complete Plan A are entered.' }];
        rc = false;
      }

      if (!form.educationTrainingA || form.educationTrainingA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure education/training location for Plan A is entered.' }];
        rc = false;
      }

      if (!form.educationTrainingCostA || form.educationTrainingCostA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure education/training cost for Plan A is entered.' }];
        rc = false;
      }

      if (!form.paymentPlanA || form.paymentPlanA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure payment plan for Plan A is entered.' }];
        rc = false;
      }

      if (!form.goodPlanA || form.goodPlanA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a reason why you think Plan A is a good fit for you.' }];
        rc = false;
      }

      if (!form.barriersPlanA || form.barriersPlanA.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure any barriers for Plan A are entered.' }];
        rc = false;
      }

      //Plan B
      if (!form.fallPlanB || form.fallPlanB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Plan B is required.' }];
        rc = false;
      }

      if (!form.planDescriptionB || form.planDescriptionB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please describe Plan B.' }];
        rc = false;
      }

      if (!form.jobRequirementsB || form.jobRequirementsB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure requirements needed to complete Plan B are entered.' }];
        rc = false;
      }

      if (!form.educationTrainingB || form.educationTrainingB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure education/training location for Plan B is entered.' }];
        rc = false;
      }

      if (!form.educationTrainingCostB || form.educationTrainingCostB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure education/training cost for Plan B is entered.' }];
        rc = false;
      }

      if (!form.paymentPlanB || form.paymentPlanB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure payment plan for Plan B is entered.' }];
        rc = false;
      }

      if (!form.goodPlanB || form.goodPlanB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a reason why you think Plan B is a good fit for you.' }];
        rc = false;
      }

      if (!form.barriersPlanB || form.barriersPlanB.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Ensure any barriers for Plan B are entered.' }];
        rc = false;
      }

      //Plan C - if Plan C is specified, all fields under plan C are required
      if (form.fallPlanC && form.fallPlanC.length > 0) {
        if (!form.planDescriptionC || form.planDescriptionC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe Plan C.' }];
          rc = false;
        }

        if (!form.jobRequirementsC || form.jobRequirementsC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Ensure requirements needed to complete Plan C are entered.' }];
          rc = false;
        }

        if (!form.educationTrainingC || form.educationTrainingC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Ensure education/training location for Plan C is entered.' }];
          rc = false;
        }

        if (!form.educationTrainingCostC || form.educationTrainingCostC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Ensure education/training cost for Plan C is entered.' }];
          rc = false;
        }

        if (!form.paymentPlanC || form.paymentPlanC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Ensure payment plan for Plan C is entered.' }];
          rc = false;
        }

        if (!form.goodPlanC || form.goodPlanC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a reason why you think Plan C is a good fit for you.' }];
          rc = false;
        }

        if (!form.barriersPlanC || form.barriersPlanC.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Ensure any barriers for Plan C are entered.' }];
          rc = false;
        }
      }

      let totalTasksEntered = 0;
      if (form.tasks && form.tasks.length > 0) {
        for (let i = 0; i < form.tasks.length; i++) {
          const task = form.tasks[i];
          if (task.name && task.name.length > 0 && task.name !== '') {
            totalTasksEntered += 1;
          }
        }
        if (totalTasksEntered < 3) {
          requiredFields = [...requiredFields, { requirement: 'You do not have enough tasks listed. Ensure at least 3 tasks have been entered.' }];
          rc = false;
        }
      }

      if (requiredFields.length === 1 && requiredFields[0].requirement.includes('ACT')) {
        setOptionalFieldOnly(true);
        setRequiredFieldsTitle('Planning Form #1 Warning');
        setRequiredFieldsIntro('You have some missing information on your Planning Form #1.');
      } else {
        setOptionalFieldOnly(false);
        setRequiredFieldsTitle('Planning Form #1 Errors');
        setRequiredFieldsIntro(
          'You have not completed all of the required information on your Planning Form #1. Please make these adjustments before submitting.'
        );
      }

      setRequiredFields(requiredFields);
      return rc;
    };

    const handleRequiredInfoClose = () => {
      setShowRequiredFieldsDialog(false);
    };

    const handleRequiredInfoContinue = () => {
      setShowRequiredFieldsDialog(false);
      submitPlanningFormContinue();
    };

    const lookupMotivationValue = (value) => {
      switch (value) {
        case 1:
          return 'Student demonstrates faint interest in working on their post-graduation plan, moving through the motions and completing most basic tasks and expectations with lots of prompts, motivators, and consequences, and showing no initiative or enthusiasm.';
        case 2:
          return 'Student demonstrates some interest in working on the post-graduation plan, completes most basic tasks and expectations with a few prompts, motivators, and consequences, and shows very little initiative or enthusiasm.';
        case 3:
          return 'Student demonstrates interest in working on the post-graduation plan, completes most basic tasks and expectations without prompts, motivators, consequences, has a positive attitude, and shows some initiative and enthusiasm.';
        case 4:
          return 'Student works on their plan with persistence, a positive attitude, self-discipline, and lots of initiative and enthusiasm asking for help and keeping track of all requirements independently.';
        default:
          return '';
      }
    };

    const lookupFutureValue = (value) => {
      switch (value) {
        case 1:
          return 'Student refuses to set a realistic post-graduation plan.';
        case 2:
          return "Student has an unrealistic view of their abilities and post-graduation plans as evidenced by contradictory MAP, ACT, and ASVAB scores, the team's overall unfavorable support, and student is reluctant to feedback received during the Sr. Planning sessions.";
        case 3:
          return 'Student is somewhat realistic about their abilities and post-graduation plans as evidenced by supportive MAP, ACT, and ASVAB scores and team-neutral support, student is open to feedback received during the Sr. Planning sessions.';
        case 4:
          return 'Student is realistic about their abilities and plans for their future as evidenced by supportive MAP, ACT, and ASVAB scores and team overall favorable support.';
        default:
          return '';
      }
    };

    const lookupSFTeamValue = (value) => {
      switch (value) {
        case 1:
          return 'Never: Student does not reach out via email, call, or attend afterschool hours.';
        case 2:
          return 'Sometimes: Student reaches out to SFS only when facing consequences or past due on their requirements.';
        case 3:
          return 'Often: Student reaches out to SFS with proactive questions about their senior planning binder completion and some about their post-graduation plan.';
        case 4:
          return 'Very Often: Student reaches out to SFS with proactive questions about their post-graduation plan and senior planning binder completion. Communication is fluid and allows for relationship building.';
        default:
          return '';
      }
    };

    const showSaveButton = () => {
      // Status Values: submitted, returned, approved
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        return true;
      }

      if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        if (form.status === 'created' || form.status === 'returned') {
          return true;
        }
      }
      return false;
    };

    const showDisposition = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        // Status Values: created, submitted, approved, returned
        if (originalStatus === 'submitted' || originalStatus === 'incomplete' || showIncomplete) {
          rc = true;
        }
      }
      return rc;
    };

    const shouldDisplayStatusNotes = () => {
      if (form.dispositionReason && form.dispositionReason.length > 0) return true;
      return false;
    };

    const markFormIncomplete = () => {
      setShowIncomplete(!showIncomplete);
      setForm({ ...form, status: 'incomplete' });
    };

    const canDisposition = () => {
      let rc = true;
      let requiredFields = [];

      if (form.status === 'approved') {
        if (
          form.gradingSheet1Completed === undefined ||
          form.gradingSheet1Submitted === undefined ||
          form.gradingSheet1Clarity === undefined ||
          form.module1Submitted === undefined ||
          form.module1Completed === undefined ||
          form.module2Submitted === undefined ||
          form.module2Completed === undefined ||
          form.module3Submitted === undefined ||
          form.module3Completed === undefined ||
          (form.gradingSheet1Completed && form.gradingSheet1Completed < 0) ||
          (form.gradingSheet1Submitted && form.gradingSheet1Submitted < 0) ||
          (form.gradingSheet1Clarity && form.gradingSheet1Clarity < 0) ||
          (form.module1Submitted && form.module1Submitted < 0) ||
          (form.module1Completed && form.module1Completed < 0) ||
          (form.module2Submitted && form.module2Submitted < 0) ||
          (form.module2Completed && form.module2Completed < 0) ||
          (form.module3Submitted && form.module3Submitted < 0) ||
          (form.module3Completed && form.module3Completed < 0)
        ) {
          requiredFields = [
            ...requiredFields,
            { requirement: 'You must score the application before approving. Please score each item before changing the status to approved.' },
          ];
          rc = false;
        }
      }

      setRequiredFieldsIntro('Please make these adjustments before continuing.');
      setRequiredFields(requiredFields);
      return rc;
    };

    return (
      <React.Fragment>
        <PleaseWait isLoading={store.loading} />
        {store.senior && (
          <summary>
            <section align="left" className={formClasses.unprintedContent} style={{ textAlign: 'center' }}>
              NOTE: This Senior Planning Form - Meeting #1 will be automatically saved every five minutes.
              <br />
              <br />
            </section>
            <Grid container align="left" className={formClasses.unprintedContent}>
              <Grid item xs={12} align="center" className={formClasses.textRowLabelSpacer} style={{ minHeight: 60, marginBottom: 10 }}>
                <aside style={{ fontWeight: 600, fontSize: '1.25rem' }}>Current Status: {titleize(displayStatus(form.status))}</aside>
                {(auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && form.status === 'returned' && (
                  <aside className={formClasses.error}>
                    NOTE: This Planning Form was returned for additional information. Please make the necessary changes and re-submit.
                  </aside>
                )}
              </Grid>
            </Grid>
            <div className={formClasses.printedContent}>
              <aside style={{ fontWeight: 600, fontSize: '1.50rem', paddingBottom: 10 }} align="center">
                Current Status: {titleize(displayStatus(form.status))}
              </aside>
              {(auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && form.status === 'returned' && (
                <aside className={formClasses.error}>
                  NOTE: This Planning Form was returned for additional information. Please make the necessary changes and re-submit.
                </aside>
              )}
            </div>
            {(form.status === 'approved' || form.status === 'incomplete') && (
              <>
                <aside className={formClasses.totalScore} align="center">
                  Planning Form Score:{' '}
                  {(form.gradingSheet1Completed || 0) +
                    (form.gradingSheet1Submitted || 0) +
                    (form.gradingSheet1Clarity || 0) +
                    (form.module1Submitted || 0) +
                    (form.module1Completed || 0) +
                    (form.module2Submitted || 0) +
                    (form.module2Completed || 0) +
                    (form.module3Submitted || 0) +
                    (form.module3Completed || 0) >
                  0
                    ? (form.gradingSheet1Completed > -1 ? form.gradingSheet1Completed : 0) +
                      (form.gradingSheet1Submitted > -1 ? form.gradingSheet1Submitted : 0 || 0) +
                      (form.gradingSheet1Clarity > -1 ? form.gradingSheet1Clarity : 0 || 0) +
                      (form.module1Submitted > -1 ? form.module1Submitted : 0 || 0) +
                      (form.module1Completed > -1 ? form.module1Completed : 0 || 0) +
                      (form.module2Submitted > -1 ? form.module2Submitted : 0 || 0) +
                      (form.module2Completed > -1 ? form.module2Completed : 0 || 0) +
                      (form.module3Submitted > -1 ? form.module3Submitted : 0 || 0) +
                      (form.module3Completed > -1 ? form.module3Completed : 0 || 0)
                    : 0}
                </aside>
              </>
            )}
          </summary>
        )}

        {shouldDisplayStatusNotes() && (
          <Grid item xs={12} align="left" className={formClasses.dispositionNote}>
            {form.dispositionReason && form.dispositionReason.length > 0 && (
              <React.Fragment>
                <summary style={{ fontWeight: 600 }}>Notes Regarding Status</summary>
                <summary>{form.dispositionReason}</summary>
              </React.Fragment>
            )}
          </Grid>
        )}

        {store.senior && (
          <Grid container key={store.senior.userId} align="left">
            <Grid container direction="row">
              <Grid item xs={12} align="left" className={formClasses.firstSectionSpacer}>
                <section align="left" className={formClasses.firstSectionTitle}>
                  General Information
                </section>
              </Grid>
            </Grid>
            {store.senior && <GeneralInformation senior={store.senior} form={form} setForm={setForm} auth={auth} />}
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  When exploring my options, the following are my preliminary plans after graduation.
                </section>
              </Grid>
            </Grid>
            <Grid container direction="row" className={formClasses.smallRowSpacer} />
            <FallPlan form={form} setForm={setForm} auth={auth} />

            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Tasks I need to complete to move toward my plan.
                </section>
              </Grid>
            </Grid>
            <Grid container direction="row" className={formClasses.smallRowSpacer} />
            <Grid container direction="row" style={{ height: '185px', overflow: 'auto' }} className={formClasses.unprintedContent}>
              <Grid item xs={12} className={formClasses.textRowSpacer}>
                <ExpandingList
                  form={form}
                  setForm={setForm}
                  listname="tasks"
                  field1="name"
                  field1Cols={11}
                  field1MaxLength={500}
                  rowsRequired={5}
                  disabled={isFieldDisabled()}
                />
              </Grid>
            </Grid>
            <div className={formClasses.printedContent}>
              <div>
                <label>Tasks I need to complete to move toward my plan.</label>
              </div>
              <div>
                <summary className={formClasses.alignPrintedContent}>{createPrintedList(form.tasks)}</summary>
              </div>
              <br />
            </div>

            {/* Action Buttons */}
            <Grid container direction="row" className={formClasses.unprintedContent}>
              <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.buttonSpacer}>
                <div align="left">
                  {(!form.status || form.status === 'created' || form.status === 'returned') && (
                    <Tooltip title="Click to submit your planning form for review." placement="top">
                      <Button
                        className={formClasses.floatingSubmitButton}
                        color="secondary"
                        variant="contained"
                        onClick={(event) => handleSubmitPlanningForm1()}
                        disabled={!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')}
                        style={{ margin: '0 15px 15px 0', width: '110px' }}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                  )}
                </div>
                <div align="right">
                  {showSaveButton() ? (
                    <>
                      <Button
                        className={formClasses.floatingSaveButton}
                        variant="contained"
                        onClick={(event) => handleSaveReturn()}
                        disabled={!store.senior}
                        style={{ margin: '0 140px 15px 0', width: '110px' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={formClasses.floatingSaveButton}
                        color="primary"
                        variant="contained"
                        onClick={(event) => handleSavePlanningForm1(true)}
                        disabled={!store.senior}
                        style={{ margin: '0 15px 15px 0', width: '110px' }}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={formClasses.floatingSaveButton}
                      variant="contained"
                      onClick={(event) => handleSaveReturn()}
                      disabled={!store.senior}
                      style={{ margin: '0 15px 15px 0', width: '110px' }}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>

            {/* Submitted Date  */}
            {form.status === 'submitted' && form.submitted_date && (
              <Grid container direction="row">
                <Grid item xs={12} align="left" className={formClasses.firstSectionSpacer}>
                  <section align="left" className={formClasses.submittedInfo}>
                    <label>Planning Form Submitted:</label> {form.submitted_date}
                  </section>
                </Grid>
              </Grid>
            )}

            {/* Meeting Notes */}
            {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
              <React.Fragment>
                <Grid container direction="row" className={formClasses.sectionSpacer}>
                  <Grid item xs={12} sm={12} md={6} align="left">
                    <section align="left" className={formClasses.sectionTitle}>
                      Meeting Notes
                    </section>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.textArea5RowSpacer}>
                    <FormControl fullWidth>
                      <TextField
                        id="meetingComment"
                        ref={meetingCommentRef}
                        size="small"
                        hiddenLabel
                        variant="outlined"
                        value={form.meetingNote}
                        onChange={(event) => setAddMeetingNote(event.target.value)}
                        disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        InputProps={{
                          classes: { input: formClasses.textField },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.textArea5RowSpacer}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => handleAddMeetingComment()}
                      disabled={!addMeetingNote}
                      style={{ margin: '0 0 0 15px' }}
                    >
                      Add Note
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} className={formClasses.textRowSpacer}>
                    <aside className={formClasses.meetingSaveNote}>NOTE: Meeting notes are not visible to students.</aside>
                  </Grid>
                </Grid>
                <summary
                  className={formClasses.unprintedContent}
                  style={{ height: '300px', width: '90%', border: '1px solid #808080', overflowY: 'scroll' }}
                >
                  <Grid container direction="row" className={formClasses.sectionSpacer}>
                    <Grid item xs={12} sm={12} md={9}>
                      {form.meetingComments &&
                        form.meetingComments
                          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                          .map((comment, idx) => {
                            return (
                              <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                                <tr style={{ margin: '5px 0' }}>
                                  <td style={{ width: '100%', verticalAlign: 'top' }}>
                                    <aside>
                                      <label>Logged at</label> {comment.timestamp}
                                    </aside>
                                    <aside>
                                      <label>By</label> {comment.user}
                                    </aside>
                                    <summary>{comment.text}</summary>
                                  </td>
                                </tr>
                              </table>
                            );
                          })}
                    </Grid>
                  </Grid>
                </summary>
                <summary className={formClasses.printedContent} style={{ width: '90%' }}>
                  {form.meetingComments &&
                    form.meetingComments
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((comment, idx) => {
                        return (
                          <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                            <tr style={{ margin: '5px 0' }}>
                              <td style={{ width: '100%', verticalAlign: 'top' }}>
                                <aside>
                                  <label>Logged at</label> {comment.timestamp}
                                </aside>
                                <aside>
                                  <label>By</label> {comment.user}
                                </aside>
                                <summary>{comment.text}</summary>
                              </td>
                            </tr>
                          </table>
                        );
                      })}
                </summary>
              </React.Fragment>
            )}

            {/* Comments */}
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} sm={12} md={6} align="left">
                {auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI') ? (
                  <section align="left" className={formClasses.disabledSectionTitle}>
                    Comments
                  </section>
                ) : (
                  <section align="left" className={formClasses.sectionTitle}>
                    Comments
                  </section>
                )}
              </Grid>
            </Grid>
            {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
              <React.Fragment>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.textArea5RowSpacer}>
                    <FormControl fullWidth>
                      <TextField
                        id="comment"
                        ref={commentRef}
                        size="small"
                        hiddenLabel
                        variant="outlined"
                        value={form.note}
                        onChange={(event) => setAddNote(event.target.value)}
                        disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        InputProps={{
                          classes: { input: formClasses.textField },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.textArea5RowSpacer}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => handleAddComment()}
                      disabled={!addNote}
                      style={{ margin: '0 0 0 15px' }}
                    >
                      Add Comment
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} sm={10} md={9} className={formClasses.textArea5RowSpacer}>
                    <aside className={formClasses.commentSaveNote}>
                      NOTE: Comments should be used to advise students. They will be seen by the seniors. After adding a comment you MUST click "SAVE"
                      to save the Planning Form.
                    </aside>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            <summary
              className={formClasses.unprintedContent}
              style={{ height: '300px', width: '90%', border: '1px solid #808080', overflowY: 'scroll', marginBottom: 35 }}
            >
              <Grid container direction="row" className={formClasses.sectionSpacer}>
                <Grid item xs={12} sm={12} md={9}>
                  {form.comments &&
                    form.comments
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((comment, idx) => {
                        return (
                          <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                            <tr style={{ margin: '5px 0' }}>
                              <td style={{ width: '100%', verticalAlign: 'top' }}>
                                <aside>
                                  <label>Logged at</label> {comment.timestamp}
                                </aside>
                                <aside>
                                  <label>By</label> {comment.user}
                                </aside>
                                <summary>{comment.text}</summary>
                              </td>
                            </tr>
                          </table>
                        );
                      })}
                </Grid>
              </Grid>
            </summary>
            <summary className={formClasses.printedContent} style={{ width: '90%', marginBottom: 35 }}>
              {form.comments &&
                form.comments
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((comment, idx) => {
                    return (
                      <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                        <tr style={{ margin: '5px 0' }}>
                          <td style={{ width: '100%', verticalAlign: 'top' }}>
                            <aside>
                              <label>Logged at</label> {comment.timestamp}
                            </aside>
                            <aside>
                              <label>By</label> {comment.user}
                            </aside>
                            <summary>{comment.text}</summary>
                          </td>
                        </tr>
                      </table>
                    );
                  })}
            </summary>
          </Grid>
        )}

        {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
          <Grid container direction="row" className={formClasses.unprintedContent}>
            <Grid item xs={12} align="left">
              <FormControlLabel
                style={{ marginLeft: '10px' }}
                control={
                  <Tooltip
                    title="Student has not submitted the planning form, but we need to score the other pieces required for incentive payments."
                    placement="top"
                  >
                    <Checkbox
                      color="default"
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      checked={showIncomplete}
                      onChange={(ev) => {
                        markFormIncomplete();
                      }}
                    />
                  </Tooltip>
                }
                label="Show Admin Form for Incomplete Grading"
              />
            </Grid>
          </Grid>
        )}

        {/* Administrative Disposition Panel */}
        {showDisposition() && (
          <Grid
            container
            direction="row"
            className={formClasses.unprintedContent}
            style={{ marginTop: 20, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
          >
            <Grid item xs={12} align="center">
              <label style={{ fontWeight: 600 }}>Successful Futures Administration Only</label>
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={2} className={formClasses.textRowLabelSpacer} align="left">
              <label style={{ fontWeight: 600, color: '#000000' }}>Planning Form 1 Status</label>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={6} className={formClasses.textArea5RowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="approve planning form1"
                  name="approve-planningform1"
                  horizontal="true"
                  value={form.status}
                  onChange={(event) => setForm({ ...form, status: event.target.value })}
                  disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                >
                  {!showIncomplete && (
                    <FormControlLabel
                      value="returned"
                      className={formClasses.marginRight40}
                      control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                      label={
                        <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                          Returned
                        </span>
                      }
                    />
                  )}
                  {!showIncomplete && (
                    <FormControlLabel
                      value="approved"
                      className={formClasses.marginRight40}
                      control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                      label={
                        <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                          Approved
                        </span>
                      }
                    />
                  )}
                  <FormControlLabel
                    value="incomplete"
                    className={formClasses.marginRight40}
                    control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                    label={
                      <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                        Incomplete
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className={formClasses.textArea5RowSpacer} align="right">
              <FormControlLabel
                style={{ marginLeft: '10px' }}
                control={
                  <Tooltip title="Student needs an additional senior planning meeting to address shortcomings in their current plan." placement="top">
                    <Checkbox
                      color="default"
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      checked={form.followup ? form.followup : false}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          setForm({ ...form, followup: true });
                        } else {
                          setForm({ ...form, followup: ev.target.checked });
                        }
                      }}
                    />
                  </Tooltip>
                }
                label="Needs Follow-up"
              />
            </Grid>
            {(form.status === 'returned' || form.status === 'approved') && (
              <React.Fragment>
                <Grid item xs={12} className={formClasses.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="disposition-reason"
                      size="small"
                      fullWidth
                      label={`Enter any notes for status '${titleize(form.status)}'`}
                      variant="outlined"
                      value={form.dispositionReason}
                      onChange={(event) => setForm({ ...form, dispositionReason: event.target.value })}
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      InputProps={{ maxLength: 500, classes: { input: formClasses.textField } }}
                      className={formClasses.root}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={formClasses.textRowSpacer}>
                  <label className={formClasses.note}>
                    {form.status === 'returned'
                      ? 'This reason will be displayed to the student when they view the returned planning form.'
                      : 'This reason will be displayed to the student when they view the approved planning form.'}
                  </label>
                </Grid>
              </React.Fragment>
            )}

            <Scoring form={form} setForm={setForm} disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />
          </Grid>
        )}
        <RequiredInfoDialog
          title={requiredFieldsTitle}
          introduction={requiredFieldsIntro}
          content={requiredFields}
          showDialog={showRequiredFieldsDialog}
          handleClose={handleRequiredInfoClose}
          handleContinue={handleRequiredInfoContinue}
          optional={optionalFieldOnly}
        />
        <Snackbar
          ContentProps={{
            classes: {
              root: formClasses.warning,
            },
          }}
          open={showWarning}
          autoHideDuration={6000}
          onClose={handleClose}
          message={warning}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ></Snackbar>
        <Snackbar
          ContentProps={{
            classes: {
              root: formClasses.errorAlert,
            },
          }}
          open={showError}
          autoHideDuration={10000}
          onClose={handleErrorClose}
          message={error}
          action={errorAction}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ></Snackbar>
      </React.Fragment>
    );
  })
);

SeniorPlanning1.propTypes = {
  handleSaveReturn: PropTypes.func,
};

export default SeniorPlanning1;
