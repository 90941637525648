import {
  container,
  defaultFont,
  webPrimaryColor,
  secondaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth,
  blackColor,
  whiteColor,
  grayColor,
  webInfoColor,
  hexToRgb,
} from '../../nextjs-material-kit-pro.js';

const publicHeaderStyle = (theme) => ({
  appBar: {
    display: 'flex',
    border: '0',
    padding: '0',
    marginBottom: '20px',
    color: grayColor[15],
    width: '100%',
    backgroundColor: webPrimaryColor,
    boxShadow: '0 4px 18px 0px rgba(' + hexToRgb(blackColor) + ', 0.12), 0 7px 10px -5px rgba(' + hexToRgb(blackColor) + ', 0.15)',
    transition: 'all 150ms ease 0s',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
    top: 'auto',
  },
  fixed: {
    position: 'fixed',
  },
  flexFrame: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-begin',
  },
  container: {
    ...container,
    minHeight: '70px',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    paddingLeft: 0,
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'space-between',
    },
  },
  title: {
    letterSpacing: 'unset',
    '&,& a': {
      ...defaultFont,
      minWidth: 'unset',
      lineHeight: '30px',
      fontSize: '18px',
      borderRadius: '3px',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      color: 'inherit',
      paddingBottom: 0,
      paddingTop: 0,
      [theme.breakpoints.down('lg')]: {
        marginLeft: '0',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '30px',
      },
      '&:hover,&:focus': {
        color: 'inherit',
        background: 'transparent',
      },
    },
  },
  brandNameLine1: {
    ...defaultFont,
    color: '#fdb515',
    textTransform: 'uppercase',
    fontSize: '1.10rem',
    lineHeight: '1.30rem',
    letterSpacing: '3px',
    fontWeight: 800,
    fontFamily: '"Source Sans Pro", Helvetica, Roboto, Arial, sans-serif',
  },
  brandNameLine2: {
    ...defaultFont,
    color: whiteColor,
    textTransform: 'uppercase',
    fontSize: '1.60rem',
    lineHeight: '1.10rem',
    letterSpacing: '2px',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  mobileBrandNameLine1: {
    ...defaultFont,
    color: '#fdb515',
    textTransform: 'uppercase',
    fontSize: '0.80rem',
    lineHeight: '1.00rem',
    letterSpacing: '3px',
    fontWeight: 800,
    fontFamily: '"Source Sans Pro", Helvetica, Roboto, Arial, sans-serif',
  },
  mobileBrandNameLine2: {
    ...defaultFont,
    color: whiteColor,
    textTransform: 'uppercase',
    fontSize: '1.20rem',
    lineHeight: '0.90rem',
    letterSpacing: '2px',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  logo: {
    [theme.breakpoints.down('lg')]: {
      margin: '15px 0',
      width: '60%',
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '15px 0',
      width: '60%',
      height: 'auto',
    },
  },
  appResponsive: {
    margin: '20px 10px',
    marginTop: '0px',
  },
  mobileAppResponsive: {
    margin: '20px 10px',
    marginTop: '22.5px',
  },
  primary: {
    backgroundColor: webPrimaryColor,
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(webPrimaryColor) + ', 0.46)',
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(infoColor[0]) + ', 0.46)',
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(successColor[0]) + ', 0.46)',
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(warningColor[0]) + ', 0.46)',
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(dangerColor[0]) + ', 0.46)',
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(roseColor[0]) + ', 0.46)',
  },
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    paddingTop: '25px',
    color: whiteColor,
  },
  dark: {
    color: whiteColor,
    backgroundColor: grayColor[9] + ' !important',
    boxShadow: '0 4px 20px 0px rgba(' + hexToRgb(blackColor) + ', 0.14), 0 7px 12px -5px rgba(' + hexToRgb(grayColor[9]) + ', 0.46)',
  },
  white: {
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    color: grayColor[15],
    backgroundColor: whiteColor + ' !important',
    boxShadow: '0 4px 18px 0px rgba(' + hexToRgb(blackColor) + ', 0.12), 0 7px 10px -5px rgba(' + hexToRgb(blackColor) + ', 0.15)',
  },
  drawerPaper: {
    backgroundColor: infoColor,
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: drawerWidth,
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '40vh',
    right: '0',
    left: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition,
  },
  mockDrawerPaper: {
    backgroundColor: webInfoColor,
    opacity: 0.9,
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: '100%',
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'center',
    ...transition,
    [theme.breakpoints.down('md')]: {
      top: '80px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '95px',
    },
    [theme.breakpoints.down('xs')]: {
      top: '70px',
    },
    [theme.breakpoints.up('md')]: {
      top: '120px',
    },
  },
  hidden: {
    width: '100%',
  },
  collapse: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex !important',
      MsFlexPreferredSize: 'auto',
      flexBasis: 'auto',
    },
    WebkitBoxFlex: '1',
    MsFlexPositive: '1',
    flexGrow: '1',
    WebkitBoxAlign: 'center',
    MsFlexAlign: 'center',
    alignItems: 'center',
  },
  closeButtonDrawer: {
    position: 'absolute',
    right: '8px',
    top: '9px',
    zIndex: '1',
  },
  mobileLink: {
    textTransform: 'uppercase',
    fontSize: '1.10rem',
    fontWeight: 600,
    margin: '10px 5px',
    '&, & a': {
      color: `${whiteColor} !important`,
    },
  },
  links: {
    textTransform: 'uppercase',
    fontSize: '1.00rem',
    lineHeight: '1.438rem',
    fontWeight: 600,
    [theme.breakpoints.up('lg')]: {
      display: 'flex !important',
      MsFlexPreferredSize: 'auto',
      flexBasis: 'auto',
      justifyContent: 'space-around',
    },
    WebkitBoxFlex: '1',
    MsFlexPositive: '1',
    flexGrow: '1',
    WebkitBoxAlign: 'center',
    MsFlexAlign: 'center',
    alignItems: 'center',
    '&, & a': {
      color: `${whiteColor}`,
      '&:hover,&:focus,&:active': {
        color: `${secondaryColor}`,
      },
    },
  },
  signinButton: {
    color: `${whiteColor} !important`,
    fontSize: '1.10rem',
    fontWeight: 600,
    [theme.breakpoints.up('lg')]: {
      marginLeft: '8%',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0 3px 0 -12px',
    },
  },
  mobileSigninButton: {
    color: `${whiteColor} !important`,
    fontSize: '1.10rem',
    fontWeight: 600,
    marginTop: '-8px',
  },
  appBarPosition: {
    [theme.breakpoints.up('md')]: {
      marginTop: '49px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
});

export default publicHeaderStyle;
