import React from 'react';

const PageUnauthorized = () => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} >
      <h2>Sorry you are not authorized to view this page.</h2>
    </div>

  );

}

export default PageUnauthorized;