import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@material-ui/core';

const ContactFinalAttempt = ({ classes, finalAttempt, setFinalAttempt, attemptCount, attemptResult }) => {
  return (
    <React.Fragment>
      <Grid container align="left" className={classes.rowSpacer}>
        {/* This is the attempt number prior to this attempt, so SFS is working on the 3rd attempt here. */}
        {attemptCount > 2 && attemptResult !== 'Refused' && (
          <>
            <Grid item xs={3} sm={2} md={2} lg={2} className={classes.rowSpacer}>
              <label id="Result-label" className={classes.adminLabel}>
                Final Attempt
              </label>
            </Grid>
            <Grid item xs={9} sm={8} md={8} lg={8}>
              <div>
                <FormControl fullWidth>
                  <FormControlLabel
                    className={classes.checkboxLabel + ' ' + classes.error}
                    style={{
                      verticalAlign: 'top',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginTop: 10,
                    }}
                    control={<Checkbox color="default" style={{ marginTop: '-10px' }} />}
                    label="*Contacts require at least 3 unsuccessful attempts before they can be closed. Please try to reach clients via text message, Facebook or other social media, or through an alternative legal guardian before closing."
                    checked={finalAttempt}
                    onChange={(event) => setFinalAttempt(!finalAttempt)}
                  />
                </FormControl>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

ContactFinalAttempt.propTypes = {
  classes: PropTypes.object.isRequired,
  finalAttempt: PropTypes.bool.isRequired,
  setFinalAttempt: PropTypes.func.isRequired,
  attemptCount: PropTypes.number.isRequired,
  attemptResult: PropTypes.string.isRequired,
};

export default ContactFinalAttempt;
