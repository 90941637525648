import React from 'react';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';

const AdvancedLifeSkillsSchedule = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #1
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Wednesday, August 21
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #2
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Sunday, September 15
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #3
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Wednesday, October 9
        </Grid>
        <Grid item xs={4} sm={6} md={7}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #4
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Sunday, November 10
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #5
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Wednesday, December 4
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #6
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Wednesday, January 15
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #7
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Sunday, February 16
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #8
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Wednesday, March 12
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" style={{ margin: '5px 0' }}>
        <Grid item xs={4} sm={3} md={2}>
          Class #9
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          Wednesday, April 23
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          6:30pm to 8:30pm
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdvancedLifeSkillsSchedule;
