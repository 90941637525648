import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { btOrangeColor, btLightBlueColor } from '../../assets/jss/nextjs-material-kit-pro';

const ContactVoicemailScript = ({ classes, username, type }) => {
  return (
    <React.Fragment>
      <hr style={{ borderTop: '2px solid ' + btLightBlueColor, margin: '20px 0' }} />
      <Grid container align="left">
        <Grid item xs={12} align="left" className={classes.rowSpacer}>
          <aside className={classes.reminder}>***If leaving a voice mail, use this script:</aside>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.scriptLabel}>Hello,</div>
          <br />
          <div className={classes.scriptLabel}>
            My name is <strong>{username} </strong>. I am calling you from the Successful Futures Department at Boys Town.
          </div>
          <br />
          <div className={classes.scriptLabel}>
            It has been {type} since graduation, and I am reaching out to you today to check in and see how things are going. Since this is not a good
            time, I would love if you would call me back, as we'd like to catch up and hear from you. My phone number is [
            <span style={{ textDecoration: 'underline', fontStyle: 'italic', fontSize: '1.20rem', fontWeight: 600, color: btOrangeColor }}>
              state your phone number
            </span>
            ]. Otherwise, watch for a call from me at this number tomorrow.
          </div>
          <br />
          <div className={classes.scriptLabel}>Thanks!</div>
          <br />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

ContactVoicemailScript.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ContactVoicemailScript;
