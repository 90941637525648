import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, TextareaAutosize } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  margin15Top: {
    marginTop: 15,
  },
  bigLabel: {
    fontSize: '0.85rem',
  },
  textArea8RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 280,
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      height: 165,
      marginBottom: 20,
    },
  },
  textArea10RowSpacer: {
    height: 225,
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function BackupPlan(props) {
  const { form, setForm, disabled } = props;

  const classes = styles();

  return (
    <React.Fragment>
      <Box sx={{ width: '95%' }} className={classes.margin15Top}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} align="left">
            <section align="left" className={classes.bigLabel}>
              In the event that my plan unexpectedly changes due to circumstances beyond my control (i.e., COVID), list 1) my back-up plan, and 2)
              what items I will need to have thought about and be prepared for in order to be successful with that back-up plan.
            </section>
          </Grid>
          <Grid item xs={12} className={classes.textArea8RowSpacer}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="backup-plan"
                minRows={7}
                maxRows={7}
                maxLength={1000}
                value={form.backupPlan}
                onChange={(event) => setForm({ ...form, backupPlan: event.target.value })}
                disabled={disabled}
                className={classes.textAreaFont}
                style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>
              In the event that my plan unexpectedly changes due to circumstances beyond my control (i.e., COVID), list 1) my back-up plan, and 2)
              what items I will need to have thought about and be prepared for in order to be successful with that back-up plan.
            </label>
          </div>
          <div>
            <p className={classes.printedContent} style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>
              {form.backupPlan || null}
            </p>
          </div>
        </div>

        <Grid container direction="row" className={classes.textArea10RowSpacer + ' ' + classes.unprintedContent}>
          <Grid item xs={12} align="left">
            <section align="left" className={classes.bigLabel}>
              What I need to think about and prepare for in order to be successful with this plan (i.e., Classes move to online learning? Do I have a
              laptop in order to attend classes virtually?)
            </section>
          </Grid>
          <Grid item xs={12} className={classes.textArea8RowSpacer}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="backup-plan_preparations"
                minRows={7}
                maxRows={7}
                maxLength={1000}
                value={form.backupPlanPreparations}
                onChange={(event) => setForm({ ...form, backupPlanPreparations: event.target.value })}
                disabled={disabled}
                className={classes.textAreaFont}
                style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>
              What I need to think about and prepare for in order to be successful with this plan (i.e., Classes move to online learning? Do I have a
              laptop in order to attend classes virtually?)
            </label>
          </div>
          <div>
            <p className={classes.printedContent} style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>
              {form.backupPlanPreparations || null}
            </p>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
            <label>Where will I live?</label>
          </Grid>
          <Grid item xs={12} md={6} lg={9} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="backup-plan-living"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.backupPlanLiving}
                onChange={(event) => setForm({ ...form, backupPlanLiving: event.target.value })}
                InputProps={{ classes: { input: classes.textField } }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>Where will I live?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.backupPlanLiving || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
            <label>How will I pay for it?</label>
          </Grid>
          <Grid item xs={12} md={6} lg={9} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="backup-plan-cost"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.backupPlanCost}
                onChange={(event) => setForm({ ...form, backupPlanCost: event.target.value })}
                InputProps={{ classes: { input: classes.textField } }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>How will I pay for it?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.backupPlanCost || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
            <label>What items will I need?</label>
          </Grid>
          <Grid item xs={12} md={6} lg={9} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="backup-plan-need"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.backupPlanNeed}
                onChange={(event) => setForm({ ...form, backupPlanNeed: event.target.value })}
                InputProps={{ classes: { input: classes.textField } }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What items will I need?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.backupPlanNeed || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
            <label>Who will help me?</label>
          </Grid>
          <Grid item xs={12} md={6} lg={9} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="backup-plan-help"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.backupPlanHelp}
                onChange={(event) => setForm({ ...form, backupPlanHelp: event.target.value })}
                InputProps={{ classes: { input: classes.textField } }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>Who will help me?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.backupPlanHelp || null}</summary>
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
}

BackupPlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};
