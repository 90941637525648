import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, TextareaAutosize, Tooltip } from '@material-ui/core';

const DesktopEval = ({
  formExtra,
  setFormExtra,
  record,
  setRecord,
  score_inspires,
  setScoreInspires,
  score_positive,
  setScorePositive,
  score_feedback,
  setScoreFeedback,
  score_progress,
  setScoreProgress,
  score_motivation,
  setScoreMotivation,
  score_awareness,
  setScoreAwareness,
  score_commitments,
  setScoreCommitments,
  score_resourceful,
  setScoreResourceful,
  recommend_scholarship,
  setRecommendScholarship,
  recommend_tradelife,
  setRecommendTradelife,
  recommend_housing,
  setRecommendHousing,
  recommendationText,
  setRecommendationText,
  classes,
}) => {
  const componentRef = useRef();

  return (
    <Grid container direction="row" className={classes.formContainer}>
      <Grid item xs={12}>
        <div align="left" className={classes.question}>
          <strong>Please answer the following questions using the rating scale 1 (Strongly Disagree) to 5 (Strongly Agree).</strong>
        </div>
        <br />
        <br />
        <Grid container direction="row">
          <Grid item xs={12} md={6} lg={6}>
            <label></label>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <Grid item xs={12} md={2} lg={2} align="center">
                <main>
                  Strongly
                  <br />
                  Disagree
                </main>
              </Grid>
              <Grid item xs={12} md={2} lg={2} align="center">
                <main>
                  <br />
                  Disagree
                </main>
              </Grid>
              <Grid item xs={12} md={2} lg={2} align="center">
                <main>
                  <br />
                  Neither
                </main>
              </Grid>
              <Grid item xs={12} md={2} lg={2} align="center">
                <main>
                  <br />
                  Agree
                </main>
              </Grid>
              <Grid item xs={12} md={2} lg={2} align="center">
                <main>
                  Strongly
                  <br />
                  Agree
                </main>
              </Grid>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              1. {record.student_first_name} {record.student_last_name} includes and inspires others.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="includes and inspires others"
                  name="score_inspires-others"
                  value={score_inspires}
                  onChange={(event) => setScoreInspires(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              2. {record.student_first_name} {record.student_last_name} sets a positive example by being true to self.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="sets positive examples"
                  name="score_positive-examples"
                  value={score_positive}
                  onChange={(event) => setScorePositive(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              3. {record.student_first_name} {record.student_last_name} is open to feedback.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="open to feedback"
                  name="open-to-feedback"
                  value={score_feedback}
                  onChange={(event) => setScoreFeedback(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              4. {record.student_first_name} {record.student_last_name} has a desire to maintain progress with her/his/their life.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="maintain progress"
                  name="maintain-progress"
                  value={score_progress}
                  onChange={(event) => setScoreProgress(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              5. {record.student_first_name} {record.student_last_name} maintains motivation and determination in the face of adversity.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="maintains motivation"
                  name="maintains-motivation"
                  value={score_motivation}
                  onChange={(event) => setScoreMotivation(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              6. {record.student_first_name} {record.student_last_name} has awareness of doing the right thing and applies it.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="awareness"
                  name="awareness"
                  value={score_awareness}
                  onChange={(event) => setScoreAwareness(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              7. {record.student_first_name} {record.student_last_name} makes commitments and follows through.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="makes commitments"
                  name="makes-commitments"
                  value={score_commitments}
                  onChange={(event) => setScoreCommitments(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
            <summary>
              8. {record.student_first_name} {record.student_last_name} is resourceful in securing a bright future.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="row">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="resourceful"
                  name="resourceful"
                  value={score_resourceful}
                  onChange={(event) => setScoreResourceful(event.target.value)}
                >
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Disagree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Neither" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                    <FormControlLabel value="Strongly Agree" control={<Radio color="default" />} />
                  </Grid>
                </RadioGroup>
              </FormControl>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
              <strong>
                I would recommend {record.student_first_name} {record.student_last_name} for:
              </strong>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container direction="row">
                <Grid item xs={12} md={2} lg={2} align="center">
                  <main>Yes</main>
                </Grid>
                <Grid item xs={12} md={2} lg={2} align="center">
                  <main>No</main>
                </Grid>
                <Grid item xs={12} md={2} lg={2} align="center">
                  <main>Hesitant</main>
                </Grid>
                <Grid item xs={12} md={2} lg={2} align="center"></Grid>
                <Grid item xs={12} md={2} lg={2} align="center"></Grid>
                <Grid item xs={false} md={2} lg={2} />
              </Grid>
            </Grid>

            {formExtra.scholarships && (
              <>
                <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    title={
                      <div className={classes.largeTooltip}>
                        Boys Town offers need-based and merit scholarships to alumni pursuing undergraduate and trade degrees.
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <summary>A Boys Town scholarship</summary>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Grid container direction="row">
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="Boys Town Scholarship"
                        name="boys-town-scholarship"
                        value={recommend_scholarship}
                        onChange={(event) => setRecommendScholarship(event.target.value)}
                      >
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="Yes" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="No" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="Hesitant" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}></Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}></Grid>
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={false} md={2} lg={2} />
                  </Grid>
                </Grid>
              </>
            )}

            {formExtra.tradeLife && (
              <>
                <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    title={
                      <div className={classes.largeTooltip}>
                        Trade Life services provides life skills training, mentoring, and other supports to alumni as they transition to independent
                        living.
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <summary>Successful Futures Trade Life Program</summary>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Grid container direction="row">
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="trade life program"
                        name="tradelife-program"
                        value={recommend_tradelife}
                        onChange={(event) => setRecommendTradelife(event.target.value)}
                      >
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="Yes" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="No" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="Hesitant" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}></Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}></Grid>
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={false} md={2} lg={2} />
                  </Grid>
                </Grid>
              </>
            )}

            {formExtra.housing && (
              <>
                <Grid item xs={12} md={6} lg={6} align="left" className={classes.question}>
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    title={
                      <div className={classes.largeTooltip}>
                        Successful Futures housing services support Family Home Program graduates, ages 17-21, by providing safe and affordable
                        housing.
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <summary>Successful Futures Housing Program</summary>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Grid container direction="row">
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="housing program"
                        name="housing-program"
                        value={recommend_housing}
                        onChange={(event) => setRecommendHousing(event.target.value)}
                      >
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="Yes" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="No" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}>
                          <FormControlLabel value="Hesitant" control={<Radio color="default" />} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}></Grid>
                        <Grid item xs={12} md={2} lg={2} align="center" className={classes.radio}></Grid>
                      </RadioGroup>
                    </FormControl>
                    <Grid item xs={false} md={2} lg={2} />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.formContainer}>
        <div className={classes.instructions}>
          <br />
          <br />
          Please consider the above questions while writing your recommendation.
          <br />
        </div>
        <div className={classes.textAreaWidth}>
          <FormControl fullWidth>
            <TextareaAutosize
              aria-label="plan-descriptionB"
              minRows={15}
              maxRows={15}
              value={recommendationText}
              onChange={(event) => setRecommendationText(event.target.value)}
              className={classes.textAreaFont}
            />
          </FormControl>
        </div>
      </Grid>
    </Grid>
  );
};

DesktopEval.propTypes = {
  formExtra: PropTypes.object,
  setFormExtra: PropTypes.func,
  record: PropTypes.object,
  setRecord: PropTypes.func,
  setIsLoading: PropTypes.func,
  score_inspires: PropTypes.string,
  setScoreInspires: PropTypes.func,
  score_positive: PropTypes.string,
  setScorePositive: PropTypes.func,
  score_feedback: PropTypes.string,
  setScoreFeedback: PropTypes.func,
  score_progress: PropTypes.string,
  setScoreProgress: PropTypes.func,
  score_motivation: PropTypes.string,
  setScoreMotivation: PropTypes.func,
  score_awareness: PropTypes.string,
  setScoreAwareness: PropTypes.func,
  score_commitments: PropTypes.string,
  setScoreCommitments: PropTypes.func,
  score_resourceful: PropTypes.string,
  setScoreResourceful: PropTypes.func,
  recommend_scholarship: PropTypes.string,
  setRecommendScholarship: PropTypes.func,
  recommend_tradelife: PropTypes.string,
  setRecommendTradelife: PropTypes.func,
  recommend_housing: PropTypes.string,
  setRecommendHousing: PropTypes.func,
  recommendationText: PropTypes.string,
  setRecommendationText: PropTypes.func,
  classes: PropTypes.object,
};

export default DesktopEval;
