import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Grid, FormControl, FormControlLabel } from '@material-ui/core';
import { btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  checklistRow: {
    margin: '10px 0',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checklistColumn: {
    alignItems: 'center',
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  pointsLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  dueLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '160px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    verticalAlign: 'top',
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,

    marginTop: 5,
  },
  underline: {
    margin: '5px 0',
    borderBottom: '1px solid #000',
  },
  checkOutline: {
    border: '1px solid #000',
    borderRadius: 6,
    maxWidth: '35px',
    alignContent: 'center',
    minWidth: '35px',
  },
  subtleBold: {
    fontWeight: 500,
    color: btMediumGrayColor,
  },
}));

export default function PostSecondaryCareerItems(props) {
  const { form, setForm, auth, act, alumni } = props;

  const classes = styles();

  const isFieldDisabled = (val) => {
    if (alumni) return true;
    return false;
  };

  const isConfirmDisabled = (val) => {
    if (alumni || auth.roles.includes('STUDENT')) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <main>
            <ul>
              <li>
                *If a HS diploma/GED is required prior to the application process, steps will be discussed/planned for implementation, with the
                Successful Futures Specialist, after the diploma/GED is obtained.
              </li>
            </ul>
          </main>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}></Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}></Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <main>
            <ul>
              <li>
                *If a HS diploma/GED is required prior to the application process, steps will be discussed/planned for implementation, with the
                Successful Futures Specialist, after the diploma/GED is obtained.
              </li>
            </ul>
          </main>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}></div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <main>
            <ul>
              <li>Explore and visit Career Training options that best fit your areas of interest, as well as your strengths.</li>
            </ul>
          </main>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}></Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}></Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <main>
            <ul>
              <li>Explore and visit Career Training options that best fit your areas of interest, as well as your strengths.</li>
            </ul>
          </main>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}></div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="33. Research the start dates that the programs offer - how do these start dates align with graduation dates?"
              disabled={isFieldDisabled(form.confirmSecCareerStartDates)}
              checked={form.secCareerStartDates}
              onChange={(event) => setForm({ ...form, secCareerStartDates: !form.secCareerStartDates })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerStartDates)}
            checked={form.confirmSecCareerStartDates}
            onChange={(event) => setForm({ ...form, confirmSecCareerStartDates: !form.confirmSecCareerStartDates })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="33. Research the start dates that the programs offer - how do these start dates align with graduation dates?"
              checked={form.secCareerStartDates}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerStartDates}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="34. Submit 2-3 applications to the programs you are most interested in and are the best fit for you."
              disabled={isFieldDisabled(form.confirmSecCareerSubmitApps)}
              checked={form.secCareerSubmitApps}
              onChange={(event) => setForm({ ...form, secCareerSubmitApps: !form.secCareerSubmitApps })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerSubmitApps)}
            checked={form.confirmSecCareerSubmitApps}
            onChange={(event) => setForm({ ...form, confirmSecCareerSubmitApps: !form.confirmSecCareerSubmitApps })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="34. Submit 2-3 applications to the programs you are most interested in and are the best fit for you."
              checked={form.secCareerSubmitApps}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerSubmitApps}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="35. Look into the financial cost of the training programs that you are interested in.  Are there opportunities to apply for scholarships or other forms of financial assistance?  Write down the options that are offered to help pay for the training."
              disabled={isFieldDisabled(form.confirmSecCareerFinancial)}
              checked={form.secCareerFinancial}
              onChange={(event) => setForm({ ...form, secCareerFinancial: !form.secCareerFinancial })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerFinancial)}
            checked={form.confirmSecCareerFinancial}
            onChange={(event) => setForm({ ...form, confirmSecCareerFinancial: !form.confirmSecCareerFinancial })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="35. Look into the financial cost of the training programs that you are interested in.  Are there opportunities to apply for scholarships or other forms of financial assistance?  Write down the options that are offered to help pay for the training."
              checked={form.secCareerFinancial}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerFinancial}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="36. Does the training program accept grants/loans offered by completing the FAFSA? (If yes, complete items 38-40 below.)"
              disabled={isFieldDisabled(form.confirmSecCareerAcceptFAFSASupport)}
              checked={form.secCareerAcceptFAFSASupport}
              onChange={(event) => setForm({ ...form, secCareerAcceptFAFSASupport: !form.secCareerAcceptFAFSASupport })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerAcceptFAFSASupport)}
            checked={form.confirmSecCareerAcceptFAFSASupport}
            onChange={(event) => setForm({ ...form, confirmSecCareerAcceptFAFSASupport: !form.confirmSecCareerAcceptFAFSASupport })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="36. Does the training program accept grants/loans offered by completing the FAFSA? (If yes, complete items 38-40 below.)"
              checked={form.secCareerAcceptFAFSASupport}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerAcceptFAFSASupport}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="37. a) Go to FAFSA.ed.gov and create a Federal Student Aid Account. (one for you and one for a parent, if applicable.) It may be easiest to have a parent create their FAFSA ID first and then create your FAFSA ID."
              disabled={isFieldDisabled(form.confirmSecCareerFASID)}
              checked={form.secCareerFASID}
              onChange={(event) => setForm({ ...form, secCareerFASID: !form.secCareerFASID })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>1</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerFASID)}
            checked={form.confirmSecCareerFASID}
            onChange={(event) => setForm({ ...form, confirmSecCareerFASID: !form.confirmSecCareerFASID })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="37. a) Go to FAFSA.ed.gov and create a Federal Student Aid Account. (one for you and one for a parent, if applicable.) It may be easiest to have a parent create their FAFSA ID first and then create your FAFSA ID."
              checked={form.secCareerFASID}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            1<Checkbox color="default" disabled={true} checked={form.confirmSecCareerFASID}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="38. b) Complete the FAFSA on or after October 1st."
              disabled={isFieldDisabled(form.confirmSecCareerCompleteFAFSA)}
              checked={form.secCareerCompleteFAFSA}
              onChange={(event) => setForm({ ...form, secCareerCompleteFAFSA: !form.secCareerCompleteFAFSA })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>1</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerCompleteFAFSA)}
            checked={form.confirmSecCareerCompleteFAFSA}
            onChange={(event) => setForm({ ...form, confirmSecCareerCompleteFAFSA: !form.confirmSecCareerCompleteFAFSA })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="38. b) Complete the FAFSA on or after October 1st."
              checked={form.secCareerCompleteFAFSA}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            1<Checkbox color="default" disabled={true} checked={form.confirmSecCareerCompleteFAFSA}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="39. c) Confirm you received a Student Aid Index (SAI) in your email."
              disabled={isFieldDisabled(form.confirmSecCareerLocateStudentAidRpt)}
              checked={form.secCareerLocateStudentAidRpt}
              onChange={(event) => setForm({ ...form, secCareerLocateStudentAidRpt: !form.secCareerLocateStudentAidRpt })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>1</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerLocateStudentAidRpt)}
            checked={form.confirmSecCareerLocateStudentAidRpt}
            onChange={(event) => setForm({ ...form, confirmSecCareerLocateStudentAidRpt: !form.confirmSecCareerLocateStudentAidRpt })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="39. c) Confirm you received a Student Aid Index (SAI) in your email."
              checked={form.secCareerLocateStudentAidRpt}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            1<Checkbox color="default" disabled={true} checked={form.confirmSecCareerLocateStudentAidRpt}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="40. Where will you live while you are enrolled in this training program?  Explore Housing opportunities and list two options that are close to the training program and are realistic for your situation."
              disabled={isFieldDisabled(form.confirmSecCareerExploreHousing)}
              checked={form.secCareerExploreHousing}
              onChange={(event) => setForm({ ...form, secCareerExploreHousing: !form.secCareerExploreHousing })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerExploreHousing)}
            checked={form.confirmSecCareerExploreHousing}
            onChange={(event) => setForm({ ...form, confirmSecCareerExploreHousing: !form.confirmSecCareerExploreHousing })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="40. Where will you live while you are enrolled in this training program?  Explore Housing opportunities and list two options that are close to the training program and are realistic for your situation."
              checked={form.secCareerExploreHousing}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerExploreHousing}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="41. You will need transportation to and from the training program every day.  Identify 2 affordable options for transportation."
              disabled={isFieldDisabled(form.confirmSecCareerIdentifyTransportation)}
              checked={form.secCareerIdentifyTransportation}
              onChange={(event) => setForm({ ...form, secCareerIdentifyTransportation: !form.secCareerIdentifyTransportation })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerIdentifyTransportation)}
            checked={form.confirmSecCareerIdentifyTransportation}
            onChange={(event) => setForm({ ...form, confirmSecCareerIdentifyTransportation: !form.confirmSecCareerIdentifyTransportation })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="41. You will need transportation to and from the training program every day.  Identify 2 affordable options for transportation."
              checked={form.secCareerIdentifyTransportation}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerIdentifyTransportation}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="42. Look at the required equipment necessary prior to starting the training program.  Make a list of any tools you will need.  Develop a plan for how you will get the necessary tools."
              disabled={isFieldDisabled(form.confirmSecCareerTools)}
              checked={form.secCareerTools}
              onChange={(event) => setForm({ ...form, secCareerTools: !form.secCareerTools })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerTools)}
            checked={form.confirmSecCareerTools}
            onChange={(event) => setForm({ ...form, confirmSecCareerTools: !form.confirmSecCareerTools })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="42. Look at the required equipment necessary prior to starting the training program.  Make a list of any tools you will need.  Develop a plan for how you will get the necessary tools."
              checked={form.secCareerTools}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerTools}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="43. Create a budget that you will use after graduation when you start in this program."
              disabled={isFieldDisabled(form.confirmSecCareerBudget)}
              checked={form.secCareerBudget}
              onChange={(event) => setForm({ ...form, secCareerBudget: !form.secCareerBudget })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerBudget)}
            checked={form.confirmSecCareerBudget}
            onChange={(event) => setForm({ ...form, confirmSecCareerBudget: !form.confirmSecCareerBudget })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="43. Create a budget that you will use after graduation when you start in this program."
              checked={form.secCareerBudget}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerBudget}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="44. Once accepted, ask for any necessary information (example: student ID/password) to log into an account for further information. Store any necessary/private information in your senior binder."
              disabled={isFieldDisabled(form.confirmSecCareerNecessaryInfo)}
              checked={form.secCareerNecessaryInfo}
              onChange={(event) => setForm({ ...form, secCareerNecessaryInfo: !form.secCareerNecessaryInfo })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerNecessaryInfo)}
            checked={form.confirmSecCareerNecessaryInfo}
            onChange={(event) => setForm({ ...form, confirmSecCareerNecessaryInfo: !form.confirmSecCareerNecessaryInfo })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="44. Once accepted, ask for any necessary information (example: student ID/password) to log into an account for further information. Store any necessary/private information in your senior binder."
              checked={form.secCareerNecessaryInfo}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerNecessaryInfo}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="45. Submit any deposits necessary to hold/secure your application and ensure your start date is held for you."
              disabled={isFieldDisabled(form.confirmSecCareerSendDeposits)}
              checked={form.secCareerSendDeposits}
              onChange={(event) => setForm({ ...form, secCareerSendDeposits: !form.secCareerSendDeposits })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerSendDeposits)}
            checked={form.confirmSecCareerSendDeposits}
            onChange={(event) => setForm({ ...form, confirmSecCareerSendDeposits: !form.confirmSecCareerSendDeposits })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="45. Submit any deposits necessary to hold/secure your application and ensure your start date is held for you."
              checked={form.secCareerSendDeposits}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecCareerSendDeposits}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="46. Complete applications for housing."
              disabled={isFieldDisabled(form.confirmSecCareerHousingApps)}
              checked={form.secCareerHousingApps}
              onChange={(event) => setForm({ ...form, secCareerHousingApps: !form.secCareerHousingApps })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerHousingApps)}
            checked={form.confirmSecCareerHousingApps}
            onChange={(event) => setForm({ ...form, confirmSecCareerHousingApps: !form.confirmSecCareerHousingApps })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="46. Complete applications for housing."
              checked={form.secCareerHousingApps}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerHousingApps}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="47. Will you need a part time job while attending this training program to pay for your living expenses?  If “yes” then find and apply for at least 3 part-time jobs that fit the schedule of the training program and are near the location of where you will be living."
              disabled={isFieldDisabled(form.confirmSecCareerPartTimeJobs)}
              checked={form.secCareerPartTimeJobs}
              onChange={(event) => setForm({ ...form, secCareerPartTimeJobs: !form.secCareerPartTimeJobs })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerPartTimeJobs)}
            checked={form.confirmSecCareerPartTimeJobs}
            onChange={(event) => setForm({ ...form, confirmSecCareerPartTimeJobs: !form.confirmSecCareerPartTimeJobs })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="47. Will you need a part time job while attending this training program to pay for your living expenses?  If “yes” then find and apply for at least 3 part-time jobs that fit the schedule of the training program and are near the location of where you will be living."
              checked={form.secCareerPartTimeJobs}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecCareerPartTimeJobs}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="48. Identify and plan start dates for the program and job (if necessary) and a move-in date for your housing."
              disabled={isFieldDisabled(form.confirmSecCareerPlanDates)}
              checked={form.secCareerPlanDates}
              onChange={(event) => setForm({ ...form, secCareerPlanDates: !form.secCareerPlanDates })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>April - May</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secCareerPlanDates)}
            checked={form.confirmSecCareerPlanDates}
            onChange={(event) => setForm({ ...form, confirmSecCareerPlanDates: !form.confirmSecCareerPlanDates })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="48. Identify and plan start dates for the program and job (if necessary) and a move-in date for your housing."
              checked={form.secCareerPlanDates}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecCareerPlanDates}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>April - May</div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

PostSecondaryCareerItems.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  auth: PropTypes.object,
  act: PropTypes.number,
  alumni: PropTypes.bool.isRequired,
};
