import React from 'react';
import { Grid } from '@material-ui/core';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import ACTTestDates from './SeniorInformationComponents/ACTTestDates';
import PostSecondaryOpportunities from './SeniorInformationComponents/PostSecondaryOpportunities';
import Resources from './SeniorInformationComponents/Resources';
import OtherResources from './SeniorInformationComponents/OtherResources';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';

const SeniorTips = () => {
  return (
    <React.Fragment>
      <Grid container spacing={(0.1, 3)} direction="row">
        <Grid item xs={12} lg={6}>
          <Card style={{ backgroundColor: '#F5F5F5', margin: '0, 20px', minHeight: '300px' }}>
            <CardBody style={{ color: btNavyBlueColor }}>
              <ACTTestDates />
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card style={{ backgroundColor: '#F5F5F5', margin: '0, 20px', minHeight: '300px' }}>
            <CardBody style={{ color: btNavyBlueColor }}>
              <PostSecondaryOpportunities />
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card style={{ backgroundColor: '#F5F5F5', margin: '0, 20px', minHeight: '300px' }}>
            <CardBody style={{ color: btNavyBlueColor }}>
              <Resources />
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card style={{ backgroundColor: '#F5F5F5', margin: '0, 20px', minHeight: '300px' }}>
            <CardBody style={{ color: btNavyBlueColor }}>
              <OtherResources />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SeniorTips;
