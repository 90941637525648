import { callApi } from './apiWrapper';

export const logAudit = async (auth, auditItem) => {
  try {
    const res = await callApi(auth, 'audit', 'POST', { item: auditItem });
    return res;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
