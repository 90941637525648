import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    margin: '10px 0',
    padding: '10px 0',
  },
}));

export default function ACTTestDates(props) {
  const classes = styles();

  return (
    <Box sx={{ width: '100%', maxHeight: '255px', overflowY: 'auto' }}>
      <section className={classes.title}>
        <span className={classes.iconText}>Upcoming ACT Test Dates</span>
      </section>
      <Grid container direction="row" align="left">
        <Grid item xs={4} className={classes.bold}>
          Test Date
        </Grid>
        <Grid item xs={4} className={classes.bold}>
          Registration Deadline
        </Grid>
        <Grid item xs={4} className={classes.bold}>
          Late Fee Required
        </Grid>
      </Grid>
      {new Date() < new Date('2024/02/10') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            February 10, 2024
          </Grid>
          <Grid item xs={4}>
            January 5
          </Grid>
          <Grid item xs={4}>
            January 19
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2024/04/13') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            April 13, 2024
          </Grid>
          <Grid item xs={4}>
            March 8
          </Grid>
          <Grid item xs={4}>
            March 22
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2024/06/08') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            June 8, 2024
          </Grid>
          <Grid item xs={4}>
            May 3
          </Grid>
          <Grid item xs={4}>
            May 17
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2024/07/13') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            July 13, 2024
          </Grid>
          <Grid item xs={4}>
            June 7
          </Grid>
          <Grid item xs={4}>
            June 21
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2024/09/14') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            September 14, 2024
          </Grid>
          <Grid item xs={4}>
            August 9
          </Grid>
          <Grid item xs={4}>
            August 25
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2024/10/26') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            October 26, 2024
          </Grid>
          <Grid item xs={4}>
            September 20
          </Grid>
          <Grid item xs={4}>
            November 8
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2024/12/14') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            December 14, 2024
          </Grid>
          <Grid item xs={4}>
            November 8
          </Grid>
          <Grid item xs={4}>
            November 22
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2025/02/08') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            February 8, 2025
          </Grid>
          <Grid item xs={4}>
            January 3
          </Grid>
          <Grid item xs={4}>
            January 20
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2025/04/05') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            April 5, 2025
          </Grid>
          <Grid item xs={4}>
            February 28
          </Grid>
          <Grid item xs={4}>
            March 16
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2025/06/14') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            June 14, 2025
          </Grid>
          <Grid item xs={4}>
            May 9
          </Grid>
          <Grid item xs={4}>
            May 26
          </Grid>
        </Grid>
      )}
      {new Date() < new Date('2025/07/12') && (
        <Grid container direction="row" align="left">
          <Grid item xs={4}>
            July 12, 2025
          </Grid>
          <Grid item xs={4}>
            June 6
          </Grid>
          <Grid item xs={4}>
            June 20
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" align="left">
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          For more information, recommendations, and test rules, see{' '}
          <a href="https://www.act.org/content/act/en/products-and-services/the-act/registration.html" target="_blank" rel="noreferrer">
            <span style={{ textDecoration: 'underline', color: 'blue' }}>ACT.org</span>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
}
