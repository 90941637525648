import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Grid, FormControl, FormControlLabel, TextField } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  buttonWidth: {
    width: 110,
    marginTop: 10,
  },
  resendButtonWidth: {
    width: 220,
    marginTop: 10,
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  buttonSpacer: {
    marginTop: 20,
  },
}));

export default function GeneralInformation(props) {
  const {
    // senior,
    form,
    setForm,
    formExtra,
    setFormExtra,
    disabled,
    // roles,
    showSendButton,
    handleSendInvitation,
    showResendButton,
    handleResendInvitation,
    closeInvitation,
  } = props;

  const classes = styles();

  return (
    <Box sx={{ width: '95%' }}>
      <Grid container direction="row">
        <Grid item xs={4} sm={3} md={3} lg={2} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="salutation"
              size="small"
              hiddenLabel
              variant="outlined"
              value={form.salutation}
              onChange={(event) => setForm({ ...form, salutation: event.target.value })}
              disabled={disabled}
              placeholder="Mr, Mrs, Ms..."
              style={{ width: '95%' }}
              InputProps={{ maxLength: 10, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="first-name"
              size="small"
              hiddenLabel
              variant="outlined"
              value={form.first_name}
              onChange={(event) => setForm({ ...form, first_name: event.target.value })}
              disabled={disabled}
              placeholder="First Name"
              style={{ width: '95%' }}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="last-name"
              size="small"
              hiddenLabel
              variant="outlined"
              value={form.last_name}
              onChange={(event) => setForm({ ...form, last_name: event.target.value })}
              disabled={disabled}
              placeholder="Last Name"
              style={{ width: '95%' }}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} sm={false} md={1} lg={4} />
        <Grid item xs={12} sm={7} md={6} lg={4} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="email"
              size="small"
              hiddenLabel
              variant="outlined"
              value={form.email}
              onChange={(event) => setForm({ ...form, email: event.target.value })}
              disabled={disabled}
              placeholder="Email"
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} sm={5} md={false} lg={8} className={classes.textRowSpacer} />
        <Grid item xs={12} sm={7} md={6} lg={4} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="relationship"
              size="small"
              hiddenLabel
              variant="outlined"
              value={formExtra.relationship}
              onChange={(event) => setFormExtra({ ...formExtra, relationship: event.target.value })}
              disabled={disabled}
              placeholder="Relation"
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} sm={5} md={6} lg={8} />
        <Grid item xs={12} sm={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
          This request is for (check all that apply):
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} className={classes.textRowSpacer}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={formExtra.scholarships ? formExtra.scholarships : false}
                onChange={(event) => setFormExtra({ ...formExtra, scholarships: !formExtra.scholarships })}
              />
            }
            label="Scholarships"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={formExtra.tradeLife ? formExtra.tradeLife : false}
                onChange={(event) => setFormExtra({ ...formExtra, tradeLife: !formExtra.tradeLife })}
              />
            }
            label="Trade Life"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={formExtra.housing ? formExtra.housing : false}
                onChange={(event) => setFormExtra({ ...formExtra, housing: !formExtra.housing })}
              />
            }
            label="Housing"
          />
        </Grid>
        <Grid item xs={false} sm={false} md={6} lg={6} />
        {/* <Grid item xs={12} sm={12} md={9} lg={9}>
          <div style={{ border: '1px solid #000', padding: 10 }}>
            Hello [title] [name],
            <br />
            <br />
            You have received a request from [student's name] to complete a Successful Futures recommendation on their behalf. Please click the link
            in the details below to complete the recommendation. This request will expire in 30 days.
            <br />
            <br />
            Thank you,
            <br />
            The Successful Futures Team
            <br />
          </div>
        </Grid>
        <Grid item xs={false} sm={false} md={3} lg={3} /> */}
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonSpacer}>
          {showSendButton() && (
            <Button className={classes.buttonWidth} color="primary" variant="contained" onClick={(event) => handleSendInvitation()}>
              Invite
            </Button>
          )}
          {showResendButton(form.expires_utc) && (
            <Button className={classes.resendButtonWidth} color="primary" variant="contained" onClick={(event) => handleResendInvitation()}>
              Resend Invitation
            </Button>
          )}
          <div>
            <Button
              className={classes.buttonWidth}
              // style={{ marginLeft: showSendButton() || showResendButton() ? 20 : 0 }}
              color="default"
              variant="contained"
              onClick={(event) => closeInvitation()}
            >
              {showSendButton() || showResendButton() ? 'Cancel' : 'Close'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

GeneralInformation.propTypes = {
  senior: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  formExtra: PropTypes.object,
  setFormExtra: PropTypes.func,
  disabled: PropTypes.bool,
  roles: PropTypes.array,
  showSendButton: PropTypes.func,
  handleSendInvitation: PropTypes.func,
  showResendButton: PropTypes.func,
  handleResendInvitation: PropTypes.func,
  closeInvitation: PropTypes.func,
};
