import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { dateOnlyFormat, dateTimeToS, dateUtcToS, dateToS, dateTimeUtcToS } from '../../util/dateUtil';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
  Typography,
  TablePagination,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableStyle from '../../styles/TableStyle';
import { observer, inject } from 'mobx-react';
import { logException } from '../../services/appInsights';
import { btNavyBlueColor, btMediumGrayColor, btLightBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import { callApi } from '../../util/apiWrapper';
import TitleBar from '../Shared/TitleBar';
import ContactCards from './ContactCards';
import PageUnauthorized from '../Shared/PageUnauthorized';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import AddIcon from '@material-ui/icons/Add';
import ContactCheckinDetails from './ContactCheckinDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles((theme) => ({
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  approvalLink: {
    fontSize: '0.85rem',
    padding: '2px, 5px',
    textTransform: 'none',
    justifyContent: 'center',
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  filter: {
    margin: '5px 0 15px 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
  },
  noRecordsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
  detailsLink: {
    cursor: 'pointer',
    margin: '5px 0 0 10px',
  },
  rowSpacer: {
    margin: '10px 0',
  },
  question: {
    padding: '7px 5px 7px 0',
  },
  answer: {
    padding: '7px 0',
  },
}));

const ContactList = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const history = useHistory();
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [pageState, setPageState] = useState({
      allRecords: [],
      records: [],
      filter: '',
      fetchedRecords: false,
      loading: false,
      showAllRecords: false,
      showMyRecords: false,
      sortDirection: 'asc',
      sortBy: '',
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [pageOptions, setPageOptions] = useState({
      searching: false,
      page: 0,
      perPage: 15,
    });
    const [filter, setFilter] = React.useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [contactHistory, setContactHistory] = useState([]);
    const [historyRecord, setHistoryRecord] = useState(null);
    const [showHistory, setShowHistory] = useState(null);
    // const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
    const [historyRecs, setHistoryRecs] = useState([]);

    const getStaffId = (userId) => {
      const idx = store.aftercareSpecialistList.findIndex((s) => s.user.id === userId);
      if (idx > -1) {
        return store.aftercareSpecialistList[idx].id;
      }
    };

    useEffect(() => {
      const fetchAftercareSpecialistList = async () => {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const specialists = await callApi(auth, 'staff', 'GET');
          store.setAftercareSpecialistList(
            specialists.result.filter(
              (s) =>
                // s.is_active &&     // show all specialists, even inactive
                s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534'
            )
          );
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching aftercare specialist list:', error);
          logException(error);
        }
      };

      const fetchRecords = async () => {
        try {
          setPageState({ ...pageState, loading: true });
          const rsp = await callApi(auth, 'contactlist', 'GET');

          // If coming from individual client page, filter by that client id.
          let list = [];
          if (store.checkInId && store.checkInId > 0) {
            list = rsp.result.filter((r) => {
              return r.alumni_id && r.alumni_id === store.checkInId;
            });
          } else {
            list = rsp.result;
          }

          list.forEach((r, i) => {
            r.due_date_formatted = dateUtcToS(r.due_date);
            r.due_date_datetime_formatted = dateTimeToS(r.due_date);
            r.last_attempt_date_formatted = dateTimeToS(r.last_attempt_date);
          });

          if (store.checkInId && store.checkInId > 0) {
            setPageState((p) => {
              return { ...p, showAllRecords: true };
            });
          }

          setPageState({
            ...pageState,
            loading: false,
            records: [...list],
            allRecords: [...list],
            sortBy: 'due_date',
            fetchedRecords: true,
            showAllRecords: store.checkInId && store.checkInId > 0 ? true : false, // if showing record for a single client, show all
          });
        } catch (error) {
          logException(error);
          setPageState({ ...pageState, loading: false, fetchedRecords: true });
          setErrorMsg('An unexpected error has occurred. Please try again.');
          console.log('Error fetching list of contact records:', error);
        }
      };
      if (auth.authToken && !pageState.fetchedRecords && !pageState.loading) {
        fetchAftercareSpecialistList();
        fetchRecords();
      }
    }, [auth, auth.authToken]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          const varA = !a.hasOwnProperty(key)
            ? ''
            : key === 'id'
            ? a[key].toString()
            : key === 'type' || key === 'status' || key === 'last_attempt_mode' || key === 'last_attempt_result'
            ? a[key].toLowerCase()
            : key === 'specialist_id' || key === 'submitted_by'
            ? lookupAssignee(a[key])
            : key === 'due_date_formatted' || key === 'last_attempt_date_formatted'
            ? dateOnlyFormat(a[key]).toString()
            : a[key];
          const varB = !b.hasOwnProperty(key)
            ? ''
            : key === 'id'
            ? a[key].toString()
            : key === 'type' || key === 'status' || key === 'last_attempt_mode' || key === 'last_attempt_result'
            ? b[key].toLowerCase()
            : key === 'specialist_id' || key === 'submitted_by'
            ? lookupAssignee(b[key])
            : key === 'due_date_formatted' || key === 'last_attempt_date_formatted'
            ? dateOnlyFormat(b[key]).toString()
            : b[key];
          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return order === 'desc' ? comparison * -1 : comparison;
        };
      };

      let filteredData = pageState.allRecords;

      if (filter && filter.length > 0) {
        filteredData = filteredData.filter(
          (s) =>
            (s.first_name && s.first_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.last_name && s.last_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.int_id && s.int_id.includes(filter)) ||
            (s.specialist_id && lookupAssignee(s.specialist_id).toLowerCase().includes(filter.toLowerCase())) ||
            (s.submitted_by && lookupAssignee(s.submitted_by).toLowerCase().includes(filter.toLowerCase())) ||
            (s.type && s.type.toLowerCase().includes(filter.toLowerCase())) ||
            (s.due_date_formatted && s.due_date_formatted.includes(filter)) ||
            (s.last_attempt_date_formatted && s.last_attempt_date_formatted.includes(filter)) ||
            (s.status && s.status.toLowerCase().includes(filter.toLowerCase()))
        );
      }

      let sortedRecords = filteredData;
      if (sortedRecords) {
        if (!pageState.showAllRecords) {
          sortedRecords = sortedRecords.filter((c) => c.status !== 'closed');
        }
        if (pageState.showMyRecords) {
          const staffId = getStaffId(auth.user.result.id);
          sortedRecords = sortedRecords.filter((r) => r.specialist_id === staffId);
        }
        sortedRecords.sort(compareValues(pageState.sortBy, pageState.sortDirection));
        setPageState((p) => {
          return { ...p, records: sortedRecords };
        });
      }
    }, [pageState.allRecords, pageState.sortBy, pageState.sortDirection, filter, pageState.showAllRecords, pageState.showMyRecords]);

    const setSort = (val) => {
      let direction = 'asc';
      if (val === pageState.sortBy) {
        if (pageState.sortDirection === 'asc') {
          direction = 'desc';
        } else {
          direction = 'asc';
        }
        setPageState({ ...pageState, sortDirection: direction });
      } else {
        setPageState({ ...pageState, sortDirection: 'asc', sortBy: val });
      }
    };

    const filterData = (val) => {
      setFilter(val);
    };

    // const handleOpenAlumni = (event, id) => {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   store.setBasicInfoId(id);
    //   store.setAlumni(true);
    //   history.push('/seniorplanner');
    // };

    const convertResult = (result) => {
      switch (result) {
        case 'WrongNumber':
          return 'Wrong Number';
        case 'NoAnswer':
          return 'No Answer';
        case 'VoiceMail':
          return 'Voice Mail';
        case 'OtherContacts':
          return 'Other Contacts';
        case 'EmailSent':
          return 'Email Sent';
        case 'TextSent':
          return 'Text Sent';
        case 'SocialMediaContact':
          return 'Social Media';
        default:
          return result;
      }
    };

    const handleHideHistory = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowHistory(null);
    };

    const handleShowHistory = (event, rec) => {
      event.preventDefault();
      event.stopPropagation();
      fetchContactHistory(rec.alumni_id);
      setShowHistory(rec.id);
    };

    const handleOpenCheckin = async (event, id, type) => {
      event.preventDefault();
      event.stopPropagation();
      try {
        store.setLoading(true);
        store.setSearching(true);
        let ct = await callApi(auth, 'contact/' + id, 'GET');
        store.setCheckIn(ct.result);
        const ctUserId = ct.result.user_id;
        let senior = await callApi(auth, 'studentplan/' + ctUserId, 'GET');
        store.setSenior(senior.result);
        store.setSearching(false);
        store.setLoading(false);
        history.push('/contact');
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error fetching senior:', error);
      }
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    // const handleCardSortChange = (event) => {
    //   setPageState({ ...pageState, sortBy: event.target.value });
    // };

    const displayAttemptMode = (mode) => {
      switch (mode) {
        case 'FocusMeeting':
          return 'Focus Meeting';
        case 'SocialMedia':
          return 'Social Media';
        default:
          return mode;
      }
    };

    const lookupAssignee = (assigned_to) => {
      if (!assigned_to || assigned_to === 0) return '';
      const idx = store.aftercareSpecialistList.findIndex((s) => s.id === assigned_to);
      if (idx > -1) {
        return store.aftercareSpecialistList[idx].user.first_name + ' ' + store.aftercareSpecialistList[idx].user.last_name;
      }
    };

    // Gets List Record and opens details dialog. (From table)
    const fetchContactDetails = async (alumniId) => {
      try {
        const res = await callApi(auth, 'contact/history/' + alumniId, 'GET');
        const list = res.result;
        setContactHistory(list);
        setHistoryRecord(list[0]);
        setDialogOpen(true);
      } catch (error) {
        console.log('Error fetching contact details:', error);
        logException(error);
      }
    };

    // Gets History Record and opens details dialog. (From expanded History in table)
    const fetchHistoryDetails = (id, attempt) => {
      if (!id || id === 0) return null;
      const idx = historyRecs.findIndex((s) => s.id === id && s.contact_attempt === attempt);
      if (idx > -1) {
        setHistoryRecord(historyRecs[idx]);
        setDialogOpen(true);
      }
    };

    const createSpecialistDict = () => {
      const dict = Object.assign({}, ...store.aftercareSpecialistList.map((x) => ({ [x.id]: x.user.first_name + ' ' + x.user.last_name })));
      return dict;
    };

    const fetchContactHistory = async (id) => {
      try {
        const res = await callApi(auth, 'contact/history/' + id, 'GET');
        setHistoryRecs(res.result);
      } catch (error) {
        console.log('Error fetching contact history:', error);
        logException(error);
      }
    };

    // const handleHistoryDialogClose = () => {
    //   setHistoryDialogOpen(false);
    // };

    const emptyRows =
      pageOptions.perPage - Math.min(pageOptions.perPage, pageState.records && pageState.records.length - pageOptions.page * pageOptions.perPage);

    if (auth.roles && auth.roles.includes('STUDENT')) {
      return <PageUnauthorized />;
    }

    return (
      <Grid container className={classes.whiteBg}>
        <React.Fragment>
          <PleaseWait isLoading={pageState.loading} />
          <TitleBar title="Proactive Check-Ins" currentPage="Proactive Check-Ins" paths={[{ path: '/', name: 'Home' }]} />
          <Grid container className={classes.whiteBg}>
            <Grid item xs={12}>
              <FormLabel error>{errorMsg}</FormLabel>
            </Grid>

            <Grid container className={classes.whiteBg} align="left">
              <Grid item xs={6} className={classes.whiteBg} align="left"></Grid>
              <Grid item xs={6} align="right" className={classes.whiteBg}></Grid>

              <Grid item xs={12}>
                {store.checkInId && store.checkInId > 0 ? (
                  <div></div>
                ) : (
                  <>
                    <Grid container direction="row" style={{ marginBottom: '20px' }}>
                      <Grid item xs={12} sm={8} md={4} lg={4} className={classes.whiteBg} align="left">
                        <TextField
                          id="filter-field"
                          type="search"
                          className={classes.filter}
                          size="small"
                          hiddenLabel
                          placeholder="Filter by client, specialist, or type"
                          variant="outlined"
                          onChange={(event) => filterData(event.target.value)}
                          clearable="true"
                          style={{ width: '75%' }}
                        />
                      </Grid>
                      <Grid item xs={1} sm={5} md={4} lg={5}></Grid>
                      <Grid item xs={11} sm={6} md={4} lg={3} align="left" className={classes.whiteBg}>
                        <div>
                          <FormControlLabel
                            control={
                              <Tooltip title="Show only my clients." placement="top">
                                <Checkbox
                                  color="default"
                                  checked={pageState.showMyRecords}
                                  onChange={(e) =>
                                    setPageState((p) => {
                                      return { ...p, showMyRecords: !pageState.showMyRecords };
                                    })
                                  }
                                ></Checkbox>
                              </Tooltip>
                            }
                            label="My Check-Ins"
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Tooltip title="Show closed check-in records." placement="top">
                                <Checkbox
                                  color="default"
                                  checked={pageState.showAllRecords}
                                  onChange={(e) =>
                                    setPageState((p) => {
                                      return { ...p, showAllRecords: !pageState.showAllRecords };
                                    })
                                  }
                                ></Checkbox>
                              </Tooltip>
                            }
                            label="Show Closed Check-Ins"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* Grid/Table */}
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.noSortHeader} style={{ width: '100px' }}></TableCell>
                          <Tooltip title="Click to sort by client." placement="top">
                            <TableCell onClick={() => setSort('last_name')} className={classes.sortHeader} style={{ width: '200px' }}>
                              CLIENT{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'last_name' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'last_name' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by specialist." placement="top">
                            <TableCell onClick={() => setSort('specialist_id')} className={classes.sortHeader} style={{ width: '150px' }}>
                              SPECIALIST{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'specialist_id' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'specialist_id' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by submitted by." placement="top">
                            <TableCell onClick={() => setSort('submitted_by')} className={classes.sortHeader} style={{ width: '130px' }}>
                              SUBMITTED BY{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_by' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_by' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by type." placement="top">
                            <TableCell onClick={() => setSort('type')} className={classes.sortHeader} style={{ width: '80px' }}>
                              TYPE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'type' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'type' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by due date." placement="top">
                            <TableCell onClick={() => setSort('due_date_formatted')} className={classes.sortHeader} style={{ width: '160px' }}>
                              DUE ON{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'due_date_formatted'
                                      ? pageState.sortDirection === 'asc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'due_date_formatted'
                                      ? pageState.sortDirection === 'desc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by last attempt date." placement="top">
                            <TableCell
                              onClick={() => setSort('last_attempt_date_formatted')}
                              className={classes.sortHeader}
                              style={{ width: '160px' }}
                            >
                              LAST ATTEMPT{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'last_attempt_date_formatted'
                                      ? pageState.sortDirection === 'asc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'last_attempt_date_formatted'
                                      ? pageState.sortDirection === 'desc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by mode." placement="top">
                            <TableCell onClick={() => setSort('last_attempt_mode')} className={classes.sortHeader} style={{ width: '110px' }}>
                              CONTACT MODE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'last_attempt_mode' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'last_attempt_mode'
                                      ? pageState.sortDirection === 'desc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by result." placement="top">
                            <TableCell onClick={() => setSort('last_attempt_result')} className={classes.sortHeader} style={{ width: '150px' }}>
                              LAST RESULT{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'last_attempt_result'
                                      ? pageState.sortDirection === 'asc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'last_attempt_result'
                                      ? pageState.sortDirection === 'desc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell className={classes.noSortHeader} style={{ width: '50px' }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pageOptions.searching ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          pageState.records &&
                          pageState.records
                            .slice(pageOptions.page * pageOptions.perPage, pageOptions.page * pageOptions.perPage + pageOptions.perPage)
                            .map((record, idx) => (
                              <>
                                <TableRow key={record.id}>
                                  {record.status === 'closed' ? (
                                    <>
                                      <TableCell style={{ width: '100px', paddingLeft: 10, height: '40px' }}></TableCell>
                                      <TableCell style={{ width: '200px', color: btMediumGrayColor }}>
                                        {record && record.alumni_updated_last_name && record.alumni_updated_last_name.length > 0
                                          ? record.alumni_updated_last_name
                                          : record.last_name}
                                        {', '}
                                        {record && record.alumni_updated_first_name && record.alumni_updated_first_name.length > 0
                                          ? record.alumni_updated_first_name
                                          : record.first_name}{' '}
                                        ({record.int_id})
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell style={{ width: '100px', paddingLeft: 10 }}>
                                        <IconButton
                                          size="small"
                                          aria-label="Add Check-In"
                                          onClick={(ev) => handleOpenCheckin(ev, record.id)}
                                          color="primary"
                                          style={{ padding: '5px', margin: 0, fontSize: '.80rem' }}
                                        >
                                          <AddIcon fontSize="small" /> Add New
                                        </IconButton>
                                      </TableCell>
                                      <TableCell style={{ width: '200px' }}>
                                        {record && record.alumni_updated_last_name && record.alumni_updated_last_name.length > 0
                                          ? record.alumni_updated_last_name
                                          : record.last_name}
                                        {', '}
                                        {record && record.alumni_updated_first_name && record.alumni_updated_first_name.length > 0
                                          ? record.alumni_updated_first_name
                                          : record.first_name}{' '}
                                        ({record.int_id})
                                      </TableCell>
                                    </>
                                  )}
                                  <TableCell
                                    style={{
                                      width: '150px',
                                      color: record.status === 'closed' ? btMediumGrayColor : 'inherit',
                                    }}
                                  >
                                    {lookupAssignee(record.specialist_id)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '130px',
                                      color: record.status === 'closed' ? btMediumGrayColor : 'inherit',
                                    }}
                                  >
                                    {lookupAssignee(record.submitted_by)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '80px',
                                      color: record.status === 'closed' ? btMediumGrayColor : 'inherit',
                                    }}
                                  >
                                    {record.type}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '160px',
                                      color: record.status === 'closed' ? btMediumGrayColor : 'inherit',
                                      fontWeight: record.last_attempt_result === 'Reschedule' ? 600 : 400,
                                    }}
                                  >
                                    {record.last_attempt_result === 'Reschedule' ? record.due_date_datetime_formatted : record.due_date_formatted}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '160px',
                                      color: record.status === 'closed' ? btMediumGrayColor : 'inherit',
                                    }}
                                  >
                                    {record.last_attempt_date_formatted}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '110px',
                                      color: record.status === 'closed' ? btMediumGrayColor : 'inherit',
                                    }}
                                  >
                                    {displayAttemptMode(record.last_attempt_mode)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '150px',
                                      marginTop: '12px',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {convertResult(record.last_attempt_result)}{' '}
                                    {record.last_attempt_result && (
                                      <span className={classes.detailsLink} onClick={(e) => fetchContactDetails(record.alumni_id)}>
                                        <RemoveRedEyeIcon style={{ color: btLightBlueColor }} />
                                      </span>
                                    )}
                                  </TableCell>
                                  {record.last_attempt_result && record.last_attempt_result.length > 0 && (
                                    <TableCell style={{ width: '50px', paddingLeft: 10 }}>
                                      {showHistory !== record.id && (
                                        <IconButton
                                          size="small"
                                          aria-label="Show history"
                                          onClick={(ev) => handleShowHistory(ev, record)}
                                          color="primary"
                                          style={{ marginTop: '-7px', fontSize: '.80rem' }}
                                        >
                                          <ExpandMoreIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                      {showHistory === record.id && (
                                        <IconButton
                                          size="small"
                                          aria-label="Hide history"
                                          onClick={(ev) => handleHideHistory(ev)}
                                          color="primary"
                                          style={{ marginTop: '-7px', fontSize: '.80rem' }}
                                        >
                                          <ExpandLessIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  )}
                                </TableRow>
                                {/* Child Table */}
                                <TableRow key={record.id + 'history'}>
                                  <TableCell style={{ padding: 0 }} colSpan={10}>
                                    <Collapse in={showHistory === record.id} timeout="auto" unmountOnExit>
                                      <Box>
                                        <Table aria-label="Check-In History">
                                          <TableBody>
                                            <TableRow key={record.i + 'history'} style={{ padding: '-10px 0', height: '22px' }}>
                                              <TableCell style={{ padding: 0 }}>
                                                <Table className={tableClasses.table} style={{ marginBottom: '-1px' }}>
                                                  <TableBody>
                                                    {historyRecs.map((record, idx) => (
                                                      <TableRow
                                                        key={record.id + '' + record.contact_attempt}
                                                        style={{ borderBottom: 0, backgroundColor: '#f5f5f5', height: '22px' }}
                                                      >
                                                        <TableCell style={{ width: '100px', color: '#696969' }}> </TableCell>
                                                        <TableCell style={{ width: '200px', color: '#696969' }}> </TableCell>
                                                        <TableCell style={{ width: '150px', color: '#696969' }}> </TableCell>
                                                        <TableCell style={{ width: '130px', color: '#696969' }}>
                                                          <div style={{ marginLeft: '-8px' }}>{createSpecialistDict()[record.specialist_id]}</div>
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            width: '80px',
                                                            color: '#696969',
                                                          }}
                                                        >
                                                          <div style={{ marginLeft: '-10px' }}>{record.type}</div>
                                                        </TableCell>
                                                        <TableCell style={{ width: '160px', color: '#696969' }}>
                                                          <div style={{ marginLeft: '-14px' }}>
                                                            {record.last_attempt_result === 'Reschedule'
                                                              ? dateTimeUtcToS(record.due_date)
                                                              : dateUtcToS(record.due_date)}
                                                          </div>
                                                        </TableCell>
                                                        <TableCell style={{ width: '160px', color: '#696969' }}>
                                                          <div style={{ marginLeft: '-16px' }}>{dateTimeToS(record.contact_date)}</div>
                                                        </TableCell>
                                                        <TableCell style={{ width: '110px', color: '#696969' }}>
                                                          <div style={{ marginLeft: '-20px' }}>{displayAttemptMode(record.contact_mode)}</div>
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            width: '130px',
                                                            color: '#696969',
                                                            paddingLeft: '10px',
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: 'flex',
                                                              flexDirection: 'row',
                                                              justifyContent: 'flex-start',
                                                              alignItems: 'center',
                                                              marginLeft: '-20px',
                                                            }}
                                                          >
                                                            <span>{convertResult(record.result)} </span>
                                                            <span
                                                              className={classes.subDetailsLink}
                                                              onClick={(e) => fetchHistoryDetails(record.id, record.contact_attempt)}
                                                            >
                                                              <RemoveRedEyeIcon
                                                                fontSize="small"
                                                                style={{ margin: '5px 0 0 5px', color: btLightBlueColor }}
                                                              />
                                                            </span>
                                                          </div>
                                                        </TableCell>
                                                        <TableCell style={{ width: '50px', color: '#696969' }}> </TableCell>
                                                      </TableRow>
                                                    ))}
                                                    {!history || history.length === 0 ? (
                                                      <TableRow>
                                                        <TableCell align="center" colSpan={11} className={classes.rowSpacer}>
                                                          <summary className={classes.noRecordsMsg}>No records found.</summary>
                                                        </TableCell>
                                                      </TableRow>
                                                    ) : (
                                                      <React.Fragment />
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))
                        )}
                        {!pageOptions.searching && pageState.records && pageState.records.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noRecordsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        InputProps: { 'aria-label': 'rows per page' },
                        MenuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100]}
                      count={pageState.records ? pageState.records.length : 0}
                      rowsPerPage={pageOptions.perPage}
                      page={pageOptions.page}
                      onChangePage={(ev, val) => {
                        setPageOptions({ ...pageOptions, page: val });
                      }}
                      onChangeRowsPerPage={(ev) => setPageOptions({ ...pageOptions, perPage: parseInt(ev.target.value, 10), page: 0 })}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                </Hidden>

                {/* Cards */}
                <Hidden lgUp implementation="css">
                  <ContactCards list={pageState.records} open={dialogOpen} handleOpenCheckin={handleOpenCheckin} />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>

          <ContactCheckinDetails
            handleDialogClose={handleDialogClose}
            historyRecord={historyRecord}
            classes={classes}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        </React.Fragment>
      </Grid>
    );
  })
);

export default ContactList;
