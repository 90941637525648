const TableStyle = {
  table: {
    marginBottom: 30,
    '& th': {
      padding: '2px 10px',
      fontWeight: 'bold',
      fontSize: '0.75rem',
    },
    '& th:first-child': {
      paddingLeft: 40,
    },
    '& td': {
      padding: '5px 10px',
      maxHeight: 15,
      borderBottom: 'none',
    },
    '& td:first-child': {
      paddingLeft: 40,
    },
    '& tr': {
      backgroundColor: 'white',
      borderBottom: '2px solid rgba(0,0,0,0.05)',
    },
  },
  tableBorderedUnstriped: {
    '& td': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  awardHeaderTable: {
    // marginBottom: 30,
    backgroundColor: 'transparent',
    '& td': {
      padding: '2px 0',
      height: 35,
      borderBottom: 'none',
      color: '#fff',
    },
  },
};

export default TableStyle;
