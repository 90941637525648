import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, TextareaAutosize, Select, MenuItem } from '@material-ui/core';
import { btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  box: {
    padding: '30px',
  },
  boxA: {
    '@media print': {
      backgroundColor: 'transparent',
    },
    '@media screen': {
      backgroundColor: '#F2F2FA',
    },
  },
  boxB: {
    '@media print': {
      backgroundColor: 'transparent',
    },
    '@media screen': {
      backgroundColor: '#fcf1f2',
    },
  },
  boxC: {
    '@media print': {
      backgroundColor: 'transparent',
    },
    '@media screen': {
      backgroundColor: '#EBFAF2',
    },
  },
  textSpacer: {
    margin: '15px 0 5px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textFieldBackground: {
    backgroundColor: 'white',
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  bold: {
    fontWeight: 600,
  },
  subtleBold: {
    fontWeight: 500,
    color: btMediumGrayColor,
  },
  subtleBoldLabel: {
    fontWeight: 500,
    fontSize: '0.95rem',
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function FallPlan(props) {
  const { form, setForm, auth } = props;

  const classes = styles();

  const isFieldDisabled = () => {
    if (!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')) return true;
    if (form.status === 'submitted' || form.status === 'approved') return true;
    return false;
  };

  return (
    <React.Fragment>
      <Box sx={{ width: '90%' }} className={classes.box + ' ' + classes.boxA}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={3} md={2} lg={1} className={classes.textRowLabelSpacer}>
            <label className={classes.bold}>Plan A</label>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <Select
                variant="outlined"
                labelId="plan-for-fall-labelA"
                id="plan-for-fallA"
                value={form.fallPlanA}
                onChange={(event) => setForm({ ...form, fallPlanA: event.target.value })}
                displayEmpty
                disabled={isFieldDisabled()}
                className={classes.textFieldBackground}
              >
                <MenuItem value="" disabled>
                  Select Option for Plan A
                </MenuItem>
                <MenuItem value={'Community College/Trade'}>Community College/Trade</MenuItem>
                <MenuItem value={'4-Year College'}>4-Year College</MenuItem>
                <MenuItem value={'Job Corps'}>Job Corps</MenuItem>
                <MenuItem value={'Military'}>Military</MenuItem>
                <MenuItem value={'Continue Employment'}>Continue Employment</MenuItem>
                <MenuItem value={''}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={4}>
              <label className={classes.alignPrintedContent}>Plan A</label>
            </Grid>
            <Grid item xs={8}>
              <summary className={classes.alignPrintedContent}>{form.fallPlanA || null}</summary>
            </Grid>
          </Grid>
        </div>

        <Grid container direction="row" className={classes.textSpacer} />
        <Grid container direction="row">
          <Grid item xs={12}>
            <label>Describe your plan.</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="plan-descriptionA"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.planDescriptionA}
                onChange={(event) => setForm({ ...form, planDescriptionA: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.planDescriptionA || null}
        </p>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>What is required for this type of job/career?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="job-requirementsA"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.jobRequirementsA}
                onChange={(event) => setForm({ ...form, jobRequirementsA: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What is required for this type of job/career?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.jobRequirementsA || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Where would I get this education and/or training?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="education-trainingA"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.educationTrainingA}
                onChange={(event) => setForm({ ...form, educationTrainingA: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>Where would I get this education and/or training?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.educationTrainingA || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>What is the cost of this education and/or training?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="education-training-costA"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.educationTrainingCostA}
                onChange={(event) => setForm({ ...form, educationTrainingCostA: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What is the cost of this education and/or training?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.educationTrainingCostA || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>How will I pay for this plan?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="payment-planA"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.paymentPlanA}
                onChange={(event) => setForm({ ...form, paymentPlanA: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>How will I pay for this plan?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.paymentPlanA || null}</summary>
          </div>
        </div>

        <Grid container direction="row">
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Why is this a good plan for me?</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="good-planA"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.goodPlanA}
                onChange={(event) => setForm({ ...form, goodPlanA: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.goodPlanA || null}
        </p>

        <Grid container direction="row">
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Describe any barriers to this plan.</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="barriers-planA"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.barriersPlanA}
                onChange={(event) => setForm({ ...form, barriersPlanA: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.barriersPlanA || null}
        </p>
      </Box>

      <Grid item xs={12} className={classes.rowSpacer} />

      <Box sx={{ width: '90%' }} className={classes.box + ' ' + classes.boxB}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={3} md={2} lg={1} className={classes.textRowLabelSpacer}>
            <label className={classes.bold}>Plan B</label>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <Select
                variant="outlined"
                labelId="plan-for-fall-labelB"
                id="plan-for-fallB"
                value={form.fallPlanB}
                onChange={(event) => setForm({ ...form, fallPlanB: event.target.value })}
                displayEmpty
                disabled={isFieldDisabled()}
                className={classes.textFieldBackground}
              >
                <MenuItem value="" disabled>
                  Select Option for Plan B
                </MenuItem>
                <MenuItem value={'Community College/Trade'}>Community College/Trade</MenuItem>
                <MenuItem value={'4-Year College'}>4-Year College</MenuItem>
                <MenuItem value={'Job Corps'}>Job Corps</MenuItem>
                <MenuItem value={'Military'}>Military</MenuItem>
                <MenuItem value={'Continue Employment'}>Continue Employment</MenuItem>
                <MenuItem value={''}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={4}>
              <label className={classes.alignPrintedContent}>Plan B</label>
            </Grid>
            <Grid item xs={8}>
              <summary className={classes.alignPrintedContent}>{form.fallPlanB || null}</summary>
            </Grid>
          </Grid>
        </div>

        <Grid container direction="row" className={classes.textSpacer} />
        <Grid container direction="row">
          <Grid item xs={12}>
            <label>Describe your plan.</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="plan-descriptionB"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.planDescriptionB}
                onChange={(event) => setForm({ ...form, planDescriptionB: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.planDescriptionB || null}
        </p>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>What is required for this type of job/career?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="job-requirementsB"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.jobRequirementsB}
                onChange={(event) => setForm({ ...form, jobRequirementsB: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What is required for this type of job/career?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.jobRequirementsB || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Where would I get this education and/or training?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="education-trainingB"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.educationTrainingB}
                onChange={(event) => setForm({ ...form, educationTrainingB: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>Where would I get this education and/or training?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.educationTrainingB || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>What is the cost of this education and/or training?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="education-training-costB"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.educationTrainingCostB}
                onChange={(event) => setForm({ ...form, educationTrainingCostB: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What is the cost of this education and/or training?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.educationTrainingCostB || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>How will I pay for this plan?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="payment-planB"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.paymentPlanB}
                onChange={(event) => setForm({ ...form, paymentPlanB: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>How will I pay for this plan?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.paymentPlanB || null}</summary>
          </div>
        </div>

        <Grid container direction="row">
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Why is this a good plan for me?</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="good-planB"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.goodPlanB}
                onChange={(event) => setForm({ ...form, goodPlanB: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.goodPlanB || null}
        </p>

        <Grid container direction="row">
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Describe any barriers to this plan.</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="barriers-planB"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.barriersPlanB}
                onChange={(event) => setForm({ ...form, barriersPlanB: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.barriersPlanB || null}
        </p>
      </Box>

      <Grid item xs={12} className={classes.rowSpacer} />

      <Box sx={{ width: '90%' }} className={classes.box + ' ' + classes.boxC}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={3} md={2} lg={1} className={classes.textRowLabelSpacer}>
            <label className={classes.bold}>Plan C</label>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <Select
                variant="outlined"
                labelId="plan-for-fall-labelC"
                id="plan-for-fallC"
                value={form.fallPlanC}
                onChange={(event) => setForm({ ...form, fallPlanC: event.target.value })}
                displayEmpty
                disabled={isFieldDisabled()}
                className={classes.textFieldBackground}
              >
                <MenuItem value="" disabled>
                  Select Option for Plan C
                </MenuItem>
                <MenuItem value={'Community College/Trade'}>Community College/Trade</MenuItem>
                <MenuItem value={'4-Year College'}>4-Year College</MenuItem>
                <MenuItem value={'Job Corps'}>Job Corps</MenuItem>
                <MenuItem value={'Military'}>Military</MenuItem>
                <MenuItem value={'Continue Employment'}>Continue Employment</MenuItem>
                <MenuItem value={''}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={4}>
              <label className={classes.alignPrintedContent}>Plan C</label>
            </Grid>
            <Grid item xs={8}>
              <summary className={classes.alignPrintedContent}>{form.fallPlanC || null}</summary>
            </Grid>
          </Grid>
        </div>

        <Grid container direction="row" className={classes.textSpacer} />
        <Grid container direction="row">
          <Grid item xs={12}>
            <label>Describe your plan.</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="plan-descriptionC"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.planDescriptionC}
                onChange={(event) => setForm({ ...form, planDescriptionC: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.planDescriptionC || null}
        </p>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>What is required for this type of job/career?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="job-requirementsC"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.jobRequirementsC}
                onChange={(event) => setForm({ ...form, jobRequirementsC: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What is required for this type of job/career?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.jobRequirementsC || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Where would I get this education and/or training?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="education-trainingC"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.educationTrainingC}
                onChange={(event) => setForm({ ...form, educationTrainingC: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>Where would I get this education and/or training?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.educationTrainingC || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>What is the cost of this education and/or training?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="education-training-costC"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.educationTrainingCostC}
                onChange={(event) => setForm({ ...form, educationTrainingCostC: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>What is the cost of this education and/or training?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.educationTrainingCostC || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textSpacer}>
            <label>How will I pay for this plan?</label>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextField
                id="payment-planC"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.paymentPlanC}
                onChange={(event) => setForm({ ...form, paymentPlanC: event.target.value })}
                disabled={isFieldDisabled()}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                className={classes.textFieldBackground}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>How will I pay for this plan?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.paymentPlanC || null}</summary>
          </div>
        </div>

        <Grid container direction="row">
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Why is this a good plan for me?</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="good-planC"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.goodPlanC}
                onChange={(event) => setForm({ ...form, goodPlanC: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.goodPlanC || null}
        </p>

        <Grid container direction="row">
          <Grid item xs={12} className={classes.textSpacer}>
            <label>Describe any barriers to this plan.</label>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="barriers-planC"
                minRows={5}
                maxRows={5}
                maxLength={750}
                value={form.barriersPlanC}
                onChange={(event) => setForm({ ...form, barriersPlanC: event.target.value })}
                disabled={isFieldDisabled()}
                className={classes.textAreaFont}
                style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.2)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
          {form.barriersPlanC || null}
        </p>
      </Box>
    </React.Fragment>
  );
}

FallPlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  auth: PropTypes.object,
};
