import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, Select, MenuItem } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function HealthInsurancePlan(props) {
  const { form, setForm, disabled, saveHealthInsuranceTask } = props;

  const classes = styles();

  return (
    <React.Fragment>
      <Box sx={{ width: '90%' }}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={4} md={3} lg={2} className={classes.textRowLabelSpacer}>
            <label>Health Insurance Plan</label>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.healthInsPlan}
                onChange={(event) => saveHealthInsuranceTask(event.target.value)}
                disabled={disabled}
              >
                <MenuItem value="Medicaid">Medicaid</MenuItem>
                <MenuItem value="Private Insurance">Private Insurance</MenuItem>
                <MenuItem value="Insurance through Employer">Insurance through Employer</MenuItem>
                <MenuItem value="None">None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <summary className={classes.printedContent}>{form.healthInsPlan || null}</summary>

        {form.healthInsPlan !== 'None' && (
          <>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="health-ins-cover"
                    size="small"
                    fullWidth
                    label="What will my health insurance plan cover? (medical, mental health, dental, etc.)"
                    variant="outlined"
                    value={form.healthInsCover}
                    onChange={(event) => setForm({ ...form, healthInsCover: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                    className={classes.root}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <summary className={classes.printedContent}>
              <label>What will my health insurance plan cover? (medical, mental health, dental, etc.)</label>
            </summary>
            <summary className={classes.printedContent}>{form.healthInsCover || null}</summary>
          </>
        )}

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={3} lg={2} className={classes.textRowLabelSpacer}>
            <label>Primary Doctor</label>
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="primary-doctor"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.primaryDoctor}
                onChange={(event) => setForm({ ...form, primaryDoctor: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={4}>
            <label className={classes.alignPrintedContent}>Primary Doctor</label>
          </Grid>
          <Grid item xs={8}>
            <summary className={classes.alignPrintedContent}>{form.primaryDoctor || null}</summary>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={3} lg={2} className={classes.textRowLabelSpacer}>
            <label>Dentist</label>
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="dentist"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.dentist}
                onChange={(event) => setForm({ ...form, dentist: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={4}>
            <label className={classes.alignPrintedContent}>Dentist</label>
          </Grid>
          <Grid item xs={8}>
            <summary className={classes.alignPrintedContent}>{form.dentist || null}</summary>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={3} lg={2} className={classes.textRowLabelSpacer}>
            <label>Mental Health</label>
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="mental-health"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.therapist}
                onChange={(event) => setForm({ ...form, therapist: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={4}>
            <label className={classes.alignPrintedContent}>Mental Health</label>
          </Grid>
          <Grid item xs={8}>
            <summary className={classes.alignPrintedContent}>{form.therapist || null}</summary>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

HealthInsurancePlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
  saveHealthInsuranceTask: PropTypes.func,
};
