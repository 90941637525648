import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Card from '../../Card/Card.js';
import CardBody from '../../Card/CardBody.js';
import PersonalInfoIcon from '../../../assets/img/planner-personalinfo-icon.svg';
import HomeIcon from '@material-ui/icons/Home';
import BasicInformation from '../BasicInformation';

const BasicInfo = ({ alumni, roles, store, auth, handleAfterSaveReturn, setPage, handleReturnToSeniorList, classes }) => {
  return (
    <React.Fragment>
      {!roles.includes('STUDENT') && !roles.includes('ALUMNI') && (
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12}>
            <div className={classes.breadcrumb}>
              <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnToSeniorList()}>
                  {alumni ? 'Alumni List' : 'Senior List'}
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(e) => setPage('home')}>
                  {alumni ? 'Alumni Planner' : 'Senior Planner'}
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbText}>
                  Personal Information {store && store.senior && ' for '}
                  {store && store.senior && store.senior.alumni_updated_first_name && store.senior.alumni_updated_first_name.length > 0
                    ? store.senior.alumni_updated_first_name
                    : store.senior.first_name}{' '}
                  {store && store.senior && store.senior.alumni_updated_last_name && store.senior.alumni_updated_last_name.length > 0
                    ? store.senior.alumni_updated_last_name
                    : store.senior.last_name}
                </Typography>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} direction="row" className={classes.menu}>
        <Grid item xs={12} align="left" className={classes.unprintedContent}>
          <Card className={classes.pageHeader}>
            <CardBody className={classes.headerCard}>
              <Grid container direction="row">
                <Grid item xs={11} align="left">
                  <section className={classes.pageTitle}>
                    <img src={PersonalInfoIcon} alt="Personal Information Icon" className={classes.pageIcon} />
                    <span className={classes.pagecardHeader}>Personal Information</span>
                  </section>
                  <aside className={classes.headerDesc}>
                    {roles.includes('ALUMNI')
                      ? 'View your personal information and update address and phone numbers.'
                      : 'View your student information and update graduation date, email, GPA, and test scores.'}
                  </aside>
                </Grid>
                <Grid item xs={1} align="right">
                  <Tooltip title="Click to return to the planner home page." placement="bottom">
                    <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                      <HomeIcon className={classes.homeIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} align="left" className={classes.printedContent}>
          <section className={classes.pageTitle}>
            <span className={classes.printHeaderText}>Personal Information</span>
          </section>
          <aside className={classes.printHeaderDesc}>
            {roles.includes('ALUMNI')
              ? 'View your personal information and update address and phone numbers.'
              : 'View your student information and update graduation date, email, GPA, and test scores.'}
          </aside>
        </Grid>
        <Grid item xs={12}>
          <BasicInformation SeniorStore={store} AuthStore={auth} handleSaveReturn={handleAfterSaveReturn} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

BasicInfo.propTypes = {
  alumni: PropTypes.bool,
  roles: PropTypes.array,
  store: PropTypes.object,
  auth: PropTypes.object,
  handleAfterSaveReturn: PropTypes.func,
  setPage: PropTypes.func,
  handleReturnToSeniorList: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default BasicInfo;
