import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { Button } from '@material-ui/core';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { displayDateAsUTC } from '../../util/dateUtil';
import { titleize } from '../../util/stringUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 15,
    maxWidth: '350px',
    height: '160px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  dataBold: {
    fontWeight: 500,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    float: 'right',
    textTransform: 'none',
  },
}));

const AccessRequestCard = ({ request, showAllRecords }) => {
  const classes = useStyles({ showAllRecords });

  return (
    <React.Fragment>
      <Card className={classes.card} key={request.id}>
        <CardHeader className={classes.cardHeader}>
          {request.submitted_by}
          <Button
            className={classes.idLink}
            color="primary"
            size="small"
            aria-describedby="review Successful Futures application"
            //onClick={(ev) => handleClickId(ev, request.email)}
          >
            Review
          </Button>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <Grid container direction="row" align="left">
            <Grid item xs={5} className={classes.whiteBg} align="left">
              <label>Service Type</label>
            </Grid>
            <Grid item xs={7} className={classes.whiteBg} align="left">
              {request.service_type_formatted}
            </Grid>
          </Grid>
          <Grid container direction="row" align="left">
            <Grid item xs={5} className={classes.whiteBg} align="left">
              <label>Status</label>
            </Grid>
            <Grid item xs={7} className={classes.whiteBg} align="left">
              {titleize(request.status)}
            </Grid>
          </Grid>
          <Grid container direction="row" align="left">
            <Grid item xs={5} className={classes.whiteBg} align="left">
              <label>Submitted Date</label>
            </Grid>
            <Grid item xs={7} className={classes.whiteBg} align="left">
              {request.submitted_date_formatted}
            </Grid>
          </Grid>
          <Grid container direction="row" align="left">
            <Grid item xs={5} className={classes.whiteBg} align="left">
              <label>Interview Date</label>
            </Grid>
            <Grid item xs={7} className={classes.whiteBg} align="left">
              {request.interview_at_formatted ? displayDateAsUTC(request.interview_at_formatted) : ''}
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

AccessRequestCard.propTypes = {
  request: PropTypes.object.isRequired,
  showAllRecords: PropTypes.bool.isRequired,
};

export default AccessRequestCard;
