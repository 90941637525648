import React from 'react';
import { useHistory } from 'react-router-dom';
import { dateToS, dateUtcToS, dateToApi, dateToMMMMYYYY, dateDiffYears } from '../../util/dateUtil';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { observer, inject } from 'mobx-react';
import { Button } from '@material-ui/core';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { getRowFromListById } from '../../util/miscUtil';

const useStyles = makeStyles((theme) => ({
  edit: {
    textAlign: 'center',
  },
  popover: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'space-evenly',
  },
  card: {
    margin: 15,
    maxWidth: '320px',
    height: '195px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  tools: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  space5: {
    padding: 5,
  },
  noNotes: {
    paddingTop: 10,
  },
  dataLabel: {
    paddingTop: 10,
  },
  attachIcon: {
    paddingTop: 5,
  },
  dataBold: {
    fontWeight: 700,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    align: 'right',
  },
  cardLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
    textDecoration: 'underline',
  },
}));

const ActivityCard = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;

    const classes = useStyles();

    const history = useHistory();

    const handleClickId = (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setBasicInfoId(id);
      store.setAlumni(false);
      history.push('/seniorplanner');
    };

    const handleClickAddEmail = (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setAddEmailId(id);
      store.setShowAddEmail(true);
    };

    const getSpecialistInfo = (id) => {
      const spec = getRowFromListById(store.aftercareSpecialistList, id);
      if (spec) {
        return spec.user.first_name + ' ' + spec.user.last_name;
      }
      console.warn('Some seniors are assigned to invalid Successful Future specialists.');
      return '';
    };
    return (
      <React.Fragment>
        <Card className={classes.card} key={props.senior.id}>
          <CardHeader className={classes.cardHeader}>
            <Button
              className={classes.idLink}
              color="primary"
              size="small"
              aria-describedby="view personal information"
              onClick={(ev) => handleClickId(ev, props.senior.id)}
            >
              {props.senior.last_name}, {props.senior.first_name} ({props.senior.int_id})
            </Button>
            {props.senior.program}
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <section className={classes.dataBold}>
              <label>Birth Date</label> {dateUtcToS(props.senior.dob)}
            </section>
            <section className={classes.dataBold}>
              <label>Graduation Date</label> {props.senior.graduation_date > /Date(0)/ && dateToMMMMYYYY(props.senior.graduation_date)}
              {props.senior.graduation_date > /Date(0)/ &&
                ' (' + dateDiffYears(dateToApi(props.senior.dob), dateToApi(props.senior.graduation_date)) + ' years)'}
            </section>
            {props.senior.departure_date && store.showAllRecords && (
              <section className={classes.dataBold}>
                <label>Departure Date</label> {dateUtcToS(props.senior.departure_date)}
              </section>
            )}
            <section className={classes.dataBold}>
              <label>Site</label> {props.senior.site}
            </section>
            <section className={classes.dataBold}>
              <label>Email Address</label>{' '}
              {props.senior.departure_date && props.senior.user.email && <label className={classes.idNoLink}>{props.senior.user.email}</label>}
              {!props.senior.departure_date && props.senior.user.email ? (
                <React.Fragment>{props.senior.user.email}</React.Fragment>
              ) : (
                !props.senior.departure_date && (
                  <React.Fragment>
                    <Button
                      className={classes.cardLink}
                      aria-describedby="Add email address for senior"
                      onClick={(ev) => handleClickAddEmail(ev, props.senior.id)}
                    >
                      Add Email Address
                    </Button>
                  </React.Fragment>
                )
              )}
            </section>
            {props.senior.aftercare_specialist > 0 && (
              <section className={classes.dataBold}>
                <label>Specialist</label> {getSpecialistInfo(props.senior.aftercare_specialist)}
              </section>
            )}
            {!props.senior.departure_date && props.senior.user.updated_at && (
              <section>
                {props.senior.user.created_at && (
                  <React.Fragment>
                    {`Invited on ${dateToS(props.senior.user.updated_at)}`}{' '}
                    {!props.senior.departure_date && props.senior.user.updated_at && (
                      <Button
                        className={classes.cardLink}
                        aria-describedby="Resend invitation"
                        style={{ marginTop: '-3px' }}
                        onClick={(ev) => props.resendEmail(props.senior.id)}
                      >
                        <MailOutlineIcon color="primary" />
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </section>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  })
);

export default ActivityCard;
