import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Card from '../../Card/Card.js';
import CardBody from '../../Card/CardBody.js';
import HelpRequestIcon from '../../../assets/img/planner-help-icon.svg';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/Timer';
import { webPrimaryColor } from '../../../assets/jss/nextjs-material-kit-pro.js';
import { dateToS } from '../../../util/dateUtil';

const HelpApps = ({
  senior,
  roles,
  helpAppList,
  setPage,
  handleReturnToSeniorList,
  handleCreateHelpApp,
  openHelpApp,
  summarizeHelpOptions,
  displayStatus,
  classes,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={3} direction="row" className={classes.subMenu}>
        <Grid item xs={12}>
          {!roles.includes('STUDENT') && !roles.includes('ALUMNI') && (
            <div className={classes.breadcrumb}>
              <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnToSeniorList()}>
                  Senior List
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(e) => setPage('home')}>
                  Senior Planner
                </Typography>
                <Typography color="textPrimary" className={classes.breadCrumbText}>
                  Help Requests
                </Typography>
              </Breadcrumbs>
            </div>
          )}
        </Grid>
        <Grid item xs={12} className={classes.alumniWelcomeGrid}>
          <Typography variant="h4" className={classes.welcome} style={{ margin: '5px 20px' }}>
            {roles.includes('ALUMNI') ? (
              <main>Help Requests</main>
            ) : (
              <main>
                Help Requests for{' '}
                {senior && senior.alumni_updated_first_name && senior.alumni_updated_first_name.length > 0
                  ? senior.alumni_updated_first_name
                  : senior.first_name}{' '}
                {senior && senior.alumni_updated_last_name && senior.alumni_updated_last_name.length > 0
                  ? senior.alumni_updated_last_name
                  : senior.last_name}
              </main>
            )}
          </Typography>
          <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
            <HomeIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={3} direction="row" className={classes.menu}>
        {(roles.includes('ADMIN') || roles.includes('AFTERCARE_SPECIALIST')) && helpAppList.length === 0 && (
          <Grid item xs={12} align="center">
            <summary className={classes.noApps}>{senior.first_name} does not have any Help Requests.</summary>
          </Grid>
        )}

        <Grid item xs={12} align="left" className={classes.cardGrid} style={{ width: '100vw' }}>
          {roles.includes('ALUMNI') && (
            <nav onClick={(event) => handleCreateHelpApp()} className={classes.nav} disabled={!roles.includes('ALUMNI')}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={HelpRequestIcon} alt="Help Requests Icon" className={classes.cardIcon} />
                    <Tooltip title="Click to create a new Help Request" placement="bottom">
                      <section className={classes.addSection}>
                        <AddIcon className={classes.addIcon} />
                      </section>
                    </Tooltip>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {helpAppList
            .slice()
            .sort((a, b) => b.id - a.id)
            .map((app, idx) => {
              return (
                <nav onClick={(event) => openHelpApp(app.id)} className={classes.nav}>
                  <Card className={classes.card}>
                    <CardBody className={classes.cardBody}>
                      <div className={classes.cardContainer}>
                        <img src={HelpRequestIcon} alt="Help Requests Icon" className={classes.cardIcon} />
                        <section className={classes.helpSubmittedDate}>
                          <span className={classes.cardHeader}>
                            {(app.submitted_date > /Date(0)/ && dateToS(app.submitted_date)) || 'Unsubmitted'}
                          </span>
                        </section>
                        <aside className={classes.helpOptionsList}>
                          {app.application && summarizeHelpOptions(app.application).toString().length > 40
                            ? summarizeHelpOptions(app.application).join(', ').substr(0, 35) + '...'
                            : summarizeHelpOptions(app.application).join(', ')}
                        </aside>
                        <summary className={classes.statusBottom}>
                          <section className={classes.appStatus}>
                            {app.status === 'closed' ? (
                              <CheckIcon className={classes.statusIcon} />
                            ) : (
                              <HelpOutlineIcon className={classes.statusIcon} />
                            )}{' '}
                            <span className={classes.iconText}>{displayStatus(app.status)}</span>
                          </section>
                          <section className={classes.appStatusDate}>
                            <span className={classes.iconText}>{dateToS(app.status_date)}</span>
                          </section>
                        </summary>
                      </div>
                    </CardBody>
                  </Card>
                </nav>
              );
            })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

HelpApps.propTypes = {
  senior: PropTypes.object,
  roles: PropTypes.array,
  helpAppList: PropTypes.array,
  setPage: PropTypes.func,
  handleReturnToSeniorList: PropTypes.func,
  handleCreateHelpApp: PropTypes.func,
  openHelpApp: PropTypes.func,
  summarizeHelpOptions: PropTypes.func,
  displayStatus: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default HelpApps;
