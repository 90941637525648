import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import SeniorListCard from './SeniorListCard';

const SeniorListCards = observer((props) => {
  const store = props.SeniorStore;
  const resendEmail = props.resendEmail;

  return (
    <Grid container direction="row">
      {store.allseniors &&
        store.allseniors.length > 0 &&
        store.allseniors.map((senior) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={senior.id}>
              <SeniorListCard senior={senior} store={store} key={senior.id} resendEmail={resendEmail} />
            </Grid>
          );
        })}
    </Grid>
  );
});

export default SeniorListCards;
