import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

const Needs = ({ form, setForm, classes, disabled, needsName }) => {
  const checkedField = `${needsName.replace(' ', '')}NeedsChecked`;
  const descField = `${needsName.replace(' ', '')}NeedsDesc`;

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sm={3} className={[classes.textRowLabelSpacer, classes.unprintedContent].join(' ')}>
          <FormControlLabel
            id={`${needsName.replace(' ', '')}-needs`}
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={form.hasOwnProperty(checkedField) ? form[checkedField] : false}
                onChange={(ev) => setForm({ ...form, [checkedField]: ev.target.checked })}
              />
            }
            label={`${needsName} Needs`}
          />
        </Grid>
        {form.hasOwnProperty(checkedField) && form[checkedField] && (
          <Grid item xs={12} sm={3} className={[classes.textRowLabelSpacer, classes.printedContent].join(' ')}>
            <label>{`${needsName} Needs`}</label>
          </Grid>
        )}
        {form[checkedField] && (
          <>
            <Grid item xs={12} sm={9} className={[classes.textRowLabelSpacer, classes.unprintedContent].join(' ')}>
              <FormControl fullWidth>
                <TextField
                  id={`${needsName.replace(' ', '')}-needs-desc`}
                  size="small"
                  hiddenLabel
                  placeholder={`Describe my ${needsName.toLowerCase()} needs.`}
                  variant="outlined"
                  value={form.hasOwnProperty(descField) ? form[descField] : ''}
                  onChange={(event) => setForm({ ...form, [descField]: event.target.value })}
                  InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            {form.hasOwnProperty(checkedField) && form[checkedField] && (
              <Grid item xs={12} sm={9} className={[classes.textRowLabelSpacer, classes.printedContent].join(' ')}>
                {form.hasOwnProperty(descField) ? form[descField] : ''}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

Needs.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  needsName: PropTypes.string.isRequired,
};

export default Needs;
