import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services/appInsights';
import './App.css';
import Default from './components/MainMenu/index';
import AlumniListTable from './components/AlumniList/AlumniListTable';
import SeniorListTable from './components/SeniorList/SeniorListTable';
import SeniorPlanner from './components/SeniorList/SeniorPlanner';
import Unauthorized from './components/Unauthorized/index';
import Layout from './components/Shared/Layout';
import AccessRequest from './components/AccessRequest/index';
import AccessRequestList from './components/AccessRequest/accessRequestList';
import AftercareApplicationsList from './components/AftercareApplications/AftercareApplicationsList';
import AftercareDisposition from './components/Aftercare/AftercareDisposition';
import ScholarshipApplicationList from './components/ScholarshipApplications/ScholarshipApplicationList';
import HelpApplicationList from './components/HelpApplications/HelpApplicationList';
import ContactList from './components/Contact/ContactList';
import Contact from './components/Contact/Contact';
import ScholarshipDisposition from './components/Scholarship/ScholarshipDisposition';
import HelpRequestDisposition from './components/HelpApplications/HelpRequestDisposition';
import Homepage from './components/Pages/Homepage';
import HowCanWeHelp from './components/Pages/HowCanWeHelp';
import AlumniAssociation from './components/Pages/AlumniAssociation';
import Housing from './components/Pages/Housing';
import Tradelife from './components/Pages/Tradelife';
import Scholarships from './components/Pages/Scholarships';
import AboutUs from './components/Pages/AboutUs';
import ReportList from './components/Reports/ReportList';
import Recommendation from './components/Pages/Recommendation';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <div className="App">
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          onError={() => (
            <Router>
              <Layout>
                <h2>Something unexpected has happened. Please try again.</h2>
              </Layout>
            </Router>
          )}
          appInsights={reactPlugin}
        >
          <Router>
            <Layout>
              <Route path="/home" component={Homepage} />
              <Route path="/help" component={HowCanWeHelp} />
              <Route path="/alumni" component={AlumniAssociation} />
              <Route path="/housing" component={Housing} />
              <Route path="/tradelife" component={Tradelife} />
              <Route path="/scholarships" component={Scholarships} />
              <Route path="/about" component={AboutUs} />
              <Route path="/requestaccess" component={AccessRequest} />
              <Route path="/recommendation/:token?" component={Recommendation} />
              <PrivateRoute path="/" exact>
                <Default />
              </PrivateRoute>
              <PrivateRoute path="/alumnilist">
                <AlumniListTable />
              </PrivateRoute>
              <PrivateRoute path="/seniorlist">
                <SeniorListTable />
              </PrivateRoute>
              <PrivateRoute path="/seniorplanner">
                <SeniorPlanner />
              </PrivateRoute>
              <PrivateRoute path="/accessrequestlist">
                <AccessRequestList />
              </PrivateRoute>
              <PrivateRoute path="/aftercarelist">
                <AftercareApplicationsList />
              </PrivateRoute>
              <PrivateRoute path="/aftercareapp">
                <AftercareDisposition />
              </PrivateRoute>
              <PrivateRoute path="/scholarshiplist">
                <ScholarshipApplicationList />
              </PrivateRoute>
              <PrivateRoute path="/helprequestlist">
                <HelpApplicationList />
              </PrivateRoute>
              <PrivateRoute path="/helprequest">
                <HelpRequestDisposition />
              </PrivateRoute>
              <PrivateRoute path="/scholarshipapp">
                <ScholarshipDisposition />
              </PrivateRoute>
              <PrivateRoute path="/reports">
                <ReportList />
              </PrivateRoute>
              <PrivateRoute path="/contactlist">
                <ContactList />
              </PrivateRoute>
              <PrivateRoute path="/contact">
                <Contact />
              </PrivateRoute>
              <Route path="/default" render={() => <Redirect to="/" />} />
              <Route path="/unauthorized" component={Unauthorized} />
            </Layout>
          </Router>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    </div>
  );
}

export default App;
