import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import AlumniListCard from './AlumniListCard';

const AlumniListCards = observer((props) => {
  const store = props.SeniorStore;

  return (
    <React.Fragment>
      <Grid container direction="row">
        {store.searching ? (
          <Grid item xs={12}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              Searching...
            </Typography>
          </Grid>
        ) : store.seniordata.length > 0 ? (
          props.seniors &&
          props.seniors.map((senior, idx) => (
            <Grid item xs={12} sm={6} md={4} key={senior.id + '_' + idx}>
              <AlumniListCard senior={senior} store={store} programs={props.programs} key={senior.id} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} align="center">
            <Typography variant="h5">No alumni found.</Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
});

export default AlumniListCards;
