import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomButton from '../../CustomButtons/Button.js';
import { observer } from 'mobx-react';
import {
  blackColor,
  whiteColor,
  btLightBlueColor,
  btMediumGrayColor,
  btOrangeColor,
  btNavyBlueColor,
} from '../../../assets/jss/nextjs-material-kit-pro.js';
import TableStyle from '../../../styles/TableStyle';
import Paper from '@material-ui/core/Paper';
import { dateToS } from '../../../util/dateUtil';
import InfoIcon from '@material-ui/icons/Info';
import AwardHeaderComponent from './AwardHeaderComponent';
// import CompleteAppDialog from './CompleteAppDialog';
import LuxonUtils from '@date-io/luxon';
import CloseIcon from '@material-ui/icons/Close';

const tableStyles = makeStyles(TableStyle);

const formStyles = makeStyles((theme) => ({
  header: {
    padding: 20,
    width: '100%',
    backgroundColor: btLightBlueColor,
    color: whiteColor,
  },
  headingSummary: {
    fontSize: '1.00rem',
  },
  dialog: {
    width: 800,
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  buttonWidth: {
    width: '110px',
  },
  buttonContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  smallHeader: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  mediumHeader: {
    [theme.breakpoints.only('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      display: 'block',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  largeHeader: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  textField: {
    fontSize: '.90rem',
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  headerSelect: {
    width: '130px',
    color: '#fff',
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#fff',
    },
  },
  headerSelectIcon: {
    fill: '#fff',
  },
  infoIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    fontSize: '1.25rem',
    color: btOrangeColor,
  },
  flexRowStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  scholarshipCell: {
    width: '280px',
    padding: '5px 15px 5px 5px',
  },
  cellData: {
    color: btNavyBlueColor,
  },
  deleteIconCell: {
    padding: '10px, 0 0 0',
    margin: 0,
    textAlign: 'center',
  },
  datepicker: {
    width: '150px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
}));

const AwardDialog = observer((props) => {
  const {
    showDialog,
    setShowDialog,
    handleClose,
    application,
    senior,
    user,
    scholarships,
    appAwards,
    setAppAwards,
    showAddAward,
    setShowAddAward,
    updateAward,
    createAward,
    updateAwardScholarship,
    updateAwardStatus,
    updateAwardAmount,
    updateAwardNotes,
    updateAwardedOn,
    awardPendingTotal,
    awardAwardedTotal,
    awardTotal,
    seniorPlanningScore,
    updateApplicationStatus,
    deleteAward,
    parent,
  } = props;

  const formClasses = formStyles();
  const tableClasses = tableStyles();

  const todaysDate = new Date();

  const showScholarshipInfo = (scholarship_id) => {
    if (!scholarship_id || scholarship_id === 0) return '';
    const scholarship = scholarships && scholarships.length > 0 && scholarships.find((s) => s.id === scholarship_id);
    if (!scholarship) return '';
    return (
      <div style={{ padding: 5 }}>
        <div style={{ fontWeight: 600, fontSize: '0.80rem' }}>{scholarship.name}</div>
        <br />
        <div>{scholarship.description}</div>
        <br />
        <div>
          <strong>Criteria:</strong>
          <br />
        </div>
        <div>{scholarship.criteria}</div>
        <br />
        <div>
          <strong>Amount:</strong>
          <br />
        </div>
        {scholarship.amount}
      </div>
    );
  };

  const displayStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'awarded':
        return 'Awarded';
      case 'denied':
        return 'Denied';
      default:
        return status;
    }
  };

  const cancelAwards = () => {
    setShowDialog(false);
    setShowAddAward(false);
  };

  const handleAddAward = (appId) => {
    let awards = appAwards;
    const newAward = {
      'tempid': appAwards.filter((a) => a.scholarship_application_id === appId).length + 1,
      'scholarship_application_id': appId,
      'userId': senior.user_id,
      'name': '',
      'status': '',
      'amount': '',
      'notes': '',
      'awarded_on': null,
      'updated_at': new Date(),
      'updated_by': user,
    };
    awards.push(newAward);
    setAppAwards(awards);
  };

  const canSave = (rec) => {
    if (rec.amount && isNaN(rec.amount)) {
      return false;
    } else if (!rec.amount) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    appAwards.forEach((award, i) => {
      if (canSave(award)) {
        if (award.hasOwnProperty('id')) {
          updateAward(award);
        } else {
          createAward(award);
        }
      } else {
        setShowDialog(true);
      }
    });
    setShowDialog(false);
  };

  return (
    <Dialog fullWidth maxWidth="800px" open={showDialog} onClose={handleClose} scroll="paper" aria-labelledby="Awards">
      <React.Fragment>
        <DialogTitle id="basic-info-dialog" className={formClasses.header}>
          <AwardHeaderComponent
            formClasses={formClasses}
            tableClasses={tableClasses}
            senior={senior}
            application={application}
            todaysDate={todaysDate}
            updateApplicationStatus={updateApplicationStatus}
            awardPendingTotal={awardPendingTotal}
            awardAwardedTotal={awardAwardedTotal}
            awardTotal={awardTotal}
            seniorPlanningScore={seniorPlanningScore}
            parent={parent}
          />
        </DialogTitle>
        <DialogContent className={formClasses.dialog} style={{ width: '100%', marginTop: 20 }} align="center">
          <TableContainer component={Paper}>
            <Table className={tableClasses.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={formClasses.noSortHeader} style={{ width: '50px', textAlign: 'center', padding: 0 }}>
                    {(application.status === 'submitted' ||
                      application.status === 'returned' ||
                      application.status === 'qualified' ||
                      application.status === 'qualifiedinitial' ||
                      application.status === 'qualifiedprogram') &&
                      'Delete'}
                  </TableCell>
                  <TableCell className={formClasses.noSortHeader} style={{ width: '280px' }}>
                    Scholarship/Award
                  </TableCell>
                  <TableCell className={formClasses.noSortHeader} style={{ width: '130px' }}>
                    Status
                  </TableCell>
                  <TableCell className={formClasses.noSortHeader} style={{ width: '120px', textAlign: 'center' }}>
                    Amount Awarded
                  </TableCell>
                  <TableCell className={formClasses.noSortHeader} style={{ width: '230px' }}>
                    Notes
                  </TableCell>
                  <TableCell className={formClasses.noSortHeader} style={{ width: '120px', paddingRight: 35, textAlign: 'right' }}>
                    Awarded / Denied On
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appAwards &&
                  appAwards.map((record, idx) => (
                    <TableRow key={record.id}>
                      {application.status === 'awarded' || application.status === 'denied' ? (
                        <>
                          <TableCell className={formClasses.deleteIconCell} style={{ width: '50px', padding: 0 }} align="center">
                            {(application.status === 'submitted' ||
                              application.status === 'returned' ||
                              application.status === 'qualified' ||
                              application.status === 'qualifiedinitial' ||
                              application.status === 'qualifiedprogram') && (
                              <Tooltip title="Remove award" placement="top">
                                <IconButton color="inherit" aria-label="delete award row" onClick={(e) => deleteAward(record)}>
                                  <CloseIcon style={{ marginRight: 5, fontSize: '1.25rem', color: btNavyBlueColor }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className={formClasses.scholarshipCell + ' ' + formClasses.cellData}>
                            <div className={formClasses.flexRowStart}>
                              {record.name}{' '}
                              <Tooltip title={record && showScholarshipInfo(record.scholarship_id)} placement="top">
                                <InfoIcon className={formClasses.infoIcon} />
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell className={formClasses.cellData} style={{ width: '120px' }}>
                            {displayStatus(record.status)}
                          </TableCell>
                          <TableCell className={formClasses.cellData} align="center" style={{ width: '120px' }}>
                            {record.amount &&
                              record.amount.length > 0 &&
                              parseInt(record.amount, 10).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                          </TableCell>
                          <TableCell className={formClasses.cellData} style={{ width: '230px' }}>
                            {record.notes}
                          </TableCell>
                          <TableCell style={{ width: '150px', textAlign: 'center' }}>{dateToS(record.awarded_on) || null}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className={formClasses.deleteIconCell} align="center" style={{ width: '50px', padding: 0 }}>
                            {(application.status === 'submitted' ||
                              application.status === 'returned' ||
                              application.status === 'qualified' ||
                              application.status === 'qualifiedinitial' ||
                              application.status === 'qualifiedprogram') && (
                              <Tooltip title="Remove award" placement="top">
                                <IconButton color="inherit" aria-label="delete award row" onClick={(e) => deleteAward(record)}>
                                  <CloseIcon style={{ marginRight: 5, fontSize: '1.25rem', color: btNavyBlueColor }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className={formClasses.scholarshipCell}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                padding: 0,
                              }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  labelId="scholarship_select-label"
                                  id="scholarship_select"
                                  value={record.scholarship_id}
                                  onChange={(event) => updateAwardScholarship(record, event.target.value)}
                                  style={{ fontSize: '.9rem' }}
                                >
                                  <MenuItem value={0}></MenuItem>
                                  {scholarships.slice().map((s) => {
                                    return (
                                      <MenuItem value={s.id} key={s.id} style={{ fontSize: '.9rem' }}>
                                        {s.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>{' '}
                              <Tooltip title={record && showScholarshipInfo(record.scholarship_id)} placement="top">
                                <InfoIcon className={formClasses.infoIcon} />
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell style={{ width: '130px' }}>
                            <FormControl fullWidth>
                              <Select
                                labelId="scholarship_status-label"
                                id="scholarship_status"
                                value={record.status || 'pending'}
                                onChange={(event) => updateAwardStatus(record, event.target.value)}
                                style={{ fontSize: '.9rem' }}
                              >
                                <MenuItem value={'pending'} style={{ fontSize: '.9rem' }}>
                                  Pending
                                </MenuItem>
                                <MenuItem value={'awarded'} style={{ fontSize: '.9rem' }}>
                                  Awarded
                                </MenuItem>
                                <MenuItem value={'denied'} style={{ fontSize: '.9rem' }}>
                                  Denied
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell style={{ width: '120px' }} align="center">
                            <FormControl style={{ width: '110px' }} error={isNaN(record.amount) || !record.amount}>
                              <TextField
                                id="scholarship_amount"
                                size="small"
                                hiddenLabel
                                placeholder="Amount"
                                variant="outlined"
                                value={record.amount}
                                onChange={(event) => updateAwardAmount(record, event.target.value)}
                                InputProps={{ maxLength: 50, classes: { input: formClasses.textField } }}
                                error={isNaN(record.amount) || !record.amount}
                              />
                            </FormControl>{' '}
                          </TableCell>
                          <TableCell style={{ width: '230px' }}>
                            <FormControl fullWidth>
                              <TextField
                                id="scholarship_notes"
                                size="small"
                                hiddenLabel
                                placeholder="Notes"
                                variant="outlined"
                                value={record.notes}
                                onChange={(event) => updateAwardNotes(record, event.target.value)}
                                InputProps={{ maxLength: 2000, classes: { input: formClasses.textField } }}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell style={{ width: '150px', textAlign: 'right' }}>
                            {record.status === 'awarded' || record.status === 'denied' ? (
                              <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                  autoOk
                                  variant="inline"
                                  id="awarded-on"
                                  hiddenLabel
                                  disableFuture
                                  value={dateToS(record.awarded_on) || null}
                                  onChange={(date) => updateAwardedOn(record, date ? date : null)}
                                  format="MM/dd/yyyy"
                                  className={formClasses.datepicker}
                                  maxDateMessage="Date cannot be later than today."
                                  disabled={record.status === 'pending'}
                                />
                              </MuiPickersUtilsProvider>
                            ) : record.status === 'awarded' || record.status === 'denied' ? (
                              dateToS(record.awarded_on)
                            ) : (
                              ''
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                {appAwards && appAwards.length === 0 && showDialog && !showAddAward && (
                  <TableRow>
                    <TableCell align="center" colSpan={6} className={formClasses.noLeftPadding}>
                      <summary className={formClasses.noSeniorsMsg}>No scholarship awards found.</summary>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {(application.status === 'submitted' ||
            application.status === 'returned' ||
            application.status === 'qualified' ||
            application.status === 'qualifiedinitial' ||
            application.status === 'qualifiedprogram') && (
            <div className={formClasses.buttonContainer}>
              <Button color="primary" variant="outlined" size="small" onClick={(e) => handleAddAward(application.id)}>
                Add Award
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions className={formClasses.dialogHeaderFooter}>
          <CustomButton color="default" size="sm" className={formClasses.buttonWidth} onClick={(e) => cancelAwards()}>
            Cancel
          </CustomButton>
          <CustomButton color="primary" size="sm" className={formClasses.buttonWidth} onClick={(e) => handleSave()}>
            Save
          </CustomButton>
        </DialogActions>
      </React.Fragment>
      {/* <CompleteAppDialog showDialog={showCompleteDialog} handleClose={handleCompleteClose} completeApp={completeApp} /> */}
    </Dialog>
  );
});

export default AwardDialog;

AwardDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  senior: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  scholarships: PropTypes.array.isRequired,
  appAwards: PropTypes.array.isRequired,
  setAppAwards: PropTypes.func.isRequired,
  showAddAward: PropTypes.bool.isRequired,
  setShowAddAward: PropTypes.func.isRequired,
  updateAward: PropTypes.func.isRequired,
  createAward: PropTypes.func.isRequired,
  updateAwardScholarship: PropTypes.func.isRequired,
  updateAwardStatus: PropTypes.func.isRequired,
  updateAwardAmount: PropTypes.func.isRequired,
  updateAwardNotes: PropTypes.func.isRequired,
  updateAwardedOn: PropTypes.func.isRequired,
  awardPendingTotal: PropTypes.number,
  awardAwardedTotal: PropTypes.number,
  awardTotal: PropTypes.number,
  seniorPlanningScore: PropTypes.number,
  updateApplicationStatus: PropTypes.func.isRequired,
  showCompleteDialog: PropTypes.bool.isRequired,
  setShowCompleteDialog: PropTypes.func.isRequired,
  handleCompleteClose: PropTypes.func.isRequired,
  completeApp: PropTypes.func.isRequired,
  deleteAward: PropTypes.func.isRequired,
  parent: PropTypes.string.isRequired,
};
