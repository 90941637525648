import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Grid, IconButton, Snackbar, Tooltip } from '@material-ui/core';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import {
  webPrimaryColor,
  btNavyBlueColor,
  btLightGrayColor,
  whiteColor,
  webSecondaryColor,
  btLightBlueColor,
} from '../../assets/jss/nextjs-material-kit-pro.js';
import '../../assets/css/nextjs-material-kit-pro.css';
import PleaseWait from '../Shared/PleaseWait';
import Button from '../CustomButtons/Button.js';
import { callApi } from '../../util/apiWrapper';
import { logException, appInsights } from '../../services/appInsights';
import { dateToS, dateDiffDays } from '../../util/dateUtil';
import CloseIcon from '@material-ui/icons/Close';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import DeskTopEval from './Components/DeskTopEval';
import MobileEval from './Components/MobileEval';
import RecommendationIcon from '../../assets/img/planner-recommendations-icon.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    backgroundColor: '#A7B2BE',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      padding: '30px 25px 15px 25px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '35px 25px 15px 25px',
    },
  },
  headerText: {
    color: '#fff',
    fontWeight: 600,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    [theme.breakpoints.up('md')]: {
      fontSize: '42px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
  },
  root: {
    minWidth: '100%',
    display: 'flex',
    position: 'relative',
  },
  introContainer: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  buttonContainer: {
    margin: '0 auto',
    width: '75vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '20px 0',
  },
  textAreaWidth: {
    margin: '0 auto',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: '75vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  intro: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      width: '75vw',
      margin: '20px 0 5px 0',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '25px 10% 65px 10%',
    },
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: '"Source Sans Pro", Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  relativePosition: {
    position: 'relative',
    textAlign: 'left',
    padding: '30px 30px 10px 30px',
    marginTop: '25px',
  },
  textRowSpacer: {
    [theme.breakpoints.up('md')]: {
      height: 50,
    },
  },
  question: {
    padding: '12px 50px 0 120px',
  },
  instructions: {
    width: '75vw',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontWeight: 600,
  },
  radio: {
    paddingLeft: 25,
  },
  buttonWidth: {
    width: 110,
    marginLeft: 10,
  },
  thankYouContainer: {
    margin: '0 auto',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    color: btNavyBlueColor,
  },
  linkContainer: {
    margin: '0 auto',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    color: btNavyBlueColor,
  },
  warning: {
    backgroundColor: btLightGrayColor,
    color: '#000',
  },
  largeTooltip: {
    fontSize: '0.80rem',
    lineHeight: '1.00rem',
    backgroundColor: '#fff',
    color: webPrimaryColor,
  },
  customTooltip: {
    marginLeft: '-120px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${webPrimaryColor}`,
    color: webPrimaryColor,
    boxShadow: '0px 10px 8px rgba(0,0,0,0.4)',
  },
  customArrow: {
    fontSize: 24,
    color: '#c3c3c3',
    '&::before': {
      backgroundColor: whiteColor,
      border: `.5px solid ${webPrimaryColor}`,
      borderRadius: '2px 0',
    },
  },
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px 20px',
    padding: '1% 10% !important',
  },
  recommendationCard: {
    backgroundColor: whiteColor,
    [theme.breakpoints.down('sm')]: {
      width: '235px',
      height: '275px',
    },
    [theme.breakpoints.up('md')]: {
      width: '235px',
      height: '275px',
    },
    margin: '0, 5px',
  },
  cardIcon: {
    position: 'absolute',
    top: '-55px',
    left: '28%',
  },
  cardBody: {
    padding: '35px 10px',
  },
  cardContainer: {
    alignItems: 'left',
  },
  recommendationDates: {
    fontSize: '1.50rem',
    color: webPrimaryColor,
    margin: '15px 0',
    padding: 10,
  },
  cardHeader: {
    color: webPrimaryColor,
    fontSize: '1.175rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    textAlign: 'center',
    minHeight: '3.15rem',
  },
  applicationArea: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webSecondaryColor,
  },
  showAllLink: {
    fontSize: '1.15rem',
    lineHeight: '1.375rem',
    color: btLightBlueColor,
    margin: '20px',
    cursor: 'pointer',
  },
}));

const Recommendation = (props) => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState(null);

  const classes = useStyles();

  const [formExtra, setFormExtra] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [allRecommendations, setAllRecommendations] = useState([]);

  const [requiredFields, setRequiredFields] = useState(null);
  const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
  const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);

  const [score_inspires, setScoreInspires] = useState('');
  const [score_positive, setScorePositive] = useState('');
  const [score_feedback, setScoreFeedback] = useState('');
  const [score_progress, setScoreProgress] = useState('');
  const [score_motivation, setScoreMotivation] = useState('');
  const [score_awareness, setScoreAwareness] = useState('');
  const [score_commitments, setScoreCommitments] = useState('');
  const [score_resourceful, setScoreResourceful] = useState('');

  const [recommend_scholarship, setRecommendScholarship] = useState('');
  const [recommend_tradelife, setRecommendTradelife] = useState('');
  const [recommend_housing, setRecommendHousing] = useState('');

  const [recommendationText, setRecommendationText] = useState('');

  const [warning, setWarning] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    record && record.status === 'Complete' && setFormComplete(true);
  }, [record]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        setIsLoading(true);
        const rec = await callApi(null, `recommendation/${token}`, 'GET');
        setFormExtra(JSON.parse(rec.result.recommendation_json));
        setRecord(rec.result);
        setIsLoading(false);
      } catch (error) {
        setRecord('error');
        setIsLoading(false);
        logException(error);
      }
    };

    if (token && token.length > 0) {
      fetchToken();
    }
  }, [token]);

  // Complete form based on values loaded from db
  useEffect(() => {
    formExtra && setScoreInspires(formExtra.score_inspires);
    formExtra && setScorePositive(formExtra.score_positive);
    formExtra && setScoreFeedback(formExtra.score_feedback);
    formExtra && setScoreProgress(formExtra.score_progress);
    formExtra && setScoreMotivation(formExtra.score_motivation);
    formExtra && setScoreAwareness(formExtra.score_awareness);
    formExtra && setScoreCommitments(formExtra.score_commitments);
    formExtra && setScoreResourceful(formExtra.score_resourceful);
    formExtra && setRecommendScholarship(formExtra.recommend_scholarship);
    formExtra && setRecommendTradelife(formExtra.recommend_tradelife);
    formExtra && setRecommendHousing(formExtra.recommend_housing);
    formExtra && setRecommendationText(formExtra.recommendationText);
  }, [formExtra]);

  const bannerText = (
    <div className={classes.header}>
      <h1 className={classes.headerText}>Recommendation Portal</h1>
    </div>
  );

  if (!token || token.length === 0 || record === 'error') {
    return (
      <div className={classes.root}>
        <main style={{ width: '100vw', height: '50vh' }}>
          {bannerText}
          <div>
            <h3>Sorry, this link is invalid. Please use the link in your recommendation request email.</h3>
          </div>
        </main>
      </div>
    );
  }

  const showAllView = () => {
    fetchAllRecommendations();
    setShowAll(true);
  };

  const fetchAllRecommendations = async () => {
    try {
      let recs = await callApi(null, 'recommendation/email/' + record.email, 'GET');
      setAllRecommendations(recs.result);
    } catch (err) {
      logException(err);
      console.error(err);
    }
  };

  const isReadyToExpire = (expires) => {
    if (dateDiffDays(dateToS(expires)) < 7) {
      return <span style={{ color: 'red' }}>**</span>;
    }
  };

  const decipherRecommendationAreas = (app) => {
    let areas = [];
    if (app.recommendation_json && app.recommendation_json.length > 0) {
      const rec_json = JSON.parse(app.recommendation_json);
      if (rec_json.scholarships) {
        areas.push('Scholarships');
      }
      if (rec_json.tradeLife) {
        areas.push('Trade Life');
      }
      if (rec_json.housing) {
        areas.push('Housing');
      }
    }
    return areas;
  };

  const getRecommendationList = () => {
    setShowAll(true);
    fetchAllRecommendations();
  };

  const sendRecommendationSubmittedEmail = async () => {
    try {
      await callApi(null, 'recommendation/receivedemail', 'POST', { student_id: record.student_id, rec: record });
      appInsights.trackTrace({ message: `Recommendation submitted email sent to ${record.student_first_name} ${record.student_last_name}` });
    } catch (error) {
      logException(error);
      console.log('Error sending Recommendation submitted email:', error);
    }
  };

  const writeRecommendation = async (id, data, close) => {
    try {
      const request = {
        rec_id: id,
        rec_json: JSON.stringify(data),
        close_rec: close,
      };
      await callApi(null, 'recommendation/requestupdate', 'PUT', request);
      appInsights.trackTrace({ message: `Recommendation ${id} saved.` });
    } catch (error) {
      logException(error);
      console.log('Error saving letter of recommendation:', error);
    }
  };

  const handleSaveRecommendation = () => {
    let data = formExtra;
    data.score_inspires = score_inspires;
    data.score_positive = score_positive;
    data.score_feedback = score_feedback;
    data.score_progress = score_progress;
    data.score_motivation = score_motivation;
    data.score_awareness = score_awareness;
    data.score_commitments = score_commitments;
    data.score_resourceful = score_resourceful;
    data.recommend_scholarship = recommend_scholarship;
    data.recommend_tradelife = recommend_tradelife;
    data.recommend_housing = recommend_housing;
    data.recommendationText = recommendationText;
    writeRecommendation(record.recommendation_id, data, 0);
    setWarning('Recommendation saved.');
    setShowWarning(true);
  };

  const handleSubmitRecommendation = () => {
    if (canSubmitRecommendation()) {
      let data = formExtra;
      data.score_inspires = score_inspires;
      data.score_positive = score_positive;
      data.score_feedback = score_feedback;
      data.score_progress = score_progress;
      data.score_motivation = score_motivation;
      data.score_awareness = score_awareness;
      data.score_commitments = score_commitments;
      data.score_resourceful = score_resourceful;
      data.recommend_scholarship = recommend_scholarship;
      data.recommend_tradelife = recommend_tradelife;
      data.recommend_housing = recommend_housing;
      data.recommendationText = recommendationText;
      writeRecommendation(record.recommendation_id, data, 1);
      setFormComplete(true);
      getRecommendationList();
      sendRecommendationSubmittedEmail();
    } else {
      setShowRequiredFieldsDialog(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowWarning(false);
  };

  const handleRequiredInfoClose = () => {
    setShowRequiredFieldsDialog(false);
  };

  const canSubmitRecommendation = () => {
    let rc = true;
    let requiredFields = [];

    if (!score_inspires || score_inspires.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student includes and inspires others.' }];
      rc = false;
    }

    if (!score_positive || score_positive.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student sets a positive example.' }];
      rc = false;
    }

    if (!score_feedback || score_feedback.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student is open to feedback.' }];
      rc = false;
    }

    if (!score_progress || score_progress.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student has a desire to maintain progress in their life.' }];
      rc = false;
    }

    if (!score_motivation || score_motivation.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student maintains motivation and determination.' }];
      rc = false;
    }

    if (!score_awareness || score_awareness.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student has awareness of doing the right thing.' }];
      rc = false;
    }

    if (!score_commitments || score_commitments.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student makes commitments and follows through.' }];
      rc = false;
    }

    if (!score_resourceful || score_resourceful.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please indicate whether the student is resourceful.' }];
      rc = false;
    }

    if (formExtra.scholarships) {
      if (!recommend_scholarship || recommend_scholarship.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate whether you would recommend the student for a Boys Town scholarship.' }];
        rc = false;
      }
    }

    if (formExtra.tradeLife) {
      if (!recommend_tradelife || recommend_tradelife.length === 0) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Please indicate whether you would recommend the student for the Successful Futures Trade Life program.' },
        ];
        rc = false;
      }
    }

    if (formExtra.housing) {
      if (!recommend_housing || recommend_housing.length === 0) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Please indicate whether you would recommend the student for the Successful Futures Housing program.' },
        ];
        rc = false;
      }
    }

    if (!recommendationText || recommendationText.length === 0) {
      requiredFields = [...requiredFields, { requirement: 'Please write your recommendation in the field provided.' }];
      rc = false;
    }

    setRequiredFieldsIntro(
      'You have not completed all of the required information on the Letter of Recommendation form. Please make these adjustments before submitting the form.'
    );
    setRequiredFields(requiredFields);
    return rc;
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <PleaseWait isLoading={isLoading} />
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.warning,
          },
        }}
        open={showWarning}
        autoHideDuration={6000}
        onClose={handleClose}
        message={warning}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      ></Snackbar>
      <main>
        {bannerText}
        {record && (
          <>
            {!showAll && !formComplete && (
              <>
                <div className={classes.showAllLink} onClick={(e) => showAllView()}>
                  Show All Pending Recommendation Requests
                </div>
                <div className={classes.introContainer}>
                  <p className={classes.intro}>
                    Dear {record.salutation} {record.first_name} {record.last_name},
                    <br />
                    <br />
                    Successful Futures requires Boys Town seniors to submit two letters of recommendation with their Scholarship, Trade Life, and/or
                    Housing application. Thank you for helping students complete these application requirements.
                    <br />
                    <br />
                    <strong>
                      {record.student_first_name} {record.student_last_name} has requested your recommendation for the following program(s):
                    </strong>
                    <br />
                    <br />
                    <ol style={{ maxWidth: '400px' }}>
                      {formExtra.scholarships && (
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                          }}
                          title={
                            <div className={classes.largeTooltip}>
                              Boys Town offers need-based and merit scholarships to alumni pursuing undergraduate and trade degrees.
                            </div>
                          }
                          placement="top"
                          arrow
                        >
                          <li>A Boys Town scholarship</li>
                        </Tooltip>
                      )}
                      {formExtra.tradeLife && (
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                          }}
                          title={
                            <div className={classes.largeTooltip}>
                              Trade Life services provides life skills training, mentoring, and other supports to alumni as they transition to
                              independent living.
                            </div>
                          }
                          placement="top"
                          arrow
                        >
                          <li>Successful Futures Trade Life Program</li>
                        </Tooltip>
                      )}
                      {formExtra.housing && (
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                          }}
                          title={
                            <div className={classes.largeTooltip}>
                              Successful Futures housing services support Family Home Program graduates, ages 17-21, by providing safe and affordable
                              housing.
                            </div>
                          }
                          placement="top"
                          arrow
                        >
                          <li>Successful Futures Housing Program</li>
                        </Tooltip>
                      )}
                    </ol>
                  </p>
                </div>
              </>
            )}
            {!showAll && !formComplete && (
              <>
                <Hidden smDown implementation="css">
                  <DeskTopEval
                    formExtra={formExtra}
                    setFormExtra={setFormExtra}
                    record={record}
                    setRecord={setRecord}
                    score_inspires={score_inspires}
                    setScoreInspires={setScoreInspires}
                    score_positive={score_positive}
                    setScorePositive={setScorePositive}
                    score_feedback={score_feedback}
                    setScoreFeedback={setScoreFeedback}
                    score_progress={score_progress}
                    setScoreProgress={setScoreProgress}
                    score_motivation={score_motivation}
                    setScoreMotivation={setScoreMotivation}
                    score_awareness={score_awareness}
                    setScoreAwareness={setScoreAwareness}
                    score_commitments={score_commitments}
                    setScoreCommitments={setScoreCommitments}
                    score_resourceful={score_resourceful}
                    setScoreResourceful={setScoreResourceful}
                    recommend_scholarship={recommend_scholarship}
                    setRecommendScholarship={setRecommendScholarship}
                    recommend_tradelife={recommend_tradelife}
                    setRecommendTradelife={setRecommendTradelife}
                    recommend_housing={recommend_housing}
                    setRecommendHousing={setRecommendHousing}
                    recommendationText={recommendationText}
                    setRecommendationText={setRecommendationText}
                    classes={classes}
                  />
                </Hidden>
                <Hidden mdUp>
                  <MobileEval
                    formExtra={formExtra}
                    setFormExtra={setFormExtra}
                    record={record}
                    setRecord={setRecord}
                    score_inspires={score_inspires}
                    setScoreInspires={setScoreInspires}
                    score_positive={score_positive}
                    setScorePositive={setScorePositive}
                    score_feedback={score_feedback}
                    setScoreFeedback={setScoreFeedback}
                    score_progress={score_progress}
                    setScoreProgress={setScoreProgress}
                    score_motivation={score_motivation}
                    setScoreMotivation={setScoreMotivation}
                    score_awareness={score_awareness}
                    setScoreAwareness={setScoreAwareness}
                    score_commitments={score_commitments}
                    setScoreCommitments={setScoreCommitments}
                    score_resourceful={score_resourceful}
                    setScoreResourceful={setScoreResourceful}
                    recommend_scholarship={recommend_scholarship}
                    setRecommendScholarship={setRecommendScholarship}
                    recommend_tradelife={recommend_tradelife}
                    setRecommendTradelife={setRecommendTradelife}
                    recommend_housing={recommend_housing}
                    setRecommendHousing={setRecommendHousing}
                    recommendationText={recommendationText}
                    setRecommendationText={setRecommendationText}
                    classes={classes}
                  />
                </Hidden>
              </>
            )}

            {!showAll && !formComplete && (
              <Grid container direction="row" className={classes.buttonContainer}>
                <Grid item xs={12}>
                  <Button className={classes.buttonWidth} variant="contained" color="secondary" onClick={(event) => handleSaveRecommendation()}>
                    Save
                  </Button>
                  <Button className={classes.buttonWidth} variant="contained" color="primary" onClick={(event) => handleSubmitRecommendation()}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            )}

            {formComplete && (
              <Grid container direction="row" className={classes.thankYouContainer}>
                <Grid item xs={12}>
                  <h3>
                    Thank you for helping students complete their Successful Futures application requirements.
                    <br />
                    Your feedback is greatly appreciated.
                  </h3>
                </Grid>
              </Grid>
            )}

            {formComplete && (!allRecommendations || allRecommendations.length === 0) && (
              <Grid container direction="row" className={classes.thankYouContainer}>
                <Grid item xs={12}>
                  <h4>You do not have any pending Letter of Recommendation requests.</h4>
                </Grid>
              </Grid>
            )}

            {(formComplete || showAll) && allRecommendations && allRecommendations.length > 0 && (
              <div className={classes.linkContainer}>
                <h3>Pending Letter of Recommendation Requests</h3>
                <h5>
                  This is a list of Letter of Recommendation requests waiting for your input.
                  <br />
                  <br />
                </h5>
                <Grid item xs={12} align="left" className={classes.cardGrid} style={{ width: '100vw' }}>
                  <Grid item xs={12} />
                  {allRecommendations &&
                    allRecommendations
                      .filter((r) => r.status !== 'Complete')
                      .sort((a, b) => {
                        return a.last_date_requested > b.last_date_requested ? 1 : -1;
                      })
                      .map((rec, idx) => {
                        return (
                          <Link href={rec.token} key={rec.recommendation_id}>
                            <Card className={classes.recommendationCard}>
                              <CardBody className={classes.cardBody}>
                                <div className={classes.cardContainer}>
                                  <img src={RecommendationIcon} alt="Recommendation Icon" className={classes.cardIcon} />
                                  <section className={classes.recommendationDates}>
                                    <main className={classes.cardHeader}>
                                      {rec.student_first_name} {rec.student_last_name}
                                    </main>
                                    <aside style={{ marginTop: '10px' }}>
                                      <label>Requested On</label> <span className={classes.applicationArea}>{dateToS(rec.last_date_requested)}</span>
                                    </aside>
                                    <aside>
                                      <label>Expires</label>{' '}
                                      <span className={classes.applicationArea}>
                                        {dateToS(rec.expires_utc)}
                                        {isReadyToExpire(rec.expires_utc)}
                                      </span>
                                    </aside>
                                    <aside>
                                      <label>Requested for </label>
                                      <div className={classes.applicationArea}>{decipherRecommendationAreas(rec).toString()}</div>
                                    </aside>
                                  </section>
                                </div>
                              </CardBody>
                            </Card>
                          </Link>
                        );
                      })}
                  <Grid item xs={12} align="center">
                    <div>
                      <span style={{ color: 'red' }}>**</span> Request will expire soon
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            {/* <div className={classes.introContainer}>
              <p className={classes.intro}>
                {record && (
                  <>
                    <pre>{JSON.stringify(record, null, 2)}</pre>
                  </>
                )}
              </p>
            </div> */}
          </>
        )}
      </main>
      <RequiredInfoDialog
        title="Letter of Recommendation Errors"
        introduction={requiredFieldsIntro}
        content={requiredFields}
        showDialog={showRequiredFieldsDialog}
        handleClose={handleRequiredInfoClose}
      />
    </div>
  );
};

export default Recommendation;
