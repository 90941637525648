import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Grid, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText } from '@material-ui/core';
import Button from '../../CustomButtons/Button.js';
import { observer } from 'mobx-react';
import { blackColor, whiteColor } from '../../../assets/jss/nextjs-material-kit-pro';
import PhoneInput from '../../Shared/PhoneInput';

const formStyles = makeStyles({
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  dialog: {
    width: 500,
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: '30px',
  },
  rowSpacer80: {
    paddingTop: 20,
    height: '80px',
  },
  textRowLabelSpacer: {
    height: 70,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttonWidth: {
    width: '110px',
  },
  message: {
    fontSize: '0.95rem',
    marginBottom: '10px',
  },
  warning: {
    color: 'blue',
    fontSize: '0.95rem',
    fontWeight: 500,
    paddingTop: 20,
    marginBottom: '15px',
    height: '65px',
  },
  error: {
    color: 'red',
    fontSize: '0.95rem',
    fontWeight: 500,
    marginTop: '10px',
  },
  alignLabel: {
    lineHeight: '35px',
    verticalAlign: 'middle',
  },
});

const PhoneDialog = observer((props) => {
  const { message, staff, showDialog, handleClose, handleSave } = props;

  const formClasses = formStyles();

  const [officePhone, setOfficePhone] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [officePhoneIsValid, setOfficePhoneIsValid] = useState(true);
  const [cellPhoneIsValid, setCellPhoneIsValid] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    staff && setOfficePhone(staff.office_phone);
    staff && setCellPhone(staff.cell_phone);
  }, [staff]);

  const save = (officephone, cellphone) => {
    let canSave = true;
    if (!staff) {
      canSave = false;
    }
    if (
      (staff.position_class === '1347' || staff.position_class === '2001' || staff.position_class === '2148' || staff.position_class === '1534') &&
      !officePhone
    ) {
      setError('Office phone is required.');
      canSave = false;
    }
    if (!officePhoneIsValid) {
      canSave = false;
    }
    if (cellPhone && !cellPhoneIsValid) {
      canSave = false;
    }

    if (canSave) handleSave(officePhone, cellPhone);
  };

  return (
    <React.Fragment>
      <Dialog open={showDialog} onClose={handleClose} scroll="paper" aria-labelledby="Pop-up Help">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeaderFooter}>
            My Contact Information
          </DialogTitle>
          <DialogContent className={formClasses.dialog} style={{ minHeight: 200 }}>
            <Box sx={{ width: '100%' }}>
              <section className={formClasses.rowSpacer}>
                <label>Name</label> {staff.user.first_name + ' ' + staff.user.last_name}
              </section>
              <section className={formClasses.rowSpacer}>
                <label>Email</label> {staff.user.email}
              </section>
              <section className={formClasses.rowSpacer80}>Your phone numbers are displayed for your students on their Senior Planner page.</section>
              {message && message.length > 0 && <section className={formClasses.warning}>{message}</section>}
              <Grid container direction="row">
                <Grid item xs={3} md={5} lg={4} className={formClasses.rowSpacer} style={{ marginTop: '10px' }}>
                  <label className={formClasses.alignLabel}>Office Phone</label>
                </Grid>
                <Grid item xs={4} md={4} lg={4} className={formClasses.textRowLabelSpacer}>
                  <FormControl fullWidth error={!officePhoneIsValid}>
                    <PhoneInput
                      id="phone"
                      variant="outlined"
                      value={officePhone}
                      required
                      handleChange={(val, isValid) => {
                        setOfficePhone(val.replace(/\D/g, ''));
                        setOfficePhoneIsValid(isValid);
                      }}
                    />
                    <FormHelperText id="my-helper-text">{!officePhoneIsValid && 'Invalid office phone number.'}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={5} md={3} lg={4} className={formClasses.rowSpacer} />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={3} md={5} lg={4} className={formClasses.rowSpacer} style={{ marginTop: '10px' }}>
                  <label className={formClasses.alignLabel}>Cell Phone</label>
                </Grid>
                <Grid item xs={4} md={4} lg={4} className={formClasses.textRowLabelSpacer}>
                  <FormControl fullWidth error={!cellPhoneIsValid}>
                    <PhoneInput
                      id="phone"
                      variant="outlined"
                      value={cellPhone}
                      handleChange={(val, isValid) => {
                        setCellPhone(val.replace(/\D/g, ''));
                        setCellPhoneIsValid(isValid);
                      }}
                    />
                    <FormHelperText id="my-helper-text">{!cellPhoneIsValid && 'Invalid cell phone number.'}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={5} md={3} lg={4} className={formClasses.rowSpacer} />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} className={formClasses.error}>
                  {error}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </React.Fragment>
        <DialogActions className={formClasses.dialogHeaderFooter}>
          <Button
            className={formClasses.buttonWidth}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              handleClose();
            }}
          >
            Close
          </Button>
          <Button color="primary" className={formClasses.buttonWidth} onClick={(e) => save(officePhone, cellPhone)} disabled={!staff}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default PhoneDialog;

PhoneDialog.propTypes = {
  message: PropTypes.string,
  staff: PropTypes.object.isRequired,
  showDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
