import React from 'react';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import BTGlobalHeader from './BTGlobalHeader';

import styles from '../../assets/jss/nextjs-material-kit-pro/components/headerStyle.js';

const useStyles = makeStyles(styles);
export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, links, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  return (
    <AppBar className={appBarClasses + ' ' + classes.appBarPosition}>
      <Toolbar
        className={classes.container}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'space-between' }}
      >
        {/* Desktop */}
        <Hidden mdDown implementation="css">
          <Link href="/" style={{ cursor: 'pointer' }}>
            <img className={classes.logo} src="/boystown_successful_futures_logo_white.png" alt="Logo" />
          </Link>
        </Hidden>
        <Hidden mdDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{links}</div>
        </Hidden>

        {/* Mobile */}
        <Hidden lgUp>
          <Link href="/" style={{ cursor: 'pointer' }}>
            <img
              style={{ width: '30%', height: 'auto', marginRight: '5px' }}
              className={classes.logo}
              src="/boystown_successful_futures_logo_white.png"
              alt="Logo"
            />
          </Link>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>

      {/* Mobile */}
      <Hidden lgUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} className={classes.closeButtonDrawer}>
            <Close />
          </IconButton>
          <div className={classes.appResponsive} onClick={(e) => handleDrawerToggle()}>
            {links}
          </div>
          <div>
            <BTGlobalHeader />
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: 'white',
};

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']).isRequired,
  }),
};
