import React from 'react';
import { useHistory } from 'react-router-dom';
import { dateUtcToS, dateToApi, dateDiffYears, getTodaysDate } from '../../util/dateUtil';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { observer, inject } from 'mobx-react';
import { Button } from '@material-ui/core';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';

const useStyles = makeStyles((theme) => ({
  edit: {
    textAlign: 'center',
  },
  popover: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'space-evenly',
  },
  card: {
    margin: 15,
    maxWidth: '320px',
    height: '160px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  tools: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  space5: {
    padding: 5,
  },
  noNotes: {
    paddingTop: 10,
  },
  dataLabel: {
    paddingTop: 10,
  },
  attachIcon: {
    paddingTop: 5,
  },
  dataBold: {
    fontWeight: 700,
  },
  programHighlight: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    align: 'right',
  },
  idNoLink: {
    fontSize: '0.85rem',
    padding: 0,
    align: 'right',
    textTransform: 'lowercase',
  },
  cardLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
    textDecoration: 'underline',
  },
}));

const AlumniListCard = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;

    const classes = useStyles();

    const history = useHistory();

    const handleClickId = (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setBasicInfoId(id);
      store.setAlumni(true);
      history.push('/seniorplanner');
    };

    const lookupProgram = (id) => {
      if (!id || id === 0) return '';
      const idx = props.programs.findIndex((p) => p.int_id === id);
      if (idx > -1) {
        switch (props.programs[idx].program_node_name) {
          case 'Aftercare Independence':
            return 'Trade Life';
          case 'Aftercare Transitional Living':
            return 'Housing';
          default:
            return '';
        }
      }
    };

    return (
      <React.Fragment>
        <Card className={classes.card} key={props.senior.id}>
          <CardHeader className={classes.cardHeader}>
            <Button
              className={classes.idLink}
              color="primary"
              size="small"
              aria-describedby="view personal information"
              onClick={(ev) => handleClickId(ev, props.senior.id)}
            >
              {props.senior.last_name}, {props.senior.first_name} ({props.senior.int_id})
            </Button>
            <div>{props.senior.user.is_active ? 'ACTIVE' : 'Inactive'}</div>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <section className={classes.dataBold}>
              <label>Site</label> {props.senior.site}
            </section>
            {props.programs && <section className={classes.programHighlight}>{lookupProgram(props.senior.int_id)}</section>}
            <section className={classes.dataBold}>
              <label>Email Address</label>{' '}
              {props.senior.departure_date && props.senior.user.email && (
                <label className={classes.dataBold + ' ' + classes.idNoLink}>{props.senior.user.email}</label>
              )}
            </section>
            <section className={classes.dataBold}>
              <label>Age</label> {props.senior.dob && dateDiffYears(dateToApi(props.senior.dob), dateToApi(getTodaysDate()))}
            </section>
            {props.senior.departure_date && (
              <section className={classes.dataBold}>
                <label>Departure Date</label> {dateUtcToS(props.senior.departure_date)}
              </section>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  })
);

export default AlumniListCard;
