import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { Button } from '@material-ui/core';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { dateToMMMMYYYY } from '../../util/dateUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 15,
    maxWidth: '330px',
    height: props => props.showAllRecords ? '280px' : '205px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  dataBold: {
    fontWeight: 500,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    float: 'right',
    textTransform: 'none'
  },
}));

const AccessRequestCard = ({request, showAllRecords}) => {
    const classes = useStyles({showAllRecords});

    return (
      <React.Fragment>
        <Card className={classes.card} key={request.id}>
          <CardHeader className={classes.cardHeader}>
            {request.first_name} {request.last_name}
            {request.approved && <label className={classes.idNoLink}> ({request.email})</label>}
            {!request.approved && (
              <Button
                className={classes.idLink}
                color="primary"
                size="small"
                aria-describedby="approve request"
                //onClick={(ev) => handleClickId(ev, request.email)}
              >
                {request.email}
              </Button>
            )}
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <section className={classes.dataBold}>
              <label>Birth Date</label> {request.dob_formatted}
            </section>
            <section className={classes.dataBold}>
              <label>Programs Attended</label> {request.program_attended}
            </section>
            <section className={classes.dataBold}>
              <label>Admission Date</label> {request.admission_date_formatted}
            </section>
            <section className={classes.dataBold}>
              <label>Departure Date</label> {request.departure_date_formatted}
            </section>
            {showAllRecords && (
              <section className={classes.dataBold}>
                <label>Approved</label> {request.approved && request.approved === true && <CheckIcon color="primary" /> }
              </section>
            )}
            {showAllRecords && (
              <section className={classes.dataBold}>
                <label>Approved By</label> {request.approved_by}
              </section>
            )}
            {showAllRecords && (
              <section className={classes.dataBold}>
                <label>Approved Date</label> {request.approved_date ? dateToMMMMYYYY(request.approved_date) : ""}
              </section>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
}

AccessRequestCard.propTypes = {
  request: PropTypes.object.isRequired,
  showAllRecords: PropTypes.bool.isRequired
}

export default AccessRequestCard;
