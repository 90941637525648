import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { webInfoColor, whiteColor, btOrangeColor, webPrimaryColor, webSecondaryColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import Link from '@material-ui/core/Link';
import Button from '../CustomButtons/Button.js';
import '../../assets/css/nextjs-material-kit-pro.css';
import AlumniBanner from '../../assets/img/alumni-banner.png';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    display: 'flex',
    position: 'relative',
  },
  introContainer: {
    width: '100vw',
  },
  intro: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    width: '75vw',
    [theme.breakpoints.up('md')]: {
      margin: '40px 15% 30px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '25px 10% 30px 10%',
    },
  },
  breadcrumb: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '10px 15% 10px 15%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  banner: {
    display: 'block',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  emailContact: {
    fontSize: '0.90rem',
  },
  phoneContact: {
    margin: '5px 0 5px 0',
  },
  bannerImage: {
    width: '100%',
  },
  relativePosition: {
    position: 'relative',
  },
  titleBannerContainer: {
    position: 'relative',
  },
  titleBanner: {
    position: 'absolute',
    backgroundColor: 'rgba(36, 62, 93, 0.4)',
    textAlign: 'left',
    height: '122px',
    minWidth: '100%',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      left: 0,
      top: '-170px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine1: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: btOrangeColor,
    fontSize: '2.50rem',
    lineHeight: '3.563rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-160px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine2: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: whiteColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '2.625rem',
    lineHeight: '3.438rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-115px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  story: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '10px 15% 30px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 10% 30px 10%',
    },
  },
  learnMoreButton: {
    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '102vw',
      margin: '10px 0 40px -5px',
    },
  },
}));

const AlumniAssociation = (props) => {
  const classes = useStyles();

  const learnMore = () => {
    window.open('https://www.boystownalumni.org', '_blank');
  };

  return (
    <div className={classes.root}>
      <main>
        <div className={classes.relativePosition}>
          <div className={classes.banner}>
            <img src={AlumniBanner} alt="Alumni Association Banner" className={classes.bannerImage} />
            <div className={classes.titleBannerContainer}>
              <div className={classes.titleBanner}></div>
              <div className={classes.titleBannerLine1}>Join Our</div>
              <div className={classes.titleBannerLine2}>Alumni Association!</div>
            </div>
          </div>
        </div>
        <div className={classes.breadcrumb}>
          <Breadcrumbs separator={<PlayArrowIcon style={{ fontSize: '1.50rem', color: webInfoColor }} />} aria-label="breadcrumb">
            <Link href="/home">
              <HomeIcon style={{ marginTop: '5px', fontSize: '1.80rem', color: webInfoColor }} />
            </Link>
            <div style={{ color: webSecondaryColor }}>Alumni Association</div>
          </Breadcrumbs>
        </div>
        <div className={classes.introContainer}>
          <p className={classes.intro}>
            On June 4, 1951, 39 Boys Town citizens, together with 67 High School Seniors, who a week later would become alumni, gathered in the Boys
            Town high school library at the invitation of Monsignor Nicholas H. Wagner, Director of Boys Town, and formed the Boys Town Alumni
            Association. This made a realization of Father Flanagan’s idea and desire to form an Alumni Association.
          </p>
        </div>
        <p className={classes.story}>
          The Boys Town National Alumni Association® is now over 70 years old and has various local chapters all across the country. Successful
          Futures encourages Alumni engagement and strives to keep Alumni bonds and friendships alive. Successful Futures’ goal is to assist alumni
          and support the Boys Town name and mission whenever possible.
        </p>
        <p className={classes.story}>
          The BTNAA states its purpose is “To keep alive among the alumni, bonds of friendship formed at Boys Town; to assist fellow alumni whenever
          possible, to maintain closer contacts with, manifest interest in, and foster genuine loyalty to the home and school; to promote the general
          good and welfare of Father Flanagan’s Boys Town by good works, examples and words.”
        </p>
        <p>&nbsp;</p>
        <Button color="primary" className={classes.learnMoreButton} onClick={(e) => learnMore()}>
          LEARN MORE
        </Button>
      </main>
    </div>
  );
};

export default AlumniAssociation;
