import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, InputLabel, Select, MenuItem, TextareaAutosize, FormHelperText } from '@material-ui/core';
import PhoneInput from '../../Shared/PhoneInput';
import TwoYearCollege from './TwoYearCollege';
import FourYearCollege from './FourYearCollege';
import MilitaryPlan from './MilitaryPlan';
import JobCorpsPlan from './JobCorpsPlan';
import WorkforcePlan from './WorkforcePlan';
import { stateLabelValues } from '../../../util/stringUtil';

const PostGradSection = ({ form, setForm, classes, disabled }) => {
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  const POST_GRAD_PLANS = ['Trade/2-year College', '4-year College', 'Military', 'Job Corps', 'Workforce', 'Other'];
  const cityStateZip = `${form.city ? form.city : ''}, ${form.state ? form.state : ''} ${form.zipCode ? form.zipCode : ''}`;

  return (
    <Grid container align="left">
      <Grid item xs={12}>
        <section align="left" className={classes.sectionTitle}>
          Post-Graduation Contact Information
        </section>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Address</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="address1"
              size="small"
              hiddenLabel
              placeholder="Street Address"
              variant="outlined"
              value={form.address1}
              onChange={(event) => setForm({ ...form, address1: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="address2"
              size="small"
              hiddenLabel
              placeholder="Street Address 2"
              variant="outlined"
              value={form.address2}
              onChange={(event) => setForm({ ...form, address2: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="city"
              size="small"
              hiddenLabel
              placeholder="City"
              variant="outlined"
              value={form.city}
              onChange={(event) => setForm({ ...form, city: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={6} md={6} lg={6} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              //native
              labelId="state-label"
              label="State"
              value={form.state ?? ''}
              onChange={(e) => setForm({ ...form, state: e.target.value })}
              InputProps={{
                'aria-label': 'State',
                name: 'state',
                id: 'state-input',
              }}
              disabled={disabled}
            >
              <MenuItem aria-label="None" value=""></MenuItem>
              {stateLabelValues.map((s) => {
                return (
                  <MenuItem value={s.value} key={s.value}>
                    {s.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} sm={false} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="zip"
              size="small"
              hiddenLabel
              placeholder="Zip Code"
              variant="outlined"
              value={form.zipCode}
              helperText={form.zipCode && form.zipCode.length < 5 && 'Please enter a 5 digit zip code'}
              error={form.zipCode && form.zipCode.length < 5}
              onChange={(event) => {
                if (/^\d*$/.test(event.target.value)) {
                  setForm({ ...form, zipCode: event.target.value });
                }
              }}
              InputProps={{ maxLength: 5, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={false} sm={3} md={3} lg={3} />
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Address</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} className={classes.textRowSpacer}>
          <>
            {form.address1} <br />
            {form.address2 && (
              <>
                {form.address2}
                <br />
              </>
            )}
            {(form.city || form.state || form.zip) && <span>{cityStateZip}</span>}
          </>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Phone Number</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} md={10} className={classes.rowSpacer}>
          <FormControl fullWidth error={!phoneIsValid}>
            <PhoneInput
              id="phone"
              variant="outlined"
              value={form.phoneNumber}
              handleChange={(val, isValid) => {
                setForm({ ...form, phoneNumber: val.replace(/\D/g, '') });
                setPhoneIsValid(isValid);
              }}
              disabled={disabled}
            />
            <FormHelperText id="my-helper-text">{!phoneIsValid && 'Please enter a valid phone number'}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.textRowLabelSpacer}>
          <label>Phone Number</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} md={10} className={classes.rowSpacer}>
          {form.phoneNumber}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12}>
          <section align="left" className={classes.sectionTitle}>
            Post-Graduation Plan
          </section>
        </Grid>

        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>What best describes my post-graduation plan?</label>
        </Grid>
        {/* <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} /> */}
        <Grid item xs={11} sm={5} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <Select
              //native
              label="Post Grad Plan"
              value={form.postGradPlan ?? ''}
              onChange={(e) => {
                if (e.target.value !== '4-year College') {
                  // clear studentAidReport file in case it was set - only needed for 4-year college
                  setForm({ ...form, postGradPlan: e.target.value, studentAidReport: null });
                } else if (e.target.value !== 'Workforce') {
                  // clear resume file in case it was set - only needed for Workforce
                  setForm({ ...form, postGradPlan: e.target.value, resume: null });
                } else {
                  setForm({ ...form, postGradPlan: e.target.value });
                }
              }}
              InputProps={{
                'aria-label': 'Post Graduation Plan',
                name: 'post-grad-plan',
                id: 'post-grad-plan',
              }}
              disabled={disabled}
            >
              <MenuItem aria-label="None" value=""></MenuItem>
              {POST_GRAD_PLANS.map((s) => {
                return (
                  <MenuItem value={s} key={s.replace(' ', '-')}>
                    {s}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={6}>
          <label>What best describes my post-graduation plan?</label>
        </Grid>
        <Grid item xs={6}>
          {form.postGradPlan}
        </Grid>
      </Grid>

      {/* const POST_GRAD_PLANS = ["Trade/2-year College", "4-year College", "Military", "Job Corps", "Workforce", "Other"]; */}
      {form.postGradPlan === 'Trade/2-year College' && <TwoYearCollege form={form} setForm={setForm} classes={classes} disabled={disabled} />}
      {form.postGradPlan === '4-year College' && <FourYearCollege form={form} setForm={setForm} classes={classes} disabled={disabled} />}
      {form.postGradPlan === 'Military' && <MilitaryPlan form={form} setForm={setForm} classes={classes} disabled={disabled} />}
      {form.postGradPlan === 'Job Corps' && <JobCorpsPlan form={form} setForm={setForm} classes={classes} disabled={disabled} />}
      {form.postGradPlan === 'Workforce' && <WorkforcePlan form={form} setForm={setForm} classes={classes} disabled={disabled} />}

      <Grid container direction="row" style={{ marginTop: '15px' }}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>
            Please explain, in detail, my final post-graduation plan. Include details of what, when, where and how I am paying for my plan.
          </label>
        </Grid>
        <Grid item xs={12} className={[classes.unprintedContent, classes.textArea5RowSpacer].join(' ')}>
          <FormControl fullWidth>
            <TextareaAutosize
              aria-label="final-post-grad-plan"
              minRows={5}
              maxRows={5}
              maxLength={1000}
              placeholder="Please explain my post-graduation plan."
              value={form.finalPostGradPlan}
              onChange={(event) => setForm({ ...form, finalPostGradPlan: event.target.value })}
              disabled={disabled}
              className={classes.textAreaFont}
              style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={[classes.printedContent, classes.textArea5RowSpacer].join(' ')}>
          {form.finalPostGradPlan}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>What needs to be done to finalize my plan.</label>
        </Grid>
        <Grid item xs={12} className={[classes.unprintedContent, classes.textArea5RowSpacer].join(' ')}>
          <FormControl fullWidth>
            <TextareaAutosize
              aria-label="outstanding-final-plans"
              minRows={5}
              maxRows={5}
              maxLength={1000}
              placeholder="Describe what must be done to finalize my post-graduation plan."
              value={form.outstandingFinalPlans}
              onChange={(event) => setForm({ ...form, outstandingFinalPlans: event.target.value })}
              disabled={disabled}
              className={classes.textAreaFont}
              style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={[classes.printedContent, classes.textArea5RowSpacer].join(' ')}>
          {form.outstandingFinalPlans}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Challenges/Barriers to my plan.</label>
        </Grid>
        <Grid item xs={12} className={[classes.unprintedContent, classes.textArea5RowSpacer].join(' ')}>
          <FormControl fullWidth>
            <TextareaAutosize
              aria-label="Final Plan Challenges"
              minRows={5}
              maxRows={5}
              maxLength={1000}
              placeholder="Describe the challenges and/or barriers to my plan."
              value={form.finalPlanChallenges}
              onChange={(event) => setForm({ ...form, finalPlanChallenges: event.target.value })}
              disabled={disabled}
              className={classes.textAreaFont}
              style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={[classes.printedContent].join(' ')}>
          {form.finalPlanChallenges}
        </Grid>
      </Grid>
    </Grid>
  );
};

PostGradSection.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PostGradSection;
