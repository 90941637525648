import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import Button from '../../CustomButtons/Button.js';
import { observer } from 'mobx-react';
import { blackColor, whiteColor, webPrimaryColor } from '../../../assets/jss/nextjs-material-kit-pro';
import CloseIcon from '@material-ui/icons/Close';

const formStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  closeIcon: {
    fontSize: '20px',
  },
  dialog: {
    width: '600px',
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: '30px',
  },
  buttonWidth: {
    width: '110px',
  },
  fontSize: '0.85rem',
  fontWeight: 500,
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
  dialogExplain: {
    margin: '0 15px 10px 20px',
    fontSize: '0.90rem',
    fontWeight: 500,
  },
  labelInlineEditSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 40,
      paddingTop: 3,
    },
    [theme.breakpoints.up('md')]: {
      height: 45,
      paddingTop: 5,
    },
  },
  filterOptionGroup: {
    minWidth: '99%',
  },
  controlSet: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FilterDialog = observer((props) => {
  const {
    filterOption,
    setFilterOption,
    filterAll,
    setFilterAll,
    filterStatus,
    setFilterStatus,
    filterLoggedIn,
    setFilterLoggedIn,
    filterSite,
    setFilterSite,
    filterProgram,
    setFilterProgram,
    filterChecklist,
    setFilterChecklist,
    filterPlanningForm1,
    setFilterPlanningForm1,
    filterPlanningForm2,
    setFilterPlanningForm2,
    filterScholarship,
    setFilterScholarship,
    filterAftercare,
    setFilterAftercare,
    aftercareList,
    aftercareSpecialist,
    setAftercareSpecialist,
    setAftercareSpecialistName,
    showDialog,
    handleClose,
  } = props;

  const formClasses = formStyles();

  const setAftercareSpecialistInfo = (event) => {
    setAftercareSpecialist(event.target.value);
    if (event.target.value > 0) {
      const spec = aftercareList.find((x) => x.id === event.target.value);
      setAftercareSpecialistName(spec.user.first_name + ' ' + spec.user.last_name);
    } else {
      setAftercareSpecialistName('');
    }
  };

  const clearAllFilters = () => {
    setFilterAll(true);
    setFilterStatus('');
    setFilterLoggedIn('');
    setFilterProgram('');
    setFilterChecklist('');
    setFilterPlanningForm1('');
    setFilterPlanningForm2('');
    setFilterScholarship('');
    setFilterAftercare('');
    setFilterSite('');
  };

  return (
    <React.Fragment>
      <Dialog open={showDialog} onClose={handleClose} scroll="paper" aria-labelledby="Expanded Filter Menu">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeaderFooter}>
            Expanded Filter Options
          </DialogTitle>
          <summary className={formClasses.dialogExplain}>
            Filter senior list by Successful Futures Specialist and/or progess in the senior planning process.
          </summary>
          <DialogContent className={formClasses.dialog} style={{ minHeight: 250 }}>
            <Box sx={{ width: '100%' }}>
              <Grid container direction="row">
                <Grid item xs={12} sm={6} md={5} lg={4} className={formClasses.labelInlineEditSpacer}>
                  <label>Successful Futures Specialist</label>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={8} className={formClasses.textEditSpacer}>
                  <FormControl fullWidth>
                    <Select
                      labelId="aftercare-specialist-label"
                      id="aftercare-specialist"
                      value={aftercareSpecialist}
                      onChange={(event) => setAftercareSpecialistInfo(event)}
                    >
                      <MenuItem value={0}>All Specialists</MenuItem>
                      {aftercareList &&
                        aftercareList
                          .slice()
                          .sort((a, b) => {
                            return a.user.first_name > b.user.first_name ? 1 : -1;
                          })
                          .map((specialist) => {
                            return (
                              <MenuItem value={specialist.id}>
                                {specialist.user.first_name} {specialist.user.last_name}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={formClasses.filterOptionGroup}>
                  <FormControl fullWidth>
                    <RadioGroup aria-label="filter-all" name="filter-all" value={filterAll} onChange={(event) => clearAllFilters()}>
                      <div className={formClasses.controlSet}>
                        <FormControlLabel
                          value="all"
                          control={<Radio color="default" />}
                          label={<span className={formClasses.radioLabel}>All (Clear All Filters)</span>}
                          style={{ marginLeft: '5px' }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-status"
                      name="filter-status"
                      value={filterStatus}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterStatus(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Status</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="active"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Active</span>}
                            />
                            <FormControlLabel
                              value="inactive"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Inactive</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterStatus('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-loggedin"
                      name="filter-loggedin"
                      value={filterLoggedIn}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterLoggedIn(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Logged In</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="loggedin"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Logged In</span>}
                            />
                            <FormControlLabel
                              value="neverloggedin"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Never Logged In</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterLoggedIn('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-site"
                      name="filter-site"
                      value={filterSite}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterSite(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Location</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="homecampus"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Home Campus</span>}
                            />
                            <FormControlLabel
                              value="sites"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Other Sites</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterSite('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-program"
                      name="filter-program"
                      value={filterProgram}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterProgram(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Program</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="FFS"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>FFS</span>}
                            />
                            <FormControlLabel
                              value="FHP"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>FHP</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterProgram('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-checklist"
                      name="filter-checklist"
                      value={filterChecklist}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterChecklist(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Checklist</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="checklistinprogress"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>In Progress</span>}
                            />
                            <FormControlLabel
                              value="checklistsubmitted"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Submitted</span>}
                            />
                            <FormControlLabel
                              value="checklistapproved"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Approved</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterChecklist('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-planningform1"
                      name="filter-planningform1"
                      value={filterPlanningForm1}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterPlanningForm1(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Senior Planning Form 1</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="planning1inprogress"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>In Progress</span>}
                            />
                            <FormControlLabel
                              value="planning1submitted"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Submitted</span>}
                            />
                            <FormControlLabel
                              value="planning1approved"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Approved</span>}
                            />
                            <FormControlLabel
                              value="planning1followup"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Needs Follow-up</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterPlanningForm1('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-planningform2"
                      name="filter-planningform2"
                      value={filterPlanningForm2}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterPlanningForm2(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Senior Planning Form 2</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="planning2inprogress"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>In Progress</span>}
                            />
                            <FormControlLabel
                              value="planning2submitted"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Submitted</span>}
                            />
                            <FormControlLabel
                              value="planning2approved"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Approved</span>}
                            />
                            <FormControlLabel
                              value="planning2followup"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Needs Follow-up</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterPlanningForm2('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-scholarship"
                      name="filter-scholarship"
                      value={filterScholarship}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterScholarship(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Scholarship Application</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="notstartedscholarship"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Not Started</span>}
                            />
                            <FormControlLabel
                              value="scholarshipinprogress"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>In Progress</span>}
                            />
                            <FormControlLabel
                              value="submittedscholarship"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Submitted</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterScholarship('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <RadioGroup
                      aria-label="filter-aftercare"
                      name="filter-aftercare"
                      value={filterAftercare}
                      onChange={(event) => {
                        setFilterOption('');
                        setFilterAftercare(event.target.value);
                      }}
                    >
                      <fieldset style={{ marginBottom: '15px' }}>
                        <legend>Successful Futures Application</legend>
                        <div className={formClasses.controlSet}>
                          <span>
                            <FormControlLabel
                              value="notstartedaftercareapp"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Not Started</span>}
                            />
                            <FormControlLabel
                              value="aftercareappinprogress"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>In Progress</span>}
                            />
                            <FormControlLabel
                              value="submittedaftercareapp"
                              control={<Radio color="default" />}
                              label={<span className={formClasses.radioLabel}>Submitted</span>}
                            />
                          </span>
                          <IconButton color="inherit" aria-label="clear status filter" onClick={(e) => setFilterAftercare('')}>
                            <CloseIcon className={formClasses.closeIcon} style={{ color: webPrimaryColor }} />
                          </IconButton>
                        </div>
                      </fieldset>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </React.Fragment>
        <DialogActions className={formClasses.dialogHeaderFooter}>
          <Button className={formClasses.buttonWidth} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default FilterDialog;

FilterDialog.propTypes = {
  filterOption: PropTypes.string,
  setFilterOption: PropTypes.func,
  filterAll: PropTypes.string,
  setFilterAll: PropTypes.func,
  filterStatus: PropTypes.string,
  setFilterStatus: PropTypes.func,
  filterLoggedIn: PropTypes.string,
  setFilterLoggedIn: PropTypes.func,
  filterSite: PropTypes.string,
  setFilterSite: PropTypes.func,
  filterProgram: PropTypes.string,
  setFilterProgram: PropTypes.func,
  filterChecklist: PropTypes.string,
  setFilterChecklist: PropTypes.func,
  filterPlanningForm1: PropTypes.string,
  setFilterPlanningForm1: PropTypes.func,
  filterPlanningForm2: PropTypes.string,
  setFilterPlanningForm2: PropTypes.func,
  filterScholarship: PropTypes.string,
  setFilterScholarship: PropTypes.func,
  filterAftercare: PropTypes.string,
  setFilterAftercare: PropTypes.func,
  aftercareList: PropTypes.array,
  aftercareSpecialist: PropTypes.number,
  aftercareSpecialistName: PropTypes.string,
  showDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
