import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './services/msal';
import AuthStore from './stores/AuthStore';
import SeniorStore from './stores/SeniorListIndexStore';
import { Provider } from 'mobx-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const stores = {
  AuthStore: new AuthStore(msalInstance),
  SeniorStore: new SeniorStore(),
};

// Production redirect if aftercare.boystown.org
const url = window.location.href;
if (url.match(/aftercare.boystown.org/gi)) {
  window.location.href = 'https://successfulfutures.boystown.org';
}

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider {...stores}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
