import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  table: {
    width: '100%',
    padding: 0,
  },
  deleteButton: {
    marginTop: -5,
  },
  smallButton: {
    padding: 10,
  },
  smallIcon: {
    fontSize: '1.00rem',
  },
  addButton: {
    padding: 5,
    color: btNavyBlueColor,
  },
}));

const ExpandingListWithList = ({
  form,
  setForm,
  listname,
  field1,
  field1Placeholder,
  field1Cols,
  field1MaxLength,
  field2,
  field2Cols,
  field2MaxLength,
  rowsRequired,
  maxRowsAllowed,
  disabled,
  title,
  list,
}) => {
  const classes = styles();
  if (!maxRowsAllowed) maxRowsAllowed = 20;

  const handleChange = (idx, field) => (e) => {
    const rows = [...form[listname]];
    rows[idx][field] = e.target.value;
    setForm({ ...form, [listname]: rows });
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const rows = [...form[listname]];
    rows.splice(idx, 1);
    setForm({ ...form, [listname]: rows });
  };

  const handleAddRow = () => {
    const rows = [...form[listname]];
    if (rows.length < maxRowsAllowed) {
      let newItem = {};
      newItem[field1] = '';
      newItem[field2] = '';
      rows.push(newItem);
      setForm({ ...form, [listname]: rows });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <div>{title}</div>
        {form[listname] && form[listname].length < maxRowsAllowed && (
          <IconButton aria-label="Add another item" color="inherit" onClick={handleAddRow} disabled={disabled} className={classes.addButton}>
            <Tooltip title="Click add a scholarship or incentive." placement="bottom">
              <AddBoxIcon />
            </Tooltip>
          </IconButton>
        )}
      </div>

      <Box sx={{ width: '95%' }}>
        {form[listname] &&
          form[listname].length > 0 &&
          form[listname].map((item, idx) => (
            <Grid container direction="row" className={classes.listRowSpacer} key={`${idx}-${listname}`}>
              <Grid item xs={field1Cols + 3} sm={field1Cols + 3} md={field1Cols + 2} lg={field1Cols}>
                <FormControl fullWidth style={{ paddingRight: 5 }}>
                  <TextField
                    id="list-item"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={item[field1]}
                    onChange={handleChange(idx, field1)}
                    placeholder={field1Placeholder}
                    disabled={disabled}
                    InputProps={{ maxLength: field1MaxLength, style: { height: 35, margin: '2px 0' } }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={field2Cols + 3} sm={field2Cols + 3} md={field2Cols + 2} lg={field2Cols} style={{ paddingLeft: 10 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="list-item-label"
                    id="list-item"
                    className={classes.valignSelect}
                    value={item[field2]}
                    onChange={handleChange(idx, field2)}
                    disabled={disabled}
                  >
                    <MenuItem value="0"></MenuItem>
                    {list.map((l, i) => {
                      return (
                        <MenuItem key={i} value={l}>
                          {l}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} style={{ paddingTop: '5px' }}>
                {idx >= rowsRequired && (
                  <IconButton
                    className={[classes.smallButton, classes.deleteButton].join(' ')}
                    onClick={handleRemoveSpecificRow(idx)}
                    disabled={disabled}
                    aria-label="remove item from list"
                    color="inherit"
                  >
                    <CloseIcon className={classes.smallIcon} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
      </Box>
    </>
  );
};

ExpandingListWithList.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  listname: PropTypes.string,
  field1: PropTypes.string,
  field1Placeholder: PropTypes.string.isRequired,
  field1Cols: PropTypes.number,
  field1MaxLength: PropTypes.number,
  field2: PropTypes.string,
  field2Cols: PropTypes.number,
  field2MaxLength: PropTypes.number,
  rowsRequired: PropTypes.number,
  maxRowsAllowed: PropTypes.number,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default ExpandingListWithList;
