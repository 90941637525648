import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { msalConfig } from '../../services/msal';
import { makeStyles } from '@material-ui/core/styles';
import {
  webInfoColor,
  whiteColor,
  btOrangeColor,
  webPrimaryColor,
  webSecondaryColor,
  webLighterBlue,
} from '../../assets/jss/nextjs-material-kit-pro.js';
import Link from '@material-ui/core/Link';
import Button from '../CustomButtons/Button.js';
import '../../assets/css/nextjs-material-kit-pro.css';
import { Card, CardContent, Typography, Tooltip } from '@material-ui/core';
import HowCanWeHelpBanner from '../../assets/img/howcanwehelp-banner.png';
import ScholarshipsIcon from '../../assets/img/help-scholarships-icon.svg';
import FinancialIcon from '../../assets/img/help-financial-icon.svg';
import TradeLifeIcon from '../../assets/img/help-tradelife-icon.svg';
import HousingIcon from '../../assets/img/help-housing-icon.svg';
import EmploymentIcon from '../../assets/img/help-employment-icon.svg';
import SomethingElseIcon from '../../assets/img/help-somethingelse-icon.svg';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  helpCards: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardGrid: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      gap: '3em 8.00em',
      margin: '0 10% 0 10%',
    },
    [theme.breakpoints.down('md')]: {
      gap: '1em 4.00em',
      margin: '0 5% 0 10%',
    },
  },
  cardIcon: {
    position: 'absolute',
    top: '-5px',
    left: '-10%',
  },
  cardContainer: {
    cursor: 'pointer',
    width: '330px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      margin: '5%',
    },
  },
  cardSize: {
    height: '75px',
    borderRadius: '12px',
    backgroundColor: webLighterBlue,
  },
  cardTitle: {
    color: whiteColor,
    fontFamily: '"Source Sans Pro", Helvetica, Roboto, Arial, sans-serif',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.20rem',
      lineHeight: '1.55rem',
      margin: '9px 0 10px 40px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.20rem',
      lineHeight: '1.55rem',
      margin: '9px 0 10px 40px',
    },
  },
  cardText: {
    color: webPrimaryColor,
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    textDecoration: 'none !important',
    '&:hover,&:focus': {
      textDecoration: 'none !important',
      color: webPrimaryColor,
    },
  },
  cardParagraph: {
    margin: '5px 0 5px 0',
    textDecoration: 'none !important',
    '&:hover,&:focus': {
      textDecoration: 'none !important',
      color: webPrimaryColor,
    },
  },
  signInButton: {
    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '102vw',
      margin: '10px 0 40px -5px',
    },
  },
  intro: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '40px 15% 5% 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '25px 10% 10% 10%',
    },
  },
  breadcrumb: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '10px 15% 10px 15%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  banner: {
    display: 'block',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  emailContact: {
    fontSize: '0.90rem',
  },
  phoneContact: {
    margin: '5px 0 5px 0',
  },
  bannerImage: {
    width: '100%',
  },
  relativePosition: {
    position: 'relative',
  },
  titleBannerContainer: {
    position: 'relative',
  },
  link: {
    color: webInfoColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  titleBanner: {
    position: 'absolute',
    backgroundColor: 'rgba(36, 62, 93, 0.4)',
    textAlign: 'left',
    height: '122px',
    minWidth: '100%',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      left: 0,
      top: '-170px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine1: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: btOrangeColor,
    fontSize: '2.50rem',
    lineHeight: '3.563rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-160px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine2: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: whiteColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '2.625rem',
    lineHeight: '3.438rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-115px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  nextStepsTitle: {
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: webSecondaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '5% 15% 0 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '3% 10% 5% 10%',
    },
  },
  nextSteps: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '10px 15% 4% 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '3% 10% 12% 10%',
    },
  },
  largeTooltip: {
    fontSize: '0.80rem',
    lineHeight: '1.00rem',
    backgroundColor: '#fff',
    color: webPrimaryColor,
  },
  customTooltip: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${webPrimaryColor}`,
    color: webPrimaryColor,
    boxShadow: '0px 10px 8px rgba(0,0,0,0.4)',
    padding: '15px',
    textAlign: 'center',
    marginTop: '-2px',
  },
  customArrow: {
    fontSize: 24,
    color: '#c3c3c3',
    '&::before': {
      backgroundColor: whiteColor,
      border: `.5px solid ${webPrimaryColor}`,
      borderRadius: '2px 0',
    },
  },
}));

const HowCanWeHelp = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const classes = useStyles();

    useEffect(() => {
      const handleLogin = async () => {
        await authStore.handleUserLogin(accounts[0]);
      };
      if (isAuthenticated && authStore.user == null) {
        console.log('authStore.user', authStore.user);
        handleLogin();
      }
    }, [accounts, authStore, isAuthenticated]);

    const handleSignIn = async () => {
      await instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
      const account = accounts[0] || null;
      authStore.handleUserLogin(account);
    };

    return (
      <div className={classes.root}>
        <main>
          <div className={classes.relativePosition}>
            <div className={classes.banner}>
              <img src={HowCanWeHelpBanner} alt="How Can We Help Banner" className={classes.bannerImage} />
              <div className={classes.titleBannerContainer}>
                <div className={classes.titleBanner}></div>
                <div className={classes.titleBannerLine1}>How Can</div>
                <div className={classes.titleBannerLine2}>We Help You?</div>
              </div>
            </div>
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumbs separator={<PlayArrowIcon style={{ fontSize: '1.50rem', color: webInfoColor }} />} aria-label="breadcrumb">
              <Link href="/home">
                <HomeIcon style={{ marginTop: '5px', fontSize: '1.80rem', color: webInfoColor }} />
              </Link>
              <div style={{ color: webSecondaryColor }}>How Can We Help?</div>
            </Breadcrumbs>
          </div>
          <p className={classes.intro}>
            Successful Futures is designed to assist you in making and implementing informed educational and occupational choices. This includes
            coordinating the distribution of need-based and merit scholarships to outstanding and qualified students pursuing undergraduate and trade
            degrees, and coordinating the implementation of learning experiences (internships, apprenticeships, shadowing, volunteering, and other
            career-oriented opportunities) to improve career decision making. We know you have many questions. We have the answers.{' '}
            <span className={classes.link} onClick={(e) => handleSignIn()}>
              Click here to sign in or create an account to get started
            </span>
            !
          </p>
          <div className={classes.helpCards}>
            <div id="cards" className={classes.cardGrid}>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div className={classes.largeTooltip}>
                    Successful Futures provides need-based and merit scholarships to alumni pursuing undergraduate and trade degrees. Click here to
                    see if you qualify!
                  </div>
                }
                placement="bottom"
                arrow
              >
                <summary className={classes.cardContainer} align="left">
                  <img src={ScholarshipsIcon} alt="Scholarship Applications" className={classes.cardIcon} />
                  <Link href="/scholarships" style={{ textDecoration: 'none' }}>
                    <Card className={classes.cardSize}>
                      <CardContent>
                        <Typography gutterBottom component="div" className={classes.cardTitle}>
                          Scholarship Applications
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </summary>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div className={classes.largeTooltip}>
                    When you are struggling to keep up with daily living expenses such as food, utilities, or something else, Successful Futures may
                    be able to help! Click here to sign in or create an account to access services!
                  </div>
                }
                placement="bottom"
                arrow
              >
                <summary className={classes.cardContainer} align="left" onClick={(e) => handleSignIn()}>
                  {/* <Link href="/financial" style={{ textDecoration: 'none' }}> */}
                  <img src={FinancialIcon} alt="Financial Assistance" className={classes.cardIcon} />
                  <Card className={classes.cardSize}>
                    <CardContent>
                      <Typography gutterBottom component="div" className={classes.cardTitle}>
                        Financial Assistance
                      </Typography>
                    </CardContent>
                  </Card>
                  {/* </Link> */}
                </summary>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div className={classes.largeTooltip}>
                    Trade Life services provides life skills training and other supports to alumni as they transition to independent living. Click
                    here to find out more and see if you qualify!
                  </div>
                }
                placement="bottom"
                arrow
              >
                <summary className={classes.cardContainer} align="left">
                  <Link href="/tradelife" style={{ textDecoration: 'none' }}>
                    <img src={TradeLifeIcon} alt="Trade Life" className={classes.cardIcon} />
                    <Card className={classes.cardSize}>
                      <CardContent>
                        <Typography gutterBottom component="div" className={classes.cardTitle}>
                          Trade Life
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </summary>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div className={classes.largeTooltip}>
                    Successful Futures housing services support Family Home Program graduates, ages 17-21, by providing safe and affordable housing.
                    Click here to learn more and see if you qualify!
                  </div>
                }
                placement="bottom"
                arrow
              >
                <summary className={classes.cardContainer} align="left">
                  <Link href="/housing" style={{ textDecoration: 'none' }}>
                    <img src={HousingIcon} alt="Housing" className={classes.cardIcon} />
                    <Card className={classes.cardSize}>
                      <CardContent>
                        <Typography gutterBottom component="div" className={classes.cardTitle}>
                          Housing
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </summary>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div className={classes.largeTooltip}>
                    When you need help writing your first resume, preparing for an interview, or finding the right place to work, let Boys Town's
                    Successful Futures team help you! Click here to sign in or create an account and talk to a specialist!
                  </div>
                }
                placement="bottom"
                arrow
              >
                <summary className={classes.cardContainer} align="left" onClick={(e) => handleSignIn()}>
                  {/* <Link href="/employment" style={{ textDecoration: 'none' }}> */}
                  <img src={EmploymentIcon} alt="Employment Assistance" className={classes.cardIcon} />
                  <Card className={classes.cardSize}>
                    <CardContent>
                      <Typography gutterBottom component="div" className={classes.cardTitle}>
                        Employment Assistance
                      </Typography>
                    </CardContent>
                  </Card>
                  {/* </Link> */}
                </summary>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div className={classes.largeTooltip}>
                    Successful Futures supports Boys Town Alumni during times of need. When you need financial assistance, educational/occupational
                    support, or other life skills training, we want to help! Click here to sign in or create an account to get started!
                  </div>
                }
                placement="bottom"
                arrow
              >
                <summary className={classes.cardContainer} align="left" onClick={(e) => handleSignIn()}>
                  {/* <Link href="/somethingelse" style={{ textDecoration: 'none' }}> */}
                  <img src={SomethingElseIcon} alt="Something Else" className={classes.cardIcon} />
                  <Card className={classes.cardSize}>
                    <CardContent>
                      <Typography gutterBottom component="div" className={classes.cardTitle}>
                        I Need Something Else
                      </Typography>
                    </CardContent>
                  </Card>
                  {/* </Link> */}
                </summary>
              </Tooltip>
            </div>
          </div>
          <div className={classes.nextStepsTitle}>Next Steps</div>
          <p className={classes.nextSteps}>
            With a strong focus on senior planning, proactive support and workforce development, Successful Futures provides an invaluable resource
            for young adults as they transition to new levels of independence. If you would like help in any of these areas,{' '}
            <span className={classes.link} onClick={(e) => handleSignIn()}>
              please create an account or sign in to your account
            </span>
            .<br />
            <br />
            <strong>Helpful Information</strong>
            <br />
            <br />
            If you are in a crisis situation and need immediate assistance, call the Boys Town National Hotline at 800-448-3000.
            <br />
            <br />
            <br />
            For questions regarding medical records, please contact the Boys Town National Research Hospital at (531) 355-6589 or visit{' '}
            <span className={classes.link}>
              <a href="https://www.boystownhospital.org/medical-records" target="_blank" rel="noreferrer">
                Medical Records
              </a>
            </span>
            . You can also email the hospital to request your records at:{' '}
            <span className={classes.link}>
              <a href="mailto: medical.records@boystownhospital.org">medical.records@boystownhospital.org</a>
            </span>
            . You will need to provide the name and address of your new medical provider for this department to forward your records.
            <br />
            <br />
            <br />
            If you have a juvenile record and are wanting to have your records sealed, you can contact Legal Aid of Nebraska or visit the link below
            for more information.
            <br />
            <br />
            <span className={classes.link}>
              <a
                href="https://supremecourt.nebraska.gov/self-help/court-records/filing-motion-seal-juvenile-criminal-record"
                target="_blank"
                rel="noreferrer"
              >
                Filing A Motion to Seal Juvenile Criminal Record
              </a>
            </span>
            <br />
            <br />
            <br />
            If you need a birth certificate, this link will help guide you in obtaining a replacement birth certificate:
            <br />
            <br />
            <span className={classes.link}>
              <a href="https://www.dmv.org/vital-records/birth-certificates.php" target="_blank" rel="noreferrer">
                How to Get a Copy of Your Lost Birth Certificate | DMV.ORG
              </a>
            </span>
            <br />
            <br />
            <br />
            If you are in need of a social security card, this link will help guide you in obtaining a replacement social security card:
            <br />
            <br />
            <span className={classes.link}>
              <a href="https://www.ssa.gov/number-card" target="_blank" rel="noreferrer">
                Social Security Number & Card | SSA
              </a>
            </span>
            <br />
            <br />
            <br />
            To request Boys Town transcripts or other youth care records, call (531) 355-1800.
          </p>
          <Button color="primary" className={classes.signInButton} onClick={(e) => handleSignIn()}>
            Register / Sign In
          </Button>
        </main>
      </div>
    );
  })
);

export default HowCanWeHelp;
