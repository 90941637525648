import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Grid, FormControl, FormControlLabel } from '@material-ui/core';
import { btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  checklistRow: {
    margin: '10px 0',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checklistColumn: {
    alignItems: 'center',
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  pointsLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  dueLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    verticalAlign: 'top',
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,

    marginTop: 5,
  },
  underline: {
    margin: '5px 0',
    borderBottom: '1px solid #000',
  },
  checkOutline: {
    border: '1px solid #000',
    borderRadius: 6,
    maxWidth: '35px',
    alignContent: 'center',
    minWidth: '35px',
  },
}));

export default function JobCorpsItems(props) {
  const { form, setForm, auth, alumni } = props;

  const classes = styles();

  const isFieldDisabled = (val) => {
    if (alumni) return true;
    return false;
  };

  const isConfirmDisabled = (val) => {
    if (alumni || auth.roles.includes('STUDENT')) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="55. Attend the Job Corps / AmeriCorps information meeting."
              disabled={isFieldDisabled(form.confirmJobcorpsMeeting)}
              checked={form.jobcorpsMeeting}
              onChange={(event) => setForm({ ...form, jobcorpsMeeting: !form.jobcorpsMeeting })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>6</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.jobcorpsMeeting)}
            checked={form.confirmJobcorpsMeeting}
            onChange={(event) => setForm({ ...form, confirmJobcorpsMeeting: !form.confirmJobcorpsMeeting })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="55. Attend the Job Corps / AmeriCorps information meeting."
              checked={form.jobcorpsMeeting}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            6<Checkbox color="default" disabled={true} checked={form.confirmJobcorpsMeeting}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="56. Identify a possible career path to follow within the Job Corps/AmeriCorps."
              disabled={isFieldDisabled(form.confirmJobcorpsCareerPath)}
              checked={form.jobcorpsCareerPath}
              onChange={(event) => setForm({ ...form, jobcorpsCareerPath: !form.jobcorpsCareerPath })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>7</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.jobcorpsCareerPath)}
            checked={form.confirmJobcorpsCareerPath}
            onChange={(event) => setForm({ ...form, confirmJobcorpsCareerPath: !form.confirmJobcorpsCareerPath })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="56. Identify a possible career path to follow within the Job Corps/AmeriCorps."
              checked={form.jobcorpsCareerPath}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            7<Checkbox color="default" disabled={true} checked={form.confirmJobcorpsCareerPath}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="57. Explore and visit the site for which you might want to apply."
              disabled={isFieldDisabled(form.confirmJobcorpsVisitSite)}
              checked={form.jobcorpsVisitSite}
              onChange={(event) => setForm({ ...form, jobcorpsVisitSite: !form.jobcorpsVisitSite })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>6</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.jobcorpsVisitSite)}
            checked={form.confirmJobcorpsVisitSite}
            onChange={(event) => setForm({ ...form, confirmJobcorpsVisitSite: !form.confirmJobcorpsVisitSite })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="57. Explore and visit the site for which you might want to apply."
              checked={form.jobcorpsVisitSite}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            6<Checkbox color="default" disabled={true} checked={form.confirmJobcorpsVisitSite}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="58. Meet with a Job Corps / AmeriCorps recruiter."
              disabled={isFieldDisabled(form.confirmJobcorpsRecruiter)}
              checked={form.jobcorpsRecruiter}
              onChange={(event) => setForm({ ...form, jobcorpsRecruiter: !form.jobcorpsRecruiter })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>7</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.jobcorpsRecruiter)}
            checked={form.confirmJobcorpsRecruiter}
            onChange={(event) => setForm({ ...form, confirmJobcorpsRecruiter: !form.confirmJobcorpsRecruiter })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="58. Meet with a Job Corps / AmeriCorps recruiter."
              checked={form.jobcorpsRecruiter}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            7<Checkbox color="default" disabled={true} checked={form.confirmJobcorpsRecruiter}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="59. Complete the application packet, including all documentation."
              disabled={isFieldDisabled(form.confirmJobcorpsCompleteApplication)}
              checked={form.jobcorpsCompleteApplication}
              onChange={(event) => setForm({ ...form, jobcorpsCompleteApplication: !form.jobcorpsCompleteApplication })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>8</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.jobcorpsCompleteApplication)}
            checked={form.confirmJobcorpsCompleteApplication}
            onChange={(event) => setForm({ ...form, confirmJobcorpsCompleteApplication: !form.confirmJobcorpsCompleteApplication })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="59. Complete the application packet, including all documentation."
              checked={form.jobcorpsCompleteApplication}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            8<Checkbox color="default" disabled={true} checked={form.confirmJobcorpsCompleteApplication}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="60. Set date of Job Corps/AmeriCorps admission."
              disabled={isFieldDisabled(form.confirmJobcorpsAdmissionDate)}
              checked={form.jobcorpsAdmissionDate}
              onChange={(event) => setForm({ ...form, jobcorpsAdmissionDate: !form.jobcorpsAdmissionDate })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>6</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.jobcorpsAdmissionDate)}
            checked={form.confirmJobcorpsAdmissionDate}
            onChange={(event) => setForm({ ...form, confirmJobcorpsAdmissionDate: !form.confirmJobcorpsAdmissionDate })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="60. Set date of Job Corps/AmeriCorps admission."
              checked={form.jobcorpsAdmissionDate}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            6<Checkbox color="default" disabled={true} checked={form.confirmJobcorpsAdmissionDate}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

JobCorpsItems.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  auth: PropTypes.object,
  alumni: PropTypes.bool.isRequired,
};
