import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { dateUtcToS } from '../../../util/dateUtil';

const CurrentEmployment = ({ form, setForm, classes, disabled }) => {
  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12}>
          <section align="left" className={classes.sectionTitle}>
            Current Employment
          </section>
        </Grid>

        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={form.notCurrentlyEmployed ? form.notCurrentlyEmployed : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    setForm({ ...form, notCurrentlyEmployed: true, currentEmployer: '' });
                  } else {
                    setForm({ ...form, notCurrentlyEmployed: ev.target.checked });
                  }
                }}
              />
            }
            label="Not currently employed"
          />
        </Grid>
        {!form.notCurrentlyEmployed && (
          <>
            <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
              <label>Employer</label>
            </Grid>
            <Grid item xs={11} md={9} className={classes.rowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="current-employer"
                  size="small"
                  hiddenLabel
                  placeholder="Current employer"
                  variant="outlined"
                  value={form.currentEmployer}
                  onChange={(event) => setForm({ ...form, currentEmployer: event.target.value })}
                  InputProps={{ maxLength: 100 }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
              <label>Start Date</label>
            </Grid>
            <Grid item xs={1} sm={true} className={classes.rowSpacer} />
            <Grid item xs={11} sm={10} className={classes.rowSpacer}>
              {!disabled ? (
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="employer-start-date"
                    disableFuture
                    hiddenLabel
                    value={form.currentEmployerStartDate || null}
                    onChange={(date) => {
                      setForm({ ...form, currentEmployerStartDate: date ? date : null });
                    }}
                    format="MM/dd/yyyy"
                    className={classes.datepicker}
                    disabled={disabled}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                  {dateUtcToS(form.currentEmployerStartDate || null)}
                </summary>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12}>
          <section align="left" className={classes.sectionTitle}>
            Current Employment
          </section>
        </Grid>
        <Grid item xs={6} md={2} className={classes.textRowLabelSpacer}>
          <label>Employer</label>
        </Grid>
        <Grid item xs={6} md={4} className={classes.textRowSpacer}>
          {form.notCurrentlyEmployed && <span>Not currently employed.</span>}
          {!form.notCurrentlyEmployed && <span>{form.currentEmployer}</span>}
        </Grid>
        <Grid item xs={6} md={2} className={classes.textRowLabelSpacer}>
          <label>Start Date</label>
        </Grid>
        <Grid item xs={6} md={4} className={classes.textRowSpacer}>
          {dateUtcToS(form.currentEmployerStartDate || null)}
        </Grid>
      </Grid>
    </>
  );
};

CurrentEmployment.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CurrentEmployment;
