import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField } from '@material-ui/core';

const EstimatedExpenses = ({ form, setForm, classes, disabled }) => {
  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12}>
          <section align="left" className={classes.sectionTitle}>
            Estimated Expenses
          </section>
        </Grid>

        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Expenses Description</label>
        </Grid>
        <Grid item xs={11} md={9} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="estimated-expenses"
              size="small"
              hiddenLabel
              title="Describe yearly expenses including tuition, travel and housing"
              placeholder="Describe yearly expenses including tuition, travel and housing"
              variant="outlined"
              value={form.estimatedExpenses}
              onChange={(event) => setForm({ ...form, estimatedExpenses: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={6} md={2} className={classes.textRowLabelSpacer}>
          <label>Expenses Description</label>
        </Grid>
        <Grid item xs={6} md={4} className={classes.textRowSpacer}>
          {form.estimatedExpenses}
        </Grid>
      </Grid>
    </>
  );
};

EstimatedExpenses.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default EstimatedExpenses;
