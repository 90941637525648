import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, Grid, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Tooltip } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandingWorkHistory from './ExpandingWorkHistory/ExpandingWorkHistory';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  marginRight30: {
    marginRight: 30,
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  marginTop15: {
    marginTop: 15,
  },
  expandingRowSpacer: {
    height: '140px',
    minHeight: '140px',
    overflow: 'auto',
  },
  hint: {
    fontSize: '0.80rem',
    fontWeight: 500,
  },
}));

export default function CareerInterests(props) {
  const { form, setForm, disabled, handleWorkHistoryRow } = props;

  const classes = styles();

  const [totalPlans, setTotalPlans] = useState('');
  const planLimit = 2;

  useEffect(() => {
    let totalPlansSelected = 0;
    if (form.plansCommunityCollege) totalPlansSelected += 1;
    if (form.plans4YearCollege) totalPlansSelected += 1;
    if (form.plansVocationalTraining) totalPlansSelected += 1;
    if (form.plansOther) totalPlansSelected += 1;
    if (form.plansMilitary) totalPlansSelected += 1;
    if (form.plansWorkforce) totalPlansSelected += 1;
    if (form.plansJobCorps) totalPlansSelected += 1;
    setTotalPlans(totalPlansSelected);
  }, [form]);

  return (
    <Box sx={{ width: '95%' }}>
      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="career-interests"
                size="small"
                fullWidth
                label="Explain your career interests."
                variant="outlined"
                value={form.careerInterests}
                onChange={(event) => setForm({ ...form, careerInterests: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 1000, classes: { input: classes.textField } }}
                className={classes.root}
              />
            </FormControl>
          </Grid>
        </Grid>
        <summary className={classes.printedContent}>
          <label>Career Interests</label>
        </summary>
        <summary className={classes.printedContent}>{form.careerInterests || null}</summary>
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowLabelSpacer}>
            <label>
              Work History{' '}
              <Tooltip title="Click to add a row." placement="top">
                <span>
                  <IconButton
                    key="add"
                    aria-label="Add a work history row"
                    color="inherit"
                    onClick={handleWorkHistoryRow}
                    className={classes.addButton}
                    disabled={disabled}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </label>{' '}
          </Grid>
          <Grid item xs={12} sm={5} md={3} lg={3} className={classes.textRowLabelSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="No Work History"
                disabled={disabled}
                checked={form.noWorkHistory}
                onChange={(event) => setForm({ ...form, noWorkHistory: !form.noWorkHistory })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={2} md={5} lg={6}></Grid>
        </Grid>

        {!form.noWorkHistory && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} className={classes.expandingRowSpacer}>
                <ExpandingWorkHistory form={form} setForm={setForm} listname="workHistory" rowsRequired={3} disabled={disabled} />
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={6} sm={4}></Grid>
              <Grid item xs={6} sm={8}>
                {!disabled && (
                  <label className={classes.hint}>
                    Hint: Dates can be typed instead of selected from the tool. If current employer, leave end date empty.
                  </label>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <summary className={classes.printedContent}>
          <label>Work History</label>
        </summary>
        {form.noWorkHistory ? (
          <summary className={classes.printedContent}>No work history.</summary>
        ) : (
          <summary className={classes.printedContent}>
            <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }}>
              <tbody>
                {form.workHistory &&
                  form.workHistory.length > 0 &&
                  form.workHistory.map((work, idx) => {
                    if (work.employer && work.employer.length > 0) {
                      return (
                        <tr key={idx}>
                          <td style={{ width: '5%' }}></td>
                          <td style={{ width: '30%', verticalAlign: 'top' }}>
                            {work.startDate} - {work.endDate}
                          </td>
                          <td style={{ width: '65%', verticalAlign: 'top' }}>{work.employer}</td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </summary>
        )}
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textRowLabelSpacer}>
            <label>What are/were your post-graduation plans? (Check up to {planLimit} options.)</label>
          </Grid>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Community College"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plansCommunityCollege === false)}
                checked={form.plansCommunityCollege}
                onChange={(event) => setForm({ ...form, plansCommunityCollege: !form.plansCommunityCollege })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Military"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plansMilitary === false)}
                checked={form.plansMilitary}
                onChange={(event) => setForm({ ...form, plansMilitary: !form.plansMilitary })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={1} md={3} lg={3} />
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="4-Year College"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plans4YearCollege === false)}
                checked={form.plans4YearCollege}
                onChange={(event) => setForm({ ...form, plans4YearCollege: !form.plans4YearCollege })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Workforce (full time)"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plansWorkforce === false)}
                checked={form.plansWorkforce}
                onChange={(event) => setForm({ ...form, plansWorkforce: !form.plansWorkforce })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={1} md={3} lg={3} />
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Vocational Training"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plansVocationalTraining === false)}
                checked={form.plansVocationalTraining}
                onChange={(event) => setForm({ ...form, plansVocationalTraining: !form.plansVocationalTraining })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Job Corps/AmeriCorps"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plansJobCorps === false)}
                checked={form.plansJobCorps}
                onChange={(event) => setForm({ ...form, plansJobCorps: !form.plansJobCorps })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={false} md={4} lg={4} />
        </Grid>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} sm={1} md={1} lg={1} />
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label="Other"
                disabled={disabled || (totalPlans > planLimit - 1 && form.plansOther === false)}
                checked={form.plansOther}
                onChange={(event) => setForm({ ...form, plansOther: !form.plansOther })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}></Grid>
        </Grid>
        {form.plansOther && (
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={false} sm={1} md={1} lg={1} />
            <Grid item xs={11} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="plans-other-explain"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  placeholder="Please explain your other plans."
                  value={form.plansOtherExplain}
                  onChange={(event) => setForm({ ...form, plansOtherExplain: event.target.value })}
                  disabled={disabled}
                  InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <summary className={classes.printedContent}>
          <label>What are/were your post-graduation plans?</label>
        </summary>
        <summary className={classes.printedContent}>{form.plansCommunityCollege && 'Community College'}</summary>
        <summary className={classes.printedContent}>{form.plansMilitary && 'Military'}</summary>
        <summary className={classes.printedContent}>{form.plans4YearCollege && '4-year College'}</summary>
        <summary className={classes.printedContent}>{form.plansWorkforce && 'Workforce (full time)'}</summary>
        <summary className={classes.printedContent}>{form.plansVocationalTraining && 'Community College'}</summary>
        <summary className={classes.printedContent}>{form.plansJobCorps && 'Job Corps/AmeriCorps'}</summary>
        <summary className={classes.printedContent}>{form.plansOther && 'Other: ' + form.plansOtherExplain}</summary>
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={12} md={6} lg={4} className={classes.textRowLabelSpacer}>
            <label>Would you be interested in employment at Boys Town?</label>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="interested in Boys Town employment"
                name="boystown-employment"
                horizontal="true"
                value={form.boystownEmployment}
                onChange={(event) => setForm({ ...form, boystownEmployment: event.target.value })}
                disabled={disabled}
              >
                <FormControlLabel
                  value="yes"
                  className={classes.marginRight30}
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>No</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={8} md={3} lg={5} />
        </Grid>
        <summary className={classes.printedContent}>
          <label>Would you be interested in employment at Boys Town?</label>
        </summary>
        <summary className={classes.printedContent}>{form.boystownEmployment || null}</summary>

        {form.boystownEmployment === 'yes' && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} className={classes.textRowLabelSpacer}>
                <label>In what areas of employment at Boys Town would you be interested?</label>
              </Grid>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Successful Futures"
                    disabled={disabled}
                    checked={form.boystownAftercare}
                    onChange={(event) => setForm({ ...form, boystownAftercare: !form.boystownAftercare })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Information Technology"
                    disabled={disabled}
                    checked={form.boystownIT}
                    onChange={(event) => setForm({ ...form, boystownIT: !form.boystownIT })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={1} md={3} lg={3} />
            </Grid>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Facilities"
                    disabled={disabled}
                    checked={form.boystownFacilities}
                    onChange={(event) => setForm({ ...form, boystownFacilities: !form.boystownFacilities })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Legal"
                    disabled={disabled}
                    checked={form.boystownLegal}
                    onChange={(event) => setForm({ ...form, boystownLegal: !form.boystownLegal })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={1} md={3} lg={3} />
            </Grid>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Finance"
                    disabled={disabled}
                    checked={form.boystownFinance}
                    onChange={(event) => setForm({ ...form, boystownFinance: !form.boystownFinance })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Marketing & Development"
                    disabled={disabled}
                    checked={form.boystownMarketing}
                    onChange={(event) => setForm({ ...form, boystownMarketing: !form.boystownMarketing })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={false} md={4} lg={4} />
            </Grid>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Hospital/Clinic"
                    disabled={disabled}
                    checked={form.boystownHospital}
                    onChange={(event) => setForm({ ...form, boystownHospital: !form.boystownHospital })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Security"
                    disabled={disabled}
                    checked={form.boystownSecurity}
                    onChange={(event) => setForm({ ...form, boystownSecurity: !form.boystownSecurity })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={false} md={4} lg={4} />
            </Grid>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Human Resources"
                    disabled={disabled}
                    checked={form.boystownHR}
                    onChange={(event) => setForm({ ...form, boystownHR: !form.boystownHR })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Youth Care"
                    disabled={disabled}
                    checked={form.boystownYouthCare}
                    onChange={(event) => setForm({ ...form, boystownYouthCare: !form.boystownYouthCare })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={false} md={4} lg={4} />
            </Grid>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Information Security"
                    disabled={disabled}
                    checked={form.boystownInfoSec}
                    onChange={(event) => setForm({ ...form, boystownInfoSec: !form.boystownInfoSec })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}></Grid>
              <Grid item xs={false} sm={false} md={4} lg={4} />
            </Grid>
            <summary className={classes.printedContent}>
              <label>In what areas of employment at Boys Town would you be interested?</label>
            </summary>
            <summary className={classes.printedContent}>{form.boystownAftercare && 'Successful Futures'}</summary>
            <summary className={classes.printedContent}>{form.boystownIT && 'Information Technology'}</summary>
            <summary className={classes.printedContent}>{form.boystownFacilities && 'Facilities'}</summary>
            <summary className={classes.printedContent}>{form.boystownLegal && 'Legal'}</summary>
            <summary className={classes.printedContent}>{form.boystownFinance && 'Finance'}</summary>
            <summary className={classes.printedContent}>{form.boystownMarketing && 'Marketing & Development'}</summary>
            <summary className={classes.printedContent}>{form.boystownHospital && 'Hospital/Clinic'}</summary>
            <summary className={classes.printedContent}>{form.boystownSecurity && 'Security'}</summary>
            <summary className={classes.printedContent}>{form.boystownHR && 'Human Resources'}</summary>
            <summary className={classes.printedContent}>{form.boystownYouthCare && 'Youth Care'}</summary>
            <summary className={classes.printedContent}>{form.boystownInfoSec && 'Information Security'}</summary>
          </React.Fragment>
        )}
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="summer-living-arrangements"
                size="small"
                fullWidth
                label="What are your living arrangement plans for the summer?"
                variant="outlined"
                value={form.acSummerLivingArrangements}
                onChange={(event) => setForm({ ...form, acSummerLivingArrangements: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 1000, classes: { input: classes.textField } }}
                className={classes.root}
              />
            </FormControl>
          </Grid>
        </Grid>
        <summary className={classes.printedContent}>
          <label>What are your living arrangement plans for the summer?</label>
        </summary>
        <summary className={classes.printedContent}>{form.acSummerLivingArrangements || null}</summary>
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="fall-living-arrangements"
                size="small"
                fullWidth
                label="What are your living arrangement plans for the fall?"
                variant="outlined"
                value={form.acFallLivingArrangements}
                onChange={(event) => setForm({ ...form, acFallLivingArrangements: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 1000, classes: { input: classes.textField } }}
                className={classes.root}
              />
            </FormControl>
          </Grid>
        </Grid>
        <summary className={classes.printedContent}>
          <label>What are your living arrangement plans for the fall?</label>
        </summary>
        <summary className={classes.printedContent}>{form.acFallLivingArrangements || null}</summary>
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={12} md={9} lg={7} className={classes.textRowLabelSpacer}>
            <label>Do you have other sources of income (state funds, trust funds, etc.) to assist you with your living needs?</label>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="other sources of income"
                name="other-income"
                horizontal="true"
                value={form.otherIncome}
                onChange={(event) => setForm({ ...form, otherIncome: event.target.value })}
                disabled={disabled}
              >
                <FormControlLabel
                  value="yes"
                  className={classes.marginRight30}
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>No</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={8} md={false} lg={2} />
        </Grid>
        <summary className={classes.printedContent}>
          <label>Do you have other sources of income (state funds, trust funds, etc.) to assist you with your living needs?</label>
        </summary>
        <summary className={classes.printedContent}>{form.otherIncome || null}</summary>
      </section>

      {form.otherIncome === 'yes' && (
        <section className={classes.marginTop15}>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="explain-other-income"
                  size="small"
                  fullWidth
                  label="Explain your other sources of income."
                  variant="outlined"
                  value={form.explainOtherIncome}
                  onChange={(event) => setForm({ ...form, explainOtherIncome: event.target.value })}
                  disabled={disabled}
                  InputProps={{ maxLength: 500, classes: { input: classes.textField } }}
                  className={classes.root}
                />
              </FormControl>
            </Grid>
          </Grid>
          <summary className={classes.printedContent}>
            <label>Explain your other sources of income.</label>
          </summary>
          <summary className={classes.printedContent}>{form.explainOtherIncome || null}</summary>
        </section>
      )}

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="guardians-financial-help"
                size="small"
                fullWidth
                label="How have your parents and/or legal guardians committed to financially help you with your post-graduation plans?"
                variant="outlined"
                value={form.guardiansFinancialHelp}
                onChange={(event) => setForm({ ...form, guardiansFinancialHelp: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 1000, classes: { input: classes.textField } }}
                className={classes.root}
              />
            </FormControl>
          </Grid>
        </Grid>
        <summary className={classes.printedContent}>
          <label>How have your parents and/or legal guardians committed to financially help you with your post-graduation plans?</label>
        </summary>
        <summary className={classes.printedContent}>{form.guardiansFinancialHelp || null}</summary>
      </section>

      <section className={classes.marginTop15}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={12} md={9} lg={7} className={classes.textRowLabelSpacer}>
            <label>Are you currently covered under a health insurance plan other than Medicaid?</label>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="health insurance other than Medicaid"
                name="health-insurance"
                horizontal="true"
                value={form.acHealthInsurance}
                onChange={(event) => setForm({ ...form, acHealthInsurance: event.target.value })}
                disabled={disabled}
              >
                <FormControlLabel
                  value="yes"
                  className={classes.marginRight30}
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>No</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={false} sm={8} md={false} lg={2} />
        </Grid>
        <summary className={classes.printedContent}>
          <label>Are you currently covered under a health insurance plan other than Medicaid?</label>
        </summary>
        <summary className={classes.printedContent}>{form.acHealthInsurance || null}</summary>
      </section>
    </Box>
  );
}

CareerInterests.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
  handleWorkHistoryRow: PropTypes.func,
};
