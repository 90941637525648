// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: `${process.env.REACT_APP_AZURE_APP_INSIGHTS_CONN_STRING}`,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

const logException = (error) => {
  appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
}


export { reactPlugin, appInsights, logException };

