import { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import HomeIcon from '@material-ui/icons/Home';
import { observer, inject } from 'mobx-react';
// import { msalConfig } from '../../services/msal';
import { logoutGoogle } from '../../util/apiWrapper';

const Unauthorized = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
      const handleSignOut = async () => {
        await logoutGoogle(authStore);
        authStore.setNotAuthorized = false;
        await instance.logoutRedirect();
        // await instance.logoutRedirect({ postLogoutRedirectUri: '/home' });
      };

      if (isAuthenticated) {
        handleSignOut();
      }
    }, [isAuthenticated, instance, authStore]);

    return (
      <div>
        {!isAuthenticated && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          >
            <a href="/" title="home">
              <HomeIcon color="primary" />
            </a>
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <h2>Sorry you are not authorized to use this application.</h2>
        </div>
      </div>
    );
  })
);

export default Unauthorized;
