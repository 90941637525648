import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import ExpandingList from '../../ExpandingList/ExpandingList';
import LuxonUtils from '@date-io/luxon';
import { dateUtcToS } from '../../../util/dateUtil';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { btNavyBlueColor, btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';
import { createPrintedList } from '../../../util/stringUtil';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  reminder: {
    color: 'blue',
    fontSize: '1.05rem',
    margin: '10px 0',
  },
  textArea8RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 280,
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      height: 165,
      marginBottom: 20,
    },
  },
  textArea10RowSpacer: {
    height: 195,
  },
  addButton: {
    padding: 0,
    color: btNavyBlueColor,
  },
  paddingDataDisplay: {
    paddingTop: 8,
  },
  bold: {
    fontWeight: 600,
  },
  subtleBold: {
    fontWeight: 500,
    color: btMediumGrayColor,
  },
  subtleBoldLabel: {
    fontWeight: 500,
    fontSize: '0.95rem',
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function FallPlan(props) {
  const {
    form,
    setForm,
    act,
    disabled,
    saveJobCorpsStartDateTask,
    saveMilitaryMEPSDateTask,
    saveMilitaryBasicTrainingDateTask,
    saveFallPlanAndTasks,
  } = props;
  const classes = styles();

  const calculate4YearCosts = () => {
    const tuition = form.fall4YearCostTuition || 0;
    const books = form.fall4YearCostBooks || 0;
    const housing = form.fall4YearCostHousing || 0;
    const other = form.fall4YearCostOther || 0;
    const meals = form.fall4YearCostMealPlan || 0;
    return parseInt(tuition, 10) + parseInt(books, 10) + parseInt(housing, 10) + parseInt(other, 10) + parseInt(meals, 10);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: '90%' }}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
            <label>My plan for the fall is</label>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <Select
                labelId="plan-for-fall-label"
                id="plan-for-fall"
                value={form.fallPlan}
                onChange={(event) => saveFallPlanAndTasks(event.target.value)}
                disabled={disabled}
              >
                <MenuItem value={'Community College/Trade'}>Community College/Trade</MenuItem>
                <MenuItem value={'4-Year College'}>4-Year College</MenuItem>
                <MenuItem value={'Job Corps'}>Job Corps</MenuItem>
                <MenuItem value={'Military'}>Military</MenuItem>
                <MenuItem value={'Continue Employment'}>Continue Employment</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={4}>
              <label className={classes.alignPrintedContent}>My plan for the fall is</label>
            </Grid>
            <Grid item xs={8}>
              <summary className={classes.alignPrintedContent}>{form.fallPlan || null}</summary>
            </Grid>
          </Grid>
        </div>

        {form.fallPlan === 'Community College/Trade' && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Name of College/Location</label>
              </Grid>
              <Grid item xs={12} sm={7} md={7} lg={6} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-cc-trade-name-location"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallCCTradeNameLocation}
                    onChange={(event) => setForm({ ...form, fallCCTradeNameLocation: event.target.value })}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={12}>
                  <label className={classes.alignPrintedContent}>Name of College/Location</label>
                </Grid>
                <Grid item xs={12}>
                  <summary className={classes.alignPrintedContent}>{form.fallCCTradeNameLocation || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Have I already been accepted?</label>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="fall CC/Trade accepted"
                    name="fall-cc-trade-accepted"
                    value={form.fallCCTradeAccepted}
                    onChange={(event) => setForm({ ...form, fallCCTradeAccepted: event.target.value })}
                    disabled={disabled}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={disabled} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={disabled} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Have I already been accepted?</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fallCCTradeAccepted || null}</summary>
                </Grid>
              </Grid>
            </div>

            {form.fallCCTradeAccepted === 'yes' && (
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} align="center" className={classes.rowSpacer}>
                  <label className={classes.reminder}>REMINDER: Bring my acceptance letter with me to the 2nd meeting.</label>
                </Grid>
              </Grid>
            )}
            <div className={classes.printedContent}>
              <div>
                <label className={classes.reminder}>REMINDER: Bring my acceptance letter with me to the 2nd meeting.</label>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Trade or Major</label>
              </Grid>
              <Grid item xs={12} sm={7} md={7} lg={6} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-trade-or-major"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallTradeOrMajor}
                    onChange={(event) => setForm({ ...form, fallTradeOrMajor: event.target.value })}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Trade or Major</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallTradeOrMajor || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Start Date</label>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} className={classes.datePickerRowSpacer}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="fall-trade-start-date"
                      hiddenLabel
                      disablePast
                      value={form.fallCCTradeStartDate || null}
                      onChange={(date) => {
                        setForm({ ...form, fallCCTradeStartDate: date ? date : null });
                      }}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      disabled={disabled}
                      minDateMessage="Date cannot be earlier than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {dateUtcToS(form.fallCCTradeStartDate || null)}
                  </summary>
                )}
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Start Date</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{dateUtcToS(form.fallCCTradeStartDate || null)}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Career Goals</label>
              </Grid>
              <Grid item xs={12} sm={7} md={7} lg={6} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-cc-trade-career-goal"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallCCTradeCareerGoal}
                    onChange={(event) => setForm({ ...form, fallCCTradeCareerGoal: event.target.value })}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Career Goals</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallCCTradeCareerGoal || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.sectionSpacer + ' ' + classes.unprintedContent}>
              <Grid item xs={12} align="left">
                <section align="left" className={classes.sectionSubTitle}>
                  Payment Plan
                </section>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.sectionSubTitle}>Payment Plan</label>
              </div>
            </div>

            <Grid container direction="row" style={{ height: '185px', overflow: 'auto' }} className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>
                  Scholarships <br />
                  (Applied for or Granted)
                </label>{' '}
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textRowSpacer}>
                <ExpandingList
                  form={form}
                  setForm={setForm}
                  listname="fallCCTradeScholarships"
                  field1="name"
                  field1Cols={11}
                  field1MaxLength={100}
                  rowsRequired={3}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Scholarships</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{createPrintedList(form.fallCCTradeScholarships)}</summary>
              </div>
            </div>

            <Grid container direction="row" style={{ height: '185px', overflow: 'auto' }} className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Loans</label>{' '}
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textRowSpacer}>
                <ExpandingList
                  form={form}
                  setForm={setForm}
                  listname="fallCCTradeLoans"
                  field1="name"
                  field1Cols={11}
                  field1MaxLength={100}
                  rowsRequired={3}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Loans</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{createPrintedList(form.fallCCTradeLoans)}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={3} md={2} lg={2} className={classes.textRowLabelSpacer}>
                <label>Family Assistance</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-family-assistance"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallCCTradeFamilyAssistance}
                    onChange={(event) => setForm({ ...form, fallCCTradeFamilyAssistance: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Family Assistance</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fall4YearFamilyAssistance || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Living Arrangements</label>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-cc-trade-living-arrangements"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallCCTradeLivingArrangements}
                    onChange={(event) => setForm({ ...form, fallCCTradeLivingArrangements: event.target.value })}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Living Arrangements</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallCCTradeLivingArrangements || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={12} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Transportation Requirements</label>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-cc-trade-transportation-requirements"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallCCTradeTransportationRequirements}
                    onChange={(event) => setForm({ ...form, fallCCTradeTransportationRequirements: event.target.value })}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Transportation Requirements</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallCCTradeTransportationRequirements || null}</summary>
              </div>
            </div>
          </React.Fragment>
        )}

        {form.fallPlan === '4-Year College' && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} align="center" className={classes.textRowSpacer}>
                <label className={classes.reminder}>REMINDER: Bring my acceptance letter with me to the 2nd meeting.</label>
              </Grid>
            </Grid>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>College Name and Location</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-name-location"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearNameLocation}
                    onChange={(event) => setForm({ ...form, fall4YearNameLocation: event.target.value })}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>College Name and Location</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fall4YearNameLocation || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Have I already been accepted?</label>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="fall 4-year accepted"
                    name="fall-4year-accepted"
                    value={form.fall4YearAccepted}
                    onChange={(event) => setForm({ ...form, fall4YearAccepted: event.target.value })}
                    disabled={disabled}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={disabled} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={disabled} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Have I already been accepted?</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fall4YearAccepted || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Major</label>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-major"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearMajor}
                    onChange={(event) => setForm({ ...form, fall4YearMajor: event.target.value })}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Major</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fall4YearMajor || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Career Goals</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-career-goal"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearCareerGoal}
                    onChange={(event) => setForm({ ...form, fall4YearCareerGoal: event.target.value })}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Career Goals</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fall4YearCareerGoal || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Start Date</label>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={3} className={classes.datePickerRowSpacer}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="fall-4-year-start-date"
                      hiddenLabel
                      disablePast
                      value={form.fall4YearStartDate || null}
                      onChange={(date) => {
                        setForm({ ...form, fall4YearStartDate: date ? date : null });
                      }}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      disabled={disabled}
                      minDateMessage="Date cannot be earlier than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {dateUtcToS(form.fall4YearStartDate)}
                  </summary>
                )}
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Start Date</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{dateUtcToS(form.fall4YearStartDate)}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>ACT Score</label>
              </Grid>
              {act === 0 ||
                (act.length === 0 ? (
                  <Grid item xs={12} sm={8} md={8} lg={9} className={classes.paddingDataDisplay}>
                    <label className={classes.reminder}>Missing ACT score! Please enter your ACT score on the Personal Information page.</label>
                  </Grid>
                ) : (
                  <Grid item xs={4} sm={2} md={1} lg={1} className={classes.paddingDataDisplay}>
                    <summary className={classes.subtleBold} disabled={disabled}>
                      {act}
                    </summary>
                  </Grid>
                ))}
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>ACT Score</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent + ' ' + classes.subtleBold}>{act}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Date FAFSA Completed</label>
              </Grid>
              <Grid item xs={6} sm={5} md={5} lg={3} className={classes.datePickerRowSpacer}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="fall-4-year-fafsa-date"
                      hiddenLabel
                      disableFuture
                      value={form.fall4YearFafsaDate || null}
                      onChange={(date) => {
                        setForm({ ...form, fall4YearFafsaDate: date ? date : null });
                      }}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      disabled={disabled}
                      maxDateMessage="Date cannot be later than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {dateUtcToS(form.fall4YearFafsaDate || null)}
                  </summary>
                )}
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Date FAFSA Completed</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{dateUtcToS(form.fall4YearFafsaDate || null)}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.sectionSpacer + ' ' + classes.unprintedContent}>
              <Grid item xs={12} align="left">
                <section align="left" className={classes.sectionSubTitle}>
                  Costs Per Year
                </section>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.sectionSubTitle}>Costs Per Year</label>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={6} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Tuition</label>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-cost-tuition"
                    size="small"
                    type="number"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearCostTuition}
                    onChange={(event) => setForm({ ...form, fall4YearCostTuition: event.target.value })}
                    disabled={disabled}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={1} md={1} lg={2} />
              <Grid item xs={6} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Books</label>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-cost-books"
                    size="small"
                    hiddenLabel
                    type="number"
                    variant="outlined"
                    value={form.fall4YearCostBooks}
                    onChange={(event) => setForm({ ...form, fall4YearCostBooks: event.target.value })}
                    disabled={disabled}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row">
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Tuition</label>
                </Grid>
                <Grid item xs={2}>
                  <summary className={classes.alignPrintedContent}>$ {form.fall4YearCostTuition || 0}</summary>
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Books</label>
                </Grid>
                <Grid item xs={2}>
                  <summary className={classes.alignPrintedContent}>$ {form.fall4YearCostBooks || 0}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} classes />
              <Grid item xs={6} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Housing</label>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-cost-housing"
                    size="small"
                    hiddenLabel
                    type="number"
                    variant="outlined"
                    value={form.fall4YearCostHousing}
                    onChange={(event) => setForm({ ...form, fall4YearCostHousing: event.target.value })}
                    disabled={disabled}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={1} md={1} lg={2} />
              <Grid item xs={6} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Other Fees</label>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-cost-other"
                    size="small"
                    hiddenLabel
                    type="number"
                    variant="outlined"
                    value={form.fall4YearCostOther}
                    onChange={(event) => setForm({ ...form, fall4YearCostOther: event.target.value })}
                    disabled={disabled}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row">
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Housing</label>
                </Grid>
                <Grid item xs={2}>
                  <summary className={classes.alignPrintedContent}>$ {form.fall4YearCostHousing || 0}</summary>
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Other Fees</label>
                </Grid>
                <Grid item xs={2}>
                  <summary className={classes.alignPrintedContent}>$ {form.fall4YearCostOther || 0}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={6} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Meal Plan</label>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-cost-mealplan"
                    size="small"
                    hiddenLabel
                    type="number"
                    variant="outlined"
                    value={form.fall4YearCostMealPlan}
                    onChange={(event) => setForm({ ...form, fall4YearCostMealPlan: event.target.value })}
                    disabled={disabled}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={1} md={1} lg={2} />
              <Grid item xs={6} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <summary className={classes.subtleBoldLabel}>Total</summary>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2} className={classes.paddingDataDisplay + ' ' + classes.subtleBold}>
                $ {calculate4YearCosts()}
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row">
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Meal Plan</label>
                </Grid>
                <Grid item xs={2}>
                  <summary className={classes.alignPrintedContent}>$ {form.fall4YearCostHousing || 0}</summary>
                </Grid>
                <Grid item xs={4}>
                  <summary className={classes.alignPrintedContent}>Total</summary>
                </Grid>
                <Grid item xs={2}>
                  <summary className={classes.alignPrintedContent}>$ {calculate4YearCosts()}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.sectionSpacer + ' ' + classes.unprintedContent}>
              <Grid item xs={12} align="left">
                <section align="left" className={classes.sectionSubTitle}>
                  Payment Plan
                </section>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.sectionSubTitle}>Payment Plan</label>
              </div>
            </div>

            <Grid container direction="row" style={{ height: '185px', overflow: 'auto' }} className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>
                  Scholarships <br />
                  (Applied for or Granted)
                </label>{' '}
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textRowSpacer}>
                <ExpandingList
                  form={form}
                  setForm={setForm}
                  listname="scholarships"
                  field1="name"
                  field1Cols={11}
                  field1MaxLength={100}
                  rowsRequired={3}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Scholarships</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{createPrintedList(form.scholarships)}</summary>
              </div>
            </div>

            <Grid container direction="row" style={{ height: '185px', overflow: 'auto' }} className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={3} md={3} lg={2} className={classes.textRowLabelSpacer}>
                <label>Loans</label>{' '}
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textRowSpacer}>
                <ExpandingList
                  form={form}
                  setForm={setForm}
                  listname="loans"
                  field1="name"
                  field1Cols={11}
                  field1MaxLength={100}
                  rowsRequired={3}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Loans</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{createPrintedList(form.loans)}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={false} sm={1} md={1} lg={1} />
              <Grid item xs={12} sm={3} md={2} lg={2} className={classes.textRowLabelSpacer}>
                <label>Family Assistance</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-family-assistance"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearFamilyAssistance}
                    onChange={(event) => setForm({ ...form, fall4YearFamilyAssistance: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Family Assistance</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fall4YearFamilyAssistance || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Living Arrangements</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-living-arrangements"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearLivingArrangements}
                    onChange={(event) => setForm({ ...form, fall4YearLivingArrangements: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Living Arrangements</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fall4YearLivingArrangements || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Transportation Requirements</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-4-year-transportation-requirements"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fall4YearTransportationRequirements}
                    onChange={(event) => setForm({ ...form, fall4YearTransportationRequirements: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Transportation Requirements</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fall4YearTransportationRequirements || null}</summary>
              </div>
            </div>
          </React.Fragment>
        )}

        {form.fallPlan === 'Job Corps' && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Site Location</label>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-job-corps-location"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallJobCorpsLocation}
                    onChange={(event) => setForm({ ...form, fallJobCorpsLocation: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Site Location</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fallJobCorpsLocation || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Training Program</label>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-job-corps-program"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallJobCorpsProgram}
                    onChange={(event) => setForm({ ...form, fallJobCorpsProgram: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Training Program</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fallJobCorpsProgram || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Start Date</label>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.datePickerRowSpacer}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="fall-job-corps-start-date"
                      hiddenLabel
                      disablePast
                      value={form.fallJobCorpsStartDateDoNotKnow ? null : form.fallJobCorpsStartDate || null}
                      disabled={disabled}
                      onChange={(date) => {
                        setForm({ ...form, fallJobCorpsStartDate: date ? date : null });
                      }}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      minDateMessage="Date cannot be earlier than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {form.fallJobCorpsStartDateDoNotKnow ? 'Unknown' : dateUtcToS(form.fallJobCorpsStartDate || null)}
                  </summary>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.datePickerRowSpacer}>
                <FormControlLabel
                  control={
                    <Tooltip title="Student does not yet know their Job Corps start date." placement="top">
                      <Checkbox
                        color="default"
                        disabled={disabled}
                        checked={form.fallJobCorpsStartDateDoNotKnow ? form.fallJobCorpsStartDateDoNotKnow : false}
                        onChange={(ev) => saveJobCorpsStartDateTask(!form.fallJobCorpsStartDateDoNotKnow)}
                      />
                    </Tooltip>
                  }
                  label="Do Not Know"
                />
              </Grid>
            </Grid>
            {form.fallJobCorpsStartDateDoNotKnow && (
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}></Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="fall-job-corps-start-date-do-not-know-reason"
                      size="small"
                      label="Explain why you do not know your start date"
                      variant="outlined"
                      value={form.fallJobCorpsStartDateDoNotKnowReason}
                      onChange={(event) => setForm({ ...form, fallJobCorpsStartDateDoNotKnowReason: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Start Date</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>
                    {form.fallJobCorpsStartDateDoNotKnow ? 'Unknown' : dateUtcToS(form.fallJobCorpsStartDate || null)}
                  </summary>
                </Grid>
              </Grid>
            </div>
            {form.fallJobCorpsStartDateDoNotKnow && (
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={4}>
                    <label className={classes.alignPrintedContent}>Reason</label>
                  </Grid>
                  <Grid item xs={8}>
                    <summary className={classes.alignPrintedContent}>{form.fallJobCorpsStartDateDoNotKnowReason}</summary>
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Transporation Requirements</label>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-job-corps-transporation"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallJobCorpsTransportation}
                    onChange={(event) => setForm({ ...form, fallJobCorpsTransportation: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Transportation Requirements</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallJobCorpsTransportation || null}</summary>
              </div>
            </div>
          </React.Fragment>
        )}

        {form.fallPlan === 'Military' && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Branch of Military</label>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-military-branch"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallMilitaryBranch}
                    onChange={(event) => setForm({ ...form, fallMilitaryBranch: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Branch of Military</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fallMilitaryBranch || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={6} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>MEPS Date</label>
              </Grid>

              <Grid item xs={6} sm={6} md={4} lg={3} className={classes.datePickerRowSpacer}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="fall-military-meps-date"
                      hiddenLabel
                      disablePast
                      value={form.fallMilitaryMepsDateDoNotKnow ? null : form.fallMilitaryMepsDate || null}
                      disabled={disabled}
                      onChange={(date) => {
                        setForm({ ...form, fallMilitaryMepsDate: date ? date : null });
                      }}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      minDateMessage="Date cannot be earlier than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {form.fallMilitaryMepsDateDoNotKnow ? 'Unknown' : dateUtcToS(form.fallMilitaryMepsDate || null)}
                  </summary>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} className={classes.datePickerRowSpacer}>
                <FormControlLabel
                  control={
                    <Tooltip title="Student does not yet know their MEPS date." placement="top">
                      <Checkbox
                        color="default"
                        disabled={disabled}
                        checked={form.fallMilitaryMepsDateDoNotKnow ? form.fallMilitaryMepsDateDoNotKnow : false}
                        onChange={(ev) => saveMilitaryMEPSDateTask(!form.fallMilitaryMepsDateDoNotKnow)}
                      />
                    </Tooltip>
                  }
                  label="Do Not Know"
                />
              </Grid>
            </Grid>
            {form.fallMilitaryMepsDateDoNotKnow && (
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}></Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="fall-military-MEPS-do-not-know-reason"
                      size="small"
                      label="Explain why you do not know your MEPS date"
                      variant="outlined"
                      value={form.fallMilitaryMepsDateDoNotKnowReason}
                      onChange={(event) => setForm({ ...form, fallMilitaryMepsDateDoNotKnowReason: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>MEPS Date</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>
                    {form.fallMilitaryMepsDateDoNotKnow ? 'Unknown' : dateUtcToS(form.fallMilitaryMepsDate || null)}
                  </summary>
                </Grid>
              </Grid>
            </div>
            {form.fallMilitaryMepsDateDoNotKnow && (
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={4}>
                    <label className={classes.alignPrintedContent}>Reason</label>
                  </Grid>
                  <Grid item xs={8}>
                    <summary className={classes.alignPrintedContent}>{form.fallMilitaryMepsDateDoNotKnowReason}</summary>
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Basic Training Date</label>
              </Grid>
              <Grid item xs={6} sm={5} md={4} lg={3} className={classes.datePickerRowSpacer}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="fall-military-basic-training-date"
                      hiddenLabel
                      disablePast
                      value={form.fallMilitaryBasicTrainingDateDoNotKnow ? null : form.fallMilitaryBasicTrainingDate || null}
                      disabled={disabled}
                      onChange={(date) => {
                        setForm({ ...form, fallMilitaryBasicTrainingDate: date ? date : null });
                      }}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      minDateMessage="Date cannot be earlier than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {form.fallMilitaryBasicTrainingDateDoNotKnow ? 'Unknown' : dateUtcToS(form.fallMilitaryBasicTrainingDate || null)}
                  </summary>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} className={classes.datePickerRowSpacer}>
                <FormControlLabel
                  control={
                    <Tooltip title="Student does not yet know their basic training date." placement="top">
                      <Checkbox
                        color="default"
                        disabled={disabled}
                        checked={form.fallMilitaryBasicTrainingDateDoNotKnow ? form.fallMilitaryBasicTrainingDateDoNotKnow : false}
                        onChange={(ev) => saveMilitaryBasicTrainingDateTask(!form.fallMilitaryBasicTrainingDateDoNotKnow)}
                      />
                    </Tooltip>
                  }
                  label="Do Not Know"
                />
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Basic Training Date</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>
                    {form.fallMilitaryBasicTrainingDateDoNotKnow ? 'Unknown' : dateUtcToS(form.fallMilitaryBasicTrainingDate || null)}
                  </summary>
                </Grid>
              </Grid>
            </div>
            {form.fallMilitaryBasicTrainingDateDoNotKnow && (
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={4}>
                    <label className={classes.alignPrintedContent}>Reason</label>
                  </Grid>
                  <Grid item xs={8}>
                    <summary className={classes.alignPrintedContent}>{form.fallMilitaryBasicTrainingDateDoNotKnowReason}</summary>
                  </Grid>
                </Grid>
              </div>
            )}
            {form.fallMilitaryBasicTrainingDateDoNotKnow && (
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}></Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="fall-military-basic-training-date-do-not-know-reason"
                      size="small"
                      label="Explain why you do not know your basic training date"
                      variant="outlined"
                      value={form.fallMilitaryBasicTrainingDateDoNotKnowReason}
                      onChange={(event) => setForm({ ...form, fallMilitaryBasicTrainingDateDoNotKnowReason: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Name of Recruiter</label>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-military-recruiter"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallMilitaryRecruiter}
                    onChange={(event) => setForm({ ...form, fallMilitaryRecruiter: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Name of Recruiter</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fallMilitaryRecruiter || null}</summary>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )}

        {form.fallPlan === 'Continue Employment' && (
          <React.Fragment>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Place of Employment</label>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-employment-place"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallEmploymentPlace}
                    onChange={(event) => setForm({ ...form, fallEmploymentPlace: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Place of Employment</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallEmploymentPlace || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Type of Employment</label>
              </Grid>
              <Grid item xs={12} sm={8} md={7} lg={6} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="fall employment type"
                    name="fall-employment-type"
                    value={form.fallEmploymentFullPartTime}
                    onChange={(event) => setForm({ ...form, fallEmploymentFullPartTime: event.target.value })}
                    disabled={disabled}
                  >
                    <FormControlLabel
                      value="full-time"
                      control={<Radio color="default" disabled={disabled} />}
                      label={<span className={classes.radioLabel}>Full Time (34+ hrs/wk)</span>}
                    />
                    <FormControlLabel
                      value="part-time"
                      control={<Radio color="default" disabled={disabled} />}
                      label={<span className={classes.radioLabel}>Part Time</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Type of Employment</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.fallEmploymentFullPartTime || null}</summary>
                </Grid>
              </Grid>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Living Arrangements/Location</label>
              </Grid>
              <Grid item xs={12} sm={8} md={7} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-living-arrangements"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallLivingArrangements}
                    onChange={(event) => setForm({ ...form, fallLivingArrangements: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Living Arrangements/Location</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallLivingArrangements || null}</summary>
              </div>
            </div>

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={4} md={4} lg={3} className={classes.textRowLabelSpacer}>
                <label>Transportation Requirements</label>
              </Grid>
              <Grid item xs={12} sm={8} md={7} lg={9} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="fall-transportation-required"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={form.fallTransportationRequired}
                    onChange={(event) => setForm({ ...form, fallTransportationRequired: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label className={classes.alignPrintedContent}>Transportation Requirements</label>
              </div>
              <div>
                <summary className={classes.alignPrintedContent}>{form.fallTransportationRequired || null}</summary>
              </div>
            </div>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}

FallPlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  act: PropTypes.number,
  disabled: PropTypes.bool,
  saveJobCorpsStartDateTask: PropTypes.func,
  saveMilitaryMEPSDateTask: PropTypes.func,
  saveMilitaryBasicTrainingDateTask: PropTypes.func,
  saveFallPlanAndTasks: PropTypes.func,
};
