import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { dateUtcToS, dateToMMMMYYYY, dateDiffYears, dateToApi } from '../../../util/dateUtil';

const AppSection = ({ form, setForm, classes, disabled, senior, roles }) => {
  const PROGRAMS = [
    'In-Home Family Services',
    'Foster Family Services',
    'Family Home Program',
    'Intervention & Assessment',
    'Residential Treatment Center',
  ];

  return (
    <Grid item xs={12} align="left">
      <Grid item xs={12}>
        <section align="left" className={classes.sectionTitle}>
          Application
        </section>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Name</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {roles.includes('ALUMNI') ? senior.alumni_updated_first_name : senior.first_name}{' '}
          {roles.includes('ALUMNI') ? senior.alumni_updated_last_name : senior.last_name}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Site</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.site}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Name</label>
        </Grid>
        <Grid item xs={3}>
          {roles.includes('ALUMNI') ? senior.alumni_updated_first_name : senior.first_name}{' '}
          {roles.includes('ALUMNI') ? senior.alumni_updated_last_name : senior.last_name}
        </Grid>
        <Grid item xs={3}>
          <label>Site</label>
        </Grid>
        <Grid item xs={3}>
          {senior.site}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Family Teachers</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.family_teaching_couple}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={3}>
          <label>Family Teachers</label>
        </Grid>
        <Grid item xs={3}>
          {senior.family_teaching_couple}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Age at Graduation</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.graduation_date > /Date(0)/ && dateDiffYears(dateToApi(senior.dob), dateToApi(senior.graduation_date))}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Community</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.community}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Age at Graduation</label>
        </Grid>
        <Grid item xs={3}>
          {senior.graduation_date > /Date(0)/ && dateDiffYears(dateToApi(senior.dob), dateToApi(senior.graduation_date))}
        </Grid>
        <Grid item xs={3}>
          <label>Community</label>
        </Grid>
        <Grid item xs={3}>
          {senior.community}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Graduation Date</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateToMMMMYYYY(senior.graduation_date)}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
        <Grid item xs={3}>
          <label>Graduation Date</label>
        </Grid>
        <Grid item xs={3}>
          {dateToMMMMYYYY(senior.graduation_date)}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Consultant</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.consultant}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Consultant</label>
        </Grid>
        <Grid item xs={3}>
          {senior.consultant}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={12} md={4} lg={3} className={classes.rowSpacer}>
          <label>I have participated in these Boys Town programs</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={12} md={5} lg={3}>
          {PROGRAMS.map((p) => {
            return (
              <FormControlLabel
                key={p.replace(' ', '-')}
                id={p}
                control={
                  <Checkbox
                    color="default"
                    disabled={disabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        form.btPrograms.push(p);
                        setForm({ ...form, btPrograms: form.btPrograms });
                      } else {
                        const found = form.btPrograms.findIndex((x) => x === p);
                        form.btPrograms.splice(found, 1);
                        setForm({ ...form, btPrograms: form.btPrograms });
                      }
                    }}
                    checked={form.btPrograms.indexOf(p) > -1}
                  />
                }
                label={p}
              />
            );
          })}
        </Grid>
      </Grid>
      <summary container direction="row" className={classes.printedContent}>
        <summary>
          <label>I have participated in these Boys Town programs</label>
        </summary>
        <summary>{form.btPrograms.join(', ')}</summary>
      </summary>
    </Grid>
  );
};

AppSection.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  senior: PropTypes.object.isRequired,
  roles: PropTypes.array,
};

export default AppSection;
