import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, Select, MenuItem } from '@material-ui/core';

const ContactResult = ({ contactMode, classes, attemptResult, setAttemptResult, showScript, clientHaveTime, handleResultAttemptResult }) => {
  return (
    <React.Fragment>
      {contactMode.length > 0 && contactMode === 'Phone' && (showScript === 'no' || clientHaveTime === 'no') && (
        <>
          <Grid container align="left" direction="row" className={classes.rowSpacer}>
            <Grid item xs={3} sm={2} md={2} lg={2} className={classes.rowSpacer}>
              <label id="Result-label" className={classes.adminLabel}>
                Result
              </label>
            </Grid>
            <Grid item xs={7} sm={5} md={5} lg={4}>
              <FormControl fullWidth>
                <Select id="result" value={attemptResult} onChange={(event) => handleResultAttemptResult(event.target.value)}>
                  {showScript === 'no' && <MenuItem value="VoiceMail">Voice Mail</MenuItem>}
                  {showScript === 'no' && <MenuItem value="NoAnswer">No Answer</MenuItem>}
                  {showScript === 'no' && <MenuItem value="Disconnected">Number Disconnected</MenuItem>}
                  <MenuItem value="WrongNumber">Wrong Number</MenuItem>
                  {showScript === 'yes' && <MenuItem value="Unavailable">Client Unavailable</MenuItem>}
                  {showScript === 'yes' && <MenuItem value="OtherContacts">Other Contacts (Friends, Family Teachers, NDB Contacts, etc.)</MenuItem>}
                  {showScript === 'yes' && <MenuItem value="Refused">Refused to Complete</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

ContactResult.propTypes = {
  contactMode: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  attemptResult: PropTypes.string.isRequired,
  setAttemptResult: PropTypes.func.isRequired,
  showScript: PropTypes.bool.isRequired,
  clientHaveTime: PropTypes.bool.isRequired,
  handleResultAttemptResult: PropTypes.func.isRequired,
};

export default ContactResult;
