import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { btNavyBlueColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    margin: '10px 0',
    padding: '10px 0',
  },
  link: {
    color: btNavyBlueColor,
    textDecoration: 'underline',
    fontSize: 16,
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: btNavyBlueColor,
  },
  spacer: {
    padding: '8px 0',
  },
}));

export default function Resources(props) {
  const classes = styles();

  return (
    <Box sx={{ width: '100%' }}>
      <section className={classes.title}>
        <span className={classes.iconText}>Education Resources</span>
      </section>
      <Grid container direction="row" align="left" className={classes.spacer}>
        <Grid item xs={12}>
          <a href="https://nebraska.kuder.com/landing-page" className={classes.link}>
            Nebraska Career Connections
          </a>
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" className={classes.spacer}>
        <Grid item xs={12}>
          <a href="https://www.educationquest.org" className={classes.link}>
            Education Quest
          </a>
          <aside className={classes.description}>
            Education Quest is a non-profit organization that provides FREE services and programs for Nebraska students and families.
          </aside>
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" className={classes.spacer}>
        <Grid item xs={12}>
          <a href="https://bigfuture.collegeboard.org/get-started/for-parents" className={classes.link}>
            Big Future/College Board (For Parents)
          </a>
        </Grid>
      </Grid>
    </Box>
  );
}
