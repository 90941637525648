import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core';
import Button from '../CustomButtons/Button.js';
import { observer } from 'mobx-react';
import { logException } from '../../services/appInsights';
// import { barelyGray } from "../../assets/jss/nextjs-material-kit-pro.js";
import { blackColor, whiteColor } from '../../assets/jss/nextjs-material-kit-pro';
import { callApi } from '../../util/apiWrapper';

const formStyles = makeStyles({
  formDates: {
    paddingLeft: -10,
    width: '100%',
  },
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIconContainer: {
    position: 'absolute',
    display: 'inline',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  dialog: {
    width: 500,
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  searchName: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
  },
  searchTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  photo: {
    '& img': {
      width: 60,
      height: 60,
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  searchButton: {
    marginLeft: 20,
  },
  staff: {
    marginTop: 20,
    '& .MuiCheckbox-root': {
      paddingTop: 0,
    },
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: '30px',
  },
  buttonWidth: {
    width: '110px',
  },
  error: {
    marginTop: 20,
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
});

const AddEmailDialog = observer((props) => {
  const store = props.SeniorStore;
  const auth = props.AuthStore;

  const formClasses = formStyles();

  const [senior, setSenior] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    setError('');
    async function fetchSenior() {
      try {
        if (store.addEmailId && store.addEmailId > 0) {
          store.setLoading(true);
          store.setSearching(true);
          const senior = await callApi(auth, 'student/' + store.addEmailId, 'GET');

          setSenior(senior.result);
          store.setSenior(senior.result);
          store.setSearching(false);
          store.setLoading(false);
        }
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error fetching senior:', error);
      }
    }
    fetchSenior();
  }, [store.addEmailId, store, auth]);

  const handleAddEmail = () => {
    if (validateGmail(email)) {
      async function addUser() {
        const existingSenior = store.allseniors.find((v) => v.user.email === email);
        if (existingSenior && existingSenior.id !== senior.id) {
          setError(`Another user is already using email address ${email}.`);
          return;
        }

        const emailObj = {
          student_id: senior.id,
          first_name: senior.first_name,
          last_name: senior.last_name,
          email: email,
        };

        await callApi(auth, 'studentuser', 'POST', {
          item: emailObj,
        });

        let allseniors = store.allseniors;
        allseniors.find((v) => v.id === senior.id).email = email;
        store.setAddEmailId(0);
        store.setSeniorData(allseniors);
        store.setAllSeniors(allseniors);
        store.setShowAddEmail(false);
        setError('');
        props.refresh();
      }
      addUser();
    } else {
      setError('You must enter a valid Gmail address.');
    }
  };

  const validateGmail = (email) => {
    const gmailRegex = /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@(?:gmail|GMAIL|test)([\.])(?:com|COM)/i;
    return gmailRegex.test(email);
  };

  return (
    <React.Fragment>
      <Dialog style={{ minHeight: 400 }} open={store.showAddEmail} onClose={props.handleClose} scroll="paper" aria-labelledby="add email address">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeaderFooter}>
            {senior && `Add Email Address for ${senior.first_name} ${senior.last_name}`}
          </DialogTitle>
          <DialogContent className={formClasses.dialog}>
            {senior && (
              <Box sx={{ width: '100%' }} key={senior.id}>
                <Grid container direction="row">
                  <Grid item xs={12} md={4} lg={3} className={formClasses.rowSpacer} style={{ marginTop: '10px' }}>
                    <label>Gmail Address</label>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9} className={formClasses.rowSpacer}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        autoFocus
                        size="small"
                        hiddenLabel
                        placeholder="enter new email address"
                        variant="outlined"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} style={{ marginTop: '5px' }} align="center">
                    {error && error.length > 0 && <summary className={formClasses.error}>{error}</summary>}
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
        </React.Fragment>
        <DialogActions className={formClasses.dialogHeaderFooter}>
          <Button className={formClasses.buttonWidth} onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            className={formClasses.buttonWidth}
            variant="contained"
            color="primary"
            disabled={!senior || !email ? true : false}
            onClick={handleAddEmail}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default AddEmailDialog;
