import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { dateUtcToS } from '../../../util/dateUtil';

const JobCorpsPlan = ({ form, setForm, classes, disabled }) => {
  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Career path to persue</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="career-path"
              size="small"
              hiddenLabel
              placeholder="Describe the career path I will be persuing"
              variant="outlined"
              value={form.careerPath}
              onChange={(event) => setForm({ ...form, careerPath: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Career path to persue</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          {form.careerPath}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Training Program</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="training-program"
              size="small"
              hiddenLabel
              placeholder="Training Program"
              variant="outlined"
              value={form.majorFieldOfWorkOrStudy}
              onChange={(event) => setForm({ ...form, majorFieldOfWorkOrStudy: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Training Program</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          {form.majorFieldOfWorkOrStudy}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Completed Application?</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Completed Application?"
              name="completed-job-corps-app"
              defaultValue={form.jobCorpsAppCompleted}
              value={form.jobCorpsAppCompleted}
              onChange={(event) => setForm({ ...form, jobCorpsAppCompleted: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Completed Application?</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={4} className={classes.rowSpacer}>
          {form.jobCorpsAppCompleted}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          {!disabled ? (
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                id="job-corps-admission-date"
                hiddenLabel
                disablePast
                value={form.jobCorpsAdmissionDate || null}
                onChange={(date) => {
                  setForm({ ...form, jobCorpsAdmissionDate: date ? date : null });
                }}
                format="MM/dd/yyyy"
                className={classes.datepicker}
                disabled={disabled}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
              {dateUtcToS(form.jobCorpsAdmissionDate || null)}
            </summary>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} className={classes.rowSpacer}>
          {dateUtcToS(form.jobCorpsAdmissionDate || null)}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Location/Site</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="job-corps-location"
              size="small"
              hiddenLabel
              placeholder="Location I will be serving"
              variant="outlined"
              value={form.jobCorpsLocation}
              onChange={(event) => setForm({ ...form, jobCorpsLocation: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Location/Site</label>
        </Grid>
        <Grid item xs={1} sm={true} className={classes.rowSpacer} />
        <Grid item xs={11} sm={10} className={classes.rowSpacer}>
          {form.jobCorpsLocation}
        </Grid>
      </Grid>
    </>
  );
};

JobCorpsPlan.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default JobCorpsPlan;
