import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import CollegeShared from './CollegeShared';

const TwoYearCollege = ({ form, setForm, classes, disabled }) => {
  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>ACCUPLACER</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl>
            <TextField
              id="accuplacer"
              size="small"
              hiddenLabel
              placeholder="ACCUPLACER Score"
              variant="outlined"
              value={form.accuplacerScore}
              onChange={(event) => setForm({ ...form, accuplacerScore: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControlLabel
            style={{ marginLeft: '10px' }}
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={form.accuplacerNotTaken ? form.accuplacerNotTaken : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    setForm({ ...form, accuplacerNotTaken: true, accuplacerScore: '' });
                  } else {
                    setForm({ ...form, accuplacerNotTaken: ev.target.checked });
                  }
                }}
              />
            }
            label="Have Not Taken"
          />
        </Grid>
      </Grid>
      {form.accuplacerNotTaken && (
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
            <label>Explain why ACCUPLACER not taken</label>
          </Grid>
          <Grid item xs={12} md={6} className={classes.rowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="accuplacer-not-taken"
                size="small"
                hiddenLabel
                placeholder="Please provide more information"
                variant="outlined"
                value={form.accuplacerNotTakenReason}
                onChange={(event) => setForm({ ...form, accuplacerNotTakenReason: event.target.value })}
                InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>ACCUPLACER</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={5} className={classes.rowSpacer}>
          {form.accuplacerScore}
        </Grid>
        {form.accuplacerNotTaken && (
          <Grid item xs={11} sm={5} className={classes.rowSpacer}>
            <span>ACCUPLACER not taken: </span>
            {form.accuplacerNotTakenReason}
          </Grid>
        )}
      </Grid>

      <CollegeShared form={form} setForm={setForm} classes={classes} disabled={disabled} />
    </>
  );
};

TwoYearCollege.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TwoYearCollege;
