import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { displayDateAsUTC } from '../../../../util/dateUtil';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  table: {
    width: '100%',
    padding: 0,
  },
  deleteButton: {
    marginTop: -5,
  },
  smallButton: {
    padding: 10,
  },
  smallIcon: {
    fontSize: '1.00rem',
  },
}));

export default function ExpandingWorkHistory(props) {
  const { form, setForm, listname, rowsRequired, disabled } = props;

  const classes = styles();

  const handleChange = (name, idx) => (e) => {
    const rows = [...form[listname]];
    switch (name) {
      case 'employer':
        rows[idx].employer = e.target.value;
        break;
      case 'startDate':
        rows[idx].startDate = displayDateAsUTC(e);
        break;
      case 'endDate':
        rows[idx].endDate = displayDateAsUTC(e);
        break;
      default:
        break;
    }
    setForm({ ...form, [listname]: rows });
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const rows = [...form[listname]];
    rows.splice(idx, 1);
    setForm({ ...form, [listname]: rows });
  };

  return (
    <Box sx={{ width: '95%' }}>
      <Grid container direction="row" className={classes.rowSpacer}>
        <Grid item xs={5} sm={3} md={3} lg={3}>
          <label className={classes.colHeader}>Employer</label>
        </Grid>
        <Grid item sm={1} />
        <Grid item xs={3} sm={3} md={3} lg={2}>
          <label className={classes.colHeader}>Start Date</label>
        </Grid>
        <Grid item sm={1} />
        <Grid item xs={3} sm={3} md={3} lg={2}>
          <label className={classes.colHeader}>End date</label>
        </Grid>
        <Grid item xs={1} className={classes.deleteButton}></Grid>
      </Grid>
      {form[listname] &&
        form[listname].length > 0 &&
        form[listname].map((item, idx) => (
          <React.Fragment key={`${idx}-${listname}`}>
            <Grid container direction="row" className={classes.rowSpacer}>
              <Grid item xs={5} sm={3} md={3} lg={3}>
                <FormControl fullWidth>
                  <TextField
                    id="work-history-employer"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={item.employer}
                    placeholder={idx + 1 + '.'}
                    onChange={handleChange('employer', idx)}
                    disabled={disabled}
                    InputProps={{ maxLength: 50, style: { height: 23, padding: '0 5px', margin: '2px 0' } }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={1} />
              <Grid item xs={3} sm={3} md={3} lg={2}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="work-history-start-date"
                      hiddenLabel
                      disableFuture
                      value={item.startDate || null}
                      disabled={disabled}
                      onChange={handleChange('startDate', idx)}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                      maxDateMessage="Date cannot be later than today."
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {displayDateAsUTC(item.startDate || null)}
                  </summary>
                )}
              </Grid>
              <Grid item sm={1} />
              <Grid item xs={3} sm={3} md={3} lg={2}>
                {!disabled ? (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      id="work-history-end-date"
                      hiddenLabel
                      disableFuture
                      value={item.endDate || null}
                      disabled={disabled}
                      onChange={handleChange('endDate', idx)}
                      format="MM/dd/yyyy"
                      className={classes.datepicker}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
                    {displayDateAsUTC(item.endDate || null)}
                  </summary>
                )}
              </Grid>
              <Grid item xs={1} className={classes.deleteButton}>
                {idx >= rowsRequired && (
                  <IconButton
                    className={classes.smallButton}
                    onClick={handleRemoveSpecificRow(idx)}
                    disabled={disabled}
                    key="delete"
                    aria-label="remove item from list"
                    color="inherit"
                  >
                    <CloseIcon className={classes.smallIcon} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
    </Box>
  );
}

ExpandingWorkHistory.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  listname: PropTypes.string,
  rowsRequired: PropTypes.number,
  disabled: PropTypes.bool,
};
