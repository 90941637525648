import { makeAutoObservable } from 'mobx';

class SeniorStore {
  loading = true;
  searching = false;
  seniordata = [];
  allseniors = [];
  totalSeniors = 0;
  senior = {};
  senior_planning_1 = {};
  senior_planning_2 = {};
  showBasicInfo = false;
  basicInfoId = 0;
  showAddEmail = false;
  addEmailId = 0;
  showAllRecords = false;
  showNeedEmail = false;
  showCompletedScholarshipApps = false;
  seniorPlan1 = {};
  seniorPlan2 = {};
  checklist = {};
  aftercareList = [];
  aftercareAppId = 0;
  aftercareApp = {};
  helpAppList = [];
  scholarshipList = [];
  recommendationList = [];
  helpAppId = 0;
  helpApp = {};
  contactId = 0;
  contact = {};
  scholarshipAppId = 0;
  scholarshipApp = {};
  newrecommendation = false;
  recommendationId = 0;
  recommendation = {};
  recommendationEmail = '';
  aftercareServiceTypeFilter = '';
  aftercareStatusFilter = '';
  aftercareSpecialistList = [];
  appCreated = false;
  page = 0;
  perPage = 15;
  advancedFilter = 'all';
  advancedFilterStatus = '';
  advancedFilterLoggedIn = '';
  advancedFilterSite = '';
  advancedFilterProgram = '';
  advancedFilterChecklist = '';
  advancedFilterPlanningForm1 = '';
  advancedFilterPlanningForm2 = '';
  advancedFilterScholarship = '';
  advancedFilterAftercare = '';
  alumni = false;
  reports = [];
  staffsites = [];
  checkInId = null;
  checkIn = {};
  checklistObj = {};
  planform1Obj = {};
  planform2Obj = {};
  scholarships = [];
  allAwards = [];
  appAwards = [];
  planningForm1Data = null;
  planningForm2Data = null;

  constructor(props) {
    // makeAutoObservable(this);
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get seniorPages() {
    // return Math.ceil(this.totalSeniors / this.perPage);
    return this.totalSeniors || 0;
  }

  get getAddEmailSenior() {
    let senior = null;
    if (this.seniordata && this.seniordata.length > 0) {
      senior = this.seniordata.filter((s) => s.id === this.addEmailId);
    }
    if (senior && senior.length === 1) {
      return senior[0];
    }
    return null;
  }

  setBasicInfoId = (val) => {
    this.basicInfoId = val;
  };

  setLoading = (val) => {
    this.loading = val;
  };

  setSearching = (val) => {
    this.searching = val;
  };

  setShowAllRecords = (checked) => {
    this.showAllRecords = checked;
    this.showNeedEmail = false;
  };

  setShowNeedEmail = (checked) => {
    this.showNeedEmail = checked;
    this.showAllRecords = false;
  };

  setShowCompletedScholarshipApps = (checked) => {
    this.showCompletedScholarshipApps = checked;
  };

  setShowBasicInfo = (val) => {
    this.showBasicInfo = val;
  };

  setSenior = (obj) => {
    this.senior = obj;
  };

  setSeniorForm2 = (obj) => {
    this.seniorForm2 = obj;
  };

  setSeniorPlan1 = (obj) => {
    this.seniorPlan1 = obj;
  };

  setSeniorPlan2 = (obj) => {
    this.seniorPlan2 = obj;
  };

  setChecklist = (obj) => {
    this.checklist = obj;
  };

  setChecklistObj = (obj) => {
    this.checklistObj = obj;
  };

  setPlanform1Obj = (obj) => {
    this.planform1Obj = obj;
  };

  setPlanform2Obj = (obj) => {
    this.planform2Obj = obj;
  };

  setAppCreated = (val) => {
    this.appCreated = val;
  };

  setAftercareList = (obj) => {
    this.aftercareList = obj;
  };

  setAftercareApp = (obj) => {
    this.aftercareApp = obj;
  };

  setAftercareAppId = (id) => {
    this.aftercareAppId = id;
  };

  setAftercareServiceTypeFilter = (val) => {
    this.aftercareServiceTypeFilter = val;
  };

  setAftercareStatusFilter = (val) => {
    this.aftercareStatusFilter = val;
  };

  setAlumni = (val) => {
    this.alumni = val;
  };

  get hasPendingScholarshipApp() {
    if (!this.scholarshipList || this.scholarshipList.length === 0) {
      return false;
    }
    return (
      this.scholarshipList.findIndex(
        (s) =>
          s.status === 'created' ||
          s.status === 'submitted' ||
          s.status === 'qualified' ||
          s.status === 'qualifiedinitial' ||
          s.status === 'qualifiedprogram' ||
          s.status === 'returned'
      ) > -1
    );
  }

  get hasPendingHelpApp() {
    if (!this.helpAppList || this.helpAppList.length === 0) {
      return false;
    }
    // return this.helpAppList.findIndex((s) => s.status === 'created' || s.status === 'submitted') > -1;
    return this.helpAppList;
  }

  setHelpAppList = (obj) => {
    this.helpAppList = obj;
  };

  setScholarshipList = (obj) => {
    this.scholarshipList = obj;
  };

  setRecommendationList = (obj) => {
    this.recommendationList = obj;
  };

  setHelpApp = (obj) => {
    this.helpApp = obj;
  };

  setContact = (obj) => {
    this.contact = obj;
  };

  setScholarshipApp = (obj) => {
    this.scholarshipApp = obj;
  };

  setRecommendation = (obj) => {
    this.recommendation = obj;
  };

  setNewRecommendation = (val) => {
    this.newrecommendation = val;
  };

  setHelpAppId = (id) => {
    this.helpAppId = id;
  };

  setContactId = (id) => {
    this.contactId = id;
  };

  setScholarshipAppId = (id) => {
    this.scholarshipAppId = id;
  };

  setRecommendationId = (id) => {
    this.recommendationId = id;
  };

  setCheckInId = (id) => {
    this.checkInId = id;
  };

  setCheckIn = (obj) => {
    this.checkIn = obj;
  };

  setRecommendationEmail = (email) => {
    this.recommendationEmail = email;
  };

  setAllSeniors = (data) => {
    this.allseniors = data;
  };

  setReports = (data) => {
    this.reports = data;
  };

  setSeniorData = (data) => {
    if (data) {
      this.seniordata = data;
      this.totalSeniors = data.length;
    }
  };

  setTotalSeniors = (val) => {
    this.totalSeniors = val;
  };

  setShowAddEmail = (val) => {
    this.showAddEmail = val;
  };

  setAddEmailId = (val) => {
    this.addEmailId = val;
  };

  setAftercareSpecialistList = (obj) => {
    this.aftercareSpecialistList = obj;
  };

  setScholarships = (list) => {
    this.scholarships = list;
  };

  setAllAwards = (list) => {
    this.allAwards = list;
  };

  setAppAwards = (list) => {
    this.appAwards = list;
  };

  setPlanningForm1Data = (data) => {
    this.planningForm1Data = data;
  };

  setPlanningForm2Data = (data) => {
    this.planningForm2Data = data;
  };

  setPage = (page) => {
    this.page = page;
  };

  async handlePageChange(ev, val) {
    this.searching = true;
    this.page = val;
    this.searching = false;
  }

  handleChangeRowsPerPage = (event) => {
    this.perPage = parseInt(event.target.value, 10);
    this.page = 0;
  };

  setAdvancedFilter = (val) => {
    this.advancedFilter = val;
  };

  setAdvancedFilterStatus = (val) => {
    this.advancedFilterStatus = val;
  };

  setAdvancedFilterLoggedIn = (val) => {
    this.advancedFilterLoggedIn = val;
  };

  setAdvancedFilterSite = (val) => {
    this.advancedFilterSite = val;
  };

  setAdvancedFilterProgram = (val) => {
    this.advancedFilterProgram = val;
  };

  setAdvancedFilterChecklist = (val) => {
    this.advancedFilterChecklist = val;
  };

  setAdvancedFilterPlanningForm1 = (val) => {
    this.advancedFilterPlanningForm1 = val;
  };

  setAdvancedFilterPlanningForm2 = (val) => {
    this.advancedFilterPlanningForm2 = val;
  };

  setAdvancedFilterScholarship = (val) => {
    this.advancedFilterScholarship = val;
  };

  setAdvancedFilterAftercare = (val) => {
    this.advancedFilterAftercare = val;
  };

  setStaffSites = (sites) => {
    if (sites) {
      this.staffsites = sites;
    }
  };
}

export default SeniorStore;
