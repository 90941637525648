import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, InputAdornment, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 85,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function SummerEmploymentPlan(props) {
  const { form, setForm, disabled, saveTalkedToManagerTask } = props;

  const classes = styles();

  return (
    <React.Fragment>
      <Box sx={{ width: '90%' }}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} md={1} lg={1} />
          <Grid item xs={12} md={5} lg={3} className={classes.textRowLabelSpacer}>
            <label>Where will I work?</label>
          </Grid>
          <Grid item xs={12} md={6} lg={8} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="summer-employ-work"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.summerEmployWork}
                onChange={(event) => setForm({ ...form, summerEmployWork: event.target.value })}
                disabled={disabled}
                InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.printedContent}>
          <div>
            <label className={classes.alignPrintedContent}>Where will I work?</label>
          </div>
          <div>
            <summary className={classes.alignPrintedContent}>{form.summerEmployWork || null}</summary>
          </div>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} md={1} lg={1} />
          <Grid item xs={12} md={5} lg={3} className={classes.textRowLabelSpacer}>
            <label>Have I applied or talked to a manager?</label>
          </Grid>
          <Grid item xs={6} md={4} lg={2} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="summer employment applied"
                name="summer-employ-apply"
                value={form.summerEmployApply}
                onChange={(event) => saveTalkedToManagerTask(event.target.value)}
                disabled={disabled}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>No</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <div>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={7}>
              <label className={classes.alignPrintedContent}>Have I applied or talked to a manager?</label>
            </Grid>
            <Grid item xs={5}>
              <summary className={classes.alignPrintedContent}>{form.summerEmployApply || null}</summary>
            </Grid>
          </Grid>
        </div>

        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={false} md={1} lg={1} />
          <Grid item xs={12} md={5} lg={3} className={classes.textRowLabelSpacer}>
            <label>How many hours per week am I available to work?</label>
          </Grid>
          <Grid item xs={6} md={3} lg={2} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="summer-employ-hours"
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                value={form.summerEmployHours}
                onChange={(event) => setForm({ ...form, summerEmployHours: event.target.value })}
                disabled={disabled}
                InputProps={{
                  maxLength: 10,
                  endAdornment: <InputAdornment position="end">hours per week</InputAdornment>,
                  classes: { input: classes.textField },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={7}>
              <label className={classes.alignPrintedContent}>How many hours am I available to work?</label>
            </Grid>
            <Grid item xs={5}>
              <summary className={classes.alignPrintedContent}>{form.summerEmployHours || null} hours per week</summary>
            </Grid>
          </Grid>
        </div>
      </Box>
    </React.Fragment>
  );
}

SummerEmploymentPlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
  saveTalkedToManagerTask: PropTypes.func,
};
