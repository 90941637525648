import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, TextareaAutosize } from '@material-ui/core';

const DesktopEval = ({
  formExtra,
  setFormExtra,
  record,
  setRecord,
  score_inspires,
  setScoreInspires,
  score_positive,
  setScorePositive,
  score_feedback,
  setScoreFeedback,
  score_progress,
  setScoreProgress,
  score_motivation,
  setScoreMotivation,
  score_awareness,
  setScoreAwareness,
  score_commitments,
  setScoreCommitments,
  score_resourceful,
  setScoreResourceful,
  recommend_scholarship,
  setRecommendScholarship,
  recommend_tradelife,
  setRecommendTradelife,
  recommend_housing,
  setRecommendHousing,
  recommendationText,
  setRecommendationText,
  classes,
}) => {
  return (
    <Grid container direction="row" className={classes.formContainer}>
      <Grid item xs={12} align="left">
        <strong>Please answer the following questions using the rating scale 1 (Strongly Disagree) to 5 (Strongly Agree).</strong>
        <Grid container direction="row">
          <Grid item xs={12} md={6} lg={6}>
            <br />
            <summary>
              1. {record.student_first_name} {record.student_last_name} includes and inspires others.
            </summary>
          </Grid>
          <Grid item xs={12} md={6} lg={6} align="left">
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="includes and inspires others"
                name="score_inspires-others"
                value={score_inspires}
                onChange={(event) => setScoreInspires(event.target.value)}
              >
                <Grid item xs={12} align="left" className={classes.radio}>
                  <FormControlLabel
                    value="Strongly Disagree"
                    control={<Radio color="default" />}
                    label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                  />
                </Grid>
                <Grid item xs={12} align="left" className={classes.radio}>
                  <FormControlLabel
                    value="Disagree"
                    control={<Radio color="default" />}
                    label={<span className={classes.radioLabel}>Disagree</span>}
                  />
                </Grid>
                <Grid item xs={12} align="left" className={classes.radio}>
                  <FormControlLabel value="Neither" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Neither</span>} />
                </Grid>
                <Grid item xs={12} align="left" className={classes.radio}>
                  <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                </Grid>
                <Grid item xs={12} align="left" className={classes.radio}>
                  <FormControlLabel
                    value="Strongly Agree"
                    control={<Radio color="default" />}
                    label={<span className={classes.radioLabel}>Strongly Agree</span>}
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                2. {record.student_first_name} {record.student_last_name} sets a positive example by being true to self.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="sets positive examples"
                  name="score_positive-examples"
                  value={score_positive}
                  onChange={(event) => setScorePositive(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                3. {record.student_first_name} {record.student_last_name} is open to feedback.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="open to feedback"
                  name="open-to-feedback"
                  value={score_feedback}
                  onChange={(event) => setScoreFeedback(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                4. {record.student_first_name} {record.student_last_name} has a desire to maintain progress with her/his/their life.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="maintain progress"
                  name="maintain-progress"
                  value={score_progress}
                  onChange={(event) => setScoreProgress(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                5. {record.student_first_name} {record.student_last_name} maintains motivation and determination in the face of adversity.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="maintains motivation"
                  name="maintains-motivation"
                  value={score_motivation}
                  onChange={(event) => setScoreMotivation(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                6. {record.student_first_name} {record.student_last_name} has awareness of doing the right thing and applies it.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="awareness"
                  name="awareness"
                  value={score_awareness}
                  onChange={(event) => setScoreAwareness(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                7. {record.student_first_name} {record.student_last_name} makes commitments and follows through.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="makes commitments"
                  name="makes-commitments"
                  value={score_commitments}
                  onChange={(event) => setScoreCommitments(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <br />
              <summary>
                8. {record.student_first_name} {record.student_last_name} is resourceful in securing a bright future.
              </summary>
            </Grid>
            <Grid item xs={12} md={6} lg={6} align="left">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="resourceful"
                  name="resourceful"
                  value={score_resourceful}
                  onChange={(event) => setScoreResourceful(event.target.value)}
                >
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Disagree</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Neither"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Neither</span>}
                    />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel value="Agree" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Agree</span>} />
                  </Grid>
                  <Grid item xs={12} align="left" className={classes.radio}>
                    <FormControlLabel
                      value="Strongly Agree"
                      control={<Radio color="default" />}
                      label={<span className={classes.radioLabel}>Strongly Agree</span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <strong>
            <br />
            <br />I would recommend {record.student_first_name} {record.student_last_name} for:
          </strong>

          {formExtra.scholarships && (
            <Grid container direction="row">
              <Grid item xs={12} md={6} lg={6}>
                <br />
                <summary>A Boys Town scholarship</summary>
              </Grid>
              <Grid item xs={12} md={6} lg={6} align="left">
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="Boys Town Scholarship"
                    name="boys-town-scholarship"
                    value={recommend_scholarship}
                    onChange={(event) => setRecommendScholarship(event.target.value)}
                  >
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel value="Yes" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Yes</span>} />
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel value="No" control={<Radio color="default" />} label={<span className={classes.radioLabel}>No</span>} />
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel
                        value="Hesitant"
                        control={<Radio color="default" />}
                        label={<span className={classes.radioLabel}>Hesitant</span>}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {formExtra.tradeLife && (
            <Grid container direction="row">
              <Grid item xs={12} md={6} lg={6}>
                <br />
                <summary>Successful Futures Trade Life Program</summary>
              </Grid>
              <Grid item xs={12} md={6} lg={6} align="left">
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="trade life program"
                    name="tradelife-program"
                    value={recommend_tradelife}
                    onChange={(event) => setRecommendTradelife(event.target.value)}
                  >
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel value="Yes" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Yes</span>} />
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel value="No" control={<Radio color="default" />} label={<span className={classes.radioLabel}>No</span>} />
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel
                        value="Hesitant"
                        control={<Radio color="default" />}
                        label={<span className={classes.radioLabel}>Hesitant</span>}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {formExtra.housing && (
            <Grid container direction="row">
              <Grid item xs={12} md={6} lg={6}>
                <br />
                <summary>Successful Futures Housing Program</summary>
              </Grid>
              <Grid item xs={12} md={6} lg={6} align="left">
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="housing program"
                    name="housing-program"
                    value={recommend_housing}
                    onChange={(event) => setRecommendHousing(event.target.value)}
                  >
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel value="Yes" control={<Radio color="default" />} label={<span className={classes.radioLabel}>Yes</span>} />
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel value="No" control={<Radio color="default" />} label={<span className={classes.radioLabel}>No</span>} />
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.radio}>
                      <FormControlLabel
                        value="Hesitant"
                        control={<Radio color="default" />}
                        label={<span className={classes.radioLabel}>Hesitant</span>}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} align="left">
          <br />
          <summary>
            <br />
            <strong>Please consider the above questions while writing your recommendation.</strong>
            <br />
            <br />
          </summary>
        </Grid>
        <Grid item xs={12} align="left">
          <div className={classes.textAreaWidth}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="plan-descriptionB"
                minRows={15}
                maxRows={15}
                maxLength={2000}
                value={recommendationText}
                onChange={(event) => setRecommendationText(event.target.value)}
                className={classes.textAreaFont}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

DesktopEval.propTypes = {
  formExtra: PropTypes.object,
  setFormExtra: PropTypes.func,
  record: PropTypes.object,
  setRecord: PropTypes.func,
  setIsLoading: PropTypes.func,
  score_inspires: PropTypes.string,
  setScoreInspires: PropTypes.func,
  score_positive: PropTypes.string,
  setScorePositive: PropTypes.func,
  score_feedback: PropTypes.string,
  setScoreFeedback: PropTypes.func,
  score_progress: PropTypes.string,
  setScoreProgress: PropTypes.func,
  score_motivation: PropTypes.string,
  setScoreMotivation: PropTypes.func,
  score_awareness: PropTypes.string,
  setScoreAwareness: PropTypes.func,
  score_commitments: PropTypes.string,
  setScoreCommitments: PropTypes.func,
  score_resourceful: PropTypes.string,
  setScoreResourceful: PropTypes.func,
  recommend_scholarship: PropTypes.string,
  setRecommendScholarship: PropTypes.func,
  recommend_tradelife: PropTypes.string,
  setRecommendTradelife: PropTypes.func,
  recommend_housing: PropTypes.string,
  setRecommendHousing: PropTypes.func,
  recommendationText: PropTypes.string,
  setRecommendationText: PropTypes.func,
  classes: PropTypes.object,
};

export default DesktopEval;
