import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Grid, FormControl, FormControlLabel } from '@material-ui/core';
import { btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  checklistRow: {
    margin: '10px 0',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checklistColumn: {
    alignItems: 'center',
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  pointsLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  dueLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    verticalAlign: 'top',
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,

    marginTop: 5,
  },
  underline: {
    margin: '5px 0',
    borderBottom: '1px solid #000',
  },
  checkOutline: {
    border: '1px solid #000',
    borderRadius: 6,
    maxWidth: '35px',
    alignContent: 'center',
    minWidth: '35px',
  },
}));

export default function WorkforceItems(props) {
  const { form, setForm, auth, alumni } = props;

  const classes = styles();

  const isFieldDisabled = (val) => {
    if (alumni) return true;
    return false;
  };

  const isConfirmDisabled = (val) => {
    if (alumni || auth.roles.includes('STUDENT')) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="61. Identify occupation path (kind of job to be performed)."
              disabled={isFieldDisabled(form.confirmWorkforceOccupation)}
              checked={form.workforceOccupation}
              onChange={(event) => setForm({ ...form, workforceOccupation: !form.workforceOccupation })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceOccupation)}
            checked={form.confirmWorkforceOccupation}
            onChange={(event) => setForm({ ...form, confirmWorkforceOccupation: !form.confirmWorkforceOccupation })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="61. Identify occupation path (kind of job to be performed)."
              checked={form.workforceOccupation}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmWorkforceOccupation}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="62. Explore housing options."
              disabled={isFieldDisabled(form.confirmWorkforceHousing)}
              checked={form.workforceHousing}
              onChange={(event) => setForm({ ...form, workforceHousing: !form.workforceHousing })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceHousing)}
            checked={form.confirmWorkforceHousing}
            onChange={(event) => setForm({ ...form, confirmWorkforceHousing: !form.confirmWorkforceHousing })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="62. Explore housing options."
              checked={form.workforceHousing}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmWorkforceHousing}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="63. Build a sample resume and have it checked."
              disabled={isFieldDisabled(form.confirmWorkforceResume)}
              checked={form.workforceResume}
              onChange={(event) => setForm({ ...form, workforceResume: !form.workforceResume })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceResume)}
            checked={form.confirmWorkforceResume}
            onChange={(event) => setForm({ ...form, confirmWorkforceResume: !form.confirmWorkforceResume })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="40. Build a sample resume and have it checked."
              checked={form.workforceResume}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmWorkforceResume}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="64. Create a list of potential employers in the area."
              disabled={isFieldDisabled(form.confirmWorkforceEmployers)}
              checked={form.workforceEmployers}
              onChange={(event) => setForm({ ...form, workforceEmployers: !form.workforceEmployers })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceEmployers)}
            checked={form.confirmWorkforceEmployers}
            onChange={(event) => setForm({ ...form, confirmWorkforceEmployers: !form.confirmWorkforceEmployers })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="64. Create a list of potential employers in the area."
              checked={form.workforceEmployers}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmWorkforceEmployers}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="65. Contact and get information for your references."
              disabled={isFieldDisabled(form.confirmWorkforceReferences)}
              checked={form.workforceReferences}
              onChange={(event) => setForm({ ...form, workforceReferences: !form.workforceReferences })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceReferences)}
            checked={form.confirmWorkforceReferences}
            onChange={(event) => setForm({ ...form, confirmWorkforceReferences: !form.confirmWorkforceReferences })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="65. Contact and get information for your references."
              checked={form.workforceReferences}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceReferences}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="66. Apply for full-time jobs."
              disabled={isFieldDisabled(form.confirmWorkforceJobApplications)}
              checked={form.workforceJobApplications}
              onChange={(event) => setForm({ ...form, workforceJobApplications: !form.workforceJobApplications })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March - April</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceJobApplications)}
            checked={form.confirmWorkforceJobApplications}
            onChange={(event) => setForm({ ...form, confirmWorkforceJobApplications: !form.confirmWorkforceJobApplications })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="66. Apply for full-time jobs."
              checked={form.workforceJobApplications}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceJobApplications}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March - April</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="67. Secure housing, pay deposits, and set moving date."
              disabled={isFieldDisabled(form.confirmWorkforceSecureHousing)}
              checked={form.workforceSecureHousing}
              onChange={(event) => setForm({ ...form, workforceSecureHousing: !form.workforceSecureHousing })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>April</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceSecureHousing)}
            checked={form.confirmWorkforceSecureHousing}
            onChange={(event) => setForm({ ...form, confirmWorkforceSecureHousing: !form.confirmWorkforceSecureHousing })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="67. Secure housing, pay deposits, and set moving date."
              checked={form.workforceSecureHousing}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceSecureHousing}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>April</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="68. Secure reliable transportation (public transportation, rides, etc.)"
              disabled={isFieldDisabled(form.confirmWorkforceTransportation)}
              checked={form.workforceTransportation}
              onChange={(event) => setForm({ ...form, workforceTransportation: !form.workforceTransportation })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>April</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceTransportation)}
            checked={form.confirmWorkforceTransportation}
            onChange={(event) => setForm({ ...form, confirmWorkforceTransportation: !form.confirmWorkforceTransportation })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="68. Secure reliable transportation (public transportation, rides, etc.)"
              checked={form.workforceTransportation}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceTransportation}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>April</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="69. Follow-up on your job applications."
              disabled={isFieldDisabled(form.confirmWorkforceFollowupOnApps)}
              checked={form.workforceFollowupOnApps}
              onChange={(event) => setForm({ ...form, workforceFollowupOnApps: !form.workforceFollowupOnApps })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>April</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceFollowupOnApps)}
            checked={form.confirmWorkforceFollowupOnApps}
            onChange={(event) => setForm({ ...form, confirmWorkforceFollowupOnApps: !form.confirmWorkforceFollowupOnApps })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="69. Follow-up on your job applications."
              checked={form.workforceFollowupOnApps}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceFollowupOnApps}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>April</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="70. Attend scheduled interviews."
              disabled={isFieldDisabled(form.confirmWorkforceInterviews)}
              checked={form.workforceInterviews}
              onChange={(event) => setForm({ ...form, workforceInterviews: !form.workforceInterviews })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>April - May</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceInterviews)}
            checked={form.confirmWorkforceInterviews}
            onChange={(event) => setForm({ ...form, confirmWorkforceInterviews: !form.confirmWorkforceInterviews })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="70. Attend scheduled interviews."
              checked={form.workforceInterviews}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceInterviews}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>April - May</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="71. Identify your start date."
              disabled={isFieldDisabled(form.confirmWorkforceFirstDateOfWork)}
              checked={form.workforceFirstDayOfWork}
              onChange={(event) => setForm({ ...form, workforceFirstDayOfWork: !form.workforceFirstDayOfWork })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>4</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>May</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.workforceFirstDayOfWork)}
            checked={form.confirmWorkforceFirstDateOfWork}
            onChange={(event) => setForm({ ...form, confirmWorkforceFirstDateOfWork: !form.confirmWorkforceFirstDateOfWork })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="71. Identify your start date."
              checked={form.workforceFirstDayOfWork}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            4<Checkbox color="default" disabled={true} checked={form.confirmWorkforceFirstDateOfWork}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>May</div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

WorkforceItems.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  auth: PropTypes.object,
  alumni: PropTypes.bool.isRequired,
};
