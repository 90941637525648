import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableContainer, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import { dateTimeToS } from '../../util/dateUtil';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { btLightBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import ContactCheckinDetails from './ContactCheckinDetails';

const ContactHistory = ({
  classes,
  tableClasses,
  history,
  specialistDict,
  getRecordDetails,
  convertResult,
  setShowHistory,
  handleHistoryDialogClose,
  historyRecord,
  historyDialogOpen,
  setHistoryDialogOpen,
}) => {
  return (
    <React.Fragment>
      <Grid container align="left" direction="row" className={classes.rowSpacer}>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          Proactive History
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} align="right" className={classes.rowSpacer}>
          <div style={{ margin: '-10px 15px 0 0' }} onClick={(event) => setShowHistory(false)}>
            <ExpandLessIcon fontSize="small" />
          </div>
        </Grid>
      </Grid>
      <Grid container align="left" direction="row" className={classes.rowSpacer}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.noRecordsMsg}>
          <TableContainer>
            <Table className={tableClasses.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.noSortHeader} style={{ width: '200px' }}>
                    DATE
                  </TableCell>
                  <TableCell className={classes.noSortHeader} style={{ width: '70px' }}>
                    TYPE
                  </TableCell>
                  <TableCell className={classes.noSortHeader} style={{ width: '200px' }}>
                    CALLER
                  </TableCell>
                  <TableCell className={classes.noSortHeader} style={{ width: '150px' }}>
                    RESULT
                  </TableCell>
                  <TableCell className={classes.noSortHeader} style={{ width: '200px' }}>
                    NOTES
                  </TableCell>
                  <TableCell style={{ width: '70px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((record, idx) => (
                  <TableRow key={record.id + '' + record.contact_attempt}>
                    <TableCell style={{ width: '200px' }}>{dateTimeToS(record.contact_date)}</TableCell>
                    <TableCell style={{ width: '70px' }}>{record.type}</TableCell>
                    <TableCell style={{ width: '200px' }}>{specialistDict[record.specialist_id]}</TableCell>
                    <TableCell style={{ width: '150px' }}>{convertResult(record.result)}</TableCell>
                    <TableCell style={{ width: '200px' }}>{record.notes}</TableCell>
                    <TableCell style={{ width: '70px' }}>
                      <span className={classes.detailsLink} onClick={(e) => getRecordDetails(record.id, record.contact_attempt)}>
                        <RemoveRedEyeIcon style={{ color: btLightBlueColor }} />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
                {!history || history.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={11} className={classes.rowSpacer}>
                      <summary className={classes.noRecordsMsg}>No records found.</summary>
                    </TableCell>
                  </TableRow>
                ) : (
                  <React.Fragment />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ContactCheckinDetails
        handleDialogClose={handleHistoryDialogClose}
        historyRecord={historyRecord}
        classes={classes}
        dialogOpen={historyDialogOpen}
        setDialogOpen={setHistoryDialogOpen}
      />
    </React.Fragment>
  );
};

ContactHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  tableClasses: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  specialistDict: PropTypes.object.isRequired,
  getRecordDetails: PropTypes.func.isRequired,
  convertResult: PropTypes.func.isRequired,
  setShowHistory: PropTypes.func.isRequired,
  handleHistoryDialogClose: PropTypes.func.isRequired,
  historyRecord: PropTypes.object.isRequired,
  historyDialogOpen: PropTypes.bool.isRequired,
  setHistoryDialogOpen: PropTypes.func.isRequired,
};

export default ContactHistory;
