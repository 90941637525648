import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { dateTimeToS, dateUtcToS } from '../../util/dateUtil';

const ContactResult = ({ historyRecord, handleDialogClose, classes, dialogOpen, setDialogOpen }) => {
  const displayAttemptMode = (mode) => {
    switch (mode) {
      case 'FocusMeeting':
        return 'Focus Meeting';
      case 'SocialMedia':
        return 'Social Media';
      default:
        return mode;
    }
  };

  const convertResult = (result) => {
    switch (result) {
      case 'WrongNumber':
        return 'Wrong Number';
      case 'NoAnswer':
        return 'No Answer';
      case 'VoiceMail':
        return 'Voice Mail';
      case 'OtherContacts':
        return 'Other Contacts';
      case 'EmailSent':
        return 'Email Sent';
      case 'TextSent':
        return 'Text Sent';
      case 'SocialMediaContact':
        return 'Social Media';
      default:
        return result;
    }
  };

  const displayHealth = (health) => {
    switch (health) {
      case 'good':
        return 'Good. No need for additional support.';
      case 'okay':
        return 'Okay. No need for additional support.';
      case 'notgood':
        return 'Not good. Need additional support. ***';
      default:
        return '';
    }
  };

  const displaySupport = (support) => {
    switch (support) {
      case 'no':
        return 'No.';
      case 'yesnosupport':
        return 'Yes, need support. ***';
      case 'yes':
        return 'Yes, but does not need support.';
      default:
        return '';
    }
  };

  const displayMoney = (money) => {
    switch (money) {
      case 'yesspent':
        return 'Yes, all awards were spent or will be spent within the next 3 months.';
      case 'yesworking':
        return 'Yes, I am working with my SFS to spend it.';
      case 'no':
        return "No, I didn't remember. Who do I need to talk to about it? ***";
      default:
        return '';
    }
  };

  const displayYesNo = (val) => {
    switch (val) {
      case 'yes':
        return 'Yes';
      case 'no':
        return 'No';
      default:
        return '';
    }
  };

  const displayFullPartTime = (val) => {
    switch (val) {
      case 'part':
        return 'Part-Time';
      case 'full':
        return 'Full-Time';
      default:
        return '';
    }
  };

  const displayPreventSchool = (details) => {
    let rc = [];
    if (details.noschoolchildcare) {
      rc.push('Childcare');
    }
    if (details.noschooldisability) {
      rc.push('Disablility');
    }
    if (details.noschoolfinancial) {
      rc.push('Financial');
    }
    if (details.noschoolhousing) {
      rc.push('Housing');
    }
    if (details.noschooleligibility) {
      rc.push('Eligibility');
    }
    if (details.noschoolhealth) {
      rc.push('Health');
    }
    if (details.noschooltransportation) {
      rc.push('Transportation');
    }
    if (details.noschoolother) {
      rc.push('Other');
    }
    return rc;
  };

  const displayPreventWork = (details) => {
    let rc = [];
    if (details.noworkchildcare) {
      rc.push('Childcare');
    }
    if (details.noworkdisability) {
      rc.push('Disablility');
    }
    if (details.noworkfinancial) {
      rc.push('Financial');
    }
    if (details.noworkhousing) {
      rc.push('Housing');
    }
    if (details.noworkeligibility) {
      rc.push('Eligibility');
    }
    if (details.noworkhealth) {
      rc.push('Health');
    }
    if (details.noworktransportation) {
      rc.push('Transportation');
    }
    if (details.noworkother) {
      rc.push('Other');
    }
    return rc;
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="Contact Details">
      {historyRecord && (
        <>
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Proactive Check-In Completed {dateUtcToS(historyRecord.contact_date)} by {historyRecord.first_name + ' ' + historyRecord.last_name}
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleDialogClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: 'center', color: 'red', fontWeight: 600 }}>
              {historyRecord.final_attempt ? '***FINAL ATTEMPT***' : ''}
            </DialogContentText>
            <DialogContentText>
              <Grid container direction="row" className={classes.rowSpacer}>
                <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                  <label>Attempt</label>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                  #{historyRecord.contact_attempt} ({displayAttemptMode(historyRecord.contact_mode)})
                </Grid>
              </Grid>
            </DialogContentText>
            <DialogContentText>
              <Grid container direction="row" className={classes.rowSpacer}>
                <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                  <label>Result</label>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                  {convertResult(historyRecord.result)}{' '}
                  {historyRecord.result === 'Reschedule' ? ' for ' + dateTimeToS(historyRecord.reschedule_date) : ''}
                </Grid>
              </Grid>
            </DialogContentText>
            {(historyRecord.result === 'Success' || historyRecord.result === 'Partial') && (
              <>
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                    <label>Are you currently attending school or vocational training?</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                    <strong>{displayYesNo(JSON.parse(historyRecord.contact_details).attendingSchool)}</strong>
                  </Grid>
                </Grid>
                {JSON.parse(historyRecord.contact_details).attendingSchool && JSON.parse(historyRecord.contact_details).attendingSchool === 'yes' && (
                  <>
                    <Grid container align="left" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>Are you going to school full-time or part-time?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{displayFullPartTime(JSON.parse(historyRecord.contact_details).schoolfullparttime)}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>Where are you attending school or vocational training?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).schoolname}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>What are you studying?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).major}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>How long have you been enrolled?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).enrolllength}</strong>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                    <label>Are you currently working?</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                    <strong>{displayYesNo(JSON.parse(historyRecord.contact_details).working)}</strong>
                  </Grid>
                </Grid>
                {JSON.parse(historyRecord.contact_details).working && JSON.parse(historyRecord.contact_details).working === 'yes' && (
                  <>
                    <Grid container align="left" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>How many hours are you currently working?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{displayFullPartTime(JSON.parse(historyRecord.contact_details).workfullparttime)}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>Where are you working?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).currentjob}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>What is your position?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).jobposition}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>How long have you been employed?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).employlength}</strong>
                      </Grid>
                    </Grid>
                  </>
                )}
                {JSON.parse(historyRecord.contact_details).attendingSchool &&
                  JSON.parse(historyRecord.contact_details).attendingSchool === 'no' &&
                  JSON.parse(historyRecord.contact_details).working &&
                  JSON.parse(historyRecord.contact_details).working === 'no' && (
                    <>
                      <Grid container align="left" className={classes.rowSpacer}>
                        <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                          <label>Are you scheduled to start working or studying in the next month?</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                          <strong>{displayYesNo(JSON.parse(historyRecord.contact_details).startingsoon)}</strong>
                        </Grid>
                      </Grid>
                    </>
                  )}
                {JSON.parse(historyRecord.contact_details).startingsoon && JSON.parse(historyRecord.contact_details).startingsoon === 'yes' && (
                  <>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>Where?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).startingsoonwhere}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>When do you start?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{dateUtcToS(JSON.parse(historyRecord.contact_details).startingsoonstartdate)}</strong>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                      <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                        <label>What is your schedule?</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                        <strong>{JSON.parse(historyRecord.contact_details).startingsoonschedule}</strong>
                      </Grid>
                    </Grid>
                  </>
                )}

                {JSON.parse(historyRecord.contact_details).attendingSchool &&
                  JSON.parse(historyRecord.contact_details).attendingSchool === 'no' &&
                  JSON.parse(historyRecord.contact_details).working &&
                  JSON.parse(historyRecord.contact_details).working === 'no' &&
                  JSON.parse(historyRecord.contact_details).startingsoon &&
                  JSON.parse(historyRecord.contact_details).startingsoon === 'no' && (
                    <>
                      <Grid container direction="row" className={classes.rowSpacer}>
                        <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                        <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                          <label>What barriers are preventing you from attending school.</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                          <strong>{displayPreventSchool(JSON.parse(historyRecord.contact_details)).join(', ')}</strong>
                        </Grid>
                      </Grid>
                      {JSON.parse(historyRecord.contact_details).noschoolother && (
                        <Grid container direction="row" className={classes.rowSpacer}>
                          <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                          <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}></Grid>
                          <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                            Other Explanation: <strong>{JSON.parse(historyRecord.contact_details).noschoolotherdesc}</strong>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container direction="row" className={classes.rowSpacer}>
                        <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                        <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}>
                          <label>What barriers are preventing you from working.</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                          <strong>{displayPreventWork(JSON.parse(historyRecord.contact_details)).join(', ')}</strong>
                        </Grid>
                      </Grid>
                      {JSON.parse(historyRecord.contact_details).noworkother && (
                        <Grid container direction="row" className={classes.rowSpacer}>
                          <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                          <Grid item xs={12} sm={12} md={8} lg={5} className={classes.question}></Grid>
                          <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                            Other Explanation: <strong>{JSON.parse(historyRecord.contact_details).noworkotherdesc}</strong>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} className={classes.question}>
                    <label>
                      Thanks for answering my questions. Do you need any support or services to continue on your path to success either at work or
                      school or finding an alternative?
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className={classes.answer}>
                    <strong>{displayYesNo(JSON.parse(historyRecord.contact_details).needsupport)}</strong>
                  </Grid>
                </Grid>
                {JSON.parse(historyRecord.contact_details).needsupport && JSON.parse(historyRecord.contact_details).needsupport === 'yes' && (
                  <Grid container direction="row" className={classes.rowSpacer}>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.question}>
                      <label id="resources-label">What resources do you need?</label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.answer}>
                      <strong>{JSON.parse(historyRecord.contact_details).resources}</strong>
                    </Grid>
                  </Grid>
                )}
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                    <label id="health-label">How is your health (mental and physical) these days?</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                    <strong>{displayHealth(JSON.parse(historyRecord.contact_details).health)}</strong>
                  </Grid>
                </Grid>
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                    <label id="housing-label">Are you experiencing any issues related to housing?</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                    <strong>{displaySupport(JSON.parse(historyRecord.contact_details).housing)}</strong>
                  </Grid>
                </Grid>
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={9} lg={6} className={classes.question}>
                    <label id="transportation-label">Are you experiencing any issues related to transportation?</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={6} className={classes.answer}>
                    <strong>{displaySupport(JSON.parse(historyRecord.contact_details).transportation)}</strong>
                  </Grid>
                </Grid>
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} className={classes.question}>
                    <label id="financial-question">
                      At graduation, you were awarded Cowboy Money, scholarships, and incentives. Seems like you have accessed{' '}
                      <span className={classes.awardAmount}>${JSON.parse(historyRecord.contact_details).moneyAccessed}</span> and have{' '}
                      <span className={classes.awardAmount}>${JSON.parse(historyRecord.contact_details).moneyRemaining}</span> remaining. Are you
                      aware of that?
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className={classes.answer}>
                    <strong>{displayMoney(JSON.parse(historyRecord.contact_details).money)}</strong>
                  </Grid>
                </Grid>
              </>
            )}
            <br />
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} className={classes.question}>
                <label id="financial-question">Additional Notes</label>
              </Grid>
              <Grid item xs={12} className={classes.answer}>
                <strong>{historyRecord.notes}</strong>
              </Grid>
            </Grid>
            <Grid container align="center" className={classes.rowSpacer}>
              <Grid item xs={12} style={{ fontStyle: 'italic' }}>
                {(JSON.parse(historyRecord.contact_details).health === 'notgood' ||
                  JSON.parse(historyRecord.contact_details).housing === 'yesnosupport' ||
                  JSON.parse(historyRecord.contact_details).transportation === 'yesnosupport' ||
                  JSON.parse(historyRecord.contact_details).money === 'no') &&
                  '*** Assistance may be required.'}
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary" autoFocus style={{ width: '130px' }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContactResult.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  historyRecord: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
};

export default ContactResult;
