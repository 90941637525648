import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { msalConfig } from '../../services/msal';
import { makeStyles } from '@material-ui/core/styles';
import { webInfoColor, whiteColor, btOrangeColor, webPrimaryColor, webSecondaryColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import Link from '@material-ui/core/Link';
import Button from '../CustomButtons/Button.js';
import '../../assets/css/nextjs-material-kit-pro.css';
import ScholarshipsBanner from '../../assets/img/scholarships-banner.png';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  intro: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '25px 15% 3% 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '25px 10% 10% 10%',
    },
  },
  offerTitle: {
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: webSecondaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '20px 15% 15px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '20px 10% 20px 10%',
    },
  },
  offers: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '25px 15% 25px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '25px 10% 25px 10%',
    },
  },
  offer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 0',
  },
  offerText: {
    color: webPrimaryColor,
    textAlign: 'left',
    padding: '5px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.063rem',
      lineHeight: '1.313rem',
      margin: '15px 0 10px 40px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.10rem',
      lineHeight: '1.55rem',
      margin: '9px 0 10px 40px',
    },
  },
  cardParagraph: {
    margin: '5px 0 5px 0',
    textDecoration: 'none !important',
    '&:hover,&:focus': {
      textDecoration: 'none !important',
      color: webPrimaryColor,
    },
  },
  signInButton: {
    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '102vw',
      margin: '10px 0 40px -5px',
    },
  },
  breadcrumb: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '10px 15% 10px 15%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  banner: {
    display: 'block',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  emailContact: {
    fontSize: '0.90rem',
  },
  phoneContact: {
    margin: '5px 0 5px 0',
  },
  bannerImage: {
    width: '100%',
  },
  relativePosition: {
    position: 'relative',
  },
  titleBannerContainer: {
    position: 'relative',
  },
  titleBanner: {
    position: 'absolute',
    backgroundColor: 'rgba(36, 62, 93, 0.4)',
    textAlign: 'left',
    height: '122px',
    minWidth: '100%',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      left: 0,
      top: '-170px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine1: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: btOrangeColor,
    fontSize: '2.50rem',
    lineHeight: '3.563rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-160px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine2: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: whiteColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '2.625rem',
    lineHeight: '3.438rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-115px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  requirementsTitle: {
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: webSecondaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '20px 15% 0 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '20px 10% 5% 10%',
    },
  },
  nextSteps: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '5px 15% 5px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 10% 10px 10%',
    },
  },
  requirementsList: {
    margin: '15px 0',
    [theme.breakpoints.up('md')]: {
      '& ul': {
        listStyle: 'none',
        '& li': {
          color: webPrimaryColor,
          padding: '5px 2ch',
          '&::marker': {
            content: "'»'",
            color: btOrangeColor,
            fontSize: '2.05rem',
          },
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      '& ul': {
        '& li': {
          color: webPrimaryColor,
          padding: '5px 2ch',
        },
      },
    },
  },
}));

const Scholarships = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const classes = useStyles();

    useEffect(() => {
      const handleLogin = async () => {
        await authStore.handleUserLogin(accounts[0]);
      };
      if (isAuthenticated && authStore.user == null) {
        console.log('authStore.user', authStore.user);
        handleLogin();
      }
    }, [accounts, authStore, isAuthenticated]);

    const handleSignIn = async () => {
      await instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
      const account = accounts[0] || null;
      authStore.handleUserLogin(account);
    };

    return (
      <div className={classes.root}>
        <main>
          <div className={classes.relativePosition}>
            <div className={classes.banner}>
              <img src={ScholarshipsBanner} alt="Scholarships Banner" className={classes.bannerImage} />
              <div className={classes.titleBannerContainer}>
                <div className={classes.titleBanner}></div>
                <div className={classes.titleBannerLine1}>Successful Futures</div>
                <div className={classes.titleBannerLine2}>Scholarships</div>
              </div>
            </div>
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumbs separator={<PlayArrowIcon style={{ fontSize: '1.50rem', color: webInfoColor }} />} aria-label="breadcrumb">
              <Link href="/home">
                <HomeIcon style={{ marginTop: '5px', fontSize: '1.80rem', color: webInfoColor }} />
              </Link>
              <Link href="/help" style={{ color: webSecondaryColor }}>
                How Can We Help?
              </Link>
              <div style={{ color: webSecondaryColor }}>Scholarships</div>
            </Breadcrumbs>
          </div>
          <p className={classes.intro}>
            Development opportunities that assist you in finding and choosing the best educational and occupational path is a key component of
            Successful Futures. This includes need-based and merit scholarships. Our staff can help you apply and receive the financial incentives you
            need to reach your goals.
          </p>
          <div className={classes.requirementsTitle}>What are the requirements?</div>
          <p className={classes.nextSteps}>
            To ensure the continuation of providing educational assistance to Boys Town Program Graduates, the following guidelines have been
            established to ensure fairness to all applicants.{' '}
            <strong>
              All applicants must have been a resident of the Home Campus at Boys Town, Nebraska, or under the care of one of Boys Town's satellite
              campuses, and meet the following criteria:
            </strong>
            <div className={classes.requirementsList}>
              <ul>
                <li>Be in good standing with Boys Town.</li>
                <li>
                  Graduated from Boys Town High School and/or lived at a Boys Town campus for a minimum of six months and departed the Family Home
                  Program successfully.
                </li>
                <li>If applying for an academic scholarship, you must attend an accredited institution of higher learning.</li>
                <li>Have and maintain a minimum of a 2.0 overall GPA in a 4.0 grading system or the equivalent in an alternate grading system.</li>
              </ul>
            </div>
          </p>

          <p>&nbsp;</p>
          <Button color="primary" className={classes.signInButton} onClick={(e) => handleSignIn()}>
            Register / Sign In
          </Button>
        </main>
      </div>
    );
  })
);

export default Scholarships;
