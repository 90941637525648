import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { msalConfig } from '../../services/msal';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { webPrimaryColor, webSecondaryColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import Hidden from '@material-ui/core/Hidden';
import Button from '../CustomButtons/Button.js';
import '../../assets/css/nextjs-material-kit-pro.css';
import PleaseWait from '../Shared/PleaseWait';
import { Card, CardContent, Typography } from '@material-ui/core';
import HomepageBanner from '../../assets/img/homepage-banner-2.jpg';
import LifePreserver from '../../assets/img/life-preserver.svg';
import AlumniAssoc from '../../assets/img/alumni-assoc-logo.svg';
import ContactTypes from '../../assets/img/contact-options.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    display: 'flex',
    position: 'relative',
  },
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '2.00em',
    [theme.breakpoints.up('md')]: {
      margin: '5% 0 0 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5% 0 0 0',
    },
  },
  cardContainer: {
    maxWidth: '275px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('md')]: {
      margin: '5%',
    },
    '&:hover,&:focus': {
      backgroundColor: 'rgba(63, 164, 240, 0.08)',
    },
  },
  cardSize: {
    height: '330px',
    borderRadius: '12px',
    border: `1px solid ${webSecondaryColor}`,
    backgroundColor: 'transparent !important',
  },
  cardTitle: {
    color: webSecondaryColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '1.20rem',
    lineHeight: '1.525rem',
    margin: '55px 0 10px 0',
    textAlign: 'center',
  },
  cardText: {
    color: webPrimaryColor,
    fontFamily: '"Source Sans Pro", Helvetica, Roboto, Arial, sans-serif',
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    '&:hover,&:focus': {
      color: webPrimaryColor,
    },
  },
  cardParagraph: {
    margin: '5px 0 5px 0',
    '&:hover,&:focus': {
      color: webPrimaryColor,
    },
  },
  signInButton: {
    [theme.breakpoints.up('xs')]: {
      width: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '102vw',
      margin: '-50px 0 -15% -10px',
    },
  },
  introContainer: {
    width: '100vw',
  },
  intro: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    width: '75vw',
    [theme.breakpoints.up('md')]: {
      margin: '25px 15% 75px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '25px 10% 65px 10%',
    },
  },
  banner: {
    display: 'block',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: 25,
    },
    [theme.breakpoints.down('md')]: {},
  },
  buttonContainer: {
    position: 'absolute',
    left: '24%',
    top: '73%',
  },
  cardIcon: {
    position: 'absolute',
    top: '-52px',
    left: '33%',
    background: '#fff',
  },
  homepageCards: {
    display: 'block',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  emailContact: {
    fontSize: '0.90rem',
  },
  phoneContact: {
    margin: '5px 0 5px 0',
  },
  bannerImage: {
    width: '100%',
  },
  relativePosition: {
    position: 'relative',
  },
}));

const Homepage = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    useEffect(() => {
      const handleLogin = async () => {
        await authStore.handleUserLogin(accounts[0]);
      };
      if (isAuthenticated && authStore.user == null) {
        handleLogin();
      }
    }, [accounts, authStore, isAuthenticated]);

    const handleSignIn = async () => {
      await instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
      const account = accounts[0] || null;
      authStore.handleUserLogin(account);
    };

    return (
      <div className={classes.root}>
        <PleaseWait isLoading={isLoading} />
        <main>
          <div className={classes.relativePosition}>
            <div className={classes.banner}>
              <img src={HomepageBanner} alt="Successful Futures Banner" className={classes.bannerImage} />
            </div>
            <div className={classes.buttonContainer}>
              <Hidden xsDown implementation="css" className={classes.hidden}>
                <Button color="primary" className={classes.signInButton} onClick={(e) => handleSignIn()}>
                  Register / Sign In
                </Button>
              </Hidden>
            </div>
            <Hidden smUp implementation="css" className={classes.hidden}>
              <Button color="secondary" className={classes.signInButton} onClick={(e) => handleSignIn()}>
                Register / Sign In
              </Button>
            </Hidden>{' '}
          </div>
          <div className={classes.introContainer}>
            <p className={classes.intro}>
              The transition from high school to adult life can be difficult for any young person. Boys Town’s Successful Futures program was designed
              to prepare young men and women, both before and after high school graduation, to successfully make this transition.
            </p>
          </div>
          <div className={classes.homepageCards}>
            <div id="cards" className={classes.cardGrid}>
              <summary className={classes.cardContainer} align="left">
                <Link href="/help" style={{ textDecoration: 'none' }}>
                  <img src={LifePreserver} alt="Life Preserver" className={classes.cardIcon} />
                  <Card className={classes.cardSize}>
                    <CardContent>
                      <Typography gutterBottom component="div" className={classes.cardTitle}>
                        How Can We Help You?
                      </Typography>
                      <Typography className={classes.cardText}>
                        <strong>Let's Do This Together!</strong>
                        <div className={classes.cardParagraph}>
                          Our staff provides stable support systems on the journey to adulthood, including crisis Intervention to help graduates cope
                          with stressful events. Remember, if you are in a crisis situation and need immediate assistance, call the Boys Town National
                          Hotline at 800-448-3000.
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </summary>
              <summary className={classes.cardContainer} align="left">
                <Link href="/alumni" style={{ textDecoration: 'none' }}>
                  <img src={AlumniAssoc} alt="Alumni Association" className={classes.cardIcon} />
                  <Card className={classes.cardSize}>
                    <CardContent>
                      <Typography gutterBottom component="div" className={classes.cardTitle}>
                        Our Alumni Association
                      </Typography>
                      <Typography className={classes.cardText}>
                        <strong>Connect With Fellow Alumni</strong>
                        <div className={classes.cardParagraph}>
                          Explore your Boys Town National Alumni Association® and connect with classmates and friends. Join your brother and sister
                          alumni as members of the BTNAA. Use the online directory and reach out to others. Learn more by clicking below or go to{' '}
                          <u>
                            <a href="https://www.boystownalumni.org" target="_blank" rel="noreferrer">
                              boystownalumni.org
                            </a>
                          </u>
                          .
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </summary>
              <summary className={classes.cardContainer} align="left">
                <Link href="mailto:successfulfutures@boystown.org" style={{ textDecoration: 'none' }}>
                  <img src={ContactTypes} alt="Methods of Contact" className={classes.cardIcon} />
                  <Card className={classes.cardSize}>
                    <CardContent>
                      <Typography gutterBottom component="div" className={classes.cardTitle}>
                        Contact Us
                      </Typography>
                      <Typography className={classes.cardText}>
                        <strong className={classes.emailContact}>SuccessfulFutures@Boystown.org</strong>
                        <div className={classes.phoneContact}>531-355-3060</div>
                        With Successful Futures, someone is always here to assist you. We can help with the application process, or, once in the
                        program, someone is available 24 hours a day to help you make the transition to independent living.
                        <br />
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </summary>
            </div>
          </div>
        </main>
      </div>
    );
  })
);

export default Homepage;
