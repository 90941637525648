import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Grid, FormControl, FormControlLabel } from '@material-ui/core';
import { btMediumGrayColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  checklistRow: {
    margin: '10px 0',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checklistColumn: {
    alignItems: 'center',
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  pointsLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  dueLabel: {
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: btMediumGrayColor,
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '160px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    verticalAlign: 'top',
    fontSize: '1.00rem',
    lineHeight: 1.5,
    fontWeight: 400,

    marginTop: 5,
  },
  underline: {
    margin: '5px 0',
    borderBottom: '1px solid #000',
  },
  checkOutline: {
    border: '1px solid #000',
    borderRadius: 6,
    maxWidth: '35px',
    alignContent: 'center',
    minWidth: '35px',
  },
  subtleBold: {
    fontWeight: 500,
    color: btMediumGrayColor,
  },
}));

export default function PostSecondaryEducationItems(props) {
  const { form, setForm, auth, act, alumni } = props;

  const classes = styles();

  const isFieldDisabled = (val) => {
    if (alumni) return true;
    return false;
  };

  const isConfirmDisabled = (val) => {
    if (alumni || auth.roles.includes('STUDENT')) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="15. Take or retake ACT/Accuplacer."
              disabled={isFieldDisabled(form.confirmSecedTakeACTOrAccuplacer)}
              checked={form.secedTakeACTOrAccuplacer}
              onChange={(event) => setForm({ ...form, secedTakeACTOrAccuplacer: !form.secedTakeACTOrAccuplacer })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedTakeACTOrAccuplacer)}
            checked={form.confirmSecedTakeACTOrAccuplacer}
            onChange={(event) => setForm({ ...form, confirmSecedTakeACTOrAccuplacer: !form.confirmSecedTakeACTOrAccuplacer })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="15. Take or retake ACT/Accuplacer."
              checked={form.secedTakeACTOrAccuplacer}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecedTakeACTOrAccuplacer}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ margin: '0 0 10px 60px' }}>
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <label>ACT Score: {act}</label>
        </Grid>
        <Grid item xs={9} sm={9} md={10} lg={10}>
          {auth.roles.includes('STUDENT') && (
            <label className={classes.unprintedContent}>
              <em>*Your ACT score can be entered on the Personal Information page.</em>
            </label>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}></Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="16. Explore and visit colleges/community colleges to which you might want to apply."
              disabled={isFieldDisabled(form.confirmSecedVisitSchools)}
              checked={form.secedVisitSchools}
              onChange={(event) => setForm({ ...form, secedVisitSchools: !form.secedVisitSchools })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>1st Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedVisitSchools)}
            checked={form.confirmSecedVisitSchools}
            onChange={(event) => setForm({ ...form, confirmSecedVisitSchools: !form.confirmSecedVisitSchools })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="16. Explore and visit colleges/community colleges to which you might want to apply."
              checked={form.secedVisitSchools}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecedVisitSchools}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>1st Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="17. Go to FAFSA.ed.gov and create a Federal Student Aid Account. (one for you and one for a parent, if applicable.) It may be easiest to have a parent create their FAFSA ID first and then create your FAFSA ID."
              disabled={isFieldDisabled(form.confirmSecedFASID)}
              checked={form.secedFASID}
              onChange={(event) => setForm({ ...form, secedFASID: !form.secedFASID })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>3</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedFASID)}
            checked={form.confirmSecedFASID}
            onChange={(event) => setForm({ ...form, confirmSecedFASID: !form.confirmSecedFASID })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="17. Go to FAFSA.ed.gov and create a Federal Student Aid Account. (one for you and one for a parent, if applicable.) It may be easiest to have a parent create their FAFSA ID first and then create your FAFSA ID."
              checked={form.secedFASID}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            3<Checkbox color="default" disabled={true} checked={form.confirmSecedFASID}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="18. Complete the FAFSA on or after October 1st."
              disabled={isFieldDisabled(form.confirmSecedCompleteFAFSA)}
              checked={form.secedCompleteFAFSA}
              onChange={(event) => setForm({ ...form, secedCompleteFAFSA: !form.secedCompleteFAFSA })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedCompleteFAFSA)}
            checked={form.confirmSecedCompleteFAFSA}
            onChange={(event) => setForm({ ...form, confirmSecedCompleteFAFSA: !form.confirmSecedCompleteFAFSA })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="18. Complete the FAFSA on or after October 1st."
              checked={form.secedCompleteFAFSA}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedCompleteFAFSA}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="19. Submit two or more completed college applications."
              disabled={isFieldDisabled(form.confirmSecedCollegeApplications)}
              checked={form.secedCollegeApplications}
              onChange={(event) => setForm({ ...form, secedCollegeApplications: !form.secedCollegeApplications })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedCollegeApplications)}
            checked={form.confirmSecedCollegeApplications}
            onChange={(event) => setForm({ ...form, confirmSecedCollegeApplications: !form.confirmSecedCollegeApplications })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="19. Submit two or more completed college applications."
              checked={form.secedCollegeApplications}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedCollegeApplications}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="20. Ensure ACT scores are forwarded to the schools to which you have applied."
              disabled={isFieldDisabled(form.confirmSecedACTForwarded)}
              checked={form.secedACTForwarded}
              onChange={(event) => setForm({ ...form, secedACTForwarded: !form.secedACTForwarded })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2.5</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedACTForwarded)}
            checked={form.confirmSecedACTForwarded}
            onChange={(event) => setForm({ ...form, confirmSecedACTForwarded: !form.confirmSecedACTForwarded })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="20. Ensure ACT scores are forwarded to the schools to which you have applied."
              checked={form.secedACTForwarded}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2.5
            <Checkbox color="default" disabled={true} checked={form.confirmSecedACTForwarded}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="21. Ensure FAFSA information is sent to the schools to which you have applied."
              disabled={isFieldDisabled(form.confirmSecedFAFSASent)}
              checked={form.secedFAFSASent}
              onChange={(event) => setForm({ ...form, secedFAFSASent: !form.secedFAFSASent })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2.5</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedFAFSASent)}
            checked={form.confirmSecedFAFSASent}
            onChange={(event) => setForm({ ...form, confirmSecedFAFSASent: !form.confirmSecedFAFSASent })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="21. Ensure FAFSA information is sent to the schools to which you have applied."
              checked={form.secedFAFSASent}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2.5
            <Checkbox color="default" disabled={true} checked={form.confirmSecedFAFSASent}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="22. Locate the Student Aid Index (SAI) in your email."
              disabled={isFieldDisabled(form.confirmSecedLocateStudentAidRpt)}
              checked={form.secedLocateStudentAidRpt}
              onChange={(event) => setForm({ ...form, secedLocateStudentAidRpt: !form.secedLocateStudentAidRpt })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedLocateStudentAidRpt)}
            checked={form.confirmSecedLocateStudentAidRpt}
            onChange={(event) => setForm({ ...form, confirmSecedLocateStudentAidRpt: !form.confirmSecedLocateStudentAidRpt })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="22. Locate the Student Aid Index (SAI) in your email."
              checked={form.secedLocateStudentAidRpt}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedLocateStudentAidRpt}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="23. Print Student Aid Index (to be submitted with your Boys Town scholarship application) and keep a copy of it in your senior binder."
              disabled={isFieldDisabled(form.confirmSecedStudentAidRpt)}
              checked={form.secedStudentAidRpt}
              onChange={(event) => setForm({ ...form, secedStudentAidRpt: !form.secedStudentAidRpt })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedStudentAidRpt)}
            checked={form.confirmSecedStudentAidRpt}
            onChange={(event) => setForm({ ...form, confirmSecedStudentAidRpt: !form.confirmSecedStudentAidRpt })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="23. Print Student Aid Index (to be submitted with your Boys Town scholarship application) and keep a copy of it in your senior binder."
              checked={form.secedStudentAidRpt}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedStudentAidRpt}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="24. Submit two or more completed college scholarship applications to the school(s) to which you are applying."
              disabled={isFieldDisabled(form.confirmSeced2ScholarshipApps)}
              checked={form.seced2ScholarshipApps}
              onChange={(event) => setForm({ ...form, seced2ScholarshipApps: !form.seced2ScholarshipApps })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.seced2ScholarshipApps)}
            checked={form.confirmSeced2ScholarshipApps}
            onChange={(event) => setForm({ ...form, confirmSeced2ScholarshipApps: !form.confirmSeced2ScholarshipApps })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="24. Submit two or more completed college scholarship applications to the school(s) to which you are applying."
              checked={form.seced2ScholarshipApps}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSeced2ScholarshipApps}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="25. Explore and complete external college scholarship applications (i.e., Buffet Scholarship, GROTC, Goodrich, etc.)."
              disabled={isFieldDisabled(form.confirmSecedExternalScholarships)}
              checked={form.secedExternalScholarships}
              onChange={(event) => setForm({ ...form, secedExternalScholarships: !form.secedExternalScholarships })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>2nd Senior Planning Meeting</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedExternalScholarships)}
            checked={form.confirmSecedExternalScholarships}
            onChange={(event) => setForm({ ...form, confirmSecedExternalScholarships: !form.confirmSecedExternalScholarships })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="25. Explore and complete external college scholarship applications (i.e., Buffet Scholarship, GROTC, Goodrich, etc.)."
              checked={form.secedExternalScholarships}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedExternalScholarships}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>2nd Senior Planning Meeting</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="26. Gather acceptance letters."
              disabled={isFieldDisabled(form.confirmSecedAcceptanceLetters)}
              checked={form.secedAcceptanceLetters}
              onChange={(event) => setForm({ ...form, secedAcceptanceLetters: !form.secedAcceptanceLetters })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedAcceptanceLetters)}
            checked={form.confirmSecedAcceptanceLetters}
            onChange={(event) => setForm({ ...form, confirmSecedAcceptanceLetters: !form.confirmSecedAcceptanceLetters })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="26. Gather acceptance letters."
              checked={form.secedAcceptanceLetters}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedAcceptanceLetters}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="27. Once accepted, obtain student ID/password used to log into your school account. Store this ID and password in your senior binder."
              disabled={isFieldDisabled(form.confirmSecedStudentId)}
              checked={form.secedStudentId}
              onChange={(event) => setForm({ ...form, secedStudentId: !form.secedStudentId })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedStudentId)}
            checked={form.confirmSecedStudentId}
            onChange={(event) => setForm({ ...form, confirmSecedStudentId: !form.confirmSecedStudentId })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="27. Once accepted, obtain student ID/password used to log into your school account. Store this ID and password in your senior binder."
              checked={form.secedStudentId}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedStudentId}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="28. Log into the school account and review financial aid documents. Keep a copy of these documents in your senior binder."
              disabled={isFieldDisabled(form.confirmSecedLogInFinancialAid)}
              checked={form.secedLogInFinancialAid}
              onChange={(event) => setForm({ ...form, secedLogInFinancialAid: !form.secedLogInFinancialAid })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedLogInFinancialAid)}
            checked={form.confirmSecedLogInFinancialAid}
            onChange={(event) => setForm({ ...form, confirmSecedLogInFinancialAid: !form.confirmSecedLogInFinancialAid })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="28. Log into the school account and review financial aid documents. Keep a copy of these documents in your senior binder."
              checked={form.secedLogInFinancialAid}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedLogInFinancialAid}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="29. Log into the school account and check for other documents that need to be submitted to the school."
              disabled={isFieldDisabled(form.confirmSecedLogInOtherDocs)}
              checked={form.secedLogInOtherDocs}
              onChange={(event) => setForm({ ...form, secedLogInOtherDocs: !form.secedLogInOtherDocs })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedLogInOtherDocs)}
            checked={form.confirmSecedLogInOtherDocs}
            onChange={(event) => setForm({ ...form, confirmSecedLogInOtherDocs: !form.confirmSecedLogInOtherDocs })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="29. Log into the school account and check for other documents that need to be submitted to the school."
              checked={form.secedLogInOtherDocs}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedLogInOtherDocs}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="30. To attend school, you must have a place to live. Finalize your housing options (on campus or off campus) and, if applicable, apply for housing and submit deposts. Keep a copy of the receipts and/or documents in your senior binder."
              disabled={isFieldDisabled(form.confirmSecedCampusHousing)}
              checked={form.secedCampusHousing}
              onChange={(event) => setForm({ ...form, secedCampusHousing: !form.secedCampusHousing })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>March - April</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedCampusHousing)}
            checked={form.confirmSecedCampusHousing}
            onChange={(event) => setForm({ ...form, confirmSecedCampusHousing: !form.confirmSecedCampusHousing })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="30. To attend school, you must have a place to live. Finalize your housing options (on campus or off campus) and, if applicable, apply for housing and submit deposts. Keep a copy of the receipts and/or documents in your senior binder."
              checked={form.secedCampusHousing}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedCampusHousing}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>March - April</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="31. Request final transcripts be submitted to the college you are going to attend."
              disabled={isFieldDisabled(form.confirmSecedTranscripts)}
              checked={form.secedTranscripts}
              onChange={(event) => setForm({ ...form, secedTranscripts: !form.secedTranscripts })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>May</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedTranscripts)}
            checked={form.confirmSecedTranscripts}
            onChange={(event) => setForm({ ...form, confirmSecedTranscripts: !form.confirmSecedTranscripts })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="31. Request final transcripts be submitted to the college you are going to attend."
              checked={form.secedTranscripts}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedTranscripts}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>May</div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 10 }}
        className={classes.unprintedContent + ' ' + classes.checkListRow}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.checklistColumn}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="32. Note the dates for college summer orientation and class registration on your calendar and keep a copy of these dates in your senior binder."
              disabled={isFieldDisabled(form.confirmSecedNoteDates)}
              checked={form.secedNoteDates}
              onChange={(event) => setForm({ ...form, secedNoteDates: !form.secedNoteDates })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '3%' }} className={classes.checklistColumn}>
          <div className={classes.pointsLabel}>2</div>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} align="left" className={classes.checklistColumn}>
          <div className={classes.dueLabel}>May</div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} lg={1} align="right" style={{ paddingRight: '2.5%' }} className={classes.checklistColumn}>
          <Checkbox
            color="default"
            disabled={isConfirmDisabled(form.secedNoteDates)}
            checked={form.confirmSecedNoteDates}
            onChange={(event) => setForm({ ...form, confirmSecedNoteDates: !form.confirmSecedNoteDates })}
          ></Checkbox>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent + ' ' + classes.underline}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox color="default" style={{ alignSelf: 'flex-start' }} />}
              label="32. Note the dates for college summer orientation and class registration on your calendar and keep a copy of these dates in your senior binder."
              checked={form.secedNoteDates}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingRight: 40 }}>
          <div className={classes.alignPrintedContent}>
            2<Checkbox color="default" disabled={true} checked={form.confirmSecedNoteDates}></Checkbox>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.alignPrintedContent}>May</div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

PostSecondaryEducationItems.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  auth: PropTypes.object,
  act: PropTypes.number,
  alumni: PropTypes.bool.isRequired,
};
