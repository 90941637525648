import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormLabel,
  FormControl,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Button from '../../CustomButtons/Button.js';
import { blackColor, whiteColor } from '../../../assets/jss/nextjs-material-kit-pro';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { dateToMMMMYYYY } from '../../../util/dateUtil';

const formStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  dialog: {
    width: 600,
    minHeight: 450,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeader: {
    backgroundColor: whiteColor,
  },
  dialogFooter: {
    backgroundColor: whiteColor,
    margin: '10px 30px 10px 0',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  search: {
    paddingBottom: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  searchButton: {
    marginLeft: 20,
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: 35,
  },
  growingSpacer: {
    minHeight: 35,
  },
  buttonWidth: {
    width: 120,
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  instructions: {
    fontSize: '.95rem',
    lineHeight: '1.15rem',
    fontWeight: 500,
  },
  denyInstructions: {
    color: 'blue',
    fontWeight: 600,
  },
}));

const DetermineAccess = observer((props) => {
  const formClasses = formStyles();

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  return (
    <React.Fragment>
      <Dialog open={props.showDetermineAccess} onClose={props.handleClose} scroll="paper" aria-labelledby="grant access">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeader}>
            {props.request && `Approve access request for ${props.request.first_name} ${props.request.last_name}`}
          </DialogTitle>
          <DialogContent className={formClasses.dialog}>
            {props.request && (
              <Box sx={{ width: '100%' }}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Name</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={11} sm={12} md={8} className={formClasses.rowSpacer}>
                    {props.request.first_name} {props.request.last_name}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Email</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={12} sm={12} md={8} className={formClasses.rowSpacer}>
                    {props.request.email}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Phone Number</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={12} sm={12} md={8} className={formClasses.rowSpacer}>
                    {formatPhoneNumber(props.request.phone)}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Date of Birth</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={12} sm={12} md={8} className={formClasses.rowSpacer}>
                    {props.request.dob_formatted}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Program Attended</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={12} sm={12} md={8} className={formClasses.rowSpacer}>
                    {props.request.program_attended}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Admission Date</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={12} sm={12} md={8} className={formClasses.rowSpacer}>
                    {dateToMMMMYYYY(props.request.admission_date_formatted)}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={3} className={formClasses.rowSpacer}>
                    <label>Departure Date</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  <Grid item xs={12} sm={12} md={8} className={formClasses.rowSpacer}>
                    {dateToMMMMYYYY(props.request.departure_date_formatted)}
                  </Grid>
                </Grid>{' '}
                <Grid container direction="row" className={formClasses.growingSpacer}>
                  <Grid item xs={12} sm={12} md={4}>
                    <label>Additional Information</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    {props.request.note}
                  </Grid>
                </Grid>
                {/* Disposition Panel */}
                <Grid
                  container
                  direction="row"
                  className={formClasses.unprintedContent}
                  style={{ marginTop: 10, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
                >
                  <Grid item xs={12} align="center">
                    <label style={{ fontWeight: 600 }}>Successful Futures Administration Only</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={6} className={formClasses.textArea5RowSpacer}>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="approve access request"
                        name="approve-access-request"
                        horizontal="true"
                        value={props.disposition}
                        onChange={(event) => props.setDisposition(event.target.value)}
                      >
                        <FormControlLabel
                          value="approved"
                          className={formClasses.marginRight40}
                          control={<Radio color="default" />}
                          label={
                            <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                              Approved
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="denied"
                          className={formClasses.marginRight40}
                          control={<Radio color="default" />}
                          label={
                            <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                              Denied
                            </span>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={formClasses.rowSpacer} style={{ padding: '10px 0', height: '70px' }}>
                    <FormControl>
                      <TextField
                        id="ndb-id"
                        size="small"
                        label="NDB Id"
                        variant="outlined"
                        value={props.ndbId}
                        onChange={(event) => props.setNdbId(event.target.value)}
                        InputProps={{ maxLength: 10 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={formClasses.rowSpacer} style={{ height: '70px' }}>
                    <summary className={formClasses.denyInstructions}>Reason for Denial:</summary>
                    <FormControl fullWidth>
                      <TextField
                        id="deny-explain"
                        size="small"
                        label="Please explain why the request was denied."
                        variant="outlined"
                        value={props.denyExplain}
                        onChange={(event) => props.setDenyExplain(event.target.value)}
                        InputProps={{ maxLength: 500 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '15px' }}>
                    <FormLabel error>{props.errorMsg}</FormLabel>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
        </React.Fragment>

        <DialogActions className={formClasses.dialogFooter}>
          <Button
            className={formClasses.buttonWidth}
            color="default"
            variant="contained"
            onClick={(event) => props.handleClose()}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <span>
            <Button
              className={formClasses.buttonWidth}
              color="primary"
              variant="contained"
              onClick={(event) => props.handleDetermineAccess(props.request, props.disposition === 'approved' ? true : false, props.ndbId)}
              startIcon={<CheckIcon />}
              disabled={props.disposition.length === 0}
            >
              Save
            </Button>
          </span>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default DetermineAccess;
