import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import AccessRequestCard from './accessRequestCard';

const AccessRequestListCards = ({list, showAllRecords}) => {

  return (
    <>
    <Grid container direction="row">
      {list && list.map(r => {
        return (
          <Grid item xs={12} sm={6} md={4} key={r.id}>
            <AccessRequestCard request={r} showAllRecords={showAllRecords} />
          </Grid>
        )
      })}
    </Grid>
    </>
  )
}

AccessRequestListCards.propTypes = {
  list: PropTypes.array.isRequired,
  showAllRecords: PropTypes.bool.isRequired
}


export default AccessRequestListCards;

