import {
  container,
  webPrimaryColor,
  webSecondaryColor,
  whiteColor,
  btnLink,
  grayColor,
  twitterColor,
  dribbbleColor,
  instagramColor,
  hexToRgb,
  btMediumGrayColor,
} from '../../nextjs-material-kit-pro.js';

const footerStyle = (theme) => ({
  left: {
    float: 'left!important',
    display: 'block',
  },
  right: {
    padding: '15px 0',
    margin: '0',
    float: 'right',
  },
  rightLinks: {
    float: 'right!important',
    '& ul': {
      marginBottom: 0,
      marginTop: 10,
      padding: 0,
      listStyle: 'none',
      height: 38,
      '& li': {
        display: 'inline-block',
      },
    },
    '& i': {
      fontSize: '20px',
    },
  },
  footer: {
    padding: '0.9375rem 0',
    textAlign: 'center',
    display: 'flex',
    zIndex: '2',
    position: 'relative',
    '& ul': {
      marginBottom: '0',
      padding: 0,
      listStyle: 'none',
    },
  },
  big: {
    padding: '1.875rem 0',
    '& h5, & h4': {
      fontWeight: 700,
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
      marginBottom: '15px',
    },
    '& p': {
      color: grayColor[0],
    },
  },
  content: {
    textAlign: 'left',
  },
  a: {
    color: webPrimaryColor,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  dark: {
    background: 'radial-gradient(ellipse at center,' + grayColor[4] + ' 0,' + grayColor[5] + ' 100%)',
    backgroundSize: '550% 450%',
    color: whiteColor,
    '& p': {
      color: grayColor[0],
    },
    '& i': {
      color: whiteColor,
    },
    '& a': {
      color: whiteColor,
      opacity: '.86',
      '&:visited': {
        color: whiteColor,
      },
      '&:focus, &:hover': {
        opacity: 1,
      },
    },
    '& hr': {
      borderColor: 'rgba(' + hexToRgb(whiteColor) + ',0.2)',
    },
    '& $btnTwitter, & $btnDribbble, & $btnInstagram': {
      color: whiteColor,
    },
  },
  white: {
    backgroundColor: whiteColor,
    color: grayColor[1],
    textDecoration: 'none',
    '& a': {
      '&:visited': {
        color: grayColor[1],
      },
      '&:hover, &:focus': {
        color: grayColor[20],
      },
    },
  },
  container,
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  icon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    top: '3px',
  },
  iconSocial: {
    width: '41px',
    height: '41px',
    fontSize: '24px',
    minWidth: '41px',
    padding: 0,
    overflow: 'hidden',
    position: 'relative',
  },
  btnTwitter: {
    ...btnLink,
    color: twitterColor,
  },
  btnDribbble: {
    ...btnLink,
    color: dribbbleColor,
  },
  btnInstagram: {
    ...btnLink,
    color: instagramColor,
  },
  footerBrand: {
    height: '50px',
    padding: '15px 15px',
    fontSize: '18px',
    lineHeight: '50px',
    marginLeft: '-15px',
    color: grayColor[1],
    textDecoration: 'none',
    fontWeight: 700,
    fontFamily: 'Source Sans Pro, Helvetica, Arial, sans-serif',
  },
  pullCenter: {
    display: 'inline-block',
    float: 'none',
  },
  clearFix: {
    clear: 'both',
  },
  contact: {
    fontSize: '1.00rem',
    color: webPrimaryColor,
    width: '100%',
    marginTop: 20,
  },
  contactInfo: {
    color: webPrimaryColor,
    fontWeight: 500,
    fontSize: '0.90rem',
    lineHeight: '1.35rem',
    padding: '5px 15%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '5px 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '5px 15%',
    },
  },
  version: {
    color: btMediumGrayColor,
    fontWeight: 500,
    fontSize: '0.80rem',
    lineHeight: '1.00rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '10px 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '10px 15% 0 15%',
    },
  },
  footerLink: {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '0.90rem',
    lineHeight: '1.10rem',
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '5px 0 2px 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '5px 15%',
    },
  },
  footerTitle: {
    textTransform: 'uppercase',
    fontSize: '1.250rem',
    fontWeight: 400,
    lineHeight: '1.649rem',
    color: webSecondaryColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '15px 0 5px 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '5px 15%',
    },
  },
});
export default footerStyle;
