import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 85,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
    },
  },
  marginRight30: {
    marginRight: 30,
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function ServiceType(props) {
  const { serviceType, setServiceType, joinBoth, setJoinBoth, disabled } = props;

  const classes = styles();

  return (
    <React.Fragment>
      <Box sx={{ width: '90%' }}>
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={12} md={5} lg={3} className={classes.textRowLabelSpacer}>
            <label>For which service are you applying?</label>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={9} className={classes.textRowSpacer}>
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="Successful Futures - Trade Life"
                name="successful-futures-trade-life"
                horizontal="true"
                value={serviceType}
                onChange={(event) => setServiceType(event.target.value)}
                disabled={disabled}
              >
                <FormControlLabel
                  value="tradelife"
                  className={classes.marginRight30}
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>Successful Futures - Trade Life</span>}
                />
                <FormControlLabel
                  value="housing"
                  control={<Radio color="default" disabled={disabled} />}
                  label={<span className={classes.radioLabel}>Successful Futures - Housing</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <div>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={12}>
              <summary className={classes.alignPrintedContent} style={{ marginBottom: 10 }}>
                {serviceType === 'tradelife' ? 'Successful Living - Trade Life' : 'Successful living - Housing'}
              </summary>
            </Grid>
          </Grid>
        </div>
      </Box>
      {serviceType === 'housing' && (
        <Box sx={{ width: '90%' }}>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={false} sm={false} md={5} lg={3} />
            <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowLabelSpacer}>
              <label>If not accepted, are you interested in joining the Successful Futures - Trade Life program?</label>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={4} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="Interested in joining Successful Futures - Trade Life?"
                  name="join-trade-life"
                  horizontal="true"
                  value={joinBoth}
                  onChange={(event) => setJoinBoth(event.target.value)}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    className={classes.marginRight30}
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
      <div>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={12}>
            <summary className={classes.alignPrintedContent} style={{ marginBottom: 10 }}>
              {joinBoth === 'yes'
                ? '**I would like to apply for both Housing and Trade Life with one application, in case I don’t get chosen for my first choice.'
                : ''}
            </summary>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

ServiceType.propTypes = {
  serviceType: PropTypes.string,
  setServiceType: PropTypes.func,
  joinBoth: PropTypes.string,
  setJoinBoth: PropTypes.func,
  disabled: PropTypes.bool,
};
