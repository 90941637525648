import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, InputAdornment, TextField } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandingListWithList from '../ExpandingList/ExpandingListWithList';

const ContactAwards = ({ contactInfo, classes, showMoney, setShowMoney, form, setForm }) => {
  return (
    <React.Fragment>
      <Grid container align="left" style={{ padding: 5, border: '1px solid #d3d3d3' }}>
        <Grid item xs={4} sm={2} md={1} lg={1}>
          <div style={{ marginTop: 10 }}>Awards</div>
        </Grid>
        <Grid item xs={6} sm={9} md={10} lg={10}>
          <div className={classes.reminder}>Before contact attempt, enter all award information, so the script can include these values.</div>
        </Grid>
        <Grid item xs={2} sm={1} md={1} lg={1} align="right" style={{ marginLeft: -5 }}>
          <div style={{ marginRight: 10, marginTop: 10 }} onClick={(event) => setShowMoney(!showMoney)}>
            {showMoney ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Grid container align="left">
              <Grid item xs={12} sm={5} md={3} lg={2} className={classes.rowSpacer}>
                <label id="money-accessed-label" className={classes.adminLabel}>
                  Cowboy Money Accessed
                </label>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    id="money-accessed"
                    size="small"
                    placeholder="Amount Accessed"
                    variant="outlined"
                    value={form.moneyAccessed}
                    onChange={(event) => setForm({ ...form, moneyAccessed: event.target.value })}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                    style={{ width: 170 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={3} md={6} lg={1}></Grid>
              <Grid item xs={12} sm={5} md={3} lg={2} className={classes.rowSpacer}>
                <label id="money-remaining-label" className={classes.adminLabel}>
                  Cowboy Money Remaining
                </label>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <FormControl fullWidth>
                  <TextField
                    id="money-remaining"
                    size="small"
                    placeholder="Amount Remaining"
                    variant="outlined"
                    value={form.moneyRemaining}
                    onChange={(event) => setForm({ ...form, moneyRemaining: event.target.value })}
                    InputProps={{
                      maxLength: 5,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: { input: classes.textField },
                    }}
                    style={{ width: 170 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%' }} className={classes.rowSpacer}>
                <ExpandingListWithList
                  form={form}
                  setForm={setForm}
                  listname="otherAwards"
                  rowsRequired={0}
                  maxRowsAllowed={99}
                  disabled={false}
                  field1="name"
                  field1Placeholder="Scholarship/Incentive Name"
                  field1Cols={3}
                  field1MaxLength={100}
                  field2="type"
                  field2Cols={2}
                  field2MaxLength={50}
                  title="Available Scholarships/Incentives"
                  list={['Scholarship', 'Incentive']}
                />
              </Grid>
            </Grid>
            <br />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

ContactAwards.propTypes = {
  contactInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showMoney: PropTypes.bool.isRequired,
  setShowMoney: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
};

export default ContactAwards;
