import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { Button } from '@material-ui/core';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { titleize } from '../../util/stringUtil';
import { dateTimeToS } from '../../util/dateUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 15,
    maxWidth: '330px',
    height: '180px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  dataBold: {
    fontWeight: 600,
    fontSize: '.95rem',
    lineHeight: '1.05rem',
    margin: '8px 0 0 0',
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    float: 'right',
    marginLeft: 5,
    textTransform: 'none',
  },
}));

const ScholarshipApplicationCard = ({ app, open, showAwards, displayStatus, displayApplicantName }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card} key={app.id}>
        <CardHeader className={classes.cardHeader}>
          {displayApplicantName(app)}{' '}
          {app.applicant_type && app.applicant_type.length > 0 && app.applicant_type === 'alumni' ? '(' + titleize(app.applicant_type) + ')' : ''}
          <Button
            className={classes.idLink}
            color="primary"
            size="small"
            variant="outlined"
            aria-describedby="review scholarship application"
            onClick={(ev) => open(ev, app.id, app.applicant_type)}
          >
            Review
          </Button>
          <Button
            className={classes.idLink}
            color="primary"
            size="small"
            variant="outlined"
            aria-describedby="see scholarship awards"
            onClick={(ev) => showAwards(ev, app, app.id, app.applicant_type)}
          >
            Awards
          </Button>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <section>
            <label>
              <strong>Status</strong>
            </label>{' '}
            {displayStatus(app.status)}
          </section>
          <section>
            <label>
              <strong>Submitted</strong>
            </label>{' '}
            {dateTimeToS(app.submitted_date)}
          </section>
          <div className={classes.dataBold}>Scholarships Awarded</div>
          <section>
            <label>
              <strong># Recommended:</strong>
            </label>{' '}
            {app.recommendedAwards}
            <br />
            <label>
              <strong># Awarded:</strong>
            </label>{' '}
            {app.awardedAwards}
            {app.awardedAwards > 0 &&
              app.awardedAmount &&
              app.awardedAmount > 0 &&
              ' (' +
                parseInt(app.awardedAmount, 10).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }) +
                ')'}
          </section>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ScholarshipApplicationCard.propTypes = {
  app: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  showAwards: PropTypes.func.isRequired,
  displayStatus: PropTypes.func.isRequired,
  displayApplicantName: PropTypes.func.isRequired,
};

export default ScholarshipApplicationCard;
