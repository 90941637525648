import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { callApi } from '../../util/apiWrapper';
import { logException } from '../../services/appInsights';
import TitleBar from '../Shared/TitleBar';

const useStyles = makeStyles((theme) => ({
  menuTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: '1.50rem',
    textAlign: 'left',
    margin: '10px 0',
    color: 'blue',
  },
  menuDesc: {
    fontSize: '1.00rem',
    textAlign: 'left',
    padding: '0 5px 15px 0',
  },
  cardSize: {
    width: '400px',
    height: '205px',
  },
  reportIcon: {
    marginRight: '5px',
  },
}));

const ReportList = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const classes = useStyles();

    useEffect(() => {
      async function fetchReports() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const reportList = await callApi(auth, `reports`, 'GET');
          store.setReports(
            reportList.result.sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            })
          );
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error fetching report list:', error);
        }
      }
      if (auth) {
        fetchReports();
      }
    }, [auth, auth.authToken, store]);

    return (
      <React.Fragment>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <TitleBar title="Reports" currentPage="Reports" paths={[{ path: '/', name: 'Home' }]} />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 0, margin: 0 }}>
          <Grid item xs={12} align="center" style={{ padding: 0, margin: 0 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0 0 0',
                flexWrap: 'wrap',
                gap: '0.10em 3.00em',
              }}
            >
              {store.reports &&
                store.reports.length > 0 &&
                store.reports.map((report, idx) => {
                  return (
                    <Card className={classes.cardSize}>
                      <CardBody>
                        <section className={classes.menuTitle}>
                          <a href={report.report_url} target="_blank" rel="noreferrer">
                            <section className={classes.menuTitle + ' ' + classes.pointerCursor}>{report.name}</section>
                          </a>
                        </section>
                        <aside className={classes.menuDesc}>{report.description}</aside>
                      </CardBody>
                    </Card>
                  );
                })}
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  })
);

export default ReportList;
