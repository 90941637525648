import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';

const HelpRequestResolution = ({ reason, name, formResolution, setFormResolution, classes }) => {
  return (
    <React.Fragment>
      <Grid item xs={12} sm={5} md={3} lg={3} className={classes.textRowLabelSpacer} align="left" style={{ paddingTop: 15, minHeight: '40px' }}>
        <label style={{ fontWeight: 600, color: '#000000' }}>{name}</label>
      </Grid>
      <Grid item xs={12} sm={7} md={9} lg={9} className={classes.resolution}>
        <FormControl fullWidth>
          <RadioGroup
            row
            aria-label="help request resolution"
            name="help-request-resolution"
            horizontal="true"
            value={formResolution[reason]}
            onChange={(event) => setFormResolution({ ...formResolution, [reason]: event.target.value })}
            style={{ minHeight: 40 }}
          >
            <Tooltip title="Tried to contact, but alumnus never responded." placement="top">
              <FormControlLabel
                value="notreached"
                className={classes.marginRight40}
                control={<Radio color="default" />}
                label={
                  <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                    Could not reach
                  </span>
                }
              />
            </Tooltip>
            <Tooltip title="Help/services were provided and resolved the request." placement="top">
              <FormControlLabel
                value="fulfilled"
                className={classes.marginRight40}
                control={<Radio color="default" />}
                label={
                  <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                    Fulfilled
                  </span>
                }
              />
            </Tooltip>
            <Tooltip title="Alumnus is requesting help with something that is outside the Successful Futures program." placement="top">
              <FormControlLabel
                value="unabletohelp"
                className={classes.marginRight40}
                control={<Radio color="default" />}
                label={
                  <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                    Unable to help
                  </span>
                }
              />
            </Tooltip>
            <Tooltip title="Alumnus is not qualified to receive help in this area." placement="top">
              <FormControlLabel
                value="notqualified"
                className={classes.marginRight40}
                control={<Radio color="default" />}
                label={
                  <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                    Not qualified
                  </span>
                }
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};

HelpRequestResolution.propTypes = {
  reason: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formResolution: PropTypes.object.isRequired,
  setFormResolution: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default HelpRequestResolution;
