import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    margin: '10px 0',
    padding: '10px 0',
  },
}));

export default function PostSecondaryOpportunities(props) {
  const classes = styles();

  return (
    <Box sx={{ width: '100%' }}>
      <section className={classes.title}>
        <span className={classes.iconText}>Post-Secondary Educational Opportunities</span>
      </section>
      <Grid container direction="row" align="left">
        <Grid item xs={4} className={classes.bold}>
          Type of School
        </Grid>
        <Grid item xs={4} className={classes.bold}>
          Timeline
        </Grid>
        <Grid item xs={4} className={classes.bold}>
          Degree
        </Grid>
      </Grid>
      <Grid container direction="row" align="left">
        <Grid item xs={4}>
          Community College
        </Grid>
        <Grid item xs={4}>
          Usually 2 years
        </Grid>
        <Grid item xs={4}>
          Associate Degree
        </Grid>
      </Grid>
      <Grid container direction="row" align="left">
        <Grid item xs={4}>
          College/University
        </Grid>
        <Grid item xs={4}>
          Usually 4 years
        </Grid>
        <Grid item xs={4}>
          Bachelor Degree
        </Grid>
      </Grid>
      <Grid container direction="row" align="left">
        <Grid item xs={4}>
          Career/Trade School
        </Grid>
        <Grid item xs={4}>
          Varies by Program
        </Grid>
        <Grid item xs={4}>
          Diploma or Cerficiate
        </Grid>
      </Grid>
    </Box>
  );
}
