import React, { useState, useEffect } from 'react';
import { placeholder } from '../../../util/apiWrapper';
import Avatar from '@material-ui/core/Avatar';

export default function StaffImage(props) {
  const baseUrl = process.env.REACT_APP_NDB_PUBLIC_BASE_URL;
  const { pidm } = props;
  const placeHolderSrc = 'data:image/jpeg;base64,' + placeholder;
  const actualSrc = `${baseUrl}staffpicture/${pidm}`;

  const [currentSrc, setCurrentSrc] = useState(placeHolderSrc);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = actualSrc;
    imageToLoad.onload = () => {
      setCurrentSrc(actualSrc);
    };
  }, [actualSrc, pidm]);

  return <Avatar alt="Staff Image" src={currentSrc} style={{ width: '180px', height: '180px' }} />;
}
