import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, TextareaAutosize, Tooltip } from '@material-ui/core';
import { dateUtcToS } from '../../../util/dateUtil';

const styles = makeStyles((theme) => ({
  bigLabel: {
    fontSize: '0.85rem',
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  rowSpacer: {
    height: 30,
  },
  sectionSpacer: {
    height: 60,
    padding: '10px 0 0 0',
  },
  sectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '10px 0 0 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0 0 0',
    },
  },
  smallRowSpacer: {
    height: 15,
  },
  textSpacer: {
    margin: '15px 0 5px 0',
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 10,
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function GeneralInformation(props) {
  const { senior, form, setForm, auth } = props;

  const classes = styles();

  const isFieldDisabled = () => {
    if (!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')) return true;
    if (form.status === 'submitted' || form.status === 'approved') return true;
    return false;
  };

  return (
    <Box sx={{ width: '95%' }}>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Name</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.first_name} {senior.last_name}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Consultant</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.consultant}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Name</label>
        </Grid>
        <Grid item xs={3}>
          {senior.first_name} {senior.last_name}
        </Grid>
        <Grid item xs={3}>
          <label>Consultant</label>
        </Grid>
        <Grid item xs={3}>
          {senior.consultant}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>ACT Score</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Tooltip
          title={
            auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')
              ? 'If you have taken the ACT, please enter your score on your personal information page in order to be included on this form.'
              : 'If the student has taken the ACT, they need to enter their score on their personal information page in order to be included on this form.'
          }
          placement="top"
        >
          <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
            {senior.act || 'N/A'}
          </Grid>
        </Tooltip>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Birth Date</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.dob)}
        </Grid>
        <Grid item xs={3}>
          <label className={classes.alignPrintedContent}>ACT Score</label>
        </Grid>
        <Grid item xs={3}>
          <summary className={classes.alignPrintedContent}>{senior.act || 'N/A'}</summary>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>ASVAB Score</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Tooltip
          title={
            auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')
              ? 'If you have taken the ASVAB, please enter your score on your personal information page in order to be included on this form.'
              : 'If the student has taken the ASVAB, they need to enter their score on their personal information page in order to be included on this form.'
          }
          placement="top"
        >
          <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
            {senior.asvab || 'N/A'}
          </Grid>
        </Tooltip>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Date of Admission</label>
        </Grid>
        <Grid item xs={3}>
          {dateUtcToS(senior.admission_date)}
        </Grid>
        <Grid item xs={3}>
          <label className={classes.alignPrintedContent}>ASVAB Score</label>
        </Grid>
        <Grid item xs={3}>
          <summary className={classes.alignPrintedContent}>{senior.asvab || 'N/A'}</summary>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Community</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.community}
        </Grid>
        {!auth.roles.includes('STUDENT') ? (
          <React.Fragment>
            <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
              <label>GPA</label>
            </Grid>
            <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
            <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
              {senior.gpa || 'N/A'}
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}></Grid>
            <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
            <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}></Grid>
          </React.Fragment>
        )}
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Community</label>
        </Grid>
        <Grid item xs={3}>
          {senior.community}
        </Grid>
        {!auth.roles.includes('STUDENT') ? (
          <React.Fragment>
            <Grid item xs={3}>
              <label className={classes.alignPrintedContent}>GPA</label>
            </Grid>
            <Grid item xs={3}>
              <summary className={classes.alignPrintedContent}>{senior.gpa || 'N/A'}</summary>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </React.Fragment>
        )}
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          <label>Family Teachers</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.family_teaching_couple}
        </Grid>
        <Grid item xs={false} sm={2} md={2} lg={2} className={classes.rowSpacer}>
          {/* <label></label> */}
        </Grid>
        <Grid item xs={false} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={false} sm={3} md={3} lg={3} className={classes.rowSpacer}></Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Family Teachers</label>
        </Grid>
        <Grid item xs={3}>
          {senior.family_teaching_couple}
        </Grid>
        <Grid item xs={3}>
          <label></label>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} align="left">
          <section align="left" className={classes.sectionTitle}>
            Career Interests
          </section>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} md={5} lg={5}>
          <section align="left" className={classes.bigLabel + ' ' + classes.textSpacer}>
            What I like and am interested in doing post-graduation.{' '}
          </section>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} className={classes.unprintedContent}>
          <FormControl fullWidth>
            <TextareaAutosize
              aria-label="interests-post-graduation"
              minRows={15}
              maxRows={15}
              maxLength={1000}
              value={form.interestsPostGraduation}
              placeholder="Describe what I like and what I am interested in after graduation."
              onChange={(event) => setForm({ ...form, interestsPostGraduation: event.target.value })}
              disabled={isFieldDisabled()}
              className={classes.textAreaFont}
              style={{ color: isFieldDisabled() && 'rgba(0, 0, 0, 0.4)' }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
        {form.interestsPostGraduation || null}
      </p>

      <Grid container direction="row" className={classes.smallRowSpacer} />
      <Grid container direction="row">
        <Grid item xs={12} md={5} lg={5}>
          <section align="left" className={classes.bigLabel + ' ' + classes.textSpacer}>
            What are my top three career interests?
          </section>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={9} lg={8} className={classes.textSpacer}>
          <FormControl fullWidth>
            <TextField
              id="top-interest1"
              size="small"
              fullWidth
              label="1."
              variant="outlined"
              value={form.interest1}
              onChange={(event) => setForm({ ...form, interest1: event.target.value })}
              disabled={isFieldDisabled()}
              InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <summary className={classes.printedContent}>
        <label>1.</label>
      </summary>
      <summary className={classes.printedContent}>{form.interest1 || null}</summary>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={9} lg={8} className={classes.textSpacer}>
          <FormControl fullWidth>
            <TextField
              id="top-interest2"
              size="small"
              fullWidth
              label="2."
              variant="outlined"
              value={form.interest2}
              onChange={(event) => setForm({ ...form, interest2: event.target.value })}
              disabled={isFieldDisabled()}
              InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <summary className={classes.printedContent}>
        <label>2.</label>
      </summary>
      <summary className={classes.printedContent}>{form.interest2 || null}</summary>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={9} lg={8} className={classes.textSpacer}>
          <FormControl fullWidth>
            <TextField
              id="top-interest3"
              size="small"
              fullWidth
              label="3."
              variant="outlined"
              value={form.interest3}
              onChange={(event) => setForm({ ...form, interest3: event.target.value })}
              disabled={isFieldDisabled()}
              InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
              className={classes.interest}
            />
          </FormControl>
        </Grid>
      </Grid>
      <summary className={classes.printedContent}>
        <label>3.</label>
      </summary>
      <summary className={classes.printedContent}>{form.interest3 || null}</summary>
    </Box>
  );
}

GeneralInformation.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  senior: PropTypes.object,
  auth: PropTypes.object,
};
