import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, InputAdornment, TextareaAutosize } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 115,
    },
    [theme.breakpoints.up('sm')]: {
      height: 105,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function SummerHousingPlan(props) {
  const { form, setForm, disabled } = props;

  const classes = styles();

  return (
    <Box sx={{ width: '90%' }}>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={false} md={1} lg={1} />
        <Grid item xs={12} md={11} lg={11} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="summer-housing-plan"
              fullWidth
              size="small"
              label="When I depart on the day of graduation, I will go to:"
              variant="outlined"
              value={form.summerHousingPlan}
              onChange={(event) => setForm({ ...form, summerHousingPlan: event.target.value })}
              disabled={disabled}
              InputProps={{ maxLength: 500, classes: { input: classes.textField } }}
              className={classes.root}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12}>
          <label className={classes.alignPrintedContent}>When I depart on the day of graduation, I will go to:</label>
        </Grid>
        <Grid item xs={12}>
          <summary className={classes.alignPrintedContent}>{form.summerHousingPlan || null}</summary>
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={false} md={1} lg={1} />
        <Grid item xs={12} md={5} lg={3} className={classes.textRowLabelSpacer}>
          <label>What is the cost per month of this housing?</label>
        </Grid>
        <Grid item xs={6} md={2} lg={2} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="summer-housing-plan-cost"
              type="number"
              size="small"
              hiddenLabel
              variant="outlined"
              value={form.summerHousingPlanCost}
              onChange={(event) => setForm({ ...form, summerHousingPlanCost: event.target.value })}
              disabled={disabled}
              InputProps={{
                maxLength: 5,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                classes: { input: classes.textField },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={4}>
          <label className={classes.alignPrintedContent}>Cost per month of this housing</label>
        </Grid>
        <Grid item xs={8}>
          <summary className={classes.alignPrintedContent}>$ {form.summerHousingPlanCost || null}</summary>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={false} md={1} lg={1} />
        <Grid item xs={12} md={5} lg={3} className={classes.rowSpacer}>
          <label>What responsibilities will I have living here?</label>
        </Grid>
        <Grid item xs={12} md={6} lg={8} className={classes.textArea5RowSpacer + ' ' + classes.unprintedContent}>
          <FormControl fullWidth>
            <TextareaAutosize
              aria-label="summer-housing-expectations"
              minRows={5}
              maxRows={5}
              maxLength={750}
              value={form.summerHousingExpectations}
              onChange={(event) => setForm({ ...form, summerHousingExpectations: event.target.value })}
              disabled={disabled}
              className={classes.textAreaFont}
              style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap' }}>
        {form.summerHousingExpectations || null}
      </p>
    </Box>
  );
}

SummerHousingPlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};
