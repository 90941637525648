import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '../CustomButtons/Button.js';
import { blackColor, whiteColor } from '../../assets/jss/nextjs-material-kit-pro';

const formStyles = makeStyles({
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  dialog: {
    // width: 600,
    minHeight: 250,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: '30px',
  },
  buttonWidth: {
    width: '110px',
  },
  intro: {
    fontSize: '0.95rem',
    fontWeight: 500,
    marginBottom: 10,
  },
  errorList: {
    fontSize: '0.85rem',
  },
});

const RequiredInfoDialog = (props) => {
  const { title, introduction, content, showDialog, handleClose, handleContinue, optional } = props;

  const formClasses = formStyles();

  return (
    <React.Fragment>
      <Dialog open={showDialog} onClose={handleClose} scroll="paper" aria-labelledby="Required Information">
        <React.Fragment>
          <DialogTitle id="basic-info-dialog" className={formClasses.dialogHeaderFooter}>
            {title}
          </DialogTitle>
          <DialogContent className={formClasses.dialog} style={{ minHeight: 250 }}>
            <Box sx={{ width: '100%' }}>
              <Grid container direction="row">
                <Grid item xs={12} className={formClasses.rowSpacer}>
                  <summary className={formClasses.intro}>{introduction}</summary>
                  <ul className={formClasses.errorList}>
                    {content &&
                      content.map((error, idx) => {
                        return <li key={idx}>{error.requirement}</li>;
                      })}
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </React.Fragment>
        {optional ? (
          <DialogActions className={formClasses.dialogHeaderFooter}>
            <Button color="secondary" className={formClasses.buttonWidth} onClick={handleContinue}>
              Continue
            </Button>
            <Button color="primary" className={formClasses.buttonWidth} onClick={handleClose}>
              Submit Later
            </Button>
          </DialogActions>
        ) : (
          <DialogActions className={formClasses.dialogHeaderFooter}>
            <Button color="primary" className={formClasses.buttonWidth} onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default RequiredInfoDialog;

RequiredInfoDialog.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object),
  showDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func,
  optional: PropTypes.bool,
};
