import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { Button } from '@material-ui/core';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 15,
    maxWidth: '330px',
    minHeight: '120px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  dataBold: {
    fontWeight: 600,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    float: 'right',
    textTransform: 'none',
  },
}));

const HelpApplicationCard = ({ app, open }) => {
  const classes = useStyles();

  const summarizeHelpOptions = (app) => {
    if (!app || (app && app.length === 0)) return [];
    let list = [];
    const appValues = JSON.parse(app);
    if (appValues.coaching) {
      list.push('Coaching');
    }
    if (appValues.dailyLiving) {
      list.push('Daily Living');
    }
    if (appValues.education) {
      list.push('Education');
    }
    if (appValues.employment) {
      list.push('Employment');
    }
    if (appValues.finances) {
      list.push('Finances');
    }
    if (appValues.food) {
      list.push('Food');
    }
    if (appValues.health) {
      list.push('Health');
    }
    if (appValues.housing) {
      list.push('Housing');
    }
    if (appValues.legalDocs) {
      list.push('Legal Documents');
    }
    if (appValues.lifeSkills) {
      list.push('Life Skills');
    }
    if (appValues.mentalHealth) {
      list.push('Mental Health');
    }
    if (appValues.scholarships) {
      list.push('Scholarships');
    }
    if (appValues.transportation) {
      list.push('Transportation');
    }
    if (appValues.utilities) {
      list.push('Utilities');
    }
    if (appValues.other) {
      list.push('Other');
    }
    // return list && list.length > 40 ? list.substring(0, 40) + '...' : list.substring(0, list.length - 2);
    return list;
  };

  const displayStatus = (status) => {
    if (status === 'new') {
      return 'New';
    }
    if (status === 'inprogress') {
      return 'In Progress';
    }
    if (status === 'completed') {
      return 'Completed';
    }
    if (status === 'created') {
      return 'Created';
    }
    return status;
  };

  return (
    <React.Fragment>
      <Card className={classes.card} key={app.id}>
        <CardHeader className={classes.cardHeader}>
          {app.status_by}
          <Button className={classes.idLink} color="primary" size="small" aria-describedby="review Help Request" onClick={(ev) => open(ev, app.id)}>
            Review
          </Button>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <section className={classes.dataBold}>{displayStatus(app.status)}</section>
          <section className={classes.dataBold}>
            <label>Case #</label>
            {new Date().getYear() + app.id.toString().padStart(6, 0)}
          </section>
          <section className={classes.dataBold}>
            <label>Reasons: </label> {summarizeHelpOptions(app.application).join(', ')}
          </section>
          <section className={classes.dataBold}>
            <label>Submitted</label> {app.submitted_date_formatted}
          </section>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

HelpApplicationCard.propTypes = {
  app: PropTypes.object.isRequired,
};

export default HelpApplicationCard;
