import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  table: {
    width: '100%',
    padding: 0,
  },
  deleteButton: {
    marginTop: -5,
  },
  smallButton: {
    padding: 10,
  },
  smallIcon: {
    fontSize: '1.00rem',
  },
  addButton: {
    padding: 5,
    color: btNavyBlueColor,
  },
}));

const ExpandingList2 = ({
  form,
  setForm,
  listname,
  field1,
  field1Cols,
  field1MaxLength,
  field2,
  field2Cols,
  field2MaxLength,
  rowsRequired,
  maxRowsAllowed,
  disabled,
}) => {
  const classes = styles();
  if (!maxRowsAllowed) maxRowsAllowed = 20;

  const handleChange = (idx, field) => (e) => {
    const rows = [...form[listname]];
    rows[idx][field] = e.target.value;
    setForm({ ...form, [listname]: rows });
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const rows = [...form[listname]];
    rows.splice(idx, 1);
    setForm({ ...form, [listname]: rows });
  };

  const handleAddRow = () => {
    const rows = [...form[listname]];
    if (rows.length < maxRowsAllowed) {
      let newItem = {};
      newItem[field1] = '';
      newItem[field2] = '';
      rows.push(newItem);
      setForm({ ...form, [listname]: rows });
    }
  };

  return (
    <Box sx={{ width: '95%' }}>
      {form[listname] &&
        form[listname].length > 0 &&
        form[listname].map((item, idx) => (
          <React.Fragment key={`${idx}-${listname}`}>
            <Grid container direction="row" className={classes.rowSpacer} key={`${idx}-${listname}`}>
              <Grid item xs={field1Cols}>
                <FormControl fullWidth style={{ paddingRight: 5 }}>
                  <TextField
                    id="list-item"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={item[field1]}
                    //placeholder={idx + 1 + '.'}
                    onChange={handleChange(idx, field1)}
                    disabled={disabled}
                    InputProps={{ maxLength: field1MaxLength, style: { height: 23, padding: '0 5px', margin: '2px 0' } }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={field2Cols}>
                <FormControl fullWidth>
                  <TextField
                    id="list-item"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    value={item[field2]}
                    //placeholder={idx + 1 + '.'}
                    onChange={handleChange(idx, field2)}
                    disabled={disabled}
                    InputProps={{ maxLength: field2MaxLength, style: { height: 23, padding: '0 5px', margin: '2px 0' } }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                {idx === 0 && form[listname].length < maxRowsAllowed && (
                  <IconButton aria-label="Add another item" color="inherit" onClick={handleAddRow} disabled={disabled} className={classes.addButton}>
                    <AddBoxIcon />
                  </IconButton>
                )}
                {idx >= rowsRequired && (
                  <IconButton
                    className={[classes.smallButton, classes.deleteButton].join(' ')}
                    onClick={handleRemoveSpecificRow(idx)}
                    disabled={disabled}
                    aria-label="remove item from list"
                    color="inherit"
                  >
                    <CloseIcon className={classes.smallIcon} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
    </Box>
  );
};

ExpandingList2.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  listname: PropTypes.string,
  field1: PropTypes.string,
  field1Cols: PropTypes.number,
  field1MaxLength: PropTypes.number,
  field2: PropTypes.string,
  field2Cols: PropTypes.number,
  field2MaxLength: PropTypes.number,
  rowsRequired: PropTypes.number,
  maxRowsAllowed: PropTypes.number,
  disabled: PropTypes.bool,
};

export default ExpandingList2;
