import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField, InputAdornment, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { dateUtcToS } from '../../../util/dateUtil';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  bold: {
    fontWeight: 600,
  },
  subtleBold: {
    fontWeight: 500,
  },
  subtleBoldLabel: {
    fontWeight: 500,
    fontSize: '0.95rem',
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function TransportationPlan(props) {
  const { form, setForm, disabled, saveDriversLicenseTask, saveDriversEdTask, saveLearnersPermitTask, saveAutoInsuranceTask, handleHaveVehicle } =
    props;
  const classes = styles();

  return (
    <Box sx={{ width: '90%' }}>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
          <label>I have a driver's license.</label>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="have a drivers license"
              name="drivers-license"
              value={form.driversLicense}
              onChange={(event) => saveDriversLicenseTask(event.target.value)}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {form.driversLicense === 'no' && (
          <React.Fragment>
            <Grid item xs={12} md={6} lg={4} className={classes.textRowLabelSpacer}>
              <label>I have completed a driver's education course.</label>
            </Grid>
            <Grid item xs={12} md={6} lg={2} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="completed drivers ed"
                  name="completed-drivers-ed"
                  value={form.completedDriversEd}
                  onChange={(event) => saveDriversEdTask(event.target.value)}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <div className={classes.printedContent}>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={6}>
            <label className={classes.alignPrintedContent}>I have a driver's license.</label>
          </Grid>
          <Grid item xs={2}>
            <summary className={classes.alignPrintedContent}>{form.driversLicense || null}</summary>
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={6}>
            <label className={classes.alignPrintedContent}>I have completed a driver's education course.</label>
          </Grid>
          <Grid item xs={2}>
            <summary className={classes.alignPrintedContent}>{form.completedDriversEd || null}</summary>
          </Grid>
        </Grid>
      </div>

      {form.driversLicense === 'no' && form.completedDriversEd === 'no' && (
        <React.Fragment>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
              <label>I have a learner's permit.</label>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="have a learners permit"
                  name="learners-permit"
                  value={form.learnersPermit}
                  onChange={(event) => saveLearnersPermitTask(event.target.value)}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.textRowLabelSpacer}>
              <label>I am currently enrolled in a driver's education course.</label>
            </Grid>
            <Grid item xs={12} md={6} lg={2} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="enrolled-in-drivers-ed"
                  name="enrolled-drivers-ed"
                  value={form.enrolledDriversEd}
                  onChange={(event) => setForm({ ...form, enrolledDriversEd: event.target.value })}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.printedContent}>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={6}>
                <label className={classes.alignPrintedContent}>I have a learner's permit.</label>
              </Grid>
              <Grid item xs={2}>
                <summary className={classes.alignPrintedContent}>{form.learnersPermit || null}</summary>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={6}>
                <label className={classes.alignPrintedContent}>Currently enrolled in a driver's education course?</label>
              </Grid>
              <Grid item xs={2}>
                <summary className={classes.alignPrintedContent}>{form.enrolledDriversEd || null}</summary>
              </Grid>
            </Grid>
          </div>

          {form.enrolledDriversEd === 'yes' && (
            <React.Fragment>
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}></Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}></Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>Date I will complete the course.</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.datePickerRowSpacer}>
                  {!disabled ? (
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        id="complete-drivers-ed-date"
                        hiddenLabel
                        disablePast
                        value={form.completeDriversEdDate || null}
                        onChange={(date) => {
                          setForm({ ...form, completeDriversEdDate: date ? date : null });
                        }}
                        format="MM/dd/yyyy"
                        className={classes.datepicker}
                        disabled={disabled}
                        minDateMessage="Date cannot be earlier than today."
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <summary style={{ color: '#808080' }}>{dateUtcToS(form.completeDriversEdDate || null)}</summary>
                  )}
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>Date I will complete the course.</label>
                  </Grid>
                  <Grid item xs={2}>
                    <summary className={classes.alignPrintedContent} style={{ color: '#808080' }}>
                      {dateUtcToS(form.completeDriversEdDate || null)}
                    </summary>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
          <label>I will have a vehicle.</label>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="have a vehicle"
              name="vehicle"
              value={form.vehicle}
              onChange={(event) => handleHaveVehicle(event.target.value)}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.printedContent}>
        <Grid container direction="row" className={classes.printedContent}>
          <Grid item xs={6}>
            <label className={classes.alignPrintedContent}>I will have a vehicle.</label>
          </Grid>
          <Grid item xs={2}>
            <summary className={classes.alignPrintedContent}>{form.vehicle || null}</summary>
          </Grid>
        </Grid>
      </div>

      {form.vehicle === 'yes' && (
        <React.Fragment>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
              <label>What is the cost (car payment only) per month?</label>
            </Grid>
            <Grid item xs={4} sm={3} md={2} lg={2} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="vehicle-cost-per-month"
                  type="number"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  value={form.vehicleCostPerMonth}
                  onChange={(event) => setForm({ ...form, vehicleCostPerMonth: event.target.value })}
                  disabled={disabled}
                  InputProps={{
                    maxLength: 10,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    classes: { input: classes.textField },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.printedContent}>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={6}>
                <label className={classes.alignPrintedContent}>What is the cost per month?</label>
              </Grid>
              <Grid item xs={4}>
                <summary className={classes.alignPrintedContent}>$ {form.vehicleCostPerMonth || 0}</summary>
              </Grid>
            </Grid>
          </div>

          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
              <label>Who will pay for the vehicle?</label>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="vehicle-paid"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  value={form.vehiclePaid}
                  onChange={(event) => setForm({ ...form, vehiclePaid: event.target.value })}
                  disabled={disabled}
                  InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.printedContent}>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={6}>
                <label className={classes.alignPrintedContent}>Who will pay for the vehicle?</label>
              </Grid>
              <Grid item xs={6}>
                <summary className={classes.alignPrintedContent}>{form.vehiclePaid || null}</summary>
              </Grid>
            </Grid>
          </div>

          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
              <label>I will have auto insurance.</label>
            </Grid>
            <Grid item xs={6} md={2} lg={3} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="have auto insurance"
                  name="auto-insurance"
                  value={form.autoInsurance}
                  onChange={(event) => saveAutoInsuranceTask(event.target.value)}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.printedContent}>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={6}>
                <label className={classes.alignPrintedContent}>I will have auto insurance.</label>
              </Grid>
              <Grid item xs={2}>
                <summary className={classes.alignPrintedContent}>{form.autoInsurance || null}</summary>
              </Grid>
            </Grid>
          </div>

          {form.vehicle === 'yes' && form.autoInsurance === 'yes' && (
            <React.Fragment>
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>What is the coverage?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={8} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="auto-insurance-coverage"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.autoInsuranceCoverage}
                      onChange={(event) => setForm({ ...form, autoInsuranceCoverage: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                      className={classes.root}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>What is the coverage?</label>
                  </Grid>
                  <Grid item xs={6}>
                    <summary className={classes.alignPrintedContent}>{form.autoInsuranceCoverage || null}</summary>
                  </Grid>
                </Grid>
              </div>

              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>What is the cost per month for insurance?</label>
                </Grid>
                <Grid item xs={4} sm={3} md={2} lg={2} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="auto-insurance-cost-per-month"
                      type="number"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.autoInsuranceCostPerMonth}
                      onChange={(event) => setForm({ ...form, autoInsuranceCostPerMonth: event.target.value })}
                      disabled={disabled}
                      InputProps={{
                        maxLength: 10,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        classes: { input: classes.textField },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>What is the cost per month for insurance?</label>
                  </Grid>
                  <Grid item xs={2}>
                    <summary className={classes.alignPrintedContent}>$ {form.autoInsuranceCostPerMonth || 0}</summary>
                  </Grid>
                </Grid>
              </div>

              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>Who will pay for the insurance?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="vehicle-paid"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.autoInsurancePaid}
                      onChange={(event) => setForm({ ...form, autoInsurancePaid: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                      className={classes.root}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>Who will pay for the insurance?</label>
                  </Grid>
                  <Grid item xs={6}>
                    <summary className={classes.alignPrintedContent}>{form.autoInsurancePaid || null}</summary>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {form.vehicle === 'no' && (
        <React.Fragment>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
              <label>I will use public transportation.</label>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="use public transportation"
                  name="use-public-transportation"
                  value={form.usePublicTransportation}
                  onChange={(event) => setForm({ ...form, usePublicTransportation: event.target.value })}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.printedContent}>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={6}>
                <label className={classes.alignPrintedContent}>I will use public transportation.</label>
              </Grid>
              <Grid item xs={3}>
                <summary className={classes.alignPrintedContent}>{form.usePublicTransportation || null}</summary>
              </Grid>
            </Grid>
          </div>

          {form.usePublicTransportation === 'yes' && (
            <React.Fragment>
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>What public transportation will I use?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={8} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="public-transportation"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.publicTransportation}
                      onChange={(event) => setForm({ ...form, publicTransportation: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                      className={classes.root}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>What public transportation will I use?</label>
                  </Grid>
                  <Grid item xs={6}>
                    <summary className={classes.alignPrintedContent}>{form.publicTransportation || null}</summary>
                  </Grid>
                </Grid>
              </div>

              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLargeLabelSpacer}>
                  <label>What is the monthly cost for public transportation?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="public-transportation-cost"
                      type="number"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.publicTransportationCost}
                      onChange={(event) => setForm({ ...form, publicTransportationCost: event.target.value })}
                      disabled={disabled}
                      InputProps={{
                        maxLength: 10,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        classes: { input: classes.textField },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>What is the monthly cost for public transportation?</label>
                  </Grid>
                  <Grid item xs={4}>
                    <summary className={classes.alignPrintedContent}>$ {form.publicTransportationCost || 0}</summary>
                  </Grid>
                </Grid>
              </div>

              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>Who will pay for the public transportation?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="public-transportation-paid"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.publicTransportationPaid}
                      onChange={(event) => setForm({ ...form, publicTransportationPaid: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                      className={classes.root}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>Who will pay for the public transportation?</label>
                  </Grid>
                  <Grid item xs={6}>
                    <summary className={classes.alignPrintedContent}>{form.publicTransportationPaid || null}</summary>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}

          {form.usePublicTransportation === 'no' && (
            <React.Fragment>
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.textRowLabelSpacer}>
                  <label>What will I use for transportation?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={8} className={classes.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="transportation-plans"
                      size="small"
                      hiddenLabel
                      variant="outlined"
                      value={form.transportationPlans}
                      onChange={(event) => setForm({ ...form, transportationPlans: event.target.value })}
                      disabled={disabled}
                      InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.printedContent}>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={6}>
                    <label className={classes.alignPrintedContent}>What will I use for transportation?</label>
                  </Grid>
                  <Grid item xs={6}>
                    <summary className={classes.alignPrintedContent}>{form.transportationPlans || null}</summary>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}

TransportationPlan.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
  saveDriversLicenseTask: PropTypes.func,
  saveDriversEdTask: PropTypes.func,
  saveLearnersPermitTask: PropTypes.func,
  saveAutoInsuranceTask: PropTypes.func,
  handleHaveVehicle: PropTypes.func,
};
