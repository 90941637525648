import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Card from '../../Card/Card.js';
import CardBody from '../../Card/CardBody.js';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import HelpOutlineIcon from '@material-ui/icons/Timer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { webPrimaryColor } from '../../../assets/jss/nextjs-material-kit-pro.js';
import RecommendationIcon from '../../../assets/img/planner-recommendations-icon.svg';
import { dateToS, dateDiffDays } from '../../../util/dateUtil';

const Recommendations = ({
  senior,
  alumni,
  roles,
  formatPhoneNumber,
  recommendationList,
  handleCreateRecommendationRequest,
  openRecommendation,
  displayStatus,
  aftercareSpecialist,
  setPage,
  handleReturnToSeniorList,
  classes,
}) => {
  window.scrollTo({ top: 0 });

  const decipherRecommendationAreas = (app) => {
    let areas = [];
    if (app.recommendation_json && app.recommendation_json.length > 0) {
      const rec_json = JSON.parse(app.recommendation_json);
      if (rec_json.scholarships) {
        areas.push('Scholarships');
      }
      if (rec_json.tradeLife) {
        areas.push('Trade Life');
      }
      if (rec_json.housing) {
        areas.push('Housing');
      }
    }
    return areas;
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} direction="row" className={classes.subMenu}>
        <Grid item xs={12} style={{ height: '25px' }} />
        {roles.includes('ALUMNI') ? (
          <>
            <Grid item xs={12} className={classes.alumniWelcomeGrid}>
              <Typography variant="h4" className={classes.welcome}>
                <main>Letters of Recommendation</main>
              </Typography>
              <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                <HomeIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={6} lg={6} className={classes.welcomeGrid}>
              <Typography variant="h4" className={classes.welcome}>
                <main>Letters of Recommendation</main>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.specialistGrid}>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Successful Futures Specialist:</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.user && aftercareSpecialist.user.id > 0
                    ? aftercareSpecialist.user.first_name + ' ' + aftercareSpecialist.user.last_name
                    : 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Office Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {(aftercareSpecialist &&
                    aftercareSpecialist.user &&
                    aftercareSpecialist.user.id > 0 &&
                    formatPhoneNumber(aftercareSpecialist.office_phone)) ||
                    'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Cell Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {(aftercareSpecialist &&
                    aftercareSpecialist.user &&
                    aftercareSpecialist.user.id > 0 &&
                    formatPhoneNumber(aftercareSpecialist.cell_phone)) ||
                    'N/A'}
                </span>
              </aside>
            </Grid>
            <Grid item xs={12}>
              <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                <HomeIcon className={classes.homeIcon} style={{ color: webPrimaryColor }} />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={3} direction="row" className={classes.menu}>
        {/* {(roles.includes('ADMIN') || roles.includes('AFTERCARE_SPECIALIST')) && recommendationList.length === 0 && (
          <Grid item xs={12} align="center">
            <summary className={classes.noApps}>{senior.first_name} has not requests for letters of recommendation.</summary>
          </Grid>
        )} */}

        <Grid item xs={12} align="left" className={classes.cardGrid} style={{ width: '100vw' }}>
          {(roles.includes('STUDENT') || roles.includes('ALUMNI')) && (
            <nav
              onClick={(event) => handleCreateRecommendationRequest()}
              className={classes.nav}
              disabled={!roles.includes('STUDENT') && !roles.includes('ALUMNI')}
            >
              <Card className={classes.recommendationCard}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={RecommendationIcon} alt="Recommendation Icon" className={classes.cardIcon} />

                    <Tooltip title="Click to request a letter of recommendation." placement="bottom">
                      <section className={classes.addSection}>
                        <AddIcon className={classes.addIcon} />
                      </section>
                    </Tooltip>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}

          {recommendationList && recommendationList.length === 0 && <div>No recommendation requests found.</div>}
          {recommendationList
            .sort((a, b) => {
              return b.last_date_requested > a.last_date_requested ? 1 : -1;
            })
            .map((app, idx) => {
              return (
                // app.status !== 'Complete' && (
                <nav key={`lor_${idx}`} onClick={(event) => openRecommendation(app.recommendation_id)} className={classes.nav}>
                  <Card
                    className={classes.recommendationCard}
                    style={{ background: app.status === 'Complete' ? 'rgba(217, 217, 217, 0.4)' : '#fff' }}
                  >
                    <CardBody className={classes.cardBody}>
                      <div className={classes.cardContainer}>
                        <img src={RecommendationIcon} alt="Recommendation Icon" className={classes.cardIcon} />
                        <section className={classes.recommendationDates}>
                          <main className={classes.cardHeader} style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>
                            {app.last_name}, {app.first_name}
                            <br />
                            <label style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>({app.email})</label>
                          </main>
                          <aside style={{ marginTop: '10px' }}>
                            <label style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>Requested On</label>{' '}
                            <span className={classes.applicationArea} style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>
                              {dateToS(app.last_date_requested)}
                            </span>
                          </aside>
                          {/* <aside>
                            <label>Expires</label> <span className={classes.applicationArea}>{dateToS(app.expires_utc)}</span>
                          </aside> */}
                          <aside>
                            <label style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>Requested for </label>
                            <div className={classes.applicationArea} style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>
                              {decipherRecommendationAreas(app).toString()}
                            </div>
                          </aside>
                          <br />
                          <summary className={classes.statusBottom}>
                            <section className={classes.appStatus}>
                              {app.status !== 'Complete' && dateDiffDays(dateToS(app.last_date_requested)) < -32 && (
                                <div>
                                  <HelpOutlineIcon className={classes.warningStatusIcon} /> <span className={classes.iconText}>Expired</span>
                                </div>
                              )}
                              {app.status === 'Complete' && (
                                <aside style={{ color: app.status === 'Complete' && 'rgba(0, 0, 0, 0.4)' }}>
                                  <CheckCircleOutlineIcon /> COMPLETED
                                </aside>
                              )}
                            </section>
                            <section className={classes.appStatusDate}>
                              <span className={classes.iconText}>{dateToS(app.status_date)}</span>
                            </section>
                          </summary>
                        </section>
                      </div>
                    </CardBody>
                  </Card>
                </nav>
                // )
              );
            })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Recommendations.propTypes = {
  senior: PropTypes.object,
  alumni: PropTypes.bool,
  roles: PropTypes.array,
  formatPhoneNumber: PropTypes.func,
  recommendationList: PropTypes.array,
  handleCreateRecommendationRequest: PropTypes.func,
  openRecommendation: PropTypes.func,
  displayStatus: PropTypes.func,
  aftercareSpecialist: PropTypes.object,
  setPage: PropTypes.func,
  handleReturnToSeniorList: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default Recommendations;
