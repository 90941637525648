import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const PhoneInput = ({ label, handleChange, helperText, value, disabled }) => {
  const [phoneNumber, setPhoneNumber] = React.useState(value);

  const handleChangeLocal = (event) => {
    const val = event.target.value;

    let count = 0;

    for (let i = 0; i < val.length; i++) if (val.charAt(i) in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) count++;

    var isValid = count === 10 ? true : false;

    val && val.length > 0 && setPhoneNumber(val);
    handleChange(val, isValid);
  };

  return (
    <>
      <FormControl variant="outlined" size={'small'} error={phoneNumber && phoneNumber.length < 10}>
        {label && (
          <InputLabel htmlFor="phone" style={{ backgroundColor: '#fff', padding: '0 5px' }}>
            {label}
          </InputLabel>
        )}
        <OutlinedInput
          onChange={(e) => handleChangeLocal(e)}
          name="phone"
          size={'small'}
          value={phoneNumber || value}
          style={{ marginTop: -1 }}
          inputComponent={TextMaskCustom}
          disabled={disabled}
        />
        <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

PhoneInput.defaultProps = {
  label: null,
  value: 0,
  disabled: false,
};

PhoneInput.propTypes = {
  helperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default PhoneInput;
