import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import { webInfoColor, whiteColor, btOrangeColor, webPrimaryColor, webSecondaryColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import Link from '@material-ui/core/Link';
import '../../assets/css/nextjs-material-kit-pro.css';
import AboutUsBanner from '../../assets/img/aboutus-banner.png';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PleaseWait from '../Shared/PleaseWait';
import { callApi } from '../../util/apiWrapper';
import StaffImage from './Components/StaffImage';
import { logException } from '../../services/appInsights';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    display: 'flex',
    position: 'relative',
  },
  learnMoreButton: {
    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '102vw',
      margin: '10px 0 40px -5px',
    },
  },
  introContainer: {
    width: '100vw',
  },
  intro: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    width: '75vw',
    [theme.breakpoints.up('md')]: {
      margin: '40px 15% 30px 15%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '25px 10% 30px 10%',
    },
  },
  breadcrumb: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '10px 15% 10px 15%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  banner: {
    display: 'block',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  emailContact: {
    fontSize: '0.90rem',
  },
  phoneContact: {
    margin: '5px 0 5px 0',
  },
  bannerImage: {
    width: '100%',
  },
  relativePosition: {
    position: 'relative',
  },
  titleBannerContainer: {
    position: 'relative',
  },
  titleBanner: {
    position: 'absolute',
    backgroundColor: 'rgba(36, 62, 93, 0.4)',
    textAlign: 'left',
    height: '122px',
    minWidth: '100%',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      left: 0,
      top: '-170px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine1: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: btOrangeColor,
    fontSize: '2.50rem',
    lineHeight: '3.563rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-160px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleBannerLine2: {
    position: 'absolute',
    textTransform: 'uppercase',
    color: whiteColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: '2.625rem',
    lineHeight: '3.438rem',
    fontWeight: 600,
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      left: '271px',
      top: '-115px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  teamContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '75px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '95vw',
    },
  },
  staffTitle: {
    color: webInfoColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    margin: '30px',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      lineHeight: '2.625rem',
      maxWidth: 300,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.525rem',
      lineHeight: '1.825rem',
      maxWidth: 300,
    },
  },
  videoTitle: {
    color: webInfoColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    margin: '30px',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      lineHeight: '2.625rem',
      maxWidth: 800,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.525rem',
      lineHeight: '1.825rem',
      maxWidth: 300,
    },
  },
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0 0 0',
    flexWrap: 'wrap',
    gap: '0 1.00em',
  },
  moduleImg: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    display: 'block',
    width: '100%',
    height: 'auto',
  },
  card: {
    boxShadow: 'none',
    margin: '10px 0 0 0 !important',
    padding: '0 !important',
    width: '280px',
  },
  cardBody: {
    display: 'flex',
    height: '330px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsCategory: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 5px',
  },
  memberName: {
    color: webPrimaryColor,
    backgroundColor: 'transparent',
    margin: '20px 0 0 0',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
  },
  memberTitle: {
    color: webPrimaryColor,
    backgroundColor: 'transparent',
    margin: '5px 0 10px 0',
    fontSize: '1.00rem',
    lineHeight: '1.425rem',
  },
  videoContainer: {
    flexGrow: 0,
    flexShrink: 1,
    margin: '35px, 0',
  },
  embedContainer: {
    border: 0,
    [theme.breakpoints.up('md')]: {
      width: '960px',
      height: '540px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      height: '180px',
    },
  },
}));

const convertValuesForSorting = (val) => {
  if (val === '4011' || val === '1614') {
    return 1;
  } else if (val === '4119' || val === '1773') {
    return 2;
  } else {
    return 3;
  }
};

const AboutUs = (props) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    const fetchAftercareSpecialistList = async () => {
      try {
        setIsLoading(true);
        const specialists = await callApi(null, 'staff/aboutus', 'GET');
        setIsLoading(false);
        setStaff(specialists.result);
      } catch (error) {
        console.log('Error fetching staff list by position:', error);
        logException(error);
      }
    };
    fetchAftercareSpecialistList();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <PleaseWait isLoading={isLoading} />
        <main>
          <div className={classes.relativePosition}>
            <div className={classes.banner}>
              <img src={AboutUsBanner} alt="About Us Banner" className={classes.bannerImage} />
              <div className={classes.titleBannerContainer}>
                <div className={classes.titleBanner}></div>
                <div className={classes.titleBannerLine1}>Successful Futures</div>
                <div className={classes.titleBannerLine2}>About Us</div>
              </div>
            </div>
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumbs separator={<PlayArrowIcon style={{ fontSize: '1.50rem', color: webInfoColor }} />} aria-label="breadcrumb">
              <Link href="/home">
                <HomeIcon style={{ marginTop: '5px', fontSize: '1.80rem', color: webInfoColor }} />
              </Link>
              <div style={{ color: webSecondaryColor }}>About Us</div>
            </Breadcrumbs>
          </div>
          <div className={classes.introContainer}>
            <p className={classes.intro}>
              The transition from high school to adult life can be difficult for any young person. Planning for a transition, developing independent
              living skills, seeking and finding a first job, as well as finding a place to live can be a lot to handle.
              <br />
              <br />
              With a strong focus on senior planning, proactive supports, individualized Life Skills Training, and workforce development, Successful
              Futures provides an invaluable resource for young adults as they transition to new levels of independence.
              <br />
              <br />
              Our team is here to help. If you’d like to speak to someone, please reach the Successful Futures department at 531-355-3060 or{' '}
              <a href="mailto:successfulfutures@boystown.org">SuccessfulFutures@Boystown.org</a>.
            </p>
          </div>
        </main>
      </div>
      <div className={classes.teamContainer}>
        <section className={classes.container}>
          <div className={classes.staffTitle}>Meet the Team</div>
          <div className={classes.cardGrid}>
            {staff &&
              staff.length > 0 &&
              staff
                .sort((a, b) => {
                  if (convertValuesForSorting(a.position_class, 10) > convertValuesForSorting(b.position_class, 10)) return 1;
                  if (convertValuesForSorting(a.position_class, 10) < convertValuesForSorting(b.position_class, 10)) return -1;
                  return 0;
                })
                .map(
                  (t, index) =>
                    t.position_title !== 'NDB Staff' && (
                      <Card key={t.id} className={classes.card}>
                        <CardBody className={classes.cardBody}>
                          <StaffImage pidm={t.pidm} />
                          <aside className={classes.memberName}>{t.user.first_name + ' ' + t.user.last_name}</aside>
                          <label className={classes.memberTitle}>{t.position_title}</label>
                        </CardBody>
                      </Card>
                    )
                )}
          </div>
        </section>
      </div>
      <div className={classes.teamContainer}>
        <section className={classes.container}>
          <div className={classes.videoTitle}>Overview of Successful Futures</div>
          <div className={classes.cardGrid}>
            <div className={classes.videoContainer}>
              {/* <div className={classes.embedContainer}> */}
              <iframe
                src="https://player.vimeo.com/video/737576159?h=8521dc39c0&byline=0&portrait=0"
                className={classes.embedContainer}
                // width="640"
                // height="360"
                // frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p>
                <a href="https://vimeo.com/737576159"></a>.
              </p>
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
