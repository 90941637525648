import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { btNavyBlueColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    margin: '10px 0',
    padding: '10px 0',
  },
  link: {
    color: btNavyBlueColor,
    textDecoration: 'underline',
    fontSize: 16,
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: btNavyBlueColor,
  },
  spacer: {
    padding: '8px 0',
  },
}));

export default function OtherResources(props) {
  const classes = styles();

  return (
    <Box sx={{ width: '100%' }}>
      <section className={classes.title}>
        <span className={classes.iconText}>Other Resources</span>
      </section>
      <Grid container direction="row" align="left" className={classes.spacer}>
        <Grid item xs={12}>
          <a href="http://www.remilitary.com/article-military-resources-parents-educators.html" className={classes.link}>
            Military Resources for Parents, Educations, and Students
          </a>
        </Grid>
      </Grid>
      <Grid container direction="row" align="left" className={classes.spacer}>
        <Grid item xs={12}>
          <a href="https://americorps.gov/" className={classes.link}>
            AmeriCorps
          </a>
          <aside className={classes.description}>
            AmeriCorps members train volunteers, tutor and mentor at-risk youth, build housing, clean up rivers and streams, help seniors live
            independently, provide emergency and long-term assistance to victims of natural disasters, and meet other community needs.
          </aside>
        </Grid>
      </Grid>
    </Box>
  );
}
