import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const AlumniAppSection = ({ form, setForm, classes, disabled, senior, formatPhoneNumber }) => {
  return (
    <Grid container align="left" style={{ marginTop: 30 }}>
      <Grid item xs={12}>
        <section align="left" className={classes.sectionTitle}>
          Contact Information
        </section>
        <section align="left" className={classes.subtleBold} style={{ marginBottom: 20 }}>
          This information will be used to contact you with any questions or updates regarding your application. If this information is incorrect or
          missing, please enter it on the Personal Information page.
        </section>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={6} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Name</label>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.alumni_updated_first_name ? senior.alumni_updated_first_name : senior.first_name}{' '}
          {senior.alumni_updated_last_name ? senior.alumni_updated_last_name : senior.last_name}
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Email Addresss</label>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior.user.email}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Name</label>
        </Grid>
        <Grid item xs={3}>
          {senior.alumni_updated_first_name ? senior.alumni_updated_first_name : senior.first_name}{' '}
          {senior.alumni_updated_last_name ? senior.alumni_updated_last_name : senior.last_name}
        </Grid>
        <Grid item xs={3}>
          <label>Email Addresss</label>
        </Grid>
        <Grid item xs={3}>
          {senior.user.email}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent} style={{ height: '100%' }}>
        <Grid item xs={6} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Address</label>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {senior && senior.alumni_address && senior.alumni_address.length > 0 && senior.alumni_address}
          <br />
          {senior && senior.alumni_address2 && senior.alumni_address2.length > 0 && senior.alumni_address2}
          {senior && senior.alumni_address2 && senior.alumni_address2.length > 0 && <br />}
          {senior &&
            senior.alumni_city &&
            (senior.alumni_city.length > 0 || senior.alumni_state.length > 0 || senior.alumni_zip.length > 0) &&
            senior.alumni_city + ', ' + senior.alumni_state + ' ' + senior.alumni_zip}
          <br />
          {senior.alumni_country}
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Phone Number</label>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
          {formatPhoneNumber(senior.alumni_phone_1)} {senior.alumni_phone_1_type}
          <br />
          {formatPhoneNumber(senior.alumni_phone_2)} {senior.alumni_phone_2_type}
          <br />
          {formatPhoneNumber(senior.alumni_phone_3)} {senior.alumni_phone_3_type}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={3}>
          <label>Address</label>
        </Grid>
        <Grid item xs={3}>
          {senior && senior.alumni_address && senior.alumni_address.length > 0 && senior.alumni_address}
          <br />
          {senior && senior.alumni_address2 && senior.alumni_address2.length > 0 && senior.alumni_address2}
          {senior && senior.alumni_address2 && senior.alumni_address2.length > 0 && <br />}
          {senior &&
            senior.alumni_city &&
            (senior.alumni_city.length > 0 || senior.alumni_state.length > 0 || senior.alumni_zip.length > 0) &&
            senior.alumni_city + ', ' + senior.alumni_state + ' ' + senior.alumni_zip}
          <br />
          {senior.alumni_country}
        </Grid>
        <Grid item xs={3}>
          <label>Phone</label>
        </Grid>
        <Grid item xs={3}>
          {formatPhoneNumber(senior.alumni_phone_1)} {senior.alumni_phone_1_type}
          <br />
          {formatPhoneNumber(senior.alumni_phone_2)} {senior.alumni_phone_2_type}
          <br />
          {formatPhoneNumber(senior.alumni_phone_3)} {senior.alumni_phone_3_type}
        </Grid>
      </Grid>
    </Grid>
  );
};

AlumniAppSection.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  senior: PropTypes.object.isRequired,
  formatPhoneNumber: PropTypes.func.isRequired,
};

export default AlumniAppSection;
