import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const ContactLastKnown = ({ classes, senior, setShowPlans }) => {
  const getFallLocation = (plan) => {
    switch (JSON.parse(plan).fallPlan) {
      case 'Military':
        return ' (' + JSON.parse(plan).fallMilitaryBranch + ')';
      case 'Continue Employment':
        return ' (' + JSON.parse(plan).fallEmploymentPlace + ')';
      case 'Job Corps':
        return ' (' + JSON.parse(plan).fallJobCorpsProgram + ')';
      case '4-Year College':
        return ' (' + JSON.parse(plan).fall4YearNameLocation + ')';
      case 'Community College/Trade':
        return ' (' + JSON.parse(plan).fallCCTradeNameLocation + ')';
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      <Grid container align="left" direction="row">
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
          Post-Grad Plan
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Post-Grad Plan</label>
        </Grid>
        <Grid item xs={7} sm={7} md={6} lg={7} align="left" className={classes.rowSpacer}>
          <strong>
            {senior &&
            senior.checklist &&
            JSON.parse(senior.checklist).postGraduationPlan &&
            JSON.parse(senior.checklist).postGraduationPlan.length > 0
              ? JSON.parse(senior.checklist).postGraduationPlan
              : 'Not Entered'}
          </strong>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} align="right" className={classes.rowSpacer}>
          <div style={{ marginRight: 15 }} onClick={(event) => setShowPlans(false)}>
            <ExpandLessIcon fontSize="small" />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Summer Employment Plan</label>
        </Grid>
        <Grid item xs={7} sm={7} md={6} lg={7} align="left" className={classes.rowSpacer}>
          <strong>
            {senior &&
            senior.planning_form_2 &&
            JSON.parse(senior.planning_form_2).summerEmployWork &&
            JSON.parse(senior.planning_form_2).summerEmployWork.length > 0
              ? JSON.parse(senior.planning_form_2).summerEmployWork
              : 'Not Entered'}
          </strong>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

        <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} className={classes.rowSpacer}>
          <label>Fall Plan</label>
        </Grid>
        <Grid item xs={7} sm={7} md={6} lg={7} align="left" className={classes.rowSpacer}>
          <strong>
            {senior && senior.planning_form_2 && JSON.parse(senior.planning_form_2).fallPlan && JSON.parse(senior.planning_form_2).fallPlan.length > 0
              ? JSON.parse(senior.planning_form_2).fallPlan
              : 'Not Entered'}
          </strong>{' '}
          <strong>{senior && senior.planning_form_2 && getFallLocation(senior.planning_form_2)}</strong>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
      </Grid>
    </React.Fragment>
  );
};

ContactLastKnown.propTypes = {
  classes: PropTypes.object.isRequired,
  senior: PropTypes.object.isRequired,
  setShowPlans: PropTypes.func.isRequired,
};

export default ContactLastKnown;
