import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useReactToPrint } from 'react-to-print';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import ContactScript from './ContactScript';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import ProactiveContactIcon from '../../assets/img/proactive-contact-icon.svg';
import { webPrimaryColor, whiteColor } from '../../assets/jss/nextjs-material-kit-pro.js';

const useStyles = makeStyles((theme) => ({
  welcome: {
    margin: 25,
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 30px',
  },
  menuTitle: {
    fontSize: 24,
    textAlign: 'left',
    margin: '5px 0',
    padding: 10,
  },
  appSubmittedDate: {
    fontSize: 24,
    margin: '5px 0 50px 0',
    padding: 10,
  },
  appStatus: {
    fontSize: 20,
    position: 'relative',
    bottom: '5px',
  },
  statusIcon: {
    fontSize: 20,
  },
  appStatusDate: {
    fontSize: 20,
    position: 'relative',
    bottom: '5px',
    paddingLeft: 25,
  },
  statusBottom: {
    marginLeft: 10,
    position: 'absolute',
    bottom: '20px',
  },
  icon: {
    fontSize: '100px',
  },
  nav: {
    cursor: 'pointer',
  },
  homeIconSpace: {
    marginTop: 50,
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      height: '280px',
      minHeight: '280px',
    },
    [theme.breakpoints.up('md')]: {
      height: '375px',
      minHeight: '375px',
    },
    margin: '0, 20px',
  },
  pageHeader: {
    margin: '10px 0 0 0',
  },
  pageTitle: {
    fontSize: '1.438rem',
    textAlign: 'left',
    fontWeight: 600,
  },
  pageIcon: {
    width: '8%',
    marginRight: '15px',
  },
  printHeaderText: {
    color: '#000',
    fontWeight: 600,
  },
  homeIcon: {
    fontSize: '30px',
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    padding: 30,
  },
  pageDesc: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: '#000',
  },
  breadcrumb: {
    padding: '18px 0 0 10px',
  },
  breadCrumbText: {
    fontSize: '0.80rem',
  },
  breadCrumbLink: {
    fontSize: '0.80rem',
    cursor: 'pointer',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
  addIcon: {
    color: whiteColor,
    fontSize: '80px',
    marginTop: 40,
    textAlign: 'center',
  },
  addSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerCard: {
    padding: '20px',
  },
  headerDesc: {
    width: '90%',
    fontSize: '1.063rem',
    lineHeight: '1.313rem',
    padding: '20px 0 5px 0',
    textAlign: 'left',
    color: webPrimaryColor,
  },
  printHeaderDesc: {
    width: '90%',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 0',
    color: webPrimaryColor,
  },
}));

const Contact = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const classes = useStyles();
    const history = useHistory();

    const [showHelp, setShowHelp] = useState(false);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const handleHelpClose = () => {
      setShowHelp(false);
    };

    const handleHelp = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowHelp(true);
    };

    const handleReturnHome = () => {
      store.setScholarshipAppId(null);
      store.setScholarshipApp(null);
      store.setSenior(null);
      history.push('/');
    };

    const handleReturnToContactList = () => {
      store.setHelpAppId(null);
      store.setHelpApp(null);
      store.setSenior(null);
      history.push('/contactlist');
    };

    return (
      <React.Fragment>
        {(auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) && (
          <Grid container direction="row">
            <Grid item xs={12}>
              <div className={classes.breadcrumb}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumbText}>
                  <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnHome()}>
                    Home
                  </Typography>
                  <Typography color="textPrimary" className={classes.breadCrumbLink} onClick={(event) => handleReturnToContactList()}>
                    Proactive Check-Ins List
                  </Typography>
                  {store.senior && (
                    <Typography color="textPrimary" className={classes.breadCrumbText}>
                      {store.senior && store.senior.alumni_updated_first_name ? store.senior.alumni_updated_first_name : store.senior.first_name}{' '}
                      {store.senior && store.senior.alumni_updated_last_name ? store.senior.alumni_updated_last_name : store.senior.last_name}
                    </Typography>
                  )}
                </Breadcrumbs>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} direction="row" className={classes.menu} ref={componentRef}>
          <Grid item xs={12} align="left">
            <Card className={classes.pageHeader}>
              <CardBody className={classes.headerCard}>
                <Grid container direction="row">
                  <Grid item xs={11} align="left">
                    <section className={classes.pageTitle}>
                      <img src={ProactiveContactIcon} alt="Proactive Check-In" className={classes.pageIcon} />
                      <span className={classes.pagecardHeader}>Proactive Check-Ins</span>
                    </section>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <Tooltip title="Click to return to the planner home page." placement="bottom">
                      <IconButton color="inherit" aria-label="return home" onClick={(e) => history.push('/contactlist')}>
                        <HomeIcon className={classes.homeIcon} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <ContactScript SeniorStore={store} AuthStore={auth} handleSaveReturn={handleReturnToContactList} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  })
);

export default Contact;
