import * as msal from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://${process.env.REACT_APP_AZURE_AD_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}/${process.env.REACT_APP_AZURE_USER_FLOW}`,
    knownAuthorities: [`${process.env.REACT_APP_AZURE_AD_TENANT_NAME}.b2clogin.com`],
    redirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  api: {
    scopes:
      process.env.REACT_APP_AZURE_API_SCOPES.indexOf(',') > -1
        ? process.env.REACT_APP_AZURE_API_SCOPES.split(',')
        : [process.env.REACT_APP_AZURE_API_SCOPES],
  },
  loginRequest: {
    scopes: ['openid', 'offline_access'],
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);
//console.log('msalInstance', msalInstance);

export { msalInstance };
