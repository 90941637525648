import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import CardHeader from '../Card/CardHeader.js';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 15,
    maxWidth: '330px',
    minHeight: '120px',
  },
  cardBody: {
    padding: 10,
  },
  cardHeader: {
    borderBottom: 'dotted 1px gray',
    padding: '15px 10px 5px 10px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: -10,
    fontWeight: 600,
  },
  dataBold: {
    fontWeight: 600,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    float: 'right',
    textTransform: 'none',
  },
}));

const ContactCard = ({ app, open, handleOpenContact }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card} key={app.id}>
        <CardHeader className={classes.cardHeader} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {app.last_name + ', ' + app.first_name} ({app.int_id})
          {app.status !== 'closed' && (
            <div>
              <IconButton
                size="small"
                aria-label="Add Check-In"
                onClick={(ev) => handleOpenContact(ev, app.id)}
                color="primary"
                style={{ padding: '5px 10px', margin: 0, fontSize: '.80rem' }}
              >
                <AddIcon fontSize="small" /> Add New
              </IconButton>
            </div>
          )}
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <section className={classes.dataBold}>{app.type}</section>
          <section className={classes.dataBold}></section>
          <section className={classes.dataBold}></section>
          <section className={classes.dataBold}>
            <label>Due Date</label> {app.last_attempt_result === 'Reschedule' ? app.due_date_datetime_formatted : app.due_date_formatted}
          </section>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ContactCard.propTypes = {
  app: PropTypes.object.isRequired,
  handleOpenContact: PropTypes.func.isRequired,
};

export default ContactCard;
