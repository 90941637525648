import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import Button from '../CustomButtons/Button.js';
import PhoneInput from '../Shared/PhoneInput';
import { stateLabelValues } from '../../util/stringUtil';

const ContactEdit = ({
  contactInfo,
  setContactInfo,
  classes,
  senior,
  specialistList,
  contactEditErrorMsg,
  handleSaveContactInfo,
  showOnlyAddress,
  handleDialogClose,
  store,
}) => {
  const [phone1IsValid, setPhone1IsValid] = useState(true);
  const [phoneTypes, setPhoneTypes] = React.useState(['Mobile', 'Home', 'Work']);

  const handleEmployer = (val) => {
    if (val.length > 0) {
      setContactInfo({ ...contactInfo, employer: val, unemployed: null });
    } else {
      setContactInfo({ ...contactInfo, employer: val });
    }
  };

  const handleUnemployed = (val) => {
    if (val.length > 0) {
      setContactInfo({ ...contactInfo, unemployed: val, employer: '' });
    } else {
      setContactInfo({ ...contactInfo, unemployed: val });
    }
  };

  const handleSpecialistUpdate = (obj, val) => {
    setContactInfo({ ...obj, alumniSFS: val });
    store.setContact({ ...contactInfo, specialist_id: val });
  };

  return (
    <React.Fragment>
      <Grid container className={classes.whiteBg} style={{ width: '100%' }}>
        <Grid item xs={12} align="left" className={classes.titlebar}>
          <div>
            {senior && (
              <Box sx={{ width: '90%' }} key={senior.id}>
                <Grid container direction="row" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={7} lg={6} className={classes.question}>
                    <label className={classes.scriptLabel}>Confirm Contact Information</label>
                  </Grid>
                </Grid>
                {showOnlyAddress === false && (
                  <Grid container direction="row" className={classes.rowSpacer}>
                    <Grid item xs={false} sm={false} md={2} lg={2}></Grid>
                    <Grid item xs={12} sm={12} md={5} lg={4} className={classes.question}>
                      <label>Name while at Boys Town</label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={3}>
                      {senior.first_name} {senior.last_name}
                    </Grid>
                  </Grid>
                )}

                {showOnlyAddress === false && (
                  <Grid container direction="row" className={classes.rowSpacer}>
                    <Grid item xs={false} sm={false} md={2} lg={2}></Grid>
                    <Grid item xs={12} sm={12} md={5} lg={4} className={classes.question}>
                      <label>Current Name</label>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={3}>
                      <FormControl fullWidth>
                        <TextField
                          id="updated-first-name"
                          size="small"
                          hiddenLabel
                          placeholder="Updated First Name"
                          variant="outlined"
                          value={contactInfo.alumniUpdatedFirstName ? contactInfo.alumniUpdatedFirstName : senior.first_name}
                          onChange={(event) => setContactInfo({ ...contactInfo, alumniUpdatedFirstName: event.target.value })}
                          InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                          style={{ marginRight: 10, width: { xs: '150px', sm: '160px', md: '190px', lg: '200px' } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={3}>
                      <FormControl fullWidth>
                        <TextField
                          id="updated-last-name"
                          size="small"
                          hiddenLabel
                          placeholder="Updated Last Name"
                          variant="outlined"
                          value={contactInfo.alumniUpdatedLastName ? contactInfo.alumniUpdatedLastName : senior.last_name}
                          onChange={(event) => setContactInfo({ ...contactInfo, alumniUpdatedLastName: event.target.value })}
                          InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                          style={{ width: { xs: '150px', sm: '160px', md: '190px', lg: '200px' } }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {showOnlyAddress === false && (
                  <Grid container direction="row" className={classes.rowSpacer}>
                    <Grid item xs={false} sm={false} md={2} lg={2}></Grid>
                    <Grid item xs={11} sm={11} md={5} lg={4} className={classes.question}>
                      <label>Successful Futures Specialist</label>
                    </Grid>
                    <Grid item xs={5} sm={5} md={4} lg={3}>
                      <FormControl fullWidth>
                        <Select
                          labelId="aftercare-specialist-label"
                          id="aftercare-specialist"
                          value={contactInfo.alumniSFS}
                          onChange={(event) => handleSpecialistUpdate(contactInfo, event.target.value)}
                        >
                          <MenuItem value="0"></MenuItem>
                          {specialistList &&
                            specialistList.length > 0 &&
                            specialistList
                              .slice()
                              .sort((a, b) => {
                                return a.user.last_name > b.user.last_name ? 1 : -1;
                              })
                              .map((s) => {
                                return (
                                  <MenuItem value={s.id}>
                                    {s.user.first_name} {s.user.last_name}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <br />
                <br />
                {/* ADD THIS LATER - IT INVOLVES ALL SORTS OF GYMNASTICS TO ENSURE THE EMAIL ISN'T ALREADY USED, ETC.
                <Grid container direction="row" className={classes.rowSpacer}>
                  <Grid item xs={12} sm={12} md={7} lg={6} className={classes.question}>
                    <label className={classes.scriptLabel}>It looks like the email address I have listed for you is</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="email"
                        size="small"
                        hiddenLabel
                        placeholder="Email Address"
                        variant="outlined"
                        value={contactInfo.alumniEmail}
                        onChange={(event) => setContactInfo({ ...contactInfo, alumniEmail: event.target.value })}
                        disabled={senior.user.id === 0}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={false} sm={false} md={false} lg={2} align="center" className={classes.question}>
                    <label className={classes.scriptLabel}> Is this correct?</label>
                  </Grid>
                </Grid>
                <br />
                <br /> */}
                {showOnlyAddress === false && (
                  <Grid container direction="row" className={classes.rowSpacer}>
                    <Grid item xs={12} sm={12} md={7} lg={6} className={classes.question}>
                      <label className={classes.scriptLabel}>Is this still the best phone number to reach you?</label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <FormControl error={!phone1IsValid}>
                        <PhoneInput
                          id="phone"
                          variant="outlined"
                          value={contactInfo.alumniPhone1 || ''}
                          handleChange={(val, isValid) => {
                            setContactInfo({ ...contactInfo, alumniPhone1: val.replace(/\D/g, '') });
                            setPhone1IsValid(isValid);
                          }}
                        />
                        <FormHelperText id="my-helper-text">{!phone1IsValid && 'Please enter a valid phone number'}</FormHelperText>
                      </FormControl>

                      <FormControl>
                        <Select
                          labelId="alumni-phone-1-type"
                          id="alumni-phone-1-type"
                          value={contactInfo.alumniPhone1Type}
                          className={classes.valignSelect}
                          style={{ width: '150px', marginLeft: 20 }}
                          onChange={(event) => setContactInfo({ ...contactInfo, alumniPhone1Type: event.target.value })}
                        >
                          <MenuItem value={''}></MenuItem>
                          {phoneTypes.slice().map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                <Grid container direction="row" className={classes.rowSpacer}>
                  <Grid
                    item
                    xs={12}
                    sm={showOnlyAddress ? 6 : 12}
                    md={showOnlyAddress ? 3 : 7}
                    lg={showOnlyAddress ? 2 : 6}
                    className={classes.question}
                  >
                    <label className={classes.scriptLabel}>{showOnlyAddress ? 'Address' : 'Are you still living at'}</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4} style={{ marginBottom: 10 }}>
                    <FormControl fullWidth>
                      <TextField
                        id="alumni-address"
                        size="small"
                        hiddenLabel
                        placeholder="Address"
                        variant="outlined"
                        value={contactInfo.alumniAddress ? contactInfo.alumniAddress : ''}
                        onChange={(event) => setContactInfo({ ...contactInfo, alumniAddress: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                        style={{ width: 350 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={false} sm={false} md={false} lg={showOnlyAddress ? 6 : 2}></Grid>

                  <Grid item xs={12} sm={showOnlyAddress ? 6 : 12} md={showOnlyAddress ? 3 : 7} lg={showOnlyAddress ? 2 : 6}></Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4} style={{ marginBottom: 10 }}>
                    <FormControl fullWidth>
                      <TextField
                        id="alumni-address2"
                        size="small"
                        hiddenLabel
                        placeholder="Address Line 2"
                        variant="outlined"
                        value={contactInfo.alumniAddress2 ? contactInfo.alumniAddress2 : ''}
                        onChange={(event) => setContactInfo({ ...contactInfo, alumniAddress2: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                        style={{ width: 350 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={false} sm={false} md={false} lg={showOnlyAddress ? 6 : 2}></Grid>

                  <Grid item xs={12} sm={showOnlyAddress ? 6 : 12} md={showOnlyAddress ? 3 : 7} lg={showOnlyAddress ? 2 : 6}></Grid>
                  <Grid item xs={12} sm={12} md={5} lg={3} style={{ marginBottom: 10 }}>
                    <FormControl fullWidth>
                      <TextField
                        id="alumni-city"
                        size="small"
                        hiddenLabel
                        placeholder="City"
                        variant="outlined"
                        value={contactInfo.alumniCity ? contactInfo.alumniCity : ''}
                        onChange={(event) => setContactInfo({ ...contactInfo, alumniCity: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                        style={{ width: 250 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={false} md={showOnlyAddress ? 3 : 7} lg={showOnlyAddress ? false : 6}></Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4}>
                    <FormControl>
                      <Select
                        id="alumni-state"
                        value={contactInfo.alumniState}
                        className={classes.valignSelect}
                        onChange={(event) => setContactInfo({ ...contactInfo, alumniState: event.target.value })}
                        style={{ width: 170, marginRight: 20 }}
                      >
                        <MenuItem value="  "></MenuItem>
                        {stateLabelValues.map((state) => {
                          return <MenuItem value={state.value}>{state.label}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="alumni-zip"
                        size="small"
                        hiddenLabel
                        placeholder="Zip Code"
                        variant="outlined"
                        value={contactInfo.alumniZip ? contactInfo.alumniZip : ''}
                        onChange={(event) => setContactInfo({ ...contactInfo, alumniZip: event.target.value })}
                        InputProps={{ maxLength: 10, classes: { input: classes.textField } }}
                        style={{ width: 125 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={6}></Grid>
                  {showOnlyAddress === false && (
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          id="alumni-country"
                          size="small"
                          hiddenLabel
                          placeholder="Country"
                          variant="outlined"
                          value={contactInfo.alumniCountry ? contactInfo.alumniCountry : ''}
                          onChange={(event) => setContactInfo({ ...contactInfo, alumniCountry: event.target.value })}
                          InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                          style={{ width: 350, marginTop: 10 }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={false} sm={false} md={false} lg={2}></Grid>
                </Grid>

                <Grid container direction="row" className={classes.rowSpacer}>
                  <Grid item xs={12} className={classes.question}>
                    <label className={classes.scriptLabel}>Current Information</label>
                  </Grid>

                  <Grid item xs={false} sm={false} md={2} lg={2}></Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4} className={classes.question}>
                    <label>Employer</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="employer-name"
                        size="small"
                        placeholder="Name of Employer"
                        variant="outlined"
                        value={contactInfo.employer}
                        onChange={(event) => handleEmployer(event.target.value)}
                        InputProps={{ maxLength: 100 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={false} sm={false} md={6} lg={6}></Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        name="unemployed"
                        value={contactInfo.unemployed}
                        onChange={(event) => handleUnemployed(event.target.value)}
                        disabled={contactInfo.employer && contactInfo.employer.length > 0}
                      >
                        <FormControlLabel
                          value="lookingforwork"
                          control={<Radio color="default" disabled={contactInfo.employer && contactInfo.employer.length > 0} />}
                          label={
                            <span className={classes.radioOption} style={{ marginRight: 10 }}>
                              Unemployed, looking for work
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="notlookingforwork"
                          control={<Radio color="default" disabled={contactInfo.employer && contactInfo.employer.length > 0} />}
                          label={<span className={classes.radioOption}>Unemployed, NOT looking for work</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={false} sm={false} md={2} lg={2}></Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4} className={classes.question}>
                    <label>School</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="school-name"
                        size="small"
                        placeholder="Name of School"
                        variant="outlined"
                        value={contactInfo.school}
                        onChange={(event) => setContactInfo({ ...contactInfo, school: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
        </Grid>
        <Grid container direction="row" style={{ width: '100%' }}>
          <Grid item xs={12} className={classes.rowSpacer}>
            {contactEditErrorMsg && contactEditErrorMsg.length > 0 && <summary className={classes.error}>{contactEditErrorMsg}</summary>}
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.rowSpacer} align="right" style={{ width: '100%' }}>
          <Grid item xs={12}>
            <span>
              <Button className={classes.buttonWidth} color="secondary" variant="outlined" onClick={(event) => handleDialogClose()}>
                Cancel
              </Button>
            </span>
            <span>
              <Button
                className={classes.buttonWidth}
                color="primary"
                variant="contained"
                onClick={(event) => handleSaveContactInfo(senior)}
                style={{ margin: '0 0 0 15px' }}
              >
                Save Contact Info
              </Button>
            </span>
          </Grid>
        </Grid>{' '}
      </Grid>
    </React.Fragment>
  );
};

ContactEdit.propTypes = {
  contactInfo: PropTypes.object.isRequired,
  setContactInfo: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  senior: PropTypes.object.isRequired,
  specialistList: PropTypes.array.isRequired,
  contactEditErrorMsg: PropTypes.string,
  handleSaveContactInfo: PropTypes.func.isRequired,
  showOnlyAddress: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
};

export default ContactEdit;
