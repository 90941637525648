import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AdvancedLifeSkillsSchedule from '../AdvancedLifeSkillsSchedule';

const Life = ({ setPage, classes }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row" className={classes.subMenu}>
        <Grid item xs={12} align="left">
          <Grid container direction="row" style={{ width: '95vw', marginTop: 20 }}>
            <Grid item xs={11} align="left">
              <Typography variant="h5" align="left" style={{ marginTop: 10 }}>
                2023-2024 Advanced Life Skills Schedule
              </Typography>
            </Grid>
            <Grid item xs={1} align="right">
              <IconButton color="inherit" aria-label="return home" onClick={(e) => setPage('home')}>
                <HomeIcon className={classes.homeIcon} style={{ color: '#000' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AdvancedLifeSkillsSchedule />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Life.propTypes = {
  setPage: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default Life;
