import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { msalConfig } from '../../services/msal';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { secondaryColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import BTGlobalHeader from './BTGlobalHeader';

import styles from '../../assets/jss/nextjs-material-kit-pro/components/publicHeaderStyle.js';

const useStyles = makeStyles(styles);

const PublicHeader = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    React.useEffect(() => {
      if (props.changeColorOnScroll) {
        window.addEventListener('scroll', headerColorChange);
      }
      return function cleanup() {
        if (props.changeColorOnScroll) {
          window.removeEventListener('scroll', headerColorChange);
        }
      };
    });

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const headerColorChange = () => {
      const { color, changeColorOnScroll } = props;
      const windowsScrollTop = window.pageYOffset;
      if (windowsScrollTop > changeColorOnScroll.height) {
        document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
      } else {
        document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
      }
    };
    const { color, fixed, absolute } = props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });

    useEffect(() => {
      const handleLogin = async () => {
        await authStore.handleUserLogin(accounts[0]);
      };
      if (isAuthenticated && authStore.user == null) {
        console.log('authStore.user', authStore.user);
        handleLogin();
      }
    }, [accounts, authStore, isAuthenticated]);

    const handleSignIn = async () => {
      await instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
      const account = accounts[0] || null;
      authStore.handleUserLogin(account);
    };

    return (
      <AppBar className={appBarClasses + ' ' + classes.appBarPosition}>
        <div className={classes.flexFrame}>
          <Toolbar className={classes.container}>
            <Link href="/home" style={{ cursor: 'pointer' }}>
              <img className={classes.logo} src="/boystown_successful_futures_logo_white.png" alt="Logo" />
            </Link>

            {/* Desktop */}
            <Hidden mdDown implementation="css" className={classes.hidden}>
              <Grid container>
                <Grid item xs={false} sm={1} md={1} />
                <Grid item xs={12} sm={11}>
                  <div className={classes.links}>
                    <a href="/help" style={{ color: window.location.pathname === '/help' && secondaryColor }}>
                      HOW CAN WE HELP?
                    </a>
                    <a href="/alumni" style={{ color: window.location.pathname === '/alumni' && secondaryColor }}>
                      ALUMNI ASSOCIATION
                    </a>
                    <a href="/about" style={{ color: window.location.pathname === '/about' && secondaryColor }}>
                      ABOUT US
                    </a>
                    <Button className={classes.signinButton} startIcon={<PersonOutlineIcon />} onClick={(e) => handleSignIn()}>
                      SIGN IN
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Hidden>

            {/* Mobile */}
            <Hidden lgUp>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
                <Menu style={{ color: '#FFF' }} />
              </IconButton>
            </Hidden>
          </Toolbar>

          <Hidden lgUp implementation="js">
            <div className={classes.mockDrawerPaper} style={{ height: mobileOpen ? '380px' : 0 }}>
              {mobileOpen && (
                <React.Fragment>
                  <div className={classes.mobileAppResponsive}>
                    <div className={classes.mobileLink}>
                      <a href="/">HOME</a>
                    </div>
                    <div className={classes.mobileLink}>
                      <a href="/help">HOW CAN WE HELP?</a>
                    </div>
                    <div className={classes.mobileLink}>
                      <a href="/alumni">ALUMNI ASSOCIATION</a>
                    </div>
                    <div className={classes.mobileLink}>
                      <a href="/about">ABOUT US</a>
                    </div>
                    <div className={classes.mobileLink} onClick={(e) => handleSignIn()}>
                      REGISTER/SIGN IN
                    </div>
                  </div>
                  <div>
                    <BTGlobalHeader />
                  </div>
                </React.Fragment>
              )}
            </div>
          </Hidden>
        </div>
      </AppBar>
    );
  })
);
export default PublicHeader;

PublicHeader.defaultProp = {
  color: 'white',
};

PublicHeader.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']).isRequired,
  }),
};
