import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Paper } from '@material-ui/core';

const Instructions = ({ classes }) => {
  return (
    <Grid item xs={12} align="left">
      <section align="left" className={classes.sectionTitle}>
        Eligibility Guidelines
      </section>
      <Paper className={classes.paper}>
        {/* <Typography variant="body1" paragraph={true}>
          Please enter all the information below and complete a 1000-word essay to apply to the Workforce Development Scholarships &amp; Post-graduation Supports. Attach copies of ALL information requested. 
      </Typography>
      <Typography variant="body1" paragraph={true}>
          <label className={classes.reminder}>Submit Completed Application by March 1st.</label>
      </Typography> */}
        {/* <Typography variant="subtitle1">
          Eligibility Guidelines
      </Typography> */}
        <Typography variant="body1" paragraph={true}>
          To ensure the continuation of providing educational assistance to Boys Town Program Graduates, the following guidelines have been
          established to ensure fairness to all applicants.
        </Typography>
        <Typography variant="body1" paragraph={true} component="div">
          All applicants must have been a resident of the Home Campus at Boys Town, Nebraska, or under the care of one of Boys Town's satellite
          campuses, and meet the following criteria:
          <ul>
            <li>Graduated from Boys Town High School and/or;</li>
            <li>Lived at a Boys Town campus for a minimum of 6 months and departed the Family Home Program successfully.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph={true} component="div">
          All applicants must:
          <ul>
            <li>Be in good standing with Boys Town.</li>
            <li>If applying for an academic scholarship, you must attend an accredited institution of higher learning.</li>
            <li>Complete, in full, the application below. All applications must be electronically signed or will be deemed incomplete.</li>
            <li>Have and maintain a minimum of a 2.0 overall GPA in a 4.0 grading system or the equivalent in an alternate grading system.</li>
            <li>
              All scholarship monies will be paid directly to the school to which the applicant is attending. Only in extraordinary circumstances and
              with board approval will monies be paid directly to an applicant.
            </li>
          </ul>
        </Typography>
      </Paper>
    </Grid>
  );
};

Instructions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Instructions;
