import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import TableStyle from '../../styles/TableStyle';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextareaAutosize,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { logException, appInsights } from '../../services/appInsights';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { getCurrentDateTime } from '../../util/dateUtil';
import { callApi } from '../../util/apiWrapper';
import { blackColor, btLightBlueColor, btLightGreenColor, btMediumGrayColor } from '../../assets/jss/nextjs-material-kit-pro';
import { logAudit } from '../../util/auditUtil';
import ContactDetails from './ContactDetails';
import ContactEdit from './ContactEdit';
import ContactResult from './ContactResult';
import ContactVoicemailScript from './ContactVoicemailScript';
import ContactFinalAttempt from './ContactFinalAttempt';
import ContactReschedule from './ContactReschedule';
import ContactLastKnown from './ContactLastKnown';
import ContactHistory from './ContactHistory';
import ContactAwards from './ContactAwards';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { dateOnlyFormat, dateToS, dateTimeToS, dateCompare } from '../../util/dateUtil';

const formStyles = makeStyles((theme) => ({
  scriptLabel: {
    fontSize: '1.50rem',
    lineHeight: '1.75rem',
    paddingTop: 7,
  },
  adminLabel: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    paddingTop: 7,
  },
  reminderScriptLabel: {
    fontSize: '1.20rem',
    lineHeight: '1.25rem',
    paddingTop: 7,
  },
  rowSpacer: {
    margin: '10px 0',
  },
  indentPadding: {
    margin: '10px 0',
  },
  messageHeader: {
    [theme.breakpoints.down('md')]: {
      margin: '10px 0 20px 0',
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 0 10px 0',
      overflow: 'auto',
    },
  },
  question: {
    paddingTop: 7,
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  checkboxLabel: {
    fontSize: '0.95rem',
    fontWeight: 500,
  },
  phone: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  email: {
    fontSize: '1.25rem',
    fontWeight: 600,
    borderBottom: '1px solid #000',
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
    marginRight: 25,
  },
  reminder: {
    fontStyle: 'italic',
    color: 'blue',
    fontSize: '1.25rem',
    margin: '10px 0 15px 0',
  },
  error: {
    fontSize: '1.15rem',
    padding: 5,
    color: '#FA0000',
    fontWeight: 600,
  },
  adminError: {
    fontSize: '1.20',
    lineHeight: '1.40rem',
    fontWeight: 600,
    paddingTop: 5,
  },
  textAreaFont: {
    fontSize: '1.00rem',
    color: blackColor,
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  sectionHeader: {
    fontSize: '1.25rem',
    fontWeight: 400,
    color: blackColor,
  },
  awardAmount: {
    textDecoration: 'underline',
    margin: '0, 10px',
    fontWeight: 600,
  },
  noRecordsMsg: {
    fontSize: '1.15rem',
  },
  detailsLink: {
    cursor: 'pointer',
    color: 'blue',
  },
  buttonWidth: {
    width: 160,
  },
  noPhone: {
    color: btMediumGrayColor,
    fontWeight: 600,
    fontStyle: 'italic',
  },
  subDetailsLink: {
    cursor: 'pointer',
    color: 'blue',
    padding: '-5px 0 5px 5px',
  },
  awardsEntry: {
    fontSize: '1.00rem',
  },
  awardList: {
    paddingTop: 12,
    fontWeight: 700,
    fontSize: '1.00rem',
  },
  valignSelect: {
    marginTop: '15px',
    height: '20px',
    padding: '10px 0 0 5px',
  },
  listRowSpacer: {
    margin: '15px 0',
  },
}));

const tableStyles = makeStyles(TableStyle);

const ContactScript = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const { handleSaveReturn } = props;

    const classes = formStyles();
    const tableClasses = tableStyles();

    const [planningForm1, setPlanningForm1] = useState(null);
    const [planningForm2, setPlanningForm2] = useState(null);
    const [checkList, setCheckList] = useState(null);

    const [errorMsg, setErrorMsg] = React.useState('');
    const [contactEditErrorMsg, setContactEditErrorMsg] = React.useState('');
    const [warning, setWarning] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [attemptNumber, setAttemptNumber] = useState(0);

    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [showScript, setShowScript] = useState('');
    const [showMoney, setShowMoney] = useState(false);
    const [canBeSuccessful, setCanBeSuccessful] = useState(false);

    const [clientHaveTime, setClientHaveTime] = useState('');

    const [contactHistory, setContactHistory] = useState([]);
    const [historyRecord, setHistoryRecord] = useState(null);
    const [latestResult, setLatestResult] = useState(null);

    const [activeSpecialists, setActiveSpecialists] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

    const [originalContactInfo, setOriginalContactInfo] = useState({
      alumniPhone1: '',
      alumniPhone1Type: '',
      alumniAddress: '',
      alumniAddress2: '',
      alumniCity: '',
      alumniState: '',
      alumniZip: '',
      alumniCountry: '',
      alumniUpdatedFirstName: '',
      alumniUpdatedLastName: '',
      alumniEmail: '',
      alumniSFS: '',
      employer: '',
      school: '',
      unemployed: '',
    });

    const [contactInfo, setContactInfo] = useState({
      alumniPhone1: '',
      alumniPhone1Type: '',
      alumniAddress: '',
      alumniAddress2: '',
      alumniCity: '',
      alumniState: '',
      alumniZip: '',
      alumniCountry: '',
      alumniUpdatedFirstName: '',
      alumniUpdatedLastName: '',
      alumniEmail: '',
      alumniSFS: '',
      employer: '',
      school: '',
      unemployed: '',
    });

    const [contactMode, setContactMode] = useState('');
    const [mode, setMode] = useState(null); // used for the NDB Incident logging PSA-388
    const [attemptDate, setAttemptDate] = useState(new Date());
    const [attemptResult, setAttemptResult] = useState('');
    const [rescheduleDate, setRescheduleDate] = useState(null);
    const [rescheduleTime, setRescheduleTime] = useState(null);
    const [finalAttempt, setFinalAttempt] = useState(false);
    const [history, setHistory] = useState([]);

    // prettier-ignore
    const [form, setForm] = useState({
      moneyAccessed: 0,
      moneyRemaining: 0,
      attendingSchool: '',
      schoolfullparttime: '',
      schoolname: '',
      major: '',
      enrolllength: '',
      working: '',
      workfullparttime: '',
      currentjob: '',
      jobposition: '',
      employlength: '',
      startingsoon: '',
      startingsoonwhere: '',
      startingsoonstartdate: null,
      startingsoonschedule: '',
      noschoolchildcare: false,
      noschooldisability: false,
      noschoolfinancial: false,
      noschoolhousing: false,
      noschooleligibility: false,
      noschoolhealth: false,
      noschooltransportation: false,
      noschoolother: false,
      noschoolotherdesc: '',
      noworkchildcare: false,
      noworkdisability: false,
      noworkfinancial: false,
      noworkhousing: false,
      noworkeligibility: false,
      noworkhealth: false,
      noworktransportation: false,
      noworkother: false,
      noworkotherdesc: '',
      needsupport: '',
      resources: '',
      health: '',
      housing: '',
      transportation: '',
      money: '',
      otherAwards: [],
    });

    const [notes, setNotes] = useState('');

    // Snackbar methods //
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowWarning(false);
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    // Snackbar methods //

    useEffect(() => {
      const fetchAftercareSpecialistList = async () => {
        try {
          const specialists = await callApi(auth, 'staff', 'GET');
          store.setAftercareSpecialistList(
            specialists.result.filter(
              (s) => s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534'
            )
          );
          setActiveSpecialists(
            specialists.result.filter(
              (s) =>
                s.is_active &&
                (s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534')
            )
          );
        } catch (error) {
          console.log('Error fetching aftercare specialist list:', error);
          logException(error);
        }
      };
      fetchAftercareSpecialistList();
    }, [store.checkIn]); // TLRTODO: Do we need more dependencies?

    useEffect(() => {
      if (store.senior && store.checkIn) {
        const fetchLastAttempt = async () => {
          try {
            const result = await callApi(auth, 'contact/attempt/' + store.checkIn.id, 'GET');
            setAttemptNumber(result.lastAttempt);
          } catch (error) {
            console.log('Error fetching last attempt number:', error);
            logException(error);
          }
        };
        const fetchContactHistory = async () => {
          try {
            const res = await callApi(auth, 'contact/history/' + store.checkIn.alumni_id, 'GET');
            const lastResult = res.result[0];
            setLatestResult(lastResult);
            setForm({
              ...form,
              moneyAccessed: JSON.parse(lastResult.contact_details).moneyAccessed,
              moneyRemaining: JSON.parse(lastResult.contact_details).moneyRemaining,
              attendingSchool: JSON.parse(lastResult.contact_details).attendingSchool,
              schoolfullparttime: JSON.parse(lastResult.contact_details).schoolfullparttime,
              schoolname: JSON.parse(lastResult.contact_details).schoolname,
              major: JSON.parse(lastResult.contact_details).major,
              enrolllength: JSON.parse(lastResult.contact_details).enrolllength,
              working: JSON.parse(lastResult.contact_details).working,
              workfullparttime: JSON.parse(lastResult.contact_details).workfullparttime,
              currentjob: JSON.parse(lastResult.contact_details).currentjob,
              jobposition: JSON.parse(lastResult.contact_details).jobposition,
              employlength: JSON.parse(lastResult.contact_details).employlength,
              startingsoon: JSON.parse(lastResult.contact_details).startingsoon,
              startingsoonwhere: JSON.parse(lastResult.contact_details).startingsoonwhere,
              startingsoonstartdate: JSON.parse(lastResult.contact_details).startingsoonstartdate,
              startingsoonschedule: JSON.parse(lastResult.contact_details).startingsoonschedule,
              noschoolchildcare: JSON.parse(lastResult.contact_details).noschoolchildcare,
              noschooldisability: JSON.parse(lastResult.contact_details).noschooldisability,
              noschoolfinancial: JSON.parse(lastResult.contact_details).noschoolfinancial,
              noschoolhousing: JSON.parse(lastResult.contact_details).noschoolhousing,
              noschooleligibility: JSON.parse(lastResult.contact_details).noschooleligibility,
              noschoolhealth: JSON.parse(lastResult.contact_details).noschoolhealth,
              noschooltransportation: JSON.parse(lastResult.contact_details).noschooltransportation,
              noschoolother: JSON.parse(lastResult.contact_details).noschoolother,
              noschoolotherdesc: JSON.parse(lastResult.contact_details).noschoolotherdesc,
              noworkchildcare: JSON.parse(lastResult.contact_details).noworkchildcare,
              noworkdisability: JSON.parse(lastResult.contact_details).noworkdisability,
              noworkfinancial: JSON.parse(lastResult.contact_details).noworkfinancial,
              noworkhousing: JSON.parse(lastResult.contact_details).noworkhousing,
              noworkeligibility: JSON.parse(lastResult.contact_details).noworkeligibility,
              noworkhealth: JSON.parse(lastResult.contact_details).noworkhealth,
              noworktransportation: JSON.parse(lastResult.contact_details).noworktransportation,
              noworkother: JSON.parse(lastResult.contact_details).noworkother,
              noworkotherdesc: JSON.parse(lastResult.contact_details).noworkotherdesc,
              needsupport: JSON.parse(lastResult.contact_details).needsupport,
              resources: JSON.parse(lastResult.contact_details).resources,
              health: JSON.parse(lastResult.contact_details).health,
              housing: JSON.parse(lastResult.contact_details).housing,
              transportation: JSON.parse(lastResult.contact_details).transportation,
              money: JSON.parse(lastResult.contact_details).money,
              otherAwards: JSON.parse(lastResult.contact_details).otherAwards,
            });
            setHistory(res.result);
          } catch (error) {
            console.log('Error fetching contact history:', error);
            logException(error);
          }
        };

        fetchLastAttempt();
        fetchContactHistory();

        //Hide/Show money section based on existing data
        if (form.moneyAccessed === 0 && form.moneyRemaining === 0 && form.otherAwards.length === 0) {
          setShowMoney(true);
        } else {
          setShowMoney(false);
        }
      }
    }, [store.senior, store.checkIn]);

    useEffect(() => {
      if (store.senior) {
        setContactInfo({
          alumniPhone1: store.senior.alumni_phone_1,
          alumniPhone1Type: store.senior.alumni_phone_1_type,
          alumniAddress: store.senior.alumni_address,
          alumniAddress2: store.senior.alumni_address2,
          alumniCity: store.senior.alumni_city,
          alumniState: store.senior.alumni_state,
          alumniZip: store.senior.alumni_zip,
          alumniCountry: store.senior.alumni_country,
          alumniUpdatedFirstName: store.senior.alumni_updated_first_name,
          alumniUpdatedLastName: store.senior.alumni_updated_last_name,
          first_name: store.senior.first_name,
          last_name: store.senior.last_name,
          alumniEmail: store.senior.user.email,
          alumniSFS: store.senior.aftercare_specialist,
          employer: store.senior.employer,
          school: store.senior.school,
          unemployed: store.senior.unemployed,
        });
        // store.senior.planning_form_1 && store.senior.planning_form_1.length > 0 && setPlanningForm1(JSON.parse(store.senior.planning_form_1));
        // store.senior.planning_form_2 && store.senior.planning_form_2.length > 0 && setPlanningForm2(JSON.parse(store.senior.planning_form_2));
        // store.senior.checklist && store.senior.checklist.length > 0 && setCheckList(JSON.parse(store.senior.checklist));
        // If no originalContact previously set, set it now.
        if (originalContactInfo.first_name && originalContactInfo.first_name.length === 0) {
          setOriginalContactInfo(contactInfo);
        }
      }
    }, [store.senior]);

    // TLRTODO
    useEffect(() => {
      if (contactMode === 'Phone' && showScript === 'no') {
        if (attemptResult === 'VoiceMail' || attemptResult === 'NoAnswer' || attemptResult === 'Disconnected') {
          setCanBeSuccessful(true);
        }
      }

      if (contactMode === 'Phone' && showScript === 'yes' && clientHaveTime === 'no') {
        if (
          attemptResult === 'Reschedule' ||
          attemptResult === 'WrongNumber' ||
          attemptResult === 'Unavailable' ||
          attemptResult === 'OtherContacts' ||
          attemptResult === 'Refused'
        ) {
          setCanBeSuccessful(true);
        }
      }

      if (contactMode === 'Phone' && showScript === 'yes' && clientHaveTime === 'yes') {
        if (attemptResult === 'Success' || attemptResult === 'Partial') {
          writeContact();
        }
      }

      if (contactMode === 'FocusMeeting') {
        if (attemptResult === 'Success' || attemptResult === 'Partial') {
          writeContact();
        }
      }

      if (contactMode === 'Email') {
        if (attemptResult === 'EmailSent') {
          writeContact();
        }
      }

      if (contactMode === 'Text') {
        if (attemptResult === 'TextSent') {
          writeContact();
        }
      }

      if (contactMode === 'SocialMedia') {
        if (attemptResult === 'SocialMediaContact') {
          writeContact();
        }
      }
    }, [attemptResult]);

    const wasContactInfoUpdated = (original, updated) => {
      if (
        original.alumniUpdatedFirstName !== updated.alumni_updated_first_name ||
        original.alumniUpdatedLastName !== updated.alumni_updated_last_name ||
        original.alumniPhone1 !== updated.alumni_phone_1 ||
        original.alumniPhone1Type !== updated.alumni_phone_1_type ||
        original.alumniPhone2 !== updated.alumni_phone_2 ||
        original.alumniPhone2Type !== updated.alumni_phone_2_type ||
        original.alumniPhone3 !== updated.alumni_phone_3 ||
        original.alumniPhone3Type !== updated.alumni_phone_3_type ||
        original.alumniAddress !== updated.alumni_address ||
        original.alumniAddress2 !== updated.alumni_address2 ||
        original.alumniCity !== updated.alumni_city ||
        original.alumniState !== updated.alumni_state ||
        original.alumniZip !== updated.alumni_zip ||
        original.alumniCountry !== updated.alumni_country ||
        original.alumniEmail !== updated.alumniEmail ||
        original.alumniSFS !== updated.alumniSFS ||
        original.employer !== updated.employer ||
        original.school !== updated.school ||
        original.unemployed !== updated.unemployed
      ) {
        return true;
      } else if (updated.alumni_updated_first_name && updated.alumni_updated_first_name.length > 0) {
        // Checking if first name was modified from either alumni name or first name
        if (updated.alumni_updated_first_name !== original.alumni_updated_first_name && updated.alumni_updated_first_name !== original.first_name) {
          return true;
        }
      } else if (original.alumni_updated_last_name && original.alumni_updated_last_name.length > 0) {
        // Checking if second name was modified from either alumni name or first name
        if (updated.alumni_updated_last_name !== original.alumni_updated_last_name && updated.alumni_updated_last_name !== original.last_name) {
          return true;
        }
      } else {
        return false;
      }
    };

    const validateGmail = (email) => {
      const gmailRegex = /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@(?:gmail|GMAIL|test)([\.])(?:com|COM)/i;
      return gmailRegex.test(email);
    };

    const validateContactInformation = () => {
      let rc = true;
      let requiredFields = [];
      if (store.senior.userId > 0 && validateGmail(contactInfo.alumniEmail) === false) {
        setErrorMsg('You must enter a valid Gmail address. ');
        return;
      }

      if (contactInfo.alumniPhone1.length > 0 && contactInfo.alumniPhone1Type.length === 0) {
        requiredFields = [
          ...requiredFields,
          {
            requirement: 'Please select a phone type for your phone number. ',
          },
        ];
        rc = false;
      }

      if (contactInfo.alumniPhone1.length === 0 && contactInfo.alumniPhone1Type.length > 0) {
        requiredFields = [
          ...requiredFields,
          {
            requirement: 'Please enter a phone number for phone type ' + contactInfo.alumniPhone1Type + '. ',
          },
        ];
        rc = false;
      }

      if (contactInfo.alumniPhone1.length > 0 && validatePhoneNumber(contactInfo.alumniPhone1) === false) {
        requiredFields = [
          ...requiredFields,
          {
            requirement: 'Please enter a valid phone number for phone type ' + contactInfo.alumniPhone1Type + '. ',
          },
        ];
        rc = false;
      }

      if (
        contactInfo.alumniCountry &&
        contactInfo.alumniCountry.length > 0 &&
        contactInfo.alumniCountry !== 'US' &&
        contactInfo.alumniCountry !== 'USA' &&
        contactInfo.alumniCountry !== 'United States' &&
        contactInfo.alumniCountry !== 'United States of America'
      ) {
        // If country other than USA and either address, city, zip completed, require all.
        if (contactInfo.alumniAddress.length > 0 || contactInfo.alumniCity.length > 0 || contactInfo.alumniZip.length > 0) {
          if (contactInfo.alumniAddress.length === 0 || contactInfo.alumniCity.length === 0 || contactInfo.alumniZip.length === 0) {
            requiredFields = [
              ...requiredFields,
              {
                requirement: 'Please enter a complete address, including city, and zip/postal code. ',
              },
            ];
            rc = false;
          }
        }
      } else {
        // If either address, city, state zip completed, require all.
        if (
          contactInfo.alumniAddress &&
          (contactInfo.alumniAddress.length > 0 ||
            contactInfo.alumniCity.length > 0 ||
            contactInfo.alumniState.trim().length > 0 ||
            contactInfo.alumniZip.length > 0)
        ) {
          if (
            contactInfo.alumniAddress &&
            (contactInfo.alumniAddress.length === 0 ||
              contactInfo.alumniCity.length === 0 ||
              contactInfo.alumniState.trim().length === 0 ||
              contactInfo.alumniZip.length === 0)
          ) {
            requiredFields = [
              ...requiredFields,
              {
                requirement: 'Please enter a complete address, including city, state, and zip code. ',
              },
            ];
            rc = false;
          }
        }
      }
      let errors = [];
      requiredFields.forEach((req, idx) => {
        errors.push(req.requirement);
      });
      if (errors.length > 0) {
        setContactEditErrorMsg(errors);
        setWarning('The contact information could not be updated. See the errors listed below.');
        setShowWarning(true);
      }
      return rc;
    };

    // Update method sent to ContactEdit component
    const handleSaveContactInfo = async () => {
      if (validateContactInformation()) {
        try {
          store.setLoading(false);
          store.setSearching(true);
          let updatedSenior = store.senior;
          updatedSenior.aftercare_specialist = contactInfo.alumniSFS;
          updatedSenior.alumni_phone_1 = contactInfo.alumniPhone1;
          updatedSenior.alumni_phone_1_type = contactInfo.alumniPhone1Type;
          updatedSenior.alumni_address = contactInfo.alumniAddress;
          updatedSenior.alumni_address2 = contactInfo.alumniAddress2;
          updatedSenior.alumni_city = contactInfo.alumniCity;
          updatedSenior.alumni_state = contactInfo.alumniState;
          updatedSenior.alumni_zip = contactInfo.alumniZip;
          updatedSenior.alumni_country = contactInfo.alumniCountry;
          updatedSenior.alumni_updated_first_name = contactInfo.alumniUpdatedFirstName;
          updatedSenior.alumni_updated_last_name = contactInfo.alumniUpdatedLastName;
          updatedSenior.employer = contactInfo.employer;
          updatedSenior.school = contactInfo.school;
          updatedSenior.unemployed = contactInfo.unemployed;
          updatedSenior.updated_by = auth.user.result.id;
          updatedSenior.updated_at = getCurrentDateTime();
          // Contact Update logging //
          if (wasContactInfoUpdated(originalContactInfo, updatedSenior)) {
            updatedSenior.contact_updated_at = new Date();
            updatedSenior.contact_updated_by = auth.user.result.id;
            // This MUST be here so the JSON is correctly formatted when written back to the db.
            updatedSenior.planning_form_1 = planningForm1;
            updatedSenior.planning_form_2 = planningForm2;
            updatedSenior.checklist = checkList;
            const student = await callApi(auth, 'student', 'PUT', {
              item: updatedSenior,
            });
            appInsights.trackTrace({ message: `Contact Information updated by ${auth.user.result.email}` });
            const auditObj = {
              audit_ts: new Date(),
              user_id: auth.user.result.id,
              username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
              audit_type: 'contact info',
              action: 'updated',
              value: JSON.stringify(updatedSenior).substr(0, 999),
            };
            logAudit(auth, auditObj);
            setWarning('Updated contact information saved.');
            setShowWarning(true);
            if (student.result) {
              store.setSenior(student.result);
            }
            handleDialogClose();
          }
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error writing updated help request:', error);
        }
      }
    };

    const stripDate = (s) => {
      const tLoc = s.indexOf('T');
      return s.substr(tLoc + 1, s.length - tLoc);
    };

    const configureRescheduleDateTime = (rescheduleDate, rescheduleTime) => {
      if (!rescheduleDate || !rescheduleTime) return null;
      let dateString = dateOnlyFormat(rescheduleDate).toString() + ' ' + stripDate(rescheduleTime.toString());
      const date = new Date(dateString);
      return date;
    };

    const fetchContactDetails = async (alumniId) => {
      try {
        const res = await callApi(auth, 'contact/history/' + alumniId, 'GET');
        const list = res.result;
        setContactHistory(list);
        setHistoryRecord(list[0]);
        setDialogOpen(true);
      } catch (error) {
        console.log('Error fetching contact history:', error);
        logException(error);
      }
    };

    const findStaffById = (id) => {
      if (!id) return null;
      if (!store.aftercareSpecialistList || store.aftercareSpecialistList.length === 0) return null;
      for (const s of store.aftercareSpecialistList) {
        if (s.user.id === id) {
          return s;
        }
      }
    };

    const createSpecialistDict = () => {
      const dict = Object.assign({}, ...store.aftercareSpecialistList.map((x) => ({ [x.id]: x.user.first_name + ' ' + x.user.last_name })));
      return dict;
    };

    const sendProactiveContactThankYouEmail = async (student_id, first_name, sfs_name, sfs_phone) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'contactthankyouemail', 'POST', {
          student_id,
          first_name,
          sfs_name,
          sfs_phone,
        });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending Proactive Check-In Thank You email:', error);
      }
    };

    const canSubmit = () => {
      let rc = true;
      let requiredFields = [];

      if (contactMode === 'Phone' && showScript === '') {
        if (!attemptResult || attemptResult.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate whether the phone call was answered. ' }];
          rc = false;
        }
      }

      if (contactMode === 'Phone' && showScript && 'yes' && clientHaveTime === '') {
        if (!attemptResult || attemptResult.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate whether client has time to talk. ' }];
          rc = false;
        }
      }

      if (
        (contactMode === 'Phone' && showScript === 'yes' && clientHaveTime === 'yes') ||
        contactMode === 'Email' ||
        contactMode === 'Text' ||
        contactMode === 'FocusMeeting' ||
        contactMode === 'SocialMedia'
      ) {
        if (!attemptResult || attemptResult.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate the result of the contact attempt. ' }];
          rc = false;
        }
      }

      if (!contactMode || contactMode.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate the mode for the contact (phone call, email, etc.). ' }];
        rc = false;
      }
      if (!attemptDate || attemptDate.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate the date of the contact attempt. ' }];
        rc = false;
      }

      // Errors for all results
      if (form.otherAwards && form.otherAwards.length > 0) {
        form.otherAwards.forEach((a, i) => {
          if (!a.name || a.name.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please provide a name for award #' + (i + 1) + '. ' }];
            rc = false;
          }
          if (!a.type || a.type.length === 0) {
            requiredFields = [
              ...requiredFields,
              { requirement: 'Please indicate whether the award is a scholarship or incentive for #' + (i + 1) + '. ' },
            ];
            rc = false;
          }
        });
      }

      switch (attemptResult) {
        case 'Success':
          let totalSchoolOptionsSelected = 0;
          let totalWorkOptionsSelected = 0;

          if (form.noschoolchildcare) totalSchoolOptionsSelected += 1;
          if (form.noschooldisability) totalSchoolOptionsSelected += 1;
          if (form.noschoolfinancial) totalSchoolOptionsSelected += 1;
          if (form.noschoolhousing) totalSchoolOptionsSelected += 1;
          if (form.noschooleligibility) totalSchoolOptionsSelected += 1;
          if (form.noschoolhealth) totalSchoolOptionsSelected += 1;
          if (form.noschooltransportation) totalSchoolOptionsSelected += 1;
          if (form.noschoolother) totalSchoolOptionsSelected += 1;

          if (form.noworkchildcare) totalWorkOptionsSelected += 1;
          if (form.noworkdisability) totalWorkOptionsSelected += 1;
          if (form.noworkfinancial) totalWorkOptionsSelected += 1;
          if (form.noworkhousing) totalWorkOptionsSelected += 1;
          if (form.noworkeligibility) totalWorkOptionsSelected += 1;
          if (form.noworkhealth) totalWorkOptionsSelected += 1;
          if (form.noworktransportation) totalWorkOptionsSelected += 1;
          if (form.noworkother) totalWorkOptionsSelected += 1;

          if (!form.attendingSchool || form.attendingSchool.length === 0) {
            requiredFields = [
              ...requiredFields,
              { requirement: 'Please indicate whether they are currently attending school or vocational training. ' },
            ];
            rc = false;
          }

          if (form.attendingSchool === 'yes') {
            if (!form.schoolfullparttime || form.schoolfullparttime.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate whether they are attending school full or part time. ' }];
              rc = false;
            }
            if (!form.schoolname || form.schoolname.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter the school name. ' }];
              rc = false;
            }
            if (!form.major || form.major.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter their major or area of study/training. ' }];
              rc = false;
            }
            if (!form.enrolllength || form.enrolllength.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter their length of enrollment in school/vocational training. ' }];
              rc = false;
            }
          }

          if (!form.working || form.working.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether you are currently working. ' }];
            rc = false;
          }

          if (form.working === 'yes') {
            if (!form.workfullparttime || form.workfullparttime.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate whether they are working full or part time. ' }];
              rc = false;
            }
            if (!form.currentjob || form.currentjob.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter where they are working. ' }];
              rc = false;
            }
            if (!form.jobposition || form.jobposition.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter their current position at their job. ' }];
              rc = false;
            }
            if (!form.employlength || form.employlength.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter their length of employment. ' }];
              rc = false;
            }
          }

          if (form.attendingSchool === 'no' && form.working === 'no') {
            if (!form.startingsoon || form.startingsoon.length === 0) {
              requiredFields = [
                ...requiredFields,
                { requirement: 'Please indicate whether they will be starting work or school/vocational training in the next month. ' },
              ];
              rc = false;
            }
          }

          if (form.startingsoon === 'yes') {
            if (!form.startingsoonwhere || form.startingsoonwhere.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter where they will be starting school/work soon. ' }];
              rc = false;
            }
            if (!form.startingsoonstartdate || form.startingsoonstartdate.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter the start date for starting school/work soon. ' }];
              rc = false;
            }
            if (!form.startingsoonschedule || form.startingsoonschedule.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate full/part time for the school/work they are starting soon. ' }];
              rc = false;
            }
          }

          if (form.startingsoon === 'no') {
            if (totalSchoolOptionsSelected < 1) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate the barriers preventing you from attending school. ' }];
              rc = false;
            }

            if (form.noschoolother && (!form.noschoolotherdesc || (form.noschoolotherdesc && form.noschoolotherdesc.length === 0))) {
              requiredFields = [...requiredFields, { requirement: 'Please describe the "other" barrier preventing you from attending school. ' }];
              rc = false;
            }

            if (totalWorkOptionsSelected < 1) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate the barriers preventing you from working. ' }];
              rc = false;
            }

            if (form.noworkother && (!form.noworkotherdesc || (form.noworkotherdesc && form.noworkotherdesc.length === 0))) {
              requiredFields = [...requiredFields, { requirement: 'Please describe the "other" barrier preventing you from working. ' }];
              rc = false;
            }
          }

          if (!form.needsupport || form.needsupport.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether they need any support or services. ' }];
            rc = false;
          }

          if (form.needsupport === 'yes') {
            if (!form.resources || form.resources.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please enter the resources they need. ' }];
              rc = false;
            }
          }

          if (!form.health || form.health.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the state of their health. ' }];
            rc = false;
          }

          if (!form.housing || form.housing.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether they are experiencing any issues with housing. ' }];
            rc = false;
          }

          if (!form.transportation || form.transportation.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether they are experiencing any issues with transportation. ' }];
            rc = false;
          }

          if (!form.money || form.money === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether they are aware of their Cowboy Money situation. ' }];
            rc = false;
          }
          break;
        case 'Reschedule':
          if (!rescheduleDate || rescheduleDate.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the reschedule date. ' }];
            rc = false;
          }
          if (!rescheduleTime || rescheduleTime.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the reschedule time. ' }];
            rc = false;
          }
          break;
        case 'EmailSent':
          break;
        case 'TextSent':
          break;
        case 'SocialMedia':
          break;
        case 'VoiceMail':
          break;
        case 'Unavailable':
          break;
        case 'Disconnected':
          break;
        case 'NoAnswer':
          break;
        case 'OtherContacts':
          break;
        case 'Refused':
          break;
        case 'WrongNumber':
          break;
        default:
          break;
      }

      let errors = [];
      requiredFields.forEach((req, idx) => {
        errors.push(req.requirement);
      });
      if (errors.length > 0) {
        setErrorMsg(errors);
        setWarning('The Proactive Check-In form could not be submitted. See the errors listed below. ');
        setShowWarning(true);
      }
      return rc;
    };

    const handleWriteContact = (val) => {
      // Checking val
      if (val === 'Partial') {
        setAttemptResult(val);
      } else {
        if (attemptResult === '') {
          switch (contactMode) {
            case 'Phone':
              setAttemptResult('Success');
              break;
            case 'FocusMeeting':
              setAttemptResult('Success');
              break;
            case 'Email':
              setAttemptResult('EmailSent');
              break;
            case 'Text':
              setAttemptResult('TextSent');
              break;
            case 'SocialMedia':
              setAttemptResult('SocialMediaContact');
              break;
            default:
              break;
          }
        } else {
          writeContact();
        }
      }
    };

    async function writeContact() {
      const auditName = auth.user.result.first_name + ' ' + auth.user.result.last_name;
      const userLogin = auth.user.result.id;

      if (canSubmit()) {
        let rescheduleDateTime = null;
        if (attemptResult === 'Reschedule') {
          rescheduleDateTime = configureRescheduleDateTime(rescheduleDate, rescheduleTime);
        }

        try {
          store.setLoading(false);
          store.setSearching(true);
          const item = {
            id: store.checkIn.id,
            created_at: store.checkIn.created_at || new Date(),
            updated_at: new Date(),
            alumni_id: store.senior.id,
            specialist_id: store.senior.aftercare_specialist || store.checkIn.specialist_id,
            type: store.checkIn.type,
            status: attemptResult === 'Refused' || attemptResult === 'Success' || finalAttempt ? 'closed' : store.checkIn.status,
            due_date: attemptResult === 'Reschedule' ? rescheduleDateTime : store.checkIn.due_date,
            reschedule_date: attemptResult === 'Reschedule' ? rescheduleDateTime : store.checkIn.reschedule_date,
            rescheduled_by: attemptResult === 'Reschedule' ? auditName : store.checkIn.rescheduled_by,
            rescheduled_at: attemptResult === 'Reschedule' ? new Date() : store.checkIn.reschedule_at,
            last_attempt_date:
              new Date(dateCompare(attemptDate)) > new Date(dateCompare(store.checkIn.last_attempt_date))
                ? attemptDate
                : store.checkIn.last_attempt_date,
            last_attempt_mode: contactMode,
            last_attempt_result: attemptResult,
            first_name: store.senior.first_name,
            last_name: store.senior.last_name,
            int_id: store.senior.int_id,
            user_id: store.senior.user_id,
          };

          const specialist = findStaffById(auth.user.result.id);
          const specId = specialist.id;
          const staffId = specialist.internal_id;
          const pidm = parseInt(specialist.pidm, 10);

          const result = {
            contact_id: store.checkIn.id,
            contact_attempt: attemptNumber + 1,
            specialist_id: specId,
            contact_mode: contactMode,
            contact_date: attemptDate,
            contact_details: form,
            notes: notes,
            result: attemptResult,
            reschedule_date: rescheduleDate ? rescheduleDateTime : null,
            final_attempt: attemptResult === 'Refused' ? true : finalAttempt,
          };

          await callApi(auth, 'contact', 'PUT', { item, result });
          appInsights.trackTrace({ message: `Proactive Check-In updated by ${auth.user.result.email}` });
          const auditObj = {
            audit_ts: new Date(),
            user_id: userLogin,
            username: auditName,
            audit_type: 'Proactive Check-In',
            action: attemptResult,
            value: JSON.stringify(form).substring(0, 999),
          };
          logAudit(auth, auditObj);

          // SENDING THANK YOU EMAIL
          if (attemptResult === 'Success' && store.senior) {
            const specialist = await callApi(auth, 'staff/user/' + auth.user.result.id, 'GET');
            const sfs = specialist.result;

            sendProactiveContactThankYouEmail(
              store.senior.id,
              store.senior.alumni_updated_first_name && store.senior.alumni_updated_first_name.length > 0
                ? store.senior.alumni_updated_first_name
                : store.senior.first_name,
              sfs.user.first_name + ' ' + sfs.user.last_name,
              formatPhoneNumber(sfs.office_phone)
            );
          }

          // LOGGING NDB INCIDENT
          let note = notes.substring(0, 999);
          if (note.length > 0) {
            note = attemptResult + ' / ' + note;
          } else {
            note = attemptResult;
          }
          const incident = {
            int_id: store.senior.int_id,
            user_id: staffId,
            begin_date: dateOnlyFormat(new Date()),
            end_date: dateOnlyFormat(new Date()),
            pidm: pidm,
            specialist_id: specId,
            event_id: 136,
            note: note,
            focus_group: mode === 2 ? true : false,
            contact_method: mode,
          };

          await callApi(auth, 'checkin/ndbincident', 'POST', incident);

          store.setSearching(false);
          store.setLoading(false);
          handleSaveReturn();
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error writing updated Proactive Check-in:', error);
        }
      }
    }

    const handleContactModeSelect = (mode) => {
      setContactMode(mode);
      setErrorMsg('');
      switch (mode) {
        case 'Phone':
          setMode(4);
          setShowScript('');
          setClientHaveTime('');
          break;
        case 'Email':
          setMode(1);
          setShowScript('');
          setClientHaveTime('');
          setCanBeSuccessful(true);
          break;
        case 'Text':
          setMode(5);
          setShowScript('');
          setClientHaveTime('');
          setCanBeSuccessful(true);
          break;
        case 'FocusMeeting':
          setMode(2);
          setShowScript('');
          setClientHaveTime('');
          setCanBeSuccessful(true);
          break;
        case 'SocialMedia':
          setMode(6);
          setShowScript('');
          setClientHaveTime('');
          setCanBeSuccessful(true);
          break;
        default:
          setShowScript('');
          setClientHaveTime('');
          setCanBeSuccessful(true);
          break;
      }
    };

    const convertResult = (result) => {
      switch (result) {
        case 'WrongNumber':
          return 'Wrong Number';
        case 'NoAnswer':
          return 'No Answer';
        case 'EmailSent':
          return 'Email Sent';
        case 'TextSent':
          return 'Email Sent';
        case 'VoiceMail':
          return 'Voice Mail';
        case 'OtherContacts':
          return 'Other Contacts';
        case 'SocialMediaContact':
          return 'Social Media';
        default:
          return result;
      }
    };

    const lookupAssignee = (assigned_to) => {
      if (!assigned_to || assigned_to === 0) return '';
      const idx = store.aftercareSpecialistList.findIndex((s) => s.id === assigned_to);
      if (idx > -1) {
        return store.aftercareSpecialistList[idx].user.first_name + ' ' + store.aftercareSpecialistList[idx].user.last_name;
      }
    };

    const isAssigneeActive = (assigned_to) => {
      if (!assigned_to || assigned_to === 0) return '';
      const idx = store.aftercareSpecialistList.findIndex((s) => s.id === assigned_to);
      if (idx > -1) {
        return store.aftercareSpecialistList[idx].is_active;
      }
    };

    // Gets History Record and opens details dialog. (Passed to ContactHistory and then to ContactCheckinDetails)
    const getRecordDetails = (id, attempt) => {
      if (!id || id === 0) return null;
      const idx = history.findIndex((s) => s.id === id && s.contact_attempt === attempt);
      if (idx > -1) {
        setHistoryRecord(history[idx]);
        setHistoryDialogOpen(true);
      }
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const handleHistoryDialogClose = () => {
      setHistoryDialogOpen(false);
    };

    const isFormDisabled = () => {
      return false;
    };

    const handleSaveAttendingSchool = (val) => {
      if (val === 'no') {
        setForm({ ...form, attendingSchool: val, schoolfullparttime: '', schoolname: '', major: '', enrolllength: '' });
      } else {
        setForm({
          ...form,
          attendingSchool: val,
          noschoolchildcare: false,
          noschooldisability: false,
          noschoolfinancial: false,
          noschoolhousing: false,
          noschooleligibility: false,
          noschoolhealth: false,
          noschooltransportation: false,
          noschoolother: false,
          noschoolotherdesc: '',
          noworkchildcare: false,
          noworkdisability: false,
          noworkfinancial: false,
          noworkhousing: false,
          noworkeligibility: false,
          noworkhealth: false,
          noworktransportation: false,
          noworkother: false,
          noworkotherdesc: '',
        });
      }
    };

    const handleSaveWorking = (val) => {
      if (val === 'no') {
        setForm({ ...form, working: val, workfullparttime: '', currentjob: '', jobposition: '', employlength: '' });
      } else {
        setForm({
          ...form,
          working: val,
          noschoolchildcare: false,
          noschooldisability: false,
          noschoolfinancial: false,
          noschoolhousing: false,
          noschooleligibility: false,
          noschoolhealth: false,
          noschooltransportation: false,
          noschoolother: false,
          noschoolotherdesc: '',
          noworkchildcare: false,
          noworkdisability: false,
          noworkfinancial: false,
          noworkhousing: false,
          noworkeligibility: false,
          noworkhealth: false,
          noworktransportation: false,
          noworkother: false,
          noworkotherdesc: '',
        });
      }
    };

    const handleSaveStartingSoon = (val) => {
      if (val === 'no') {
        setForm({ ...form, startingsoon: val, startingsoonwhere: '', startingsoonstartdate: null, startingsoonschedule: '' });
      } else {
        setForm({
          ...form,
          startingsoon: val,
          noschoolchildcare: false,
          noschooldisability: false,
          noschoolfinancial: false,
          noschoolhousing: false,
          noschooleligibility: false,
          noschoolhealth: false,
          noschooltransportation: false,
          noschoolother: false,
          noschoolotherdesc: '',
          noworkchildcare: false,
          noworkdisability: false,
          noworkfinancial: false,
          noworkhousing: false,
          noworkeligibility: false,
          noworkhealth: false,
          noworktransportation: false,
          noworkother: false,
          noworkotherdesc: '',
        });
      }
    };

    const handleSaveNeedSupport = (val) => {
      if (val === 'no') {
        setForm({ ...form, needsupport: val, resources: '' });
      } else {
        setForm({
          ...form,
          needsupport: val,
        });
      }
    };

    const handleShowScript = (val) => {
      setShowScript(val);
      setAttemptResult('');
      setClientHaveTime(null);
      if (val === 'no') {
        setCanBeSuccessful(false);
      } else {
        setCanBeSuccessful(true);
      }
    };

    const handleClientHaveTime = (val) => {
      setClientHaveTime(val);
      setAttemptResult('');
      if (val === 'no') {
        setCanBeSuccessful(false);
      } else {
        setCanBeSuccessful(true);
      }
    };

    const handleResultAttemptResult = (val) => {
      if (contactMode.length > 0 && contactMode === 'Phone' && (showScript === 'no' || clientHaveTime === 'no')) {
        if (val.length > 0) {
          setAttemptResult(val);
          setCanBeSuccessful(true);
        }
      }
    };

    const validatePhoneNumber = (phone) => {
      const phoneRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
      const phoneValidated = phone.match(phoneRegex);
      if (phoneValidated && phoneValidated.length > 0) return true;
      return false;
    };

    const showExpandPostGradPlan = (senior) => {
      let showExpand = false;
      if (senior) {
        if (senior.checklist && senior.checklist.length > 0) {
          if (JSON.parse(senior.checklist).postGraduationPlan && JSON.parse(senior.checklist).postGraduationPlan.length > 0) {
            showExpand = true;
          }
        }
        if (senior.planning_form_2 && senior.planning_form_2.length > 0) {
          if (JSON.parse(senior.planning_form_2).summerEmployWork && JSON.parse(senior.planning_form_2).summerEmployWork.length > 0) {
            showExpand = true;
          }
          if (JSON.parse(senior.planning_form_2).fallPlan && JSON.parse(senior.planning_form_2).fallPlan.length > 0) {
            showExpand = true;
          }
        }
      }
      return showExpand;
    };

    const doesSeniorHavePostGradPlan = (senior) => {
      let hasPlan = false;
      if (senior) {
        if (senior.checklist && senior.checklist.length > 0) {
          if (JSON.parse(senior.checklist).postGraduationPlan && JSON.parse(senior.checklist).postGraduationPlan.length > 0) {
            hasPlan = true;
          }
        }
      }
      return hasPlan;
    };

    const doesSeniorHaveSummerPlan = (senior) => {
      let showSummerPlan = false;
      if (senior) {
        if (senior.planning_form_2 && senior.planning_form_2.length > 0) {
          if (JSON.parse(senior.planning_form_2).summerEmployWork && JSON.parse(senior.planning_form_2).summerEmployWork.length > 0) {
            showSummerPlan = true;
          }
        }
      }
      return showSummerPlan;
    };

    const doesSeniorHaveFallPlan = (senior) => {
      let showFallPlan = false;
      if (senior) {
        if (senior.planning_form_2 && senior.planning_form_2.length > 0) {
          if (JSON.parse(senior.planning_form_2).fallPlan && JSON.parse(senior.planning_form_2).fallPlan.length > 0) {
            showFallPlan = true;
          }
        }
      }
      return showFallPlan;
    };

    const convertType = (type) => {
      if (type === '3m') {
        return '3 months';
      }
      if (type === '12m') {
        return '12 months';
      }
      if (type === '9m') {
        return '9 months';
      }
      if (type === '6m') {
        return '6 months';
      }
      return type;
    };

    const formatPhoneNumber = (str) => {
      //Filter only numbers from the input
      let cleaned = ('' + str).replace(/\D/g, '');
      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    };

    return (
      <React.Fragment>
        {store.checkIn && (
          <summary>
            <div style={{ padding: 5, border: '1px solid #d3d3d3' }}>
              {showContactInfo ? (
                <div>
                  {store.senior && (
                    <ContactDetails
                      contactInfo={contactInfo}
                      classes={classes}
                      senior={store.senior}
                      specialist={store && store.senior && lookupAssignee(store.senior.aftercare_specialist)}
                      formatPhoneNumber={formatPhoneNumber}
                      setShowContactInfo={setShowContactInfo}
                      setDialogOpen={setDialogOpen}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Grid container align="left" direction="row" style={{ minWidth: '100%' }}>
                    <Grid item xs={12} sm={5} md={2} lg={2} className={classes.rowSpacer}>
                      Client Information <EditIcon className={classes.detailsLink} onClick={(e) => setDialogOpen(true)} fontSize="small" />
                    </Grid>

                    <Grid item xs={12} sm={7} md={3} lg={3} className={classes.rowSpacer}>
                      <strong>
                        <span>
                          {contactInfo.alumniUpdatedFirstName ? contactInfo.alumniUpdatedFirstName : store.senior && store.senior.first_name}
                        </span>{' '}
                        <span>{contactInfo.alumniUpdatedLastName ? contactInfo.alumniUpdatedLastName : store.senior && store.senior.last_name}</span>{' '}
                        {store.senior && store.senior.alumni_phone_1 && formatPhoneNumber(store.senior.alumni_phone_1)}
                      </strong>
                    </Grid>

                    <Grid item xs={5} sm={5} md={3} lg={2} className={classes.rowSpacer}>
                      <label>Graduated</label>{' '}
                      <strong>{store.senior && store.senior.graduation_date && dateToS(store.senior.graduation_date)}</strong>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} lg={4} className={classes.rowSpacer}>
                      <label>Specialist</label> <strong>{store && store.senior && lookupAssignee(store.senior.aftercare_specialist)}</strong>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} align="right" className={classes.rowSpacer}>
                      <div style={{ marginRight: 15 }} onClick={(event) => setShowContactInfo(true)}>
                        <ExpandMoreIcon fontSize="small" />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>

            {showMoney ? (
              <div>
                {store.senior && (
                  <ContactAwards
                    contactInfo={contactInfo}
                    classes={classes}
                    showMoney={showMoney}
                    setShowMoney={setShowMoney}
                    form={form}
                    setForm={setForm}
                  />
                )}
              </div>
            ) : (
              <div>
                <Grid container align="left" style={{ padding: 5, border: '1px solid #d3d3d3' }}>
                  <Grid item xs={4} sm={2} md={1} lg={1}>
                    <div style={{ marginTop: 10 }}>Awards</div>
                  </Grid>
                  <Grid item xs={6} sm={9} md={10} lg={10}>
                    <div className={classes.reminder}>
                      Before contact attempt, enter all award information, so the script can include these values.
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={1} md={1} lg={1} align="right" style={{ marginLeft: -5 }}>
                    <div style={{ marginRight: 10, marginTop: 10 }} onClick={(event) => setShowMoney(!showMoney)}>
                      {showMoney ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            <div style={{ padding: 5, border: '1px solid #d3d3d3' }}>
              {showPlans ? (
                <div>{store.checkIn && <ContactLastKnown classes={classes} senior={store.senior} setShowPlans={setShowPlans} />}</div>
              ) : (
                <div>
                  <Grid container align="left" direction="row">
                    <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
                      Post-Grad Plan
                    </Grid>

                    <Grid item xs={3} sm={3} md={2} lg={2} className={classes.rowSpacer}>
                      <label>
                        {doesSeniorHavePostGradPlan(store.senior)
                          ? 'Post-Grad Plan '
                          : doesSeniorHaveSummerPlan(store.senior)
                          ? 'Summer Employment Plan '
                          : doesSeniorHaveFallPlan(store.senior)
                          ? 'Fall Plan '
                          : 'Post-Grad Plan '}
                      </label>
                    </Grid>
                    <Grid item xs={7} sm={7} md={6} lg={7} align="left" className={classes.rowSpacer}>
                      <strong>
                        {doesSeniorHavePostGradPlan(store.senior)
                          ? store &&
                            store.senior &&
                            store.senior.checklist &&
                            store.senior.checklist.length > 0 &&
                            JSON.parse(store.senior.checklist).postGraduationPlan
                          : doesSeniorHaveSummerPlan(store.senior)
                          ? store &&
                            store.senior &&
                            store.senior.planning_form_2 &&
                            store.senior.planning_form_2.length > 0 &&
                            JSON.parse(store.senior.planning_form_2).summerEmployWork
                          : doesSeniorHaveFallPlan(store.senior)
                          ? store &&
                            store.senior &&
                            store.senior.planning_form_2 &&
                            store.senior.planning_form_2.length > 0 &&
                            JSON.parse(store.senior.planning_form_2).fallPlan
                          : 'Not Entered'}
                      </strong>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} align="right" className={classes.rowSpacer}>
                      {showExpandPostGradPlan(store.senior) && (
                        <div style={{ marginRight: 15 }} onClick={(event) => setShowPlans(true)}>
                          <ExpandMoreIcon fontSize="small" />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
            <div style={{ padding: 5, border: '1px solid #d3d3d3' }}>
              {showHistory ? (
                <div>
                  {store.checkIn && store.senior && (
                    <ContactHistory
                      classes={classes}
                      tableClasses={tableClasses}
                      senior={store.senior}
                      history={history}
                      specialistDict={createSpecialistDict()}
                      getRecordDetails={getRecordDetails}
                      convertResult={convertResult}
                      setShowHistory={setShowHistory}
                      handleHistoryDialogClose={handleHistoryDialogClose}
                      historyRecord={historyRecord}
                      historyDialogOpen={historyDialogOpen}
                      setHistoryDialogOpen={setHistoryDialogOpen}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Grid container align="left" direction="row">
                    <Grid item xs={12} sm={12} md={2} lg={2} className={classes.rowSpacer}>
                      Proactive History
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5} className={classes.rowSpacer}>
                      {latestResult ? (
                        <>
                          <label>Last Attempt</label> <strong>{dateTimeToS(latestResult && latestResult.contact_date)}</strong>
                        </>
                      ) : (
                        <strong>N/A</strong>
                      )}
                    </Grid>
                    <Grid item xs={11} sm={5} md={4} lg={4} className={classes.rowSpacer}>
                      {latestResult && (
                        <>
                          <label>Result</label> <strong>{convertResult(latestResult && latestResult.result)}</strong>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} align="right" className={classes.rowSpacer}>
                      {latestResult && (
                        <div style={{ marginRight: 15 }} onClick={(event) => setShowHistory(true)}>
                          <ExpandMoreIcon fontSize="small" />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>

            {/* ------------------------------------------------------------------------------------------------------------ */}
            <hr style={{ borderTop: '2px solid ' + btLightBlueColor, margin: '20px 0' }} />
            <Grid container align="left">
              {store && store.senior && (!store.senior.alumni_phone_1 || store.senior.alumni_phone_1.length === 0) && (
                <Grid item xs={12} className={classes.rowSpacer}>
                  <div className={classes.noPhone}>The client has not provided a phone number.</div>
                </Grid>
              )}
              <Grid item xs={5} sm={3} md={2} lg={2} className={classes.rowSpacer}>
                <label id="contact-mode-label" className={classes.adminLabel}>
                  Contact Mode
                </label>
              </Grid>
              <Grid item xs={5} sm={5} md={3} lg={2}>
                <FormControl fullWidth>
                  <Select
                    id="contact-mode"
                    value={contactMode}
                    onChange={(event) => handleContactModeSelect(event.target.value)}
                    disabled={isFormDisabled()}
                  >
                    <MenuItem value=""></MenuItem>
                    {store && store.senior && store.senior.alumni_phone_1 && store.senior.alumni_phone_1.length > 0 && (
                      <MenuItem value="Phone">Phone Call</MenuItem>
                    )}
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="Text">Text Message</MenuItem>
                    <MenuItem value="FocusMeeting">Focus Meeting</MenuItem>
                    <MenuItem value="SocialMedia">Social Media</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={4} md={1} lg={1}></Grid>
              <Grid item xs={5} sm={3} md={2} lg={2} className={classes.rowSpacer}>
                <label id="attempt-date-label" className={classes.adminLabel}>
                  Attempt Date
                </label>
              </Grid>
              <Grid item xs={6} sm={5} md={4} lg={3}>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="attempt-date"
                    hiddenLabel
                    disableFuture
                    value={attemptDate}
                    onChange={(date) => {
                      setAttemptDate(date ? date : null);
                    }}
                    format="MM/dd/yyyy"
                    maxDateMessage="Date cannot be later than today."
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <br />
              <br />
            </Grid>
            {/* ------------------------------------------------------------------------------------------------------------ */}
          </summary>
        )}
        {/* ------------------------------------------------------------------------------------------------------------ */}
        {/* **** Phone Call Only **** */}
        {store.senior && contactMode === 'Phone' && (
          <Grid container align="left" style={{ margin: '-5px 0 15px 0' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <summary className={classes.error}>
                <strong>*It is recommended to dial from a Boys Town phone number to avoid being refused as a spam call.</strong>
              </summary>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <summary className={classes.reminder} style={{ paddingTop: 10 }}>
                Call {formatPhoneNumber(store.senior.alumni_phone_1)}
              </summary>
            </Grid>
          </Grid>
        )}
        {/* ------------------------------------------------------------------------------------------------------------ */}
        {/* **** Phone Call Only **** */}
        {store.senior && contactMode === 'Phone' && (
          <>
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={10} sm={7} md={8} lg={7} className={classes.question}>
                <label className={classes.scriptLabel}>Was the phone call answered?</label>
              </Grid>
              <Grid item xs={7} sm={9} md={4} lg={5}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="Was phone call answered?"
                    name="school"
                    value={showScript}
                    onChange={(event) => handleShowScript(event.target.value)}
                    disabled={isFormDisabled()}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        <hr style={{ borderTop: '2px solid ' + btLightBlueColor, margin: '20px 0' }} />
        {/* ------------------------------------------------------------------------------------------------------------ */}
        {store.senior && contactMode === 'Phone' && showScript === 'no' && (
          <Grid container align="left">
            <Grid item xs={12} align="left">
              <Grid container align="left">
                <Grid item xs={9} sm={7} md={12} lg={12}>
                  <div className={classes.reminder} style={{ marginBottom: -5 }}>
                    Please indicate the result of your check-in attempt:
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* ------------------------------------------------------------------------------------------------------------ */}
        {/* **** Phone Call and Phone Answered **** */}
        {store.senior && contactMode === 'Phone' && showScript === 'yes' && (
          <Grid container align="left">
            <Grid item xs={12} align="left">
              <Grid container align="left">
                <Grid item xs={12} sm={12} md={8} lg={7}>
                  <div className={classes.scriptLabel}>
                    Good morning/afternoon. Is this{' '}
                    <strong>
                      {contactInfo.alumniUpdatedFirstName ? contactInfo.alumniUpdatedFirstName : store.senior && store.senior.first_name}{' '}
                      {contactInfo.alumniUpdatedLastName ? contactInfo.alumniUpdatedLastName : store.senior && store.senior.last_name}
                    </strong>
                    ?
                  </div>
                  <br />
                  <div className={classes.scriptLabel}>
                    Hi, this is{' '}
                    <strong>
                      {auth.user.result.first_name} {auth.user.result.last_name}{' '}
                    </strong>
                    from Successful Futures at Boys Town. I am reaching out to you today to check in and see how things are going. Do you have a few
                    minutes?{' '}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={5}>
                  <div style={{ marginTop: 80 }}>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="Does client have a few minutes?"
                        name="school"
                        value={clientHaveTime}
                        onChange={(event) => handleClientHaveTime(event.target.value)}
                        disabled={isFormDisabled()}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="default" disabled={isFormDisabled()} />}
                          label={<span className={classes.radioLabel}>Yes</span>}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="default" disabled={isFormDisabled()} />}
                          label={<span className={classes.radioLabel}>No</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
                <br />
                <div className={classes.reminder}>{clientHaveTime === 'no' && 'Reschedule for a date and time that will work for them. ***'}</div>

                <Grid container align="left" className={classes.rowSpacer}>
                  {store.checkIn && clientHaveTime === 'no' && (
                    <>
                      <hr style={{ borderTop: '2px solid ' + btLightBlueColor, margin: '20px 0' }} />
                      <ContactReschedule
                        contactInfo={contactInfo}
                        setContactInfo={setContactInfo}
                        classes={classes}
                        senior={store.senior}
                        rescheduleDate={rescheduleDate}
                        setRescheduleDate={setRescheduleDate}
                        rescheduleTime={rescheduleTime}
                        setRescheduleTime={setRescheduleTime}
                        attemptResult={attemptResult}
                        setAttemptResult={setAttemptResult}
                        attemptCount={attemptNumber}
                        finalAttempt={finalAttempt}
                        setFinalAttempt={setFinalAttempt}
                      />
                    </>
                  )}

                  {isAssigneeActive(store.checkIn.specialist_id) === false && clientHaveTime === 'yes' && (
                    <div className={classes.scriptLabel}>
                      Previously, your SFS was <strong>{lookupAssignee(store.checkIn.specialist_id)} </strong>. He/She is no longer working with us.
                      Again, my name is{' '}
                      <strong>
                        {auth.user.result.first_name} {auth.user.result.last_name}
                      </strong>
                      .
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* ------------------------------------------------------------------------------------------------------------ */}
        {/* **** Phone Call and Phone Answered and Have Time *OR* Focus Group **** */}
        {((contactMode === 'Phone' && showScript === 'yes' && clientHaveTime === 'yes') || contactMode === 'FocusMeeting') && (
          <React.Fragment>
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label className={classes.scriptLabel}>Are you currently attending school or vocational training?</label>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={5}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="Are you currently attending school or vocational training?"
                    name="school"
                    value={form.attendingSchool}
                    onChange={(event) => handleSaveAttendingSchool(event.target.value)}
                    disabled={isFormDisabled()}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {form.attendingSchool && form.attendingSchool === 'yes' && (
              <>
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={7} lg={6} className={classes.question}>
                    <label className={classes.scriptLabel}>Are you going to school full-time or part-time?</label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={5}>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="Are you going to school full-time or part-time?"
                        name="schoolfullparttime"
                        value={form.schoolfullparttime}
                        onChange={(event) => setForm({ ...form, schoolfullparttime: event.target.value })}
                        disabled={isFormDisabled()}
                      >
                        <FormControlLabel
                          value="full"
                          control={<Radio color="default" disabled={isFormDisabled()} />}
                          label={<span className={classes.radioLabel}>Full-Time</span>}
                        />
                        <FormControlLabel
                          value="part"
                          control={<Radio color="default" disabled={isFormDisabled()} />}
                          label={<span className={classes.radioLabel}>Part-Time</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>Where are you attending school or vocational training?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={10} lg={7}>
                    <FormControl fullWidth>
                      <TextField
                        id="schoolname"
                        size="small"
                        placeholder="school/vocational training location"
                        variant="outlined"
                        value={form.schoolname}
                        onChange={(event) => setForm({ ...form, schoolname: event.target.value })}
                        InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>What are you studying?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={9} lg={7}>
                    <FormControl fullWidth>
                      <TextField
                        id="major"
                        size="small"
                        placeholder="major/focus"
                        variant="outlined"
                        value={form.major}
                        onChange={(event) => setForm({ ...form, major: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={10} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>How long have you been enrolled?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={9} lg={7}>
                    <FormControl fullWidth>
                      <TextField
                        id="enrolllength"
                        size="small"
                        placeholder="length of enrollment"
                        variant="outlined"
                        value={form.enrolllength}
                        onChange={(event) => setForm({ ...form, enrolllength: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label className={classes.scriptLabel}>Are you currently working?</label>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={5}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="Are you currently working?"
                    name="working"
                    value={form.working}
                    onChange={(event) => handleSaveWorking(event.target.value)}
                    disabled={isFormDisabled()}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            {form.working && form.working === 'yes' && (
              <>
                <Grid container align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={5} className={classes.question}>
                    <label className={classes.scriptLabel}>How many hours are you currently working?</label>{' '}
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={false} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-label="How many hours are you currently working?"
                        name="workfullparttime"
                        value={form.workfullparttime}
                        onChange={(event) => setForm({ ...form, workfullparttime: event.target.value })}
                        disabled={isFormDisabled()}
                      >
                        <FormControlLabel
                          value="full"
                          control={<Radio color="default" disabled={isFormDisabled()} />}
                          label={<span className={classes.radioLabel}>Full-Time</span>}
                        />
                        <FormControlLabel
                          value="part"
                          control={<Radio color="default" disabled={isFormDisabled()} />}
                          label={<span className={classes.radioLabel}>Part-Time</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>

                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>Where are you working?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={9} lg={7}>
                    <FormControl fullWidth>
                      <TextField
                        id="currentjob"
                        size="small"
                        placeholder="current job"
                        variant="outlined"
                        value={form.currentjob}
                        onChange={(event) => setForm({ ...form, currentjob: event.target.value })}
                        InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>What is your position?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={9} lg={7}>
                    <FormControl fullWidth>
                      <TextField
                        id="jobposition"
                        size="small"
                        placeholder="current position"
                        variant="outlined"
                        value={form.jobposition}
                        onChange={(event) => setForm({ ...form, jobposition: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>How long have you been employed?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={9} lg={7}>
                    <FormControl fullWidth>
                      <TextField
                        id="employlength"
                        size="small"
                        placeholder="length of employment"
                        variant="outlined"
                        value={form.employlength}
                        onChange={(event) => setForm({ ...form, employlength: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            {form.attendingSchool && form.attendingSchool === 'no' && form.working && form.working === 'no' && (
              <Grid container align="left" className={classes.rowSpacer}>
                <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                  <label className={classes.scriptLabel}>Are you scheduled to start working or studying in the next month?</label>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={5}>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-label="Scheduled to start?"
                      name="startingSoon"
                      value={form.startingsoon}
                      onChange={(event) => handleSaveStartingSoon(event.target.value)}
                      disabled={isFormDisabled()}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="default" disabled={isFormDisabled()} />}
                        label={<span className={classes.radioLabel}>Yes</span>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="default" disabled={isFormDisabled()} />}
                        label={<span className={classes.radioLabel}>No</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {form.startingsoon && form.startingsoon === 'yes' && (
              <>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>Where?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={7} lg={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="startingsoonwhere"
                        size="small"
                        placeholder="Where will they be starting soon?"
                        variant="outlined"
                        value={form.startingsoonwhere}
                        onChange={(event) => setForm({ ...form, startingsoonwhere: event.target.value })}
                        InputProps={{ maxLength: 150, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.question}>
                    <label id="startingsoonstartdate-label" className={classes.scriptLabel}>
                      When do you start?
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={2}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        id="startingsoonstartdate"
                        hiddenLabel
                        disablePast
                        value={form.startingsoonstartdate || null}
                        onChange={(date) => {
                          setForm({ ...form, startingsoonstartdate: date ? date : null });
                        }}
                        format="MM/dd/yyyy"
                        maxDateMessage="Date cannot be earlier than today."
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container direction="row" align="left" className={classes.rowSpacer}>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                    <label className={classes.scriptLabel}>What is your schedule?</label>
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} lg={1} className={classes.indentPadding}></Grid>
                  <Grid item xs={12} sm={12} md={7} lg={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="startingsoonschedule"
                        size="small"
                        placeholder="What is the schedule for the position they are starting soon?"
                        variant="outlined"
                        value={form.startingsoonschedule}
                        onChange={(event) => setForm({ ...form, startingsoonschedule: event.target.value })}
                        InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            {form.attendingSchool &&
              form.attendingSchool === 'no' &&
              form.working &&
              form.working === 'no' &&
              form.startingsoon &&
              form.startingsoon === 'no' && (
                <>
                  <Grid container align="left" className={classes.rowSpacer}>
                    <Grid item xs={12} sm={12} md={11} lg={11} align="left" className={classes.question}>
                      <label className={classes.scriptLabel}>
                        What barriers are preventing you from attending school. (Please check all that apply.)
                      </label>
                      <br />
                    </Grid>
                    <Grid item xs={false} sm={1} align="left"></Grid>
                    <Grid item xs={12} sm={11} md={4} lg={3} align="left">
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Childcare"
                          checked={form.noschoolchildcare}
                          onChange={(event) => setForm({ ...form, noschoolchildcare: !form.noschoolchildcare })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Disability"
                          checked={form.noschooldisability}
                          onChange={(event) => setForm({ ...form, noschooldisability: !form.noschooldisability })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Financial"
                          checked={form.noschoolfinancial}
                          onChange={(event) => setForm({ ...form, noschoolfinancial: !form.noschoolfinancial })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Housing"
                          checked={form.noschoolhousing}
                          onChange={(event) => setForm({ ...form, noschoolhousing: !form.noschoolhousing })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={false} sm={1} align="left"></Grid>
                    <Grid item xs={12} sm={11} md={4} lg={3} align="left">
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Legal Status/Eligibility"
                          checked={form.noschooleligibility}
                          onChange={(event) => setForm({ ...form, noschooleligibility: !form.noschooleligibility })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Physical/Mental Health"
                          checked={form.noschoolhealth}
                          onChange={(event) => setForm({ ...form, noschoolhealth: !form.noschoolhealth })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Transportation"
                          checked={form.noschooltransportation}
                          onChange={(event) => setForm({ ...form, noschooltransportation: !form.noschooltransportation })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Other"
                          checked={form.noschoolother}
                          onChange={(event) => setForm({ ...form, noschoolother: !form.noschoolother })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      {form.noschoolother && (
                        <FormControl fullWidth>
                          <TextField
                            id="school other desc"
                            size="small"
                            placeholder="Explain other"
                            variant="outlined"
                            value={form.noschoolotherdesc}
                            onChange={(event) => setForm({ ...form, noschoolotherdesc: event.target.value })}
                            InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container align="left" className={classes.rowSpacer}>
                    <Grid item xs={12} sm={12} md={11} lg={11} align="left" className={classes.question}>
                      <label className={classes.scriptLabel}>What barriers are preventing you from working. (Please check all that apply.)</label>
                      <br />
                    </Grid>

                    <Grid item xs={false} sm={1} align="left"></Grid>
                    <Grid item xs={12} sm={11} md={4} lg={3} align="left">
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Childcare"
                          checked={form.noworkchildcare}
                          onChange={(event) => setForm({ ...form, noworkchildcare: !form.noworkchildcare })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Disability"
                          checked={form.noworkdisability}
                          onChange={(event) => setForm({ ...form, noworkdisability: !form.noworkdisability })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Financial"
                          checked={form.noworkfinancial}
                          onChange={(event) => setForm({ ...form, noworkfinancial: !form.noworkfinancial })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Housing"
                          checked={form.noworkhousing}
                          onChange={(event) => setForm({ ...form, noworkhousing: !form.noworkhousing })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={false} sm={1} align="left"></Grid>
                    <Grid item xs={12} sm={11} md={4} lg={3} align="left">
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Legal Status/Eligibility"
                          checked={form.noworkeligibility}
                          onChange={(event) => setForm({ ...form, noworkeligibility: !form.noworkeligibility })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Physical/Mental Health"
                          checked={form.noworkhealth}
                          onChange={(event) => setForm({ ...form, noworkhealth: !form.noworkhealth })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Transportation"
                          checked={form.noworktransportation}
                          onChange={(event) => setForm({ ...form, noworktransportation: !form.noworktransportation })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={<Checkbox color="default" />}
                          label="Other"
                          checked={form.noworkother}
                          onChange={(event) => setForm({ ...form, noworkother: !form.noworkother })}
                          disabled={isFormDisabled()}
                        />
                      </FormControl>
                      {form.noworkother && (
                        <FormControl fullWidth>
                          <TextField
                            id="work other desc"
                            size="small"
                            placeholder="Explain other"
                            variant="outlined"
                            value={form.noworkotherdesc}
                            onChange={(event) => setForm({ ...form, noworkotherdesc: event.target.value })}
                            InputProps={{ maxLength: 250, classes: { input: classes.textField } }}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            <br />
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label className={classes.scriptLabel}>
                  Thanks for answering my questions. Do you need any support or services to continue on your path to success either at work or school
                  or finding an alternative?
                </label>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={5}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="Do you need any support or services?"
                    name="needsupport"
                    value={form.needsupport}
                    onChange={(event) => handleSaveNeedSupport(event.target.value)}
                    disabled={isFormDisabled()}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {form.needsupport && form.needsupport === 'yes' && (
              <Grid container align="left" direction="row" className={classes.rowSpacer}>
                <Grid item xs={12} sm={12} md={11} lg={11} className={classes.question}>
                  <label id="resources-label" className={classes.scriptLabel}>
                    What resources do you need?
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="resources"
                      minRows={5}
                      maxRows={5}
                      maxLength={1000}
                      value={form.resources}
                      placeholder="Explain the resources needed."
                      onChange={(event) => setForm({ ...form, resources: event.target.value })}
                      className={classes.textAreaFont}
                      disabled={isFormDisabled()}
                      style={{ color: isFormDisabled() && 'rgba(0, 0, 0, 0.3)' }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <br />
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label id="health-label" className={classes.scriptLabel}>
                  How is your health (mental and physical) these days?
                </label>
              </Grid>
              <Grid item xs={12} sm={7} md={4} lg={4}>
                <FormControl fullWidth>
                  <Select
                    id="health"
                    value={form.health}
                    onChange={(event) => setForm({ ...form, health: event.target.value })}
                    disabled={isFormDisabled()}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="good">Good. No need for additional support.</MenuItem>
                    <MenuItem value="okay">Okay. No need for additional support.</MenuItem>
                    <MenuItem value="notgood">Not good. Need additional support.</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label id="housing-label" className={classes.scriptLabel}>
                  Are you experiencing any issues related to housing?
                </label>
              </Grid>
              <Grid item xs={12} sm={7} md={4} lg={4}>
                <FormControl fullWidth>
                  <Select
                    id="housing"
                    value={form.housing}
                    onChange={(event) => setForm({ ...form, housing: event.target.value })}
                    disabled={isFormDisabled()}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="no">No.</MenuItem>
                    <MenuItem value="yesnosupport">Yes, need support.</MenuItem>
                    <MenuItem value="yes">Yes, but does not need support.</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label id="transportation-label" className={classes.scriptLabel}>
                  Are you experiencing any issues related to transportation?
                </label>
              </Grid>
              <Grid item xs={12} sm={7} md={3} lg={4}>
                <FormControl fullWidth>
                  <Select
                    id="transportation"
                    value={form.transportation}
                    onChange={(event) => setForm({ ...form, transportation: event.target.value })}
                    disabled={isFormDisabled()}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="no">No.</MenuItem>
                    <MenuItem value="yesnosupport">Yes, need support.</MenuItem>
                    <MenuItem value="yes">Yes, but does not need support.</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={8} lg={7} className={classes.question}>
                <label id="financial-question" className={classes.scriptLabel}>
                  At graduation you were awarded Cowboy Money, scholarships, and incentives. Seems like you have accessed{' '}
                  <span className={classes.awardAmount}>${form.moneyAccessed}</span> and have{' '}
                  <span className={classes.awardAmount}>${form.moneyRemaining}</span> remaining. Are you aware of that?
                  <br />
                </label>
              </Grid>
              <Grid item xs={12} sm={11} md={4} lg={5}>
                <FormControl fullWidth>
                  <Select id="money" value={form.money} onChange={(event) => setForm({ ...form, money: event.target.value })}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="yesspent">Yes, all awards were spent or will be spent within the next 3 months.</MenuItem>
                    <MenuItem value="yesworking">Yes, I am working with my SFS to spend it.</MenuItem>
                    <MenuItem value="no">No, I didn't remember. Who do I need to talk to about it?</MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
              </Grid>
              {form.otherAwards && form.otherAwards.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginTop: 15,
                    width: '100%',
                  }}
                >
                  <Grid item xs={12} sm={7} md={7} lg={7} className={classes.question}>
                    <label id="financial-question" className={classes.scriptLabel}>
                      Are you aware you have additional awards available to you, including:
                      <br />
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={5} md={3} lg={4} className={classes.awardList}>
                    <ul style={{ marginLeft: -25 }}>
                      {form.otherAwards.map((award, idx) => (
                        <li>
                          {award.name} {award.type}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </div>
              )}
            </Grid>
            <hr style={{ borderTop: '2px solid ' + btLightBlueColor, margin: '20px 15px 0 0' }} />
            <Grid container align="left" className={classes.rowSpacer}>
              <Grid item xs={12} sm={12} md={11} lg={12}>
                <div className={classes.scriptLabel}>
                  Those are all the questions I have for you today. Thank you for taking the time to talk with me. Do you have any question for me? Is
                  there anything I did not ask about that you might want us to know?
                </div>
                <br />
                <div className={classes.scriptLabel}>
                  I want to let you know, or perhaps remind you, of our Check-in Survey. Watch for an email from us. This is not SPAM. In fact, we
                  value your opinion and your well-being so much that we offer some money to compensate you for the time you might take to complete
                  the survey. If you complete it, you will receive an Amazon gift card just for answering the questions. The answers you give us,
                  together with the answers from everyone else, help us understand the needs of our graduates and help us learn how to improve our
                  programs. It also provides us with useful information that we can then use to locate additional funding, so we do not need to charge
                  you for our services at Successful Futures.
                </div>
                <br />
                <div className={classes.scriptLabel}>
                  Remember that we are here for you. Please call <span className={classes.phone}>531.355.3060</span> or contact us at{' '}
                  <span className={classes.email}>
                    <a href="mailto:successfulfutures@boystown.org">SuccessfulFutures@boystown.org</a>
                  </span>
                  .
                </div>
                <hr style={{ borderTop: '2px solid ' + btLightBlueColor, margin: '20px 15px 0 0' }} />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {((contactMode === 'Phone' && showScript === 'yes' && clientHaveTime === 'yes') ||
          (contactMode === 'Phone' && showScript === 'no') ||
          contactMode === 'FocusMeeting' ||
          contactMode === 'Email' ||
          contactMode === 'Text' ||
          contactMode === 'SocialMedia') && (
          <Grid container align="left" className={classes.rowSpacer}>
            <Grid item xs={12} className={classes.rowSpacer}>
              {store.checkIn && (
                <>
                  <ContactResult
                    contactMode={contactMode}
                    classes={classes}
                    username={auth.user.result.first_name + ' ' + auth.user.result.last_name}
                    type={convertType(store.checkIn.type)}
                    attemptResult={attemptResult}
                    setAttemptResult={setAttemptResult}
                    finalAttempt={finalAttempt}
                    setFinalAttempt={setFinalAttempt}
                    attemptCount={attemptNumber}
                    canBeSuccessful={canBeSuccessful}
                    isEmail={contactMode === 'Email' ? true : false}
                    isSocialMedia={contactMode === 'SocialMedia' ? true : false}
                    showScript={showScript}
                    clientHaveTime={clientHaveTime}
                    handleResultAttemptResult={handleResultAttemptResult}
                  />
                  <ContactFinalAttempt
                    classes={classes}
                    finalAttempt={finalAttempt}
                    setFinalAttempt={setFinalAttempt}
                    attemptCount={attemptNumber}
                    attemptResult={attemptResult}
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}
        {attemptResult === 'VoiceMail' && finalAttempt === false && (
          <ContactVoicemailScript
            classes={classes}
            username={auth.user.result.first_name + ' ' + auth.user.result.last_name}
            type={convertType(store.checkIn.type)}
          />
        )}
        <Grid container direction="row" className={classes.rowSpacer}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="notes"
                minRows={5}
                maxRows={5}
                maxLength={5000}
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
                placeholder="Enter any additional notes here."
                className={classes.textAreaFont}
                disabled={isFormDisabled()}
                style={{ color: isFormDisabled() && 'rgba(0, 0, 0, 0.3)' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" align="left">
          <Grid item xs={false} sm={false} md={1} lg={3}></Grid>
          <Grid item xs={12} sm={12} md={10} lg={6}>
            {errorMsg && errorMsg.length > 0 && (
              <summary className={classes.error}>
                <ul>
                  {errorMsg.map((e, idx) => (
                    <li>{e}</li>
                  ))}
                </ul>
              </summary>
            )}
          </Grid>
          <Grid item xs={false} sm={false} md={1} lg={3}></Grid>
        </Grid>
        <Grid container direction="row" align="right">
          <Grid item xs={12} className={classes.buttonSpacer}>
            <div align="right">
              <>
                <Button
                  className={classes.floatingSaveButton}
                  variant="contained"
                  onClick={(event) => handleSaveReturn()}
                  style={{ margin: '0 30px 15px 0', width: '110px' }}
                >
                  Cancel
                </Button>
                {((contactMode === 'Phone' && showScript === 'yes' && clientHaveTime === 'yes') || contactMode === 'FocusMeeting') && (
                  <Tooltip title="Click to save the Proactive Check-In with partial information in order to return later." placement="top">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => handleWriteContact('Partial')}
                      style={{ margin: '0 30px 15px 0', width: '110px' }}
                      disabled={!canBeSuccessful}
                    >
                      Save
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Click to submit a successful Proactive Check-In." placement="top">
                  <Button
                    variant="contained"
                    onClick={(event) => handleWriteContact()}
                    style={{ backgroundColor: btLightGreenColor, margin: '0 0 15px 0', width: '110px' }}
                    disabled={!canBeSuccessful}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </>
            </div>
          </Grid>
        </Grid>
        <Dialog fullWidth={true} maxWidth={'lg'} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="Client Contact Information">
          <DialogTitle>Client Contact Information</DialogTitle>
          <DialogContent>
            {store.senior && (
              <ContactEdit
                contactInfo={contactInfo}
                setContactInfo={setContactInfo}
                classes={classes}
                senior={store.senior}
                specialistList={activeSpecialists}
                contactEditErrorMsg={contactEditErrorMsg}
                handleSaveContactInfo={handleSaveContactInfo}
                showOnlyAddress={false}
                handleDialogClose={handleDialogClose}
                store={store}
              />
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          ContentProps={{
            classes: {
              root: classes.warning,
            },
          }}
          open={showWarning}
          autoHideDuration={6000}
          onClose={handleClose}
          message={warning}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ></Snackbar>
      </React.Fragment>
    );
  })
);

export default ContactScript;
